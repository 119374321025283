import { ObTypography } from '@outbound/design-system';
import { ReactNode } from 'react';

export const SideBarSectionHeader = ({
  id,
  heading,
  editButton,
}: {
  id: string;
  heading: string;
  editButton?: ReactNode;
}) => {
  return (
    <div className='flex justify-between items-center'>
      <ObTypography
        id={id}
        variant='subtitle1'
        color='secondary'
      >
        {heading}
      </ObTypography>
      <div>{editButton}</div>
    </div>
  );
};
