import { lookupDomainForObject, parseObrn, toObrn } from '@otbnd/utils';

import {
  ClientDelta,
  ObrnDomain,
  ObrnObject,
  ServerDelta,
} from '@outbound/types';
import { RootStore } from './root-store';

export interface UnsyncedLocalChange {
  lastKnownServerValue: any;
  clientDelta: ClientDelta;
}

export abstract class BaseModel {
  static readonly paths = {
    //The ID of the object within the context of it's system
    id: '/id',
    //The complete OBRN for the object
    obrn: '/obrn',
    //The scope field from the obrn this will either be "system" or the UUID of the workspace the object is scoped to
    scope: '/scope',
  };
  protected _id: string;
  protected _obrn: string;
  protected _isObservable: boolean = false;
  readonly object: ObrnObject;
  readonly objectVersion: string;
  readonly objectDomain: ObrnDomain;
  protected _clientDeltas: Map<string, ClientDelta> = new Map();
  protected _rootStore: RootStore;
  /*
   * Tracks unsynced changes that have not been sent to the transport layer.
   * This map is used on "save()" to know what changes to enqueue to the transport layer
   * as well on as "discard()" (If Implemented) to revert the changes to the last known server value.
   *
   *  The key is the attribute path to the value that has changed.
   *
   * This map should never be modified directly. Always use the addUnsyncedLocalChange method.
   */
  protected _unsyncedLocalChanges: Map<string, UnsyncedLocalChange> = new Map();
  /**
   * This will either be the workspace ID or "system" for system level objects.
   */
  protected _scope: string;

  constructor(
    rootStore: RootStore,
    object: ObrnObject,
    objectVersion: string,
    id: string,
    /**
     * @deprecated This should be replaced with only the obrn field. This can be derived from the scope segment.
     * Also not all objects will have a workspace ID sometimes they will be system level objects where the scope is just 'system'.
     */
    scope: string,
    /**
     * Eventually this should be made required but for now we will leave it optional.
     * We expect the server to generate this but we are in the process of adoption.
     */
    obrn?: string
    //Thinking of adding data:any here and letting the model itself call the transformer vs needing to specify all the properties here.
  ) {
    this._rootStore = rootStore;
    this.object = object;
    this.objectVersion = objectVersion;
    this._id = id;
    this.objectDomain = lookupDomainForObject(object);
    //Since OBRN was passed use it
    if (obrn != null && obrn.trim() !== '') {
      this._obrn = obrn;
      this._scope = parseObrn(obrn).scope;
    } else {
      /**
       * If the obrn is not provided we will generate it.
       * Ideally this should be generated by the server so that the local path ID is correct.
       * This will fail for nested object since we are taking the the id only. This will work find for top level objects.
       * This is a temporary solution until the server is generating the obrn for all the resources.
       */
      this._scope = scope;
      this._obrn = toObrn({
        objectType: object,
        localPathId: this._id,
        workspaceId: this._scope,
      });
    }
  }

  abstract applyPatch(patch: Array<ServerDelta>): void;

  /**
   * A JSON representation of the model.
   * All Attributes must start with a "/" to differentiate them from map keys.
   * The diff / patch logic depends on this convention.
   */
  toJson(): Record<string, any> {
    return {
      [BaseModel.paths.id]: this._id,
      [BaseModel.paths.obrn]: this._obrn,
      [BaseModel.paths.scope]: this._scope,
    };
  }

  /**
   * This method should be implemented by each model.
   * This method will be called by the public makeObservable method.
   */
  protected abstract makeObservableInternal(): void;

  /**
   * Public Method to make the model observable.
   * The intent here is to only make a model observable once it has been called by a user.
   * Since we pre-load models from the server, we don't want to make them observable until they are actually used.
   *
   * This may become unnecessary if we change the bootstrapping process to store data to disk vs memory.
   */
  public makeObservable(): void {
    /**
     * Mobx will throw an error if we try to make an observable object observable again.
     * so we track if the object is already observable and return if it is.
     */
    if (!this._isObservable) {
      this._isObservable = true;
      /**
       * This method should be implemented by each model.
       * It calls the makeObservable method from mobx to make the object observable.
       */
      this.makeObservableInternal();
    }
  }

  //Allows the Model to Create a Delta that can be enqueued to the transport layer.
  protected createDelta(value: any, path: any, op: 'replace'): ClientDelta {
    return {
      id: this._id,
      obrn: this.obrn,
      clientId: this._rootStore.clientId,
      object: this.object,
      clientUpdateId: crypto.randomUUID(),
      clientTimestamp: new Date().toISOString(),
      objectDomain: this.objectDomain,
      objectSchemaVersion: this.objectVersion,
      // Implementation Provided Fields
      op,
      path,
      value,
    };
  }

  /**
   * Add a client change to the model to the internal map of unsynced changes.
   * This map represents all of the changes available to be enqueued to the transport layer when "save" is next called.
   *
   * This method should only be called for non-realtime attributes where the user must call "save" to persist the change.
   * This method should never be called for non-user editable (read-only) attributes such as id, obrn, etc.
   *
   * @param nextAttributeLocalValue The latest (local) value for the path
   * @param currentAttributeLocalValue  The current (local) value for the path
   * @param attributePath The path to the value that has changed (Same Path as the JSON representation)
   */
  protected addUnsyncedLocalChange(
    nextAttributeLocalValue: any,
    currentAttributeLocalValue: any,
    attributePath: any
  ): void {
    const previousUnsyncedChange =
      this._unsyncedLocalChanges.get(attributePath);
    const clientDelta = this.createDelta(
      nextAttributeLocalValue,
      attributePath,
      'replace'
    );

    /**
     * If "discard()" is implemented for a model, the lastKnownServerValue will be used to revert the attribute to the last known server value.
     */
    this._unsyncedLocalChanges.set(attributePath, {
      lastKnownServerValue:
        previousUnsyncedChange != null
          ? previousUnsyncedChange.lastKnownServerValue
          : currentAttributeLocalValue,
      clientDelta,
    });
  }

  public getSnapshotOfUnsyncedLocalChanges(): Map<string, UnsyncedLocalChange> {
    //Prevent the caller from modifying the internal map.
    return new Map(
      [...this._unsyncedLocalChanges].map(([k, v]) => [k, { ...v }])
    );
  }

  protected shouldDeltaBeApplied(
    clientDelta: ClientDelta | undefined,
    serverDelta: ServerDelta
  ): boolean {
    if (clientDelta == null) {
      return true;
    } else if (serverDelta.serverTimestamp > clientDelta.clientTimestamp) {
      return true;
    } else if (serverDelta.serverTimestamp === clientDelta.clientTimestamp) {
      //Deterministic tie breaker
      if (clientDelta.clientId > serverDelta.clientId) {
        return true;
      }
    }
    return false;
  }

  get id(): string {
    return this._id;
  }

  get obrn(): string {
    return this._obrn;
  }

  get workspaceId(): string {
    return this._scope;
  }
}
