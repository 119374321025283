import { ReactNode } from 'react';
import { ObAvatar } from '../../avatars';
import { ObBrand } from '../../components/elements/ob-brand/ob-brand';
import { ObDivider } from '../../components/elements/ob-divider/ob-divider';
import { ObTooltip } from '../../components/elements/ob-tooltip/ob-tooltip';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObReadMoreLess } from '../../components/molecules/ob-read-more-less/ob-read-more-less';
import { SettingCardRowWithButton } from '../../components/organisms/ob-setting-card-with-button/setting-card-row-with-button';
import { BaseSection } from '../../detail-page-sections/base-section';
import { NoContentMessage } from '../../placeholders';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';
import { ReviewCallToActionMenu } from './review-cta-menu';
import { ReviewAttributeProps, ReviewCtaProps } from './review.types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ReviewDetailAttributeProps extends ReviewAttributeProps {
  /**
   * Black Box Node for Related Products and Services
   */
  relatedProductsAndServicesNode: ReactNode;

  hasRelatedProductsAndServices: boolean;
}
interface ReviewDetailLocalizationProps {
  loc__sourceLabel: string;
  /**
   * Localization for the Review Text Section
   */
  loc__ReviewTextSectionHeading: string;
  loc__ReviewTextSectionSubheading: string;
  loc__NoReviewTextProvided: string;

  /**
   * Localization for the Related Products and Services Section
   */
  loc__RelatedProductsAndServicesHeading: string;
  loc__RelatedProductsAndServicesSubheading: string;

  /**
   * Content displayed when there are no related products or services
   */
  loc__RelatedProductsAndServicesNoRelatedObjectsHeading: string;
  loc__RelatedProductsAndServicesNoRelatedObjectsDescription: string;
  loc__AddRelatedProductOrServiceCtaLabel: string;

  /**
   * Localization for the Settings Section of the Detail Page
   */
  loc__SettingSectionHeading: string;
  loc__SettingSectionSubheading: string;
  /**
   * Localization for the Delete Review Call to Action
   */
  loc__DeleteReviewImpactHeading: string;
  loc__DeleteReviewImpactDescription: string;

  loc_sourceExplanation: string;
}
export interface ReviewDetailProps
  extends ReviewCtaProps,
    ReviewDetailAttributeProps,
    ReviewDetailLocalizationProps {}
export const ReviewDetail = ({
  authorFullName,
  rating,
  source,
  reviewText,
  onEditCallback,
  onDeleteCallback,
  loc__ReviewTextSectionHeading,
  loc__ReviewTextSectionSubheading,
  loc__NoReviewTextProvided,
  loc__SettingSectionHeading,
  loc__SettingSectionSubheading,
  loc__DeleteReviewImpactHeading,
  loc__DeleteReviewImpactDescription,
  loc__editReviewCtaLabel,
  loc__editReviewCtaAriaLabel,
  loc__deleteReviewCtaLabel,
  loc__deleteReviewCtaAriaLabel,
  loc__sourceLabel,
  loc_sourceExplanation,
  relatedProductsAndServicesNode,
  hasRelatedProductsAndServices,
  loc__AddRelatedProductOrServiceCtaLabel,
  loc__RelatedProductsAndServicesHeading,
  loc__RelatedProductsAndServicesSubheading,
  loc__RelatedProductsAndServicesNoRelatedObjectsHeading,
  loc__RelatedProductsAndServicesNoRelatedObjectsDescription,
}: ReviewDetailProps) => {
  return (
    <div className='flex flex-col gap-16'>
      <ReviewDetailHero
        authorFullName={authorFullName}
        rating={rating}
        source={source}
        onDeleteCallback={onDeleteCallback}
        onEditCallback={onEditCallback}
        loc__editReviewCtaLabel={loc__editReviewCtaLabel}
        loc__editReviewCtaAriaLabel={loc__editReviewCtaAriaLabel}
        loc__deleteReviewCtaLabel={loc__deleteReviewCtaLabel}
        loc__deleteReviewCtaAriaLabel={loc__deleteReviewCtaAriaLabel}
        loc__sourceLabel={loc__sourceLabel}
        loc_sourceExplanation={loc_sourceExplanation}
      />
      <ReviewTextSection
        reviewText={reviewText}
        loc__ReviewTextSectionHeading={loc__ReviewTextSectionHeading}
        loc__ReviewTextSectionSubheading={loc__ReviewTextSectionSubheading}
        loc__NoReviewTextProvided={loc__NoReviewTextProvided}
      />
      <RelatedProductsAndServicesSection
        onEditCallback={onEditCallback}
        hasRelatedProductsAndServices={hasRelatedProductsAndServices}
        relatedProductsAndServicesNode={relatedProductsAndServicesNode}
        loc__AddRelatedProductOrServiceCtaLabel={
          loc__AddRelatedProductOrServiceCtaLabel
        }
        loc__RelatedProductsAndServicesHeading={
          loc__RelatedProductsAndServicesHeading
        }
        loc__RelatedProductsAndServicesSubheading={
          loc__RelatedProductsAndServicesSubheading
        }
        loc__RelatedProductsAndServicesNoRelatedObjectsHeading={
          loc__RelatedProductsAndServicesNoRelatedObjectsHeading
        }
        loc__RelatedProductsAndServicesNoRelatedObjectsDescription={
          loc__RelatedProductsAndServicesNoRelatedObjectsDescription
        }
      />
      <SettingsSection
        onDeleteCallback={onDeleteCallback}
        loc__deleteReviewCtaLabel={loc__deleteReviewCtaLabel}
        loc__DeleteReviewImpactDescription={loc__DeleteReviewImpactDescription}
        loc__DeleteReviewImpactHeading={loc__DeleteReviewImpactHeading}
        loc__SettingSectionHeading={loc__SettingSectionHeading}
        loc__SettingSectionSubheading={loc__SettingSectionSubheading}
      />
    </div>
  );
};

/**
 * Review Detail Hero Section
 * Renders the Hero Section of the Review Detail Page. Intended to visually match the Review Grid Card so we do not
 * shape-shift the UI when navigating to the Detail Page. The user should feel like the detail is a natural expansion of the card.
 * @param param0
 * @returns
 */
export const ReviewDetailHero = ({
  authorFullName,
  rating,
  source,
  onDeleteCallback,
  onEditCallback,
  loc__editReviewCtaLabel,
  loc__editReviewCtaAriaLabel,
  loc__deleteReviewCtaLabel,
  loc__deleteReviewCtaAriaLabel,
  loc__sourceLabel,
  loc_sourceExplanation,
}: Pick<
  ReviewDetailProps,
  | 'authorFullName'
  | 'rating'
  | 'source'
  | 'onDeleteCallback'
  | 'onEditCallback'
  | 'loc__editReviewCtaLabel'
  | 'loc__editReviewCtaAriaLabel'
  | 'loc__deleteReviewCtaLabel'
  | 'loc__deleteReviewCtaAriaLabel'
  | 'loc__sourceLabel'
  | 'loc_sourceExplanation'
>) => {
  return (
    <div className='flex flex-col gap-3 justify-between items-start self-stretch @container/hero overflow-hidden min-w-0 max-w-[100%]'>
      <div className='flex flex-row  justify-between self-stretch'>
        <div className='flex flex-row gap-2 justify-between items-center self-stretch overflow-hidden max-w-[100%] min-w-0'>
          <button
            className='flex flex-row gap-3 justify-start items-center self-stretch group overflow-hidden min-w-0 max-w-[100%]'
            aria-label={loc__editReviewCtaAriaLabel ?? loc__editReviewCtaLabel}
            onClick={onEditCallback}
          >
            <div className='hidden @lg/hero:block '>
              <ObAvatar
                shape='circle'
                size={'x-large'}
                fallbackText={authorFullName}
              />
            </div>
            <div className='block @lg/hero:hidden'>
              <ObAvatar
                shape='circle'
                size={'large'}
                fallbackText={authorFullName}
              />
            </div>
            <div className='overflow-hidden max-w-[100%] min-w-0'>
              <ObTypography
                variant='body1'
                className='line-clamp-1 text-left'
              >
                {'⭐️'.repeat(rating)}
              </ObTypography>
              <div className='flex @lg/hero:hidden flex-row gap-2 justify-center items-center overflow-hidden max-w-[100%] min-w-0'>
                <ObTypography
                  variant='h3'
                  as='h1'
                  truncate={true}
                  className='line-clamp-1 pb-1 text-left block'
                >
                  {authorFullName}
                </ObTypography>
                <ObIcon
                  classNames='opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                  icon='pencil02'
                  size={'x-small'}
                />
              </div>
              <div className='hidden @lg/hero:flex flex-row gap-2 justify-center items-center overflow-hidden max-w-[100%] min-w-0 '>
                <ObTypography
                  variant='h1'
                  as='h1'
                  truncate={true}
                  className='line-clamp-1 pb-1 text-left  block'
                >
                  {authorFullName}
                </ObTypography>
                <ObIcon
                  classNames='opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                  icon='pencil02'
                  size={'small'}
                />
              </div>
            </div>
          </button>
        </div>
        <div className='flex justify-center items-center flex-shrink-0 flex-col gap-1'>
          <ReviewCallToActionMenu
            onDeleteCallback={onDeleteCallback}
            onEditCallback={onEditCallback}
            loc__editReviewCtaLabel={loc__editReviewCtaLabel}
            loc__editReviewCtaAriaLabel={loc__editReviewCtaAriaLabel}
            loc__deleteReviewCtaLabel={loc__deleteReviewCtaLabel}
            loc__deleteReviewCtaAriaLabel={loc__deleteReviewCtaAriaLabel}
          />
        </div>
      </div>
      <ObDivider />
      <ReviewSourceBadge
        source={source}
        loc__sourceLabel={loc__sourceLabel}
        loc_sourceExplanation={loc_sourceExplanation}
      />
    </div>
  );
};
export const ReviewSourceBadge = ({
  source,
  loc__sourceLabel,
  loc_sourceExplanation,
}: Pick<
  ReviewDetailProps,
  'loc_sourceExplanation' | 'loc__sourceLabel' | 'source'
>) => {
  return (
    <div className='flex flex-col gap-0.5'>
      <ObTypography
        variant='subtitle3'
        color='tertiary'
      >
        {loc__sourceLabel}
      </ObTypography>
      <div className='flex flex-shrink'>
        <ObTooltip tooltipContent={loc_sourceExplanation}>
          <SourceBadge source={source} />
        </ObTooltip>
      </div>
    </div>
  );
};
export const SourceBadge = ({ source }: Pick<ReviewDetailProps, 'source'>) => {
  return (
    <div className='flex flex-row gap-2 items-center rounded-md border dark:border-dark/border/default/normal bg-dark/background/surface p-1'>
      <div>
        <ObBrand
          variant='icon'
          size='xSmall'
          color='full-color'
        />
      </div>

      <ObTypography
        variant='body3'
        color='secondary'
        className='capitalize'
      >
        {source}
      </ObTypography>
    </div>
  );
};
/**
 * Renders the Review Text Section of the Detail Page
 */
export const ReviewTextSection = ({
  reviewText,
  loc__ReviewTextSectionHeading,
  loc__ReviewTextSectionSubheading,
  loc__NoReviewTextProvided,
}: Pick<
  ReviewDetailProps,
  | 'reviewText'
  | 'loc__ReviewTextSectionHeading'
  | 'loc__ReviewTextSectionSubheading'
  | 'loc__NoReviewTextProvided'
>) => {
  return (
    <BaseSection
      heading={loc__ReviewTextSectionHeading}
      subheading={loc__ReviewTextSectionSubheading}
    >
      {reviewText && (
        <ObReadMoreLess
          linesToShowWhenCollapsed={5}
          lineHeight={24}
          className='max-w-[100%] min-w-0'
        >
          <ObTypography
            variant='blockquote'
            color='primary'
          >
            {reviewText?.trim()}
          </ObTypography>
        </ObReadMoreLess>
      )}
      {!reviewText && (
        <ObTypography
          variant='subtitle2'
          color='tertiary'
          className='italic'
        >
          {loc__NoReviewTextProvided}
        </ObTypography>
      )}
    </BaseSection>
  );
};

/**
 * Renders the Settings Section of the Detail Page
 * @returns
 */
export const SettingsSection = ({
  onDeleteCallback,
  loc__SettingSectionHeading,
  loc__SettingSectionSubheading,
  loc__DeleteReviewImpactDescription,
  loc__DeleteReviewImpactHeading,
  loc__deleteReviewCtaLabel,
}: Pick<
  ReviewDetailProps,
  | 'onDeleteCallback'
  | 'loc__DeleteReviewImpactDescription'
  | 'loc__DeleteReviewImpactHeading'
  | 'loc__SettingSectionHeading'
  | 'loc__SettingSectionSubheading'
  | 'loc__deleteReviewCtaLabel'
>) => {
  return (
    <BaseSection
      heading={loc__SettingSectionHeading}
      subheading={loc__SettingSectionSubheading}
    >
      <div className='flex flex-col gap-4'>
        {onDeleteCallback && (
          <SettingCardRowWithButton
            title={loc__DeleteReviewImpactHeading}
            body={loc__DeleteReviewImpactDescription}
            buttonLabel={loc__deleteReviewCtaLabel}
            handleButtonClickCallback={onDeleteCallback}
            color={'error'}
            loading={false}
          />
        )}
      </div>
    </BaseSection>
  );
};

/**
 * Renders the related Products and Services Section of the Detail Page
 * @returns
 */
export const RelatedProductsAndServicesSection = ({
  onEditCallback,
  relatedProductsAndServicesNode,
  hasRelatedProductsAndServices,
  loc__AddRelatedProductOrServiceCtaLabel,
  loc__RelatedProductsAndServicesHeading,
  loc__RelatedProductsAndServicesSubheading,
  loc__RelatedProductsAndServicesNoRelatedObjectsHeading,
  loc__RelatedProductsAndServicesNoRelatedObjectsDescription,
}: Pick<
  ReviewDetailProps,
  | 'relatedProductsAndServicesNode'
  | 'onEditCallback'
  | 'loc__AddRelatedProductOrServiceCtaLabel'
  | 'loc__RelatedProductsAndServicesHeading'
  | 'loc__RelatedProductsAndServicesSubheading'
  | 'loc__RelatedProductsAndServicesNoRelatedObjectsHeading'
  | 'loc__RelatedProductsAndServicesNoRelatedObjectsDescription'
  | 'hasRelatedProductsAndServices'
>) => {
  return (
    <BaseSection
      heading={loc__RelatedProductsAndServicesHeading}
      subheading={loc__RelatedProductsAndServicesSubheading}
      showPlaceholder={!hasRelatedProductsAndServices}
      placeholderNode={
        <NoContentMessage
          message={loc__RelatedProductsAndServicesNoRelatedObjectsDescription}
          heading={loc__RelatedProductsAndServicesNoRelatedObjectsHeading}
          actionLabel={loc__AddRelatedProductOrServiceCtaLabel}
          actionOnClick={onEditCallback}
          icon={'playbooks'}
          actionLevel='outline'
        />
      }
    >
      {relatedProductsAndServicesNode}
    </BaseSection>
  );
};
