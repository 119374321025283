import { ObButton, ObDivider } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { BackToLink } from 'src/navigation/back-to-link';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import { CONTENT_HEADER_HEIGHT_PX } from '../../dimensions';

interface ContentHeaderProps {
  onToggleLeadDetailAsideOnDesktop: () => void;
  onOpenLeadDetailAsideDrawerOnMobile: () => void;
}

export const ContentHeader = observer(
  ({
    onToggleLeadDetailAsideOnDesktop,
    onOpenLeadDetailAsideDrawerOnMobile,
  }: ContentHeaderProps) => {
    return (
      <>
        <div
          className='px-3 flex justify-between items-center flex-none'
          style={{
            height: `${CONTENT_HEADER_HEIGHT_PX}px`,
          }}
        >
          <BackToLink
            defaultNavigateTo={'../..'}
            defaultNavigateToLabel={t.BACK_TO_ALL_LEADS_LABEL}
          />
          <LeadDetailAsideVisibilityControl
            onOpenLeadDetailAsideDrawerOnMobile={
              onOpenLeadDetailAsideDrawerOnMobile
            }
            onToggleLeadDetailAsideOnDesktop={onToggleLeadDetailAsideOnDesktop}
          />
        </div>
        <ObDivider />
      </>
    );
  }
);

const LeadDetailAsideVisibilityControl = (props: ContentHeaderProps) => {
  return (
    <>
      <DesktopLeadDetailToggleAsideButton {...props} />
      <MobileLeadDetailOpenDrawerButton {...props} />
    </>
  );
};

const DesktopLeadDetailToggleAsideButton = ({
  onToggleLeadDetailAsideOnDesktop,
}: ContentHeaderProps) => {
  return (
    <div className='hidden lg:block'>
      <LeadDetailAsideButton
        onClickCallback={onToggleLeadDetailAsideOnDesktop}
      />
    </div>
  );
};

const MobileLeadDetailOpenDrawerButton = ({
  onOpenLeadDetailAsideDrawerOnMobile,
}: ContentHeaderProps) => {
  return (
    <div className='block lg:hidden'>
      <LeadDetailAsideButton
        onClickCallback={onOpenLeadDetailAsideDrawerOnMobile}
      />
    </div>
  );
};

const LeadDetailAsideButton = ({
  onClickCallback,
}: {
  onClickCallback: () => void;
}) => {
  return (
    <ObButton
      size='small'
      variant='ghost'
      buttonType='icon'
      iconLeft='layoutRight'
      onClick={onClickCallback}
    />
  );
};
