import { useSensors } from '@dnd-kit/core';
import { useKeyboardSensor } from './keyboard-sensor';
import { useMouseSensor } from './mouse-sensor';
import { useTouchSensor } from './touch-sensor';

export const useDragInteractionSensors = () => {
  const keyboardSensor = useKeyboardSensor();
  const mouseSensor = useMouseSensor();
  const touchSensor = useTouchSensor();
  return useSensors(keyboardSensor, mouseSensor, touchSensor);
};
