import { RootStore } from '../../../root-store';
import { AbstractLeadEvent, LeadEventDto } from './abstract-lead-event';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UnknownLeadEventContext {
  // Unknown context
}

export class LeadEventUnknown extends AbstractLeadEvent<UnknownLeadEventContext> {
  protected constructor(
    rootStore: RootStore,
    leadEventData: LeadEventDto,
    context: UnknownLeadEventContext
  ) {
    super(rootStore, leadEventData, context);
  }

  static reconstitute(
    rootStore: RootStore,
    data: LeadEventDto
  ): LeadEventUnknown {
    const UNKNOWN_CONTEXT = {};
    return new LeadEventUnknown(rootStore, data, UNKNOWN_CONTEXT);
  }
}
