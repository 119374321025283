import { cx } from 'class-variance-authority';
import { Fragment } from 'react';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import {
  BaseEventMajor,
  BaseEventMajorProps,
} from '../common/base-event-major';
import { CreatedFromFormSubmissionEventLocalization } from './created-from-form-submission-localization.type';

export interface Props
  extends Omit<
    BaseEventMajorProps,
    | 'actorName'
    | 'actorImageUrl'
    | 'eventIcon'
    | 'actorType'
    | 'children'
    | 'title'
  > {
  formSubmission: FormSubmissionFormattedForDisplay;
  localization: CreatedFromFormSubmissionEventLocalization;
}

export type FormSubmissionFormattedForDisplay = {
  values: Array<{
    value: string;
    label: string;
    id: string;
  }>;
};

export const CreatedFromFormSubmissionEvent = ({
  formSubmission,
  localization,
  ...baseEventMajorProps
}: Props) => {
  return (
    <BaseEventMajor
      {...baseEventMajorProps}
      title='Lead Created from Form Submission'
      actorType='SYSTEM'
      eventIcon='formLead'
      actorImageUrl=''
      actorName='System'
    >
      <FormSubmissionDetails
        localization={localization}
        formSubmission={formSubmission}
      />
    </BaseEventMajor>
  );
};

const FormSubmissionDetails = ({
  formSubmission,
  localization,
}: {
  formSubmission: FormSubmissionFormattedForDisplay;
  localization: CreatedFromFormSubmissionEventLocalization;
}) => {
  return (
    <div className='mt-2'>
      <ObTypography
        variant='subtitle1'
        color='primary'
      >
        {localization.formSubmissionLabel}
      </ObTypography>
      <DescriptionList>
        {formSubmission.values.map((field) => (
          <Fragment key={field.id}>
            <DescriptionTerm>{field.label}</DescriptionTerm>
            <DescriptionDetails>{field.value}</DescriptionDetails>
          </Fragment>
        ))}
      </DescriptionList>
    </div>
  );
};

export function DescriptionList({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'dl'>) {
  return (
    <div className='@container/dl'>
      <dl
        {...props}
        className={cx(
          className,
          'grid grid-cols-1 text-base/6 @md/dl:grid-cols-2 @md/dl:text-sm/6 '
        )}
      />
    </div>
  );
}

export function DescriptionTerm({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'dt'>) {
  return (
    <dt
      {...props}
      className={cx(
        className,
        //Pulled from Catalyst. Will come back and update to OB Styles when I have time
        'col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t sm:border-zinc-950/5 sm:py-3 dark:border-white/5 dark:text-zinc-400 sm:dark:border-white/5'
      )}
    />
  );
}

export function DescriptionDetails({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'dd'>) {
  return (
    <dd
      {...props}
      className={cx(
        className,
        //Pulled from Catalyst. Will come back and update to OB Styles when I have time
        'pt-1 pb-3 text-zinc-950 sm:border-t sm:border-zinc-950/5 sm:py-3 sm:nth-2:border-none dark:text-white dark:sm:border-white/5'
      )}
    />
  );
}
