import { generateServingStateReasonDescription } from '@otbnd/utils';
import { ObTooltip } from '../../../components/elements/ob-tooltip/ob-tooltip';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { timeAgoFromIsoString } from '../../../utilities/datetime-utilities';
import { CampaignCardProps } from '../campaign-card';

export const ServingStateBadge = ({
  servingState,
  servingStateReason,
  servingStateLastCalculatedAtTimestamp,
}: Pick<
  CampaignCardProps,
  | 'servingState'
  | 'servingStateReason'
  | 'servingStateLastCalculatedAtTimestamp'
>) => {
  return (
    <div className='flex flex-col'>
      <ObTypography
        variant='subtitle3'
        color='tertiary'
      >
        Campaign Status
      </ObTypography>
      <div className='flex flex-shrink'>
        <ObTooltip
          tooltipContent={`Last checked ${timeAgoFromIsoString(
            servingStateLastCalculatedAtTimestamp
          )}`}
        >
          {servingState === 'SERVING' && (
            <div className='flex flex-row border border-dark/border/default/normal rounded-md justify-center items-center'>
              <div className=' bg-dark/background/subtle-positive rounded-md px-2 py-1 flex-1 flex'>
                <ObTypography
                  variant='subtitle3'
                  color='positive'
                  as={'span'}
                  className='uppercase text-center'
                >
                  Ads Running
                </ObTypography>
              </div>
            </div>
          )}

          {servingState === 'PARTIALLY_SERVING' && (
            <div className='flex flex-row border border-dark/border/default/normal  rounded-md justify-center '>
              <div className='pl-2 p-1 bg-dark/background/subtle-informative rounded-l-md flex-1 self-stretch items-center justify-center flex'>
                <ObTypography
                  variant='subtitle3'
                  color='warning'
                  as={'span'}
                  className='uppercase text-center whitespace-nowrap'
                >
                  Some Ads Running
                </ObTypography>
              </div>
              <div className='border-r border-dark/border/default/normal'></div>
              <div className='p-1  rounded-r-md pr-2'>
                <ObTypography
                  variant='subtitle3'
                  color='tertiary'
                >
                  {generateServingStateReasonDescription(servingStateReason)}
                </ObTypography>
              </div>
            </div>
          )}
          {servingState === 'UNKNOWN' &&
            servingStateReason !== 'SYNCING_WITH_AD_CHANNEL' && (
              <div className='flex flex-row border border-dark/border/default/normal  rounded-md justify-center '>
                <div className='pl-2 p-1 bg-dark/background/subtle-warning rounded-l-md flex-1 self-stretch items-center justify-center flex'>
                  <ObTypography
                    variant='subtitle3'
                    color='warning'
                    as={'span'}
                    className='uppercase text-center whitespace-nowrap'
                  >
                    Unknown
                  </ObTypography>
                </div>
                <div className='border-r border-dark/border/default/normal'></div>
                <div className='p-1  rounded-r-md pr-2'>
                  <ObTypography
                    variant='subtitle3'
                    color='tertiary'
                  >
                    {generateServingStateReasonDescription(servingStateReason)}
                  </ObTypography>
                </div>
              </div>
            )}

          {servingState === 'UNKNOWN' &&
            servingStateReason === 'SYNCING_WITH_AD_CHANNEL' && (
              <div className='flex flex-row border border-dark/border/default/normal  rounded-md justify-center '>
                <div className='pl-2 p-1 bg-dark/background/subtle-warning rounded-l-md flex-1 self-stretch items-center justify-center flex gap-1'>
                  <ObTypography
                    variant='subtitle3'
                    color='warning'
                    as={'span'}
                    className='uppercase text-center whitespace-nowrap'
                  >
                    Syncing
                  </ObTypography>
                  <ObIcon
                    icon='RefreshCw05'
                    color='warning'
                    size='x-small'
                    classNames='animate-spin'
                  />
                </div>
                <div className='border-r border-dark/border/default/normal'></div>
                <div className='p-1  rounded-r-md pr-2'>
                  <ObTypography
                    variant='subtitle3'
                    color='tertiary'
                  >
                    Verifying Status with Ad Channel
                  </ObTypography>
                </div>
              </div>
            )}

          {servingState === 'NOT_SERVING' && (
            <div className='flex flex-row border border-dark/border/default/normal  rounded-md justify-center '>
              <div className='pl-2 p-1 bg-dark/background/subtle-warning rounded-l-md flex-1 self-stretch items-center justify-center flex'>
                <ObTypography
                  variant='subtitle3'
                  color='warning'
                  as={'span'}
                  className='uppercase text-center whitespace-nowrap'
                >
                  Ads Not Running
                </ObTypography>
              </div>
              <div className='border-r border-dark/border/default/normal'></div>
              <div className='p-1  rounded-r-md pr-2'>
                <ObTypography
                  variant='subtitle3'
                  color='tertiary'
                >
                  {generateServingStateReasonDescription(servingStateReason)}
                </ObTypography>
              </div>
            </div>
          )}
        </ObTooltip>
      </div>
    </div>
  );
};
