import {
  ObTypography,
  ReviewDetail,
  useDialogService,
} from '@outbound/design-system';
import { ConfirmationDialogProps } from '@outbound/design-system/src/components/services/dialog-service-provider/dialog-service-provider';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import { BackToLink } from 'src/navigation/back-to-link';
import { ServiceCardObserver } from 'src/object-card/service/service-card-observer';
import { Service } from 'src/state/mobx-experiment/business-context/service/service';
import { useRootStore } from 'src/state/mobx-experiment/use-root-store';
import { FullPageErrorMessage } from '../../campaigns/components/full-page-error-message';
import { useCreateEditReviewDrawer } from '../create-edit-drawer/use-review-create-edit-drawer';
import t from '../reviews.i18n.json';

const confirmDeleteModal: ConfirmationDialogProps = {
  title: t.DELETE_REVIEW_DIALOG.HEADING,
  body: t.DELETE_REVIEW_DIALOG.DESCRIPTION,
  affirmativeActionButtonLabel: t.DELETE_REVIEW_DIALOG.CONFIRM,
  dismissiveActionButtonLabel: t.DELETE_REVIEW_DIALOG.CANCEL,
};

const ReviewNotFound = () => {
  const { navigateToReviewList } = useAppNavigation();

  return (
    <FullPageErrorMessage
      heading={t.REVIEW_NOT_FOUND.HEADING}
      message={t.REVIEW_NOT_FOUND.DESCRIPTION}
      actionLabel={t.REVIEW_NOT_FOUND.CTA}
      icon='squaresPlus'
      actionOnClick={() => {
        navigateToReviewList();
      }}
    />
  );
};

export const ReviewDetailPage = observer(() => {
  const { reviewId, workspaceSlug } = useParams<{
    reviewId: string;
    workspaceSlug: string;
  }>();
  const { reviews } = useRootStore();
  const { navigateToReviewList } = useAppNavigation();
  const { openDrawerInEditMode } = useCreateEditReviewDrawer(workspaceSlug!);
  const { openConfirmation } = useDialogService();

  if (!reviewId) {
    return <ReviewNotFound />;
  }
  const review = reviews.getById(reviewId);

  if (review == null) {
    return <ReviewNotFound />;
  }

  return (
    <VerticalScrollableContent>
      <div className='max-w-screen-lg w-full m-auto flex flex-col gap-2'>
        <div className='flex flex-col gap-12 p-6 h-full flex-1'>
          <BackToLink
            defaultNavigateTo={'../..'}
            defaultNavigateToLabel={t.BACK_TO_ALL_REVIEWS_LABEL}
          />
          <ReviewDetail
            reviewText={review.textPlain}
            loc__editReviewCtaLabel={t.REVIEW_CTA.EDIT}
            onEditCallback={() => openDrawerInEditMode(review)}
            loc__deleteReviewCtaLabel={t.REVIEW_CTA.DELETE}
            onDeleteCallback={() => {
              openConfirmation(confirmDeleteModal)
                .then(() => {
                  navigateToReviewList();
                  review.delete();
                })
                .catch(() => {
                  // No action needed
                });
            }}
            authorFullName={review.authorFullName}
            rating={review.rating}
            source={review.source}
            loc_sourceExplanation={t.REVIEW_SOURCES.OUTBOUND_EXPLANATION}
            obrn={review.obrn}
            loc__sourceLabel={t.REVIEW_SOURCE_LABEL}
            loc__ReviewTextSectionHeading={
              t.REVIEW_DETAIL_SECTIONS.REVIEW_TEXT_HEADING
            }
            loc__ReviewTextSectionSubheading={
              t.REVIEW_DETAIL_SECTIONS.REVIEW_TEXT_SUB_HEADING
            }
            loc__NoReviewTextProvided={t.RATING_ONLY_REVIEW_TEXT}
            loc__SettingSectionHeading={
              t.REVIEW_DETAIL_SECTIONS.REVIEW_SETTING_HEADING
            }
            loc__SettingSectionSubheading={
              t.REVIEW_DETAIL_SECTIONS.REVIEW_SETTING_SUB_HEADING
            }
            loc__DeleteReviewImpactHeading={t.REVIEW_CTA.DELETE_IMPACT_HEADING}
            loc__DeleteReviewImpactDescription={
              t.REVIEW_CTA.DELETE_IMPACT_DESCRIPTION
            }
            hasRelatedProductsAndServices={
              review.relatedProductsAndServicesObjects.length > 0
            }
            relatedProductsAndServicesNode={
              <RelatedProductsAndServices
                relatedProductsAndServices={
                  review.relatedProductsAndServicesObjects
                }
              />
            }
            loc__RelatedProductsAndServicesHeading={
              t.REVIEW_DETAIL_SECTIONS.RELATED_PRODUCTS_AND_SERVICES_HEADING
            }
            loc__RelatedProductsAndServicesSubheading={
              t.REVIEW_DETAIL_SECTIONS.RELATED_PRODUCTS_AND_SERVICES_SUB_HEADING
            }
            loc__RelatedProductsAndServicesNoRelatedObjectsHeading={
              t.GENERAL_REVIEW.HEADING
            }
            loc__RelatedProductsAndServicesNoRelatedObjectsDescription={
              t.GENERAL_REVIEW.DESCRIPTION
            }
            loc__AddRelatedProductOrServiceCtaLabel={
              t.GENERAL_REVIEW.ADD_RELATED_PRODUCT_OR_SERVICE_CTA
            }
          />
          <div
            data-spacer
            className='min-h-[40px]'
          ></div>
        </div>
      </div>
    </VerticalScrollableContent>
  );
});

const RelatedProductsAndServices = ({
  relatedProductsAndServices,
}: {
  relatedProductsAndServices: Array<Service>;
}) => {
  return (
    <div className='@container/product-and-service-section flex flex-1 self-stretch'>
      <div className='grid grid-cols-1 gap-6 @2xl/product-and-service-section:grid-cols-2 @4xl/product-and-service-section:grid-cols-3 '>
        {relatedProductsAndServices.map((service, i) => {
          if (service == null) {
            return (
              <div
                key={i}
                className='border rounded-md border-dotted flex border-dark/border/default/normal p-4'
              >
                <ObTypography
                  variant='body2'
                  color='tertiary'
                >
                  Service Removed
                </ObTypography>
              </div>
            );
          }
          return (
            <ServiceCardObserver
              key={service.obrn}
              service={service}
              variant='grid'
            />
          );
        })}
      </div>
    </div>
  );
};

const VerticalScrollableContent = ({ children }: { children: ReactNode }) => {
  return <div className='h-screen overflow-y-scroll'>{children}</div>;
};
