import { useDrawerService } from '@outbound/design-system';
import Review from 'src/state/mobx-experiment/business-context/review/review';
import { useRootStore } from 'src/state/mobx-experiment/use-root-store';
import { ReviewCreateEditDrawer } from './review-create-edit-drawer';

export const useCreateEditReviewDrawer = (workspaceId: string) => {
  const drawerService = useDrawerService();
  const rootStore = useRootStore();
  const openDrawerInCreateMode = () => {
    drawerService
      .pushDrawer({
        testid: 'create-review-drawer',
        title: 'Create New Review',
        description: 'Add a Review for your Business',
        drawerContent: (
          <ReviewCreateEditDrawer
            onFormSubmitCallback={(formValues) => {
              rootStore.reviews.create({
                workspaceId,
                authorFullName: formValues.authorFullName,
                relatedProductsAndServices:
                  formValues.relatedProductsAndServices ?? [],
                reviewTextPlain: formValues.reviewText,
                rating: parseInt(formValues.rating, 10),
              });

              drawerService.popDrawer();
              return Promise.resolve();
            }}
          />
        ),
      })
      .catch((_error) => {
        //Action Cancelled, No action needed
      });
  };

  const openDrawerInEditMode = (review: Review) => {
    drawerService
      .pushDrawer({
        testid: 'edit-review-drawer',
        title: 'Edit Review',
        description: 'Update Review Details',
        drawerContent: (
          <ReviewCreateEditDrawer
            onFormSubmitCallback={(data) => {
              console.log('Review Data', review);
              console.log('Edit Data', data);
              if (data.authorFullName !== review.authorFullName) {
                review.authorFullName = data.authorFullName;
              }

              const intRating = parseInt(data.rating, 10);
              if (intRating !== review.rating) {
                review.rating = intRating;
              }

              if (data.reviewText !== review.textPlain) {
                review.textPlain = data.reviewText;
              }

              const sortedArray = data.relatedProductsAndServices.sort();
              const sortedReviewArray =
                review.relatedProductsAndServices.sort();
              if (
                JSON.stringify(sortedArray) !==
                JSON.stringify(sortedReviewArray)
              ) {
                review.relatedProductsAndServices = sortedArray;
              }
              review.save();
              drawerService.popDrawer();
              return Promise.resolve();
            }}
            reviewToEdit={review}
          />
        ),
      })
      .catch((_error) => {
        //Action Cancelled, No action needed
      });
  };
  return {
    openDrawerInCreateMode,
    openDrawerInEditMode,
  };
};
