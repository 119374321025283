import { ReviewResource, ServerDelta } from '@outbound/types';
import { BaseTransformer } from '../../base-transformer';
import { RootStore } from '../../root-store';
import { simulatePatch } from '../../sync-framework/patch-simulator/patch-simulator';
import Review from './review';

class ReviewTransformer extends BaseTransformer<ReviewResource, Review> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: ReviewResource): Review {
    const {
      rating,
      author,
      textPlain,
      relatedProductsAndServices,
      id,
      obrn,
      source,
    } = resource;
    return new Review(this._rootStore, id, obrn, {
      rating,
      textPlain,
      source,
      authorFullName: author.fullName,
      relatedProductsAndServices,
    });
  }
  createPatchForCurrentModelAndIncomingResource(
    currentModel: Review,
    incomingResource: ReviewResource
  ): ServerDelta[] {
    const incomingModel = this.fromApiResource(incomingResource);
    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(),
      this._rootStore.clientId
    );
  }
}

export default ReviewTransformer;
