import { parseObrn } from '@otbnd/utils';
import { LeadResource, ServerDelta } from '@outbound/types';

import { BaseTransformer } from '../base-transformer';
import { RootStore } from '../root-store';
import { simulatePatch } from '../sync-framework/patch-simulator/patch-simulator';
import Lead from './domain/lead';
import { LeadEventDto } from './domain/lead-events/abstract-lead-event';

class LeadTransformer extends BaseTransformer<LeadResource, Lead> {
  constructor(rootStore: RootStore) {
    super(rootStore);
  }

  fromApiResource(resource: LeadResource): Lead {
    const { scope, localPathId } = parseObrn(resource.obrn);
    return Lead.reconstituteFromPersistence({
      obrn: resource.obrn,
      workspaceId: scope,
      id: localPathId,
      rootStore: this._rootStore,
      firstName: resource.firstName,
      lastName: resource.lastName,
      email: resource.email,
      zipCode: resource.zipCode,
      phoneNumber: resource.phone,
      rating: resource.rating,
      priorityFractionalIndex: resource.priorityFractionalIndex,
      qualificationStatus: resource.leadQualificationStatus,
      lastModifiedAtTimestamp: resource.lastUpdatedAtTimestamp,
      inStatusSinceTimestamp: resource.leadQualificationStatusSinceTimestamp,
      originallyAssociatedWithServiceObrn:
        resource.originallyAssociatedWithServiceObrn,
      originallyAssociatedWithCampaignObrn:
        resource.originallyAssociatedWithCampaignObrn,
      originallyAssociatedWithCampaignHighlightObrn:
        resource.originallyAssociatedWithCampaignHighlightObrn,
      sourceType: resource.sourceType,
      createdAtTimestamp: resource.createdAtTimestamp,
      events: resource.events?.map((eventResource): LeadEventDto => {
        return {
          timestamp: eventResource.eventOccurredAtTimestamp,
          eventType: eventResource.eventType,
          actorObrn: eventResource.actorObrn,
          context: eventResource.eventContext,
        };
      }),
    });
  }
  createPatchForCurrentModelAndIncomingResource(
    currentModel: Lead,
    incomingResource: LeadResource
  ): ServerDelta[] {
    const incomingModel = this.fromApiResource(incomingResource);
    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(),
      this._rootStore.clientId
    );
  }
}

export default LeadTransformer;
