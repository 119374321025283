import { ServiceResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import { Service } from './service';
import ServiceTransformer from './service-transformer';
import ServiceTransport from './service-transport';

class ServiceStore extends BaseStore<Service, ServiceResource> {
  constructor(rootStore: RootStore, serviceTransport: ServiceTransport) {
    super(
      rootStore,
      'service',
      serviceTransport,
      new ServiceTransformer(rootStore)
    );
  }

  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this._baseTransport!.requestResourceById(id);
  }
}

export default ServiceStore;
