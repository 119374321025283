import {
  CampaignResource,
  CampaignResourceWithCreative,
  ServerDelta,
} from '@outbound/types';
import { RootStore } from '../../root-store';
import Campaign from '../campaign';
import CampaignDeployment from '../campaign-deployment/campaign-deployment';
import CampaignHighlight from '../campaign-highlight/campaign-highlight';
import CampaignLocation from '../campaign-location/campaign-location';
import CampaignCustomerProfileTransformer from './campaign-customer-profile-transformer';
import CampaignGoalContextTransformer from './campaign-goal-context-transformer';

import { toObrn } from '@otbnd/utils';
import { BaseTransformer } from '../../base-transformer';
import { simulatePatch } from '../../sync-framework/patch-simulator/patch-simulator';
import { CampaignCustomerProfile } from '../campaign-customer-profile/campaign-customer-profile';
import { createSyntheticCampaignCustomerProfileResourceFromCampaignResource } from '../campaign-customer-profile/campaign-customer-profile-utils';
import CampaignHighlightTransformer from '../campaign-highlight/campaign-highlight-transformer';
import { buildCampaignLocationResourcesFromCampaign } from '../campaign-location/camapign-location-transformer-utils';
import CampaignLocationTransformer from '../campaign-location/campaign-location-transformer';

/**
 * Responsible for transforming the Campaign Resource from the API into a Client Campaign Model
 */
class CampaignTransformer extends BaseTransformer<
  CampaignResourceWithCreative,
  Campaign
> {
  readonly campaignHighlightTransformer: CampaignHighlightTransformer;
  readonly campaignLocationTransformer: CampaignLocationTransformer;
  readonly campaignCustomerProfileTransformer: CampaignCustomerProfileTransformer;
  readonly campaignGoalContextTransformer: CampaignGoalContextTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.campaignHighlightTransformer = new CampaignHighlightTransformer(
      rootStore
    );
    this.campaignLocationTransformer = new CampaignLocationTransformer(
      rootStore
    );
    this.campaignCustomerProfileTransformer =
      new CampaignCustomerProfileTransformer(rootStore);

    this.campaignGoalContextTransformer = new CampaignGoalContextTransformer(
      rootStore
    );
  }

  /**
   * TEMPORARY CLIENT SIDE PATCH SIMULATION. IDEALLY THIS WOULD BE DONE ON THE SERVER AND COME VIA A WEBSOCKET
   * @param currentModel
   * @param resource
   * @returns
   */
  createPatchForCurrentModelAndIncomingResource(
    currentModel: Campaign,
    resource: CampaignResourceWithCreative
  ): ServerDelta[] {
    //Convert Incoming Resource to Model
    const incomingModel = this.fromApiResource(resource);
    return simulatePatch(
      currentModel,
      incomingModel,
      new Date().toISOString(), //This is a simulated server timestamp
      this._rootStore.clientId //This makes it seem like the update is coming from this client which is not true. A quick fix to satisfy the contact. Need to review of the implications of this and if it matters for now.
    );
  }

  /**
   * Since we don't have a full sync mechanism in place, we will simulate a server delta so that we can
   * incrementally update the client model with the server resource vs destroying the client model and replacing it or
   * replacing every field on the client model with the server resource.
   *
   * @param existingModel
   * @param campaignResource
   * @returns
   */
  simulateServerDeltaFromResource(
    existingModel: Campaign,
    campaignResource: CampaignResourceWithCreative | CampaignResource
  ): Array<ServerDelta> {
    const patch: Array<ServerDelta> = [];

    if (existingModel.id !== campaignResource.id) {
      throw new Error('Cannot patch a model with a different id');
    }

    const rawData = existingModel.toJson();

    //Partial POC implementation
    if (rawData.name !== campaignResource.name) {
      patch.push({
        id: existingModel.id,
        objectDomain: 'campaign',
        obrn: existingModel.obrn,
        op: 'replace',
        value: campaignResource.name,
        objectSchemaVersion: '1',
        object: 'campaign',
        serverTimestamp: campaignResource.updatedAtTimestamp,
        path: Campaign.paths.name,
        clientId: '',
        clientUpdateId: '',
        clientTimestamp: '',
      });
    }

    if (rawData.lifecycleStatus !== campaignResource.status) {
      patch.push({
        id: existingModel.id,
        objectDomain: 'campaign',
        obrn: existingModel.obrn,
        op: 'replace',
        value: campaignResource.status,
        objectSchemaVersion: '1',
        object: 'campaign',
        serverTimestamp: campaignResource.updatedAtTimestamp,
        path: Campaign.paths.lifecycleStatus,
        clientId: '',
        clientUpdateId: '',
        clientTimestamp: '',
      });
    }

    buildCampaignLocationResourcesFromCampaign(campaignResource).forEach(
      (location) => {
        const existingLocation = rawData.locations?.[location?.locationId];
        /**
         * When there is a location on the server that is not on the client, we will add it to the client
         */
        if (!existingLocation) {
          patch.push(
            this.createSimulatedServerDeltaWithoutExistingModel(
              location,
              `${Campaign.paths.locations}/${encodeURI(location.locationId)}`,
              'add',
              location.locationId,
              toObrn({
                objectType: 'campaign/location',
                localPathId: location.locationId,
                workspaceId: campaignResource.workspaceId,
              }),
              'campaign/location',
              'campaign',
              '1'
            )
          );
        }
      }
    );

    //Customer Profile.
    const existingCustomerProfile = rawData.customerProfile;
    if (!existingCustomerProfile) {
      const campaignCustomerProfileResource =
        createSyntheticCampaignCustomerProfileResourceFromCampaignResource(
          campaignResource
        );

      if (campaignCustomerProfileResource) {
        const delta = this.createSimulatedServerDeltaWithoutExistingModel(
          campaignCustomerProfileResource,
          Campaign.paths.customerProfile,
          'add',
          campaignCustomerProfileResource.customerProfileId,
          toObrn({
            objectType: 'campaign/campaign-customer-profile',
            localPathId: campaignCustomerProfileResource.customerProfileId,
            workspaceId: campaignResource.workspaceId,
          }),
          'campaign/campaign-customer-profile',
          'campaign',
          '1'
        );

        patch.push(delta);
      }
    }

    campaignResource.highlights.forEach((highlight) => {
      const existingHighlight = rawData.highlights?.[highlight?.id];
      /**
       * When there is a highlight on the server that is not on the client, we will add it to the client
       */
      if (!existingHighlight) {
        patch.push({
          id: existingModel.id,
          objectDomain: 'campaign',
          obrn: existingModel.obrn,
          op: 'add',
          value: highlight,
          objectSchemaVersion: '1',
          object: 'campaign',
          serverTimestamp: campaignResource.updatedAtTimestamp,
          path: `${Campaign.paths.highlights}/${highlight.id}`,
          clientId: '',
          clientUpdateId: '',
          clientTimestamp: '',
        });
      } else {
        //Since the highlight exists on the client, we will check if it has changed and apply any updates that are needed.
      }
    });

    return patch;
  }

  fromApiResource(
    resource: CampaignResourceWithCreative | CampaignResource
  ): Campaign {
    const {
      id,
      channelType: channel,
      name,
      outboundCampaignGoal,
      status,
      budget,
      servingState,
      servingStateReason,
      servingStateLastCalculatedAtTimestamp,
      pausedByUserState,
      latestDeployment: latestDeploymentResource,
      isCampaignCreatedOnAdChannel,
    } = resource;

    /**
     * Campaigns have a 0 to many relationship with deployments.
     * If there are no deployments, the latestDeployment will be null.
     */
    let latestDeployment: CampaignDeployment | null = null;
    if (latestDeploymentResource != null) {
      latestDeployment = new CampaignDeployment(
        this._rootStore,
        latestDeploymentResource.id,
        latestDeploymentResource.obrn,
        {
          stage: latestDeploymentResource.status,
          tags: [
            latestDeploymentResource.id,
            latestDeploymentResource.versionTimestamp,
          ],
          publishedAtTimestamp: latestDeploymentResource.updatedAtTimestamp,
          rejectionReason: latestDeploymentResource.rejectionReason,
        }
      );
    }

    /**
     * Campaigns have a 1 to many relationship with Campaign highlights
     */
    const campaignHighlights: Array<CampaignHighlight> =
      resource.highlights.map((highlight) => {
        return this.campaignHighlightTransformer.fromApiResource(highlight);
      });

    const campaignLocationResources =
      buildCampaignLocationResourcesFromCampaign(resource);
    /**
     * Campaigns have a 1 to many relationship with Campaign Locations
     */
    const campaignLocations: Array<CampaignLocation> =
      campaignLocationResources.map((location) => {
        return this.campaignLocationTransformer.fromApiResource(location);
      });
    /**
     * Campaigns have a 1 to 1 relationship with Campaign Customer Profile.
     * We could model this as embedded in the campaign, but we have chosen to model it as a separate entity
     * to keep the campaign model smaller and more focused as well as keep the transformer less noisy.
     *
     * This choice also leaves an easer path to refactor the customer profile into a 1 to many relationship with the
     * campaign if needed in the future.
     */
    let campaignCustomerProfile: CampaignCustomerProfile | null = null;
    if (resource.status === 'ACTIVE') {
      const campaignCustomerProfileResource =
        createSyntheticCampaignCustomerProfileResourceFromCampaignResource(
          resource
        );

      campaignCustomerProfile =
        this.campaignCustomerProfileTransformer.fromApiResource(
          campaignCustomerProfileResource
        );
    }

    /**
     * 1:1 relationship. The Goal Context will vary based on what the
     * Outbound Campaign Goal is.
     */
    const campaignGoalContext =
      this.campaignGoalContextTransformer.fromApiResource(resource);

    /**
     * Finally build the campaign model
     */
    return new Campaign(
      this._rootStore,
      id,
      resource.workspaceId,
      channel,
      name,
      outboundCampaignGoal,
      servingState,
      servingStateReason,
      servingStateLastCalculatedAtTimestamp,
      pausedByUserState,
      status,
      budget.amount,
      latestDeployment,
      campaignHighlights,
      campaignLocations,
      campaignCustomerProfile,
      campaignGoalContext,
      isCampaignCreatedOnAdChannel
    );
  }
}

export default CampaignTransformer;
