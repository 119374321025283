import {
  ObButton,
  ObThumbnailPlaceholder,
  ObTypography,
  useDrawerService,
} from '@outbound/design-system';

import { TemplateGalleryDrawer } from '../template-gallery-drawer/template-gallery-drawer';

import {
  BaseTemplateSettingDefinitionResource,
  BaseTemplateValueResource,
} from '@outbound/types';
import { observer } from 'mobx-react-lite';
import BaseSetting from '../../../../state/mobx-experiment/creative/setting/base-setting';
import TextSetting from '../../../../state/mobx-experiment/creative/setting/text-setting';
import { ColorPalette } from '../../creative-builder';
import { TemplateSettingsSectionDisplay } from '../../creative-builder.types';
import { TemplateSettingSection } from './components/sections/template-setting-section/template-setting-section';
import { TemplateSettingSubSection } from './components/sections/template-setting-sub-section/template-setting-sub-section';
import { TemplateSettingFieldText } from './components/template-setting-fields/template-setting-field-text/template-setting-field-text';

export interface TemplateConfigSectionProps {
  /**
   * The unique identifier for this section
   */
  id: string;
  /**
   * The title of the section that will be displayed to the user
   */
  header: string;
  /**
   * Callback function to be called when the remix button is clicked for this section
   * @param sectionId
   * @returns
   */
  handleSectionRemix: (sectionId: string) => any | void;
  /**
   * The fields for this template
   */
  settingFields: Array<BaseTemplateSettingDefinitionResource>;
}

export interface CreativeBuilderLeftPanelProps {
  /**
   * Feature Flag to enable the remix feature
   */
  FF_enableRemixFeature: boolean;

  /**
   * Indicates if the panel should be shown or hidden
   */
  showPanel: boolean;
  /**
   * Indicates the expansion state of each setting section
   */
  settingExpansionState: Record<string, boolean>;

  /**
   * Callback to alert parent that the section toggle has been called
   */
  onSectionExpansionToggle: (sectionId: string) => void;
  /**
   * Indicates which setting should be focused when the panel is opened
   * If not provided, the first setting will be focused.
   * If the setting ID is changed after the panel is open it will change the focus to the new setting.
   */
  settingIdToFocus: string | null;

  /**
   * Callback Called Each Time a Setting is Focused
   * @param settingId
   * @returns
   */
  onSettingFocused: (settingId: string) => void;

  pageHeaderHeight: number;
  panelWidth: number;
  colorPalette: ColorPalette | undefined;
  includeTemplateGallery?: boolean;
  /**
   * The Settings Sections that should be populated
   */
  settingSections: Array<TemplateSettingsSectionDisplay>;

  handleFieldUpdated: (updatedTemplateValue: BaseTemplateValueResource) => void;
  handleFieldRemix: (valueToRemix: BaseTemplateValueResource) => void;
  /**
   *
   * @param sectionId
   * @returns
   */
  onClickRemixSectionCallback: (sectionId: string) => void;
  handleFieldLockToggle: (valueToLockToggle: BaseTemplateValueResource) => void;

  /**
   * Indicates that the panel should be disabled for editing and shown in a read only mode.
   * This will disable all input fields, lock buttons, and remix buttons. Users can still expand and collapse sections.
   */
  isDisabled: boolean;

  globalTemplateValidationErrors: Array<{
    settingId: string;
    settingName: string;
    errors: string[];
  }>;
}

export const CreativeBuilderLeftPanel: React.FC<CreativeBuilderLeftPanelProps> =
  observer(
    ({
      FF_enableRemixFeature = false,
      isDisabled,
      pageHeaderHeight = 64,
      onSettingFocused,
      showPanel,
      settingIdToFocus,
      includeTemplateGallery = true,
      panelWidth = 350,
      settingSections,
      settingExpansionState,
      onSectionExpansionToggle,
      onClickRemixSectionCallback,
      globalTemplateValidationErrors,
    }: CreativeBuilderLeftPanelProps) => {
      const { pushDrawer: openSelectAssetDrawer } = useDrawerService();

      /**
       * Opens the template gallery and waits for the user to select a new template or cancel
       */
      const openSelectTemplateDrawer = () => {
        openSelectAssetDrawer({
          drawerContent: <TemplateGalleryDrawer />,
          title: '',
          description: '',
          size: 'small',
        });
      };

      /**
       * Returns the validation errors for a specific setting
       * Sets field specific validation errors first
       * Then adds global template level validation errors
       */
      const getValidationErrors = (setting: BaseSetting) => {
        if (setting.validationErrors.length > 0) {
          return setting.validationErrors;
        }

        return globalTemplateValidationErrors.reduce<string[]>((acc, error) => {
          if (error.settingId === setting.id) {
            acc.push(...error.errors);
          }
          return acc;
        }, []);
      };

      /**
       * Renders a specific field based on it's type
       * @param setting
       * @param templateValues
       * @returns
       */
      const renderSettingField = (
        setting: BaseSetting,
        onSettingFocused: (settingId: string) => void,
        isDisabled: boolean = false,
        autoFocus: boolean = false
      ) => {
        if (autoFocus) {
          console.log('setting', setting.id);
        }

        const validationErrors = getValidationErrors(setting);

        switch (setting.type) {
          case 'text': {
            const textSetting = setting as TextSetting;
            return (
              <TemplateSettingFieldText
                key={setting.id}
                FF_enableRemixFeature={FF_enableRemixFeature}
                onSettingFocused={onSettingFocused}
                isDisabled={isDisabled}
                id={setting.id}
                autoFocus={autoFocus}
                label={setting.name}
                value={textSetting?.value?.text ?? ''}
                isRequired={setting.isRequired}
                publishingValidationErrors={validationErrors}
                onValueUpdatedCallback={(updatedValue) => {
                  textSetting.value = {
                    text: updatedValue,
                  };
                  setting.value.text = updatedValue;
                }}
                isLockedForRemixValue={setting?.isRemixLocked}
                onClickIsLockedForRemixCallback={() =>
                  setting.toggleRemixLock()
                }
                onClickRemixFieldButtonCallback={() =>
                  console.log('Remix Field')
                }
              />
            );
          }
          // ADD THESE BACK IN WHEN THE COMPONENTS ARE READY
          // case 'image':
          //   return (
          //     <TemplateSettingFieldImage
          //       key={setting.id}
          //       isDisabled={isDisabled}
          //       isRequired={setting.isRequired}
          //       publishingValidationErrors={errors}
          //       imageIntent={(setting as ImageSettingFieldResource).imageIntent}
          //       value={
          //         (fieldValue as ImageTemplateValueResource)?.value?.asset ?? null
          //       }
          //       onImageUpdatedCallback={(updatedValue: AssetResource) => {
          //         let updatedImageValue: ImageSettingFieldValue = null;
          //         if (updatedValue != null) {
          //           updatedImageValue = {
          //             type: 'assetReference',
          //             assetId: updatedValue.id,
          //             asset: updatedValue,
          //           };
          //         }

          //         handleFieldInputUpdated({
          //           ...fieldValue,
          //           value: updatedImageValue,
          //         });
          //       }}
          //       id={fieldValue?.id}
          //       label={setting.name}
          //       isLockedForRemixValue={fieldValue?.remixLocked}
          //       onClickIsLockedForRemixCallback={() => {
          //         handleFieldLockToggle(fieldValue);
          //       }}
          //       onClickRemixFieldButtonCallback={() => {
          //         handleFieldRemix(fieldValue);
          //       }}
          //     />
          //   );
          // case 'color':
          //   return (
          //     <TemplateSettingFieldColor
          //       key={setting.id}
          //       id={setting.id}
          //       isDisabled={isDisabled}
          //       label={setting.name}
          //       value={fieldValue?.value || ''}
          //       isRequired={setting.isRequired || false}
          //       publishingValidationErrors={errors}
          //       onValueUpdatedCallback={(updatedValue: string) => {
          //         let updatedColorValue: ColorSettingFieldValue = null;
          //         if (updatedValue != null && updatedValue !== '') {
          //           updatedColorValue = {
          //             hex: updatedValue,
          //           };
          //         }
          //         handleFieldInputUpdated({
          //           ...fieldValue,
          //           value: updatedColorValue,
          //         });
          //       }}
          //       isLockedForRemixValue={fieldValue?.remixLocked}
          //       onClickIsLockedForRemixCallback={() =>
          //         handleFieldLockToggle(fieldValue)
          //       }
          //       onClickRemixFieldButtonCallback={() => handleFieldRemix(fieldValue)}
          //       colorPalette={
          //         [
          //           colorPalette?.primaryColor?.hex,
          //           colorPalette?.secondaryColor?.hex,
          //         ].filter((color) => color != null) as string[]
          //       }
          //     />
          // );
        }
      };

      return (
        <main
          style={{
            transform: `translateX(${showPanel ? 0 : -panelWidth}px)`,
            width: `${panelWidth}px`,
            minWidth: `${panelWidth}px`,
            height: `calc(100vh - ${pageHeaderHeight}px)`,
            position: 'absolute',
            top: `${pageHeaderHeight}px`,
            overflowY: 'scroll',
          }}
          className='hidden sm:block  bg-bgSurfaceDark transition-transform border-borderDefaultNormalDark border-r overflow-y-scroll'
        >
          {includeTemplateGallery && (
            <section>
              <header className='pt-4 pl-4 pr-1 flex flex-row justify-between items-center'>
                <ObTypography variant='body1'>Template</ObTypography>
              </header>
              <article className='flex gap-4 p-4 border-borderDefaultNormalDark border-b'>
                <ObThumbnailPlaceholder
                  size='medium'
                  shape={'rectangle'}
                />
                <div className='flex flex-col gap-4 justify-center'>
                  <ObButton
                    size='large'
                    label='Browse Templates'
                    variant='outline'
                    onClick={openSelectTemplateDrawer}
                  />
                  {/* <ObButton
              size='large'
              label='Remix'
              iconLeft='magicWand'
              variant='primary'
            /> */}
                </div>
              </article>
            </section>
          )}
          {settingSections != null && (
            <>
              {settingSections.map((section) => (
                <TemplateSettingSection
                  key={section.id}
                  remixIsDisabled={isDisabled}
                  heading={section.name}
                  isOpen={settingExpansionState[section.id]}
                  onClickToggleDisclosureCallback={() =>
                    onSectionExpansionToggle(section.id)
                  }
                  onClickRemixSectionCallback={() =>
                    onClickRemixSectionCallback(section.id)
                  }
                >
                  {section.subSections != null &&
                  section.subSections.length > 0 ? (
                    section.subSections?.map((subSection) => (
                      <TemplateSettingSubSection
                        key={subSection.id}
                        header={subSection.name}
                      >
                        {/* RENDER SETTING FIELDS FOR THIS SUBSECTION */}
                        {subSection.settings.map((field) => {
                          return renderSettingField(
                            field,
                            onSettingFocused,
                            isDisabled,
                            settingIdToFocus === field.id
                          );
                        })}
                      </TemplateSettingSubSection>
                    ))
                  ) : (
                    <TemplateSettingSubSection>
                      {/* RENDER SETTING FIELDS THAT ARE NOT ASSIGNED TO A SUBSECTION */}
                      {section.settings.map((field) => {
                        return renderSettingField(
                          field,
                          onSettingFocused,
                          isDisabled,
                          settingIdToFocus === field.id
                        );
                      })}
                    </TemplateSettingSubSection>
                  )}
                </TemplateSettingSection>
              ))}
            </>
          )}
        </main>
      );
    }
  );
