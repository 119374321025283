import { LeadResource } from '@outbound/types';
import { LeadQualificationStatus } from '@outbound/types/src/lead/api-resource/lead-qualification-status';
import { computed } from 'mobx';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Lead from './domain/lead';
import LeadTransformer from './lead-transformer';
import LeadTransport from './lead-transport';

class LeadStore extends BaseStore<Lead, LeadResource> {
  constructor(rootStore: RootStore, resourceTransport: LeadTransport) {
    super(rootStore, 'lead', resourceTransport, new LeadTransformer(rootStore));
  }
  protected async requestLoadModelFromServer(id: string): Promise<void> {
    await this._baseTransport?.requestResourceById(id);
  }

  @computed
  listGroupedByStatus(): Record<LeadQualificationStatus, Lead[]> {
    const grouped = this.list().reduce(
      (acc, lead) => {
        if (!acc[lead.qualificationStatus]) {
          acc[lead.qualificationStatus] = [];
        }
        acc[lead.qualificationStatus].push(lead);
        return acc;
      },
      {
        NEW: [] as Lead[],
        WORKING: [] as Lead[],
        NURTURE: [] as Lead[],
        UNQUALIFIED: [] as Lead[],
        QUALIFIED: [] as Lead[],
      } as Record<LeadQualificationStatus, Lead[]>
    );

    (Object.keys(grouped) as LeadQualificationStatus[]).forEach((status) => {
      grouped[status].sort(Lead.PRIORITY_COMPARATOR);
    });

    return grouped;
  }
}

export default LeadStore;
