import { AssetResource, CursorPaginationResponse } from '@outbound/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { BaseTransport } from '../base-transport';
import { Transport } from '../transport';

class AssetTransport extends BaseTransport<AssetResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  public async internalBootstrap(): Promise<void> {
    this.fetchAll();
  }

  /**
   * Used to bootstrap the store with all assets
   */
  public async fetchAll(cursor?: string) {
    const { data } = await this._axiosInstance.get<
      any,
      AxiosResponse<CursorPaginationResponse<AssetResource>>
    >('/asset-manager/assets', { params: { cursor } });
    data.items.forEach((asset) => {
      this.notifyStoreOfServiceUpdateCallback?.(asset.id, asset);
    });
    if (data.cursor) {
      await this.fetchAll(data.cursor);
    }
  }

  protected async fetchById(id: string) {
    try {
      const assetResponse = await this._axiosInstance.get<AssetResource>(
        `/asset-manager/assets/${id}`
      );

      this.notifyStoreOfServiceUpdateCallback?.(id, assetResponse.data);

      return assetResponse.data;
    } catch (error) {
      return null;
    }
  }

  public async pollForActiveImage(assetId: string): Promise<void> {
    /**
     * The Asset Images takes some time to initialize.
     * We will poll the server to check if the asset is in the PENDING_FILE_UPLOAD state.
     * In the future this would be replaced with a websocket event on the sync endpoint.
     */
    await this.pollForResourceInTargetState(
      assetId,
      (resource: AssetResource) => {
        return resource.status !== 'PENDING_FILE_UPLOAD';
      },
      (resource: AssetResource) => {
        return resource.status === 'INACTIVE';
      },
      'asset-image-generation-status'
    );
  }

  /**
   * Overriding the BaseTransport method to handle the AssetResource
   * @param id
   */
  protected onResourceFetchedFromServer(
    id: string,
    resource: AssetResource
  ): void {
    /**
     * When a asset is in the PENDING_FILE_UPLOAD state we want to poll the server until it is no longer in that state.
     */
    if (resource.status === 'PENDING_FILE_UPLOAD') {
      this.pollForActiveImage(id);
    }
  }

  public acceptEmbeddedResource(resource: AssetResource): void {
    this.onResourceFetchedFromServer(resource.id, resource);
    this.notifyStoreOfServiceUpdateCallback?.(resource.id, resource);
  }
}

export default AssetTransport;
