import { CampaignServingStateReason } from '@outbound/types';

export const generateServingStateReasonDescription = (
  servingStateReason: CampaignServingStateReason
): string => {
  switch (servingStateReason) {
    case 'SERVING':
    case 'CAMPAIGN_NEVER_DEPLOYED':
      return 'Campaign not published';
    case 'CAMPAIGN_PAUSED_BY_USER':
      return 'Campaign manually paused';
    case 'CAMPAIGN_DELETED_ON_AD_CHANNEL':
      return 'Campaign was deleted on ad channel';
    case 'FIRST_DEPLOYMENT_IN_PROGRESS':
      return 'Campaign is being published';
    case 'FIRST_DEPLOYMENT_FAILED':
      return 'Campaign failed to publish';
    case 'AD_CHANNEL_APPROVAL_PENDING':
      return 'Campaign is pending Ad Channel Approval';
    case 'AD_CHANNEL_APPROVAL_REJECTED':
      return 'Campaign Was Rejected by Ad Channel';
    case 'CAMPAIGN_SCHEDULED_TO_START_IN_FUTURE':
      return 'Scheduled to start in the future';
    case 'CAMPAIGN_SCHEDULED_TO_END_IN_PAST':
      return 'Scheduled end date past';
    case 'CAMPAIGN_INITIALIZING':
      return 'Campaign initializing';
    case 'SOME_ADS_UNDER_REVIEW':
      return 'Some ads are under review';
    case 'SOME_ADS_WERE_REJECTED':
      return 'Some ads were rejected';
    case 'TOTAL_CAMPAIGN_DRIFT_DETECTED':
    case 'PARTIAL_CAMPAIGN_DRIFT_DETECTED':
      return 'Issues detected. Re-Publish to Fix';
    case 'ALL_HIGHLIGHTS_PAUSED':
      return 'All highlights paused';
    case 'SOME_HIGHLIGHTS_PAUSED':
      return 'Some highlights paused';
    case 'CAMPAIGN_INITIALIZATION_FAILED':
      return 'Campaign initialization failed';
    case 'SYNCING_WITH_AD_CHANNEL':
      return 'Syncing with Ad Channel';
    case 'UNDEFINED':
    case 'CAMPAIGN_ARCHIVED':
    case 'UNKNOWN':
      return 'Unknown';
  }
};
