import { cx } from 'class-variance-authority';
import { ObThinkingButton } from '../../elements/ob-thinking-button/ob-thinking-button';

export interface ObDrawerFooterProps {
  primaryActionLabel?: string;
  primaryActionCallback: () => Promise<void>;
  secondaryActionLabel?: string;
  secondaryActionCallback?: () => Promise<void>;
  edgeToEdge?: boolean;
}

export const ObDrawerFooter: React.FC<ObDrawerFooterProps> = ({
  primaryActionLabel,
  primaryActionCallback,
  secondaryActionLabel,
  secondaryActionCallback,
  edgeToEdge,
}: ObDrawerFooterProps) => {
  return (
    <footer
      className={cx(
        'flex dark:bg-dark/background/surface justify-end gap-4 border-t border-t-dark/border/default/normal py-4  box-border ',
        edgeToEdge && 'pr-4'
      )}
    >
      {secondaryActionCallback && (
        <ObThinkingButton
          onClickCallback={secondaryActionCallback}
          size='large'
          variant={'secondary'}
          label={secondaryActionLabel ?? 'Cancel'}
        ></ObThinkingButton>
      )}
      <ObThinkingButton
        onClickCallback={primaryActionCallback}
        size='large'
        variant={'primary'}
        label={primaryActionLabel ?? 'Submit'}
      ></ObThinkingButton>
    </footer>
  );
};
