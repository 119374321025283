import { RootStore } from 'src/state/mobx-experiment/root-store';
import { LeadEventDto } from './abstract-lead-event';
import { LeadEventContactDetailsUpdated } from './lead-event-contact-details-updated';
import { LeadEventCreatedFromForm } from './lead-event-created-from-form';
import { LeadEventQualificationStatusUpdated } from './lead-event-qualification-status-updated';
import { LeadEvent } from './lead-event-type';
import { LeadEventUnknown } from './lead-event-unknown';

class LeadEventFactory {
  static fromDto(rootStore: RootStore, data: LeadEventDto): LeadEvent {
    switch (data.eventType) {
      case 'CREATED_FROM_SOURCE_FORM':
        return LeadEventCreatedFromForm.fromDto(rootStore, data);
      case 'QUALIFICATION_STATUS_MODIFIED':
        return LeadEventQualificationStatusUpdated.fromDto(rootStore, data);
      case 'CONTACT_DETAILS_MODIFIED':
        return LeadEventContactDetailsUpdated.fromDto(rootStore, data);
      default:
        return LeadEventUnknown.reconstitute(rootStore, data);
    }
  }
}

export default LeadEventFactory;
