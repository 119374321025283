import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { NavItem } from '../../../components/navigation/dashboard-route.type';

import { ErrorBoundary } from 'react-error-boundary';
import { FullPageErrorMessage } from '../campaigns/components/full-page-error-message';
import { LeadDetailPage } from './detail/lead-detail-page';
import t from './leads.i18n.json';
import { LeadListPage } from './list/lead-list-page';
const MODULE_HIDDEN_BY_FEATURE_FLAG = 'leadModule';

export const leadRoutes: NavItem = {
  title: 'Leads',
  route: 'leads',
  featureFlag: MODULE_HIDDEN_BY_FEATURE_FLAG,
  icon: 'goalLeadGeneration',
  component: () => (
    <ErrorBoundary
      fallback={
        <FullPageErrorMessage
          heading={t.LEAD_LOADING_ERROR.HEADING}
          message={t.LEAD_LOADING_ERROR.MESSAGE}
          actionLabel={t.LEAD_LOADING_ERROR.RELOAD_PAGE_CTA}
          actionOnClick={() => window.location.reload()}
        />
      }
    >
      <Suspense>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  ), //Handle the List or Detail View
  showInNavMenu: ['workspace-side-nav'],
  childRoutes: [
    {
      title: 'Leads',
      route: '',
      index: true,

      component: () => <LeadListPage />,
    },
    {
      title: '',
      route: ':leadId',
      component: () => <Outlet />,
      childRoutes: [
        {
          index: true,
          title: 'List Detail',
          route: 'overview',
          component: () => <Outlet />,
          childRoutes: [
            {
              title: 'List Detail',
              route: '',
              index: true,
              component: () => <LeadDetailPage />,
            },
          ],
        },
      ],
    },
  ],
};
