import { parseObrn } from '@otbnd/utils';
import { ServerDelta } from '@outbound/types';
import { ReviewSourceType } from '@outbound/types/src/review/review-source-type.type';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../base-model';
import { catchSuspense } from '../../framework/suspense-utils';
import { RootStore } from '../../root-store';
import { Service } from '../service/service';

/**
 * Expected Data to Construct a Base Landing Page
 */
export interface ReviewConstructorData {
  rating: number;
  textPlain: string;
  source: ReviewSourceType;
  authorFullName: string;
  relatedProductsAndServices: string[];
}

class Review extends BaseModel {
  private _rating: number;
  private _textPlain: string;
  private _source: ReviewSourceType;
  private _authorFullName: string;
  private _relatedProductsAndServices: string[];

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: ReviewConstructorData
  ) {
    const { scope } = parseObrn(obrn);
    super(rootStore, 'review', '1', id, scope, obrn);
    this._rating = data.rating;
    this._textPlain = data.textPlain;
    this._source = data.source;
    this._authorFullName = data.authorFullName;
    this._relatedProductsAndServices = data.relatedProductsAndServices;
  }

  static readonly paths = {
    ...BaseModel.paths,
    rating: '/rating',
    textPlain: '/textPlain',
    source: '/source',
    relatedProductsAndServices: '/relatedProductsAndServices',
    authorFullName: '/authorFullName',
  };

  applyPatch(_patch: ServerDelta[]): void {
    //TODO: Implement
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _rating: observable,
      _textPlain: observable,
      _source: observable,
      _authorFullName: observable,
      _relatedProductsAndServices: observable,
    } as any);
  }

  get rating(): number {
    return this._rating;
  }

  set rating(value: number) {
    this.addUnsyncedLocalChange(value, this._rating, Review.paths.rating);
    this._rating = value;
  }

  get textPlain(): string {
    return this._textPlain;
  }

  set textPlain(value: string) {
    this.addUnsyncedLocalChange(value, this._textPlain, Review.paths.textPlain);
    this._textPlain = value;
  }

  get source(): ReviewSourceType {
    return this._source;
  }

  get authorFullName(): string {
    return this._authorFullName;
  }

  set authorFullName(value: string) {
    this.addUnsyncedLocalChange(
      value,
      this._authorFullName,
      Review.paths.authorFullName
    );
    this._authorFullName = value;
  }

  get relatedProductsAndServices(): string[] {
    return this._relatedProductsAndServices;
  }

  get relatedProductsAndServicesObjects(): Array<Service> {
    return catchSuspense(() => {
      const services = this._relatedProductsAndServices
        .map((obrn) =>
          this._rootStore.serviceStore.getById(parseObrn(obrn).localPathId)
        )
        .filter((service) => service != null) as Array<Service>;
      return services;
    });
  }

  set relatedProductsAndServices(value: string[]) {
    this.addUnsyncedLocalChange(
      value,
      this._relatedProductsAndServices,
      Review.paths.relatedProductsAndServices
    );
    this._relatedProductsAndServices = value;
  }

  delete(): void {
    runInAction(() => {
      this._rootStore.reviews.deleteByObrn(this.obrn);
    });
  }

  /**
   * Syncs any unsynced local changes to the server via the transport layer.
   *
   * Local Changes to be Synced are stored in the "_unsyncedLocalChanges" Map.
   *
   * IMPLEMENTATION / REFACTOR NOTE:
   * This is a candidate for a base class method.
   * The trick here is to make the "Resource Transport" a generic attribute in the base model so we do not
   * need specialized knowledge of which transport to use as we do here by calling "this._rootStore.transport.reviewTransport.enqueue(value?.clientDelta);"
   *
   * Enqueue is already a base class method of the transport so that should just work.
   */
  save(): void {
    runInAction(() => {
      this._unsyncedLocalChanges.forEach((value, key) => {
        this._unsyncedLocalChanges.delete(key);
        this._rootStore.transport.reviewTransport.enqueue(value?.clientDelta);
      });
    });
  }
}

export default Review;
