import { KeyboardSensor, useSensor } from '@dnd-kit/core';
import { multipleContainersCoordinateGetter } from './multiple-containers-coordinate-getter';

export const useKeyboardSensor = () => {
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: multipleContainersCoordinateGetter,
  });

  return keyboardSensor;
};
