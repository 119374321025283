import { parseObrn } from '@otbnd/utils';
import {
  ClientDelta,
  CreateReviewResource,
  ReviewResource,
} from '@outbound/types';
import { PatchReviewResource } from '@outbound/types/src/review/api-resource/review-resource';
import { AxiosInstance, isAxiosError } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';
import Review from './review';

class ReviewTransport extends BaseTransport<ReviewResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  protected async internalBootstrap(): Promise<void> {
    const response = await this._axiosInstance.get('/reviews');
    if (response.data?.items) {
      response.data.items.forEach((result: ReviewResource) => {
        console.log('Review Transport Bootstrapped', result);
        this.updateSubjectWithResourceUpdate(result.id, result);
      });
    }
  }

  protected async fetchById(id: string): Promise<ReviewResource | null> {
    try {
      const response = await this._axiosInstance.get<ReviewResource>(
        `/reviews/${id}`
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }
      throw error;
    }
  }
  public acceptEmbeddedResource(resource: ReviewResource): void {
    this.updateSubjectWithResourceUpdate(resource.id, resource);
  }

  public async requestDeleteResourceByObrn(obrn: string): Promise<void> {
    const { localPathId } = parseObrn(obrn);
    if (!localPathId) {
      throw new Error(`Local Path Id not found for OBRN: ${obrn}`);
    }
    await this._axiosInstance.delete(`/reviews/${localPathId}`);
  }
  public async requestCreateResource(data: any): Promise<void> {
    const body: CreateReviewResource = {
      id: data.id,
      rating: data.rating,
      textPlain: data.textPlain,
      source: data.source,
      author: {
        fullName: data.authorFullName,
      },
      relatedProductsAndServices: data.relatedProductsAndServices,
    };
    await this._axiosInstance.post<{ id: string }>('/reviews', body);
  }

  protected async processDeltaQueueFlush(
    dedupeDeltaQueue: Array<ClientDelta>
  ): Promise<void> {
    const reviewPatchValues: Map<string, PatchReviewResource> = new Map();

    Object.entries(dedupeDeltaQueue).forEach(([, delta]) => {
      if (delta.object === 'review') {
        const { localPathId } = parseObrn(delta.obrn);
        let reviewPatch = reviewPatchValues.get(localPathId);
        if (reviewPatch == null) {
          reviewPatch = {};
          reviewPatchValues.set(localPathId, reviewPatch);
        }
        /**
         * We will manually craft a patch for the review object based on what is being
         * edited in the client.
         */
        switch (delta.path) {
          case Review.paths.rating:
            reviewPatch.rating = delta.value;
            break;
          case Review.paths.authorFullName:
            reviewPatch.author = {
              fullName: delta.value,
            };
            break;
          case Review.paths.textPlain:
            reviewPatch.textPlain = delta.value;
            break;
          case Review.paths.relatedProductsAndServices:
            reviewPatch.relatedProductsAndServices = delta.value;
            break;
          case Review.paths.source:
            reviewPatch.source = delta.value;
            break;
        }
      }
    });

    //Send Batched Review Patch
    await Promise.all(
      Array.from(reviewPatchValues.entries()).map(([reviewId, patch]) => {
        return this._axiosInstance.patch(`/reviews/${reviewId}`, patch);
      })
    );
  }
}

export default ReviewTransport;
