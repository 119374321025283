import {
  BaseLocationResource,
  CreatePhysicalLocationResourceRequest,
  CreateServiceAreaResourceRequest,
  ServiceAreaPatchRequest,
} from '@outbound/types';
import { AxiosInstance, isAxiosError } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';

class LocationTransport extends BaseTransport<BaseLocationResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  public async internalBootstrap() {
    const response = await this._axiosInstance.get('/locations');
    if (response.data?.items) {
      response.data.items.forEach((result: BaseLocationResource) => {
        this.updateSubjectWithResourceUpdate?.(result.id, result);
      });
    }
  }

  public acceptEmbeddedResource(resource: BaseLocationResource): void {
    this.updateSubjectWithResourceUpdate?.(resource.id, resource);
  }

  public async fetchById(id: string): Promise<BaseLocationResource | null> {
    try {
      const response = await this._axiosInstance.get<BaseLocationResource>(
        `/locations/${id}`
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }
      throw error;
    }
  }

  public async createLocation(
    data:
      | CreatePhysicalLocationResourceRequest
      | CreateServiceAreaResourceRequest
  ): Promise<string> {
    const response = await this._axiosInstance.post<{ id: string }>(
      '/locations',
      data
    );
    return response.data.id;
  }

  public async patchServiceArea(
    id: string,
    data: Partial<ServiceAreaPatchRequest>
  ): Promise<void> {
    await this._axiosInstance.patch(`/locations/${id}`, data);
  }

  public async pollForMapImageGeneration(locationId: string): Promise<void> {
    /**
     * The Location Map Image takes some time to initialize.
     * We will poll the server to check if the location is in the WAITING_FOR_MAP_IMAGE_GENERATION state.
     * In the future this would be replaced with a websocket event on the sync endpoint.
     */
    this.pollForResourceInTargetState(
      locationId,
      (resource: BaseLocationResource) => {
        return (
          resource.locationImageStatus !== 'WAITING_FOR_MAP_IMAGE_GENERATION'
        );
      },
      (resource: BaseLocationResource) => {
        return resource.locationImageStatus === 'FAILED';
      },
      'location-image-generation-status'
    );
  }

  public async deleteLocation(id: string): Promise<void> {
    await this._axiosInstance.delete(`/locations/${id}`);
  }
}

export default LocationTransport;
