import { Deferred } from '@otbnd/utils';
import { ObrnObject } from '@outbound/types';
import { action, computed, observable, ObservableMap } from 'mobx';
import { Subscription } from 'rxjs';
import { BaseModel } from './base-model';
import { BaseStoreModelSyncMeta } from './base-store-model-sync-meta';
import { BaseTransformer } from './base-transformer';
import { BaseTransport, ResourceUpdate } from './base-transport';
import { UnableToFetchDataFromServerError } from './exceptions/unable-to-fetch-data-from-server';
import { RootStore } from './root-store';

export abstract class BaseStore<M extends BaseModel, R> {
  protected _baseTransport: BaseTransport<R> | null = null;
  protected readonly _resourceTransformer: BaseTransformer<R, M> | null = null;
  private subscription: Subscription | null = null;
  /**
   * Holds the Models in the Store.
   */
  @observable
  protected modelMap: ObservableMap<string, M> = observable.map();

  /**
   * Holds the Models that are marked for deletion.
   * In the case of an optimistic delete the model will be removed from the modelMap and added to this map.
   * If the server responds with a 200 status code the model will be removed from this map.
   * If the server responds with a 400 status code the model will be moved back to the modelMap.
   */
  protected optimisticDeleteModelMap: Map<string, M> = new Map<string, M>();

  /**
   * Holds the Sync Meta for the Models in the Store.
   */
  protected _modelSyncMeta: Map<string, BaseStoreModelSyncMeta<M>> = new Map();

  /**
   * The Root Store that this and all Stores belongs to.
   */
  protected rootStore: RootStore;

  protected _object: ObrnObject;

  constructor(
    rootStore: RootStore,
    object: ObrnObject,
    resourceTransport?: BaseTransport<R>,
    resourceTransformer?: BaseTransformer<R, M>
  ) {
    this.rootStore = rootStore;
    this._object = object;
    this._baseTransport = resourceTransport ?? null;
    this._resourceTransformer = resourceTransformer ?? null;
    /**
     * If the needed classes are provided we will subscribe to the resource updates automatically.
     */
    if (this._baseTransport != null && this._resourceTransformer != null) {
      console.log(`MobX | Subscribe to Resource Updates | ${this._object}`);
      this.subscription = this._baseTransport?.subscribeToResourceUpdates({
        next: (update: ResourceUpdate<R>) => {
          switch (update.type) {
            case 'null':
            case 'resource':
              console.log(
                `MobX | Transport Subscription | Resource Update | ${this._object} | ${update.id} | ${update.type}`
              );
              this.handleServerUpdateGeneric(
                update.id,
                update.data,
                this._resourceTransformer!
              );
              break;
          }
        },
        error: (error: Error) => {
          console.error('Error in Transport Subscription', error);
        },
        complete: () => {
          console.log('Transport Subscription Complete');
        },
      });
    } else {
      console.log(
        `MobX | Not Subscribing to Transport | ${
          this._object
        } | Base Transport: ${!!this
          ._baseTransport} | Resource Transformer: ${!!this
          ._resourceTransformer}`
      );
    }
  }

  public dispose(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Gets a Model from the Store by its ID.
   * This must be implemented by the Subclass.
   * @param id The Unique Identifier of the Model we Want to Return
   * @throws {Promise} If the Model needs to be lazy loaded a Promise is thrown
   *          which will be resolved once the Model is fetched.
   **/
  public getById(id: string): M | null {
    try {
      const model = this.getModelFromStoreOrLazyLoadFromServerById(id);
      //Make the model observable before returning it to the UI
      model?.makeObservable();
      return model;
    } catch (error) {
      console.log('Caught Error', error);
      throw error;
    }
  }

  /**
   * @param compareFn A function that defines the sort order of the Models
   * @returns A list of all Models in the Store sorted by ID (So that the order is predictable)
   */

  public list(
    compareFn: (a: M, b: M) => number = (a, b) => a.id.localeCompare(b.id)
  ): Array<M> {
    if (this._baseTransport != null) {
      const { status, promise } = this._baseTransport.bootstrapStatus();
      if (status === 'PENDING') {
        console.log(`MobX | Suspense:Trigger | ${this._object} | list`);

        promise.then(() => {
          console.log(`MobX | Suspense:Resolve | ${this._object} | list`);
        });

        throw promise; //Transport is Still Bootstrapping
      }
      if (status === 'REJECTED') {
        console.log(
          `MobX | List Models | ${this._object} | Bootstrap Rejected`
        );
        throw new UnableToFetchDataFromServerError({
          object: this._object,
          // Using object name as the ID since we don't have an ID and this is during bootstrapping of the object
          id: this._object,
        });
      }
    }

    const allModels = Array.from(this.modelMap.values()).sort(compareFn);
    /**
     * If the object is a location and the list is empty we will bootstrap the transport again.
     * This is in place due to generating locations in the back end during the onboarding step function
     * when making the Generative AI requests.
     *
     * This is a temporary fix and should be removed once we have a more sophisticated way to handle this (i.e. Websocket).
     *
     * Checking for type of location here to limit the impact of this.
     */
    if (this._object === 'location' && allModels?.length == 0) {
      console.log(
        `MobX | List Models | ${this._object} | Empty List - Attempting to Bootstrap`
      );
      this._baseTransport?.bootstrap();
    }

    allModels.forEach((model) => {
      model.makeObservable();
    });

    return allModels;
  }

  @computed
  get listWithDefaultSort(): Array<M> {
    return this.list();
  }

  /**
   * Access the Model directly from the Store without lazy loading.
   * @param id
   * @returns
   */
  protected getModelFromStore(id: string): M | null {
    return this.modelMap.get(id) ?? null;
  }

  private internalRequestLoadModelFromServer(id: string): void {
    console.log(`MobX | Fetch Model | ${this._object} | ${id}`);

    this.requestLoadModelFromServer(id).catch((error) => {
      console.error(
        `MobX | Fetch Model Error Caught Attempt 1 of 2 | ${this._object} | ${id} | Will retry in 1000ms`,
        error
      );
      setTimeout(() => {
        //Wait one second and try again
        this.requestLoadModelFromServer(id).catch((error2) => {
          console.error(
            `MobX | Fetch Model Error Caught Attempt 2 of 2 | ${this._object} | ${id} | Setting Resource as Errored`,
            error2
          );
          this.updateSyncMetaForTransportErrorModel(id);
        });
      }, 1000);
    });
  }

  protected abstract requestLoadModelFromServer(id: string): Promise<void>;

  /**
   * Update Sync Meta state to reflect that the model has been loaded from the server
   * @param id
   * @param model
   */
  protected updateSyncMetaForLoadedModel(id: string, model: M): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isNotFound = false;
    syncMeta.model = model;
    /**
     * If lazyDeferred is null it means no one is waiting for the model to be fetched.
     * It may of been fetched as part of bootstrapping or another call may of provided it
     * as an embedded resource.
     */
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(`MobX | Suspense:Resolve | ${this._object} | ${id}`);
      syncMeta.lazyDeferred?.resolve();
    }
    syncMeta.isLoading = false;
  }

  /**
   * Called when the frontend instantiates a new model. We optimistically add the model to the store
   * @param id
   * @param model
   */
  protected createOptimisticSyncMetaForNewModel(id: string, model: M): void {
    const modelSyncMeta: BaseStoreModelSyncMeta<M> = {
      id,
      model: model,
      lazyDeferred: undefined,
      error: undefined,
      isError: false,
      isLoading: false,
      isFetching: false,
      isNotFound: false,
      isOptimisticCreate: true,
      isOptimisticallyDeleted: false,
      lastFetchedAtTimestamp: new Date().toISOString(),
    };
    this._modelSyncMeta.set(id, modelSyncMeta);
  }

  protected updateSyncMetaForOptimisticallyDeletedModel(id: string): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.isOptimisticallyDeleted = true;
  }

  protected undoOptimisticDeleteSyncMetaForModel(id: string): void {
    console.log('Undoing Optimistic Delete Sync Meta for', id);
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.isOptimisticallyDeleted = false;
  }

  protected updateSyncMetaForUpdatedModel(id: string, model: M): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isLoading = false;
    syncMeta.isNotFound = false;
    syncMeta.model = model;
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(`MobX | Suspense:Resolve | ${this._object} | ${id}`);
      syncMeta.lazyDeferred.resolve();
    }
  }

  protected updateSyncMetaForNotFoundModel(id: string): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isLoading = false;
    syncMeta.isNotFound = true;
    syncMeta.isOptimisticallyDeleted = false;
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(
        `MobX | Suspense:Resolve (Not Found) | ${this._object} | ${id}`
      );
      syncMeta.lazyDeferred?.resolve();
    }
    syncMeta.model = null;
  }

  protected updateSyncMetaForTransportErrorModel(id: string): void {
    const syncMeta = this.getModelSyncMeta(id);
    syncMeta.lastFetchedAtTimestamp = new Date().toISOString();
    syncMeta.isFetching = false;
    syncMeta.isLoading = false;
    syncMeta.isNotFound = true;
    syncMeta.isOptimisticallyDeleted = false;
    syncMeta.isError = true;
    if (syncMeta.lazyDeferred && syncMeta.lazyDeferred.status === 'PENDING') {
      console.log(
        `MobX | Suspense:Resolve (Not Found) | ${this._object} | ${id}`
      );
      syncMeta.lazyDeferred?.resolve();
    }
    syncMeta.model = null;
    syncMeta.error = new UnableToFetchDataFromServerError({
      object: this._object,
      id,
    });
  }

  protected getModelFromStoreOrLazyLoadFromServerById(id: string): M | null {
    const modelSyncMeta = this.getModelSyncMeta(id);
    if (modelSyncMeta.isError && modelSyncMeta.error) {
      throw modelSyncMeta.error;
    }
    if (modelSyncMeta.isNotFound) {
      /**
       * Since the model is not found we will return null. Our contract with the UI is that null values are used
       * To indicate that a model with the given ID does not exist.
       *
       * TODO Think though if there are any cases where something is currently not found but can "Become" found?
       * - Eventual Consistency being the main issue?
       */
      return null;
    }
    if (modelSyncMeta.lastFetchedAtTimestamp == null) {
      /**
       * Since the last fetched timestamp is null it means that we have never loaded this model from the server.
       * At this point we need to lazy load it. Before we do we want to make sure that we are not already loading it.
       */
      if (!modelSyncMeta.isLoading) {
        /**
         * Since we have validated that we are not already lazy loading the model we can now set the isLoading flag to true.
         * This will prevent any other calls to this function from trying to load the model again.
         */
        modelSyncMeta.isLoading = true;
        modelSyncMeta.lazyDeferred = new Deferred();
        this.internalRequestLoadModelFromServer(id);
      }

      if (modelSyncMeta.lazyDeferred === undefined) {
        /**
         * This is an invalid state that should never happen. If it does it means that we have a bug in our code.
         * When the model is being fetched the lazyDeferred should always be defined.
         */
        throw new Error('Invalid Store State. Lazy Deferred is not defined');
      }
      /**
       * Trigger the nearest Suspense boundary to wait for the model to be fetched.
       * The promise will be resolved once the model is received from the server at which point in
       * time the lastFetchedAtTimestamp will be set and the model will be returned.
       */
      console.log(`MobX | Suspense:Trigger | ${this._object} | ${id}`);

      throw modelSyncMeta.lazyDeferred.promise;
    } else {
      console.log(`MobX | Get Model (Cache Hit) | ${this._object} | ${id}`);

      return this.getModelFromStore(id);
    }
  }

  private initSyncMetaForUnFetchedModel(id: string): BaseStoreModelSyncMeta<M> {
    const modelSyncMeta: BaseStoreModelSyncMeta<M> = {
      id,
      model: null,
      lazyDeferred: undefined,
      isError: false,
      isLoading: false,
      isFetching: false,
      isNotFound: false,
      isOptimisticCreate: false,
      isOptimisticallyDeleted: false,
      lastFetchedAtTimestamp: null,
    };
    this._modelSyncMeta.set(id, modelSyncMeta);
    return modelSyncMeta;
  }

  /**
   * Returns the model sync meta for a given model id.
   * if we have no record of the model id, we will create a new sync meta for it
   * which indicates that the model is not fetched yet.
   * @param id
   * @returns
   */
  protected getModelSyncMeta(id: string): BaseStoreModelSyncMeta<M> {
    return (
      this._modelSyncMeta.get(id) ?? this.initSyncMetaForUnFetchedModel(id)
    );
  }

  /**
   * This should eventually become the default way to handle server updates.
   * This is labeled generic to help migrate from the initial implementation
   * that saw each store handle server updates in a custom way. I realized that
   * after implementing several stores that the logic was the same and could be
   * abstracted into a single method. We need to go back and convert all the stores
   * to use this method and than we can just update the method signature to remove the generic
   * @param id
   * @param resource
   * @param transformer
   */
  protected handleServerUpdateGeneric = (
    id: string,
    resource: any,
    transformer: BaseTransformer<any, any>
  ): void => {
    const clientCachedModel = this.modelMap.get(id);
    if (resource == null) {
      console.info(
        `MobX | Apply Server Update | ${this._object} | ${id} | Object Not Found on Server`
      );
      this.updateSyncMetaForNotFoundModel(id);
      this.modelMap.delete(id);
    } else if (clientCachedModel) {
      try {
        console.info(
          `MobX | Apply Server Update | ${this._object} | ${id} | Update Existing Model from Server`
        );
        const patch = transformer.createPatchForCurrentModelAndIncomingResource(
          clientCachedModel,
          resource
        );
        try {
          clientCachedModel.applyPatch(patch);
          this.updateSyncMetaForUpdatedModel(id, clientCachedModel);
        } catch (error) {
          console.log(error);
          console.error(
            `MobX | Apply Patch | ${this._object} | ${id} | Exception Caught when apply patch`,
            error,
            {
              patch,
            }
          );
          throw error;
        }
      } catch (error) {
        console.error(
          `MobX | Apply Server Update | ${this._object} | ${id} | Exception Caught when patching existing Model`,
          error
        );
        this.updateSyncMetaForTransportErrorModel(id);
      }
    } else {
      try {
        const model = transformer.fromApiResource(resource);
        this.modelMap.set(id, model);
        this.updateSyncMetaForLoadedModel(id, model);
      } catch (error) {
        console.error(error);
        console.error(
          `MobX | Apply Server Update | ${this._object} | ${id} | Failed to Transform Resource to Model`,
          error
        );
        this.updateSyncMetaForTransportErrorModel(id);
      }
    }
  };
  @action
  protected optimisticCreateModel(model: M): void {
    const existingModel = this.modelMap.get(model.id);
    if (existingModel) {
      throw new Error(`Model with ID ${model.id} already exists in the store`);
    } else {
      this.modelMap.set(model.id, model);
      this.createOptimisticSyncMetaForNewModel(model.id, model);
    }
  }
  @action
  protected optimisticDeleteModel(id: string): void {
    const model = this.modelMap.get(id);
    if (model) {
      console.log('Optimistically Deleting Model', id);
      this.optimisticDeleteModelMap.set(id, model);
      this.modelMap.delete(id);

      this.updateSyncMetaForOptimisticallyDeletedModel(id);
    }
  }

  @action
  protected undoOptimisticDeleteModel(id: string): void {
    const model = this.optimisticDeleteModelMap.get(id);
    if (model) {
      console.log('Undoing Optimistic Delete', id);
      this.modelMap.set(id, model);
      this.optimisticDeleteModelMap.delete(id);
      this.undoOptimisticDeleteSyncMetaForModel(id);
    }
  }
}
