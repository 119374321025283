import { LeadQualificationStatus } from '@outbound/types/src/lead/api-resource/lead-qualification-status';
import Lead from '../lead';

class UnableToPrioritizeDueToDifferentStatusException extends Error {
  constructor(
    prioritizedLead: Lead,
    under: Lead | undefined,
    above: Lead | undefined
  ) {
    const prioritizedLeadStatus: LeadQualificationStatus =
      prioritizedLead.qualificationStatus;
    const underLeadStatus: LeadQualificationStatus | undefined =
      under?.qualificationStatus;
    const aboveLeadStatus: LeadQualificationStatus | undefined =
      above?.qualificationStatus;
    const statuses: Array<LeadQualificationStatus> = [
      prioritizedLeadStatus,
      underLeadStatus,
      aboveLeadStatus,
    ].filter(Boolean) as Array<LeadQualificationStatus>;
    const message = `Leads must have the same status to be prioritized. First update the status, then prioritize the Leads. Current Statues Include: ${statuses
      .toSorted()
      .join(', ')}`;
    super(message);
  }
}
export default UnableToPrioritizeDueToDifferentStatusException;
