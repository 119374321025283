import { CreatedFromFormSubmissionEvent } from '@outbound/design-system';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import { LeadEventCreatedFromForm } from 'src/state/mobx-experiment/leads/domain/lead-events/lead-event-created-from-form';

export interface EventCreatedFromFormProps {
  event: LeadEventCreatedFromForm;
}

export const EventCreatedFromForm = ({ event }: EventCreatedFromFormProps) => {
  const formValues = event.context.values;

  return (
    <CreatedFromFormSubmissionEvent
      localization={{
        formSubmissionLabel:
          t.EVENTS.LEAD_CREATED_FROM_FORM_FORM_SUBMISSION_LABEL,
      }}
      formSubmission={{
        values: [
          {
            id: 'ob-default:full-name',
            label: 'Name',
            value: formValues.fullName,
          },
          {
            id: 'ob-default:email',
            label: 'Email',
            value: formValues.email,
          },
          {
            id: 'ob-default:phone',
            label: 'Phone',
            value: formValues.phone,
          },
          {
            id: 'ob-default:zip-code',
            label: 'Zip Code',
            value: formValues.zipCode,
          },
          {
            id: 'ob-default:message',
            label: 'Message',
            value: formValues.message,
          },
        ],
      }}
      isLastFetchedEvent={false}
      timestamp={event.timestamp}
    />
  );
};
