import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { NavItem } from '../../../../../components/navigation/dashboard-route.type';
import { IntegrationDetailPage } from './integration-detail';
import { IntegrationsListPage } from './integration-list';

export const integrationsPageRoute: NavItem = {
  title: 'Integrations',
  route: 'integrations',
  component: () => (
    <Suspense fallback={<div> Integrations Loading</div>}>
      <Outlet />
    </Suspense>
  ), //Handle the List or Detail View
  showInNavMenu: ['workspace-settings-nav'],
  icon: 'companies',
  childRoutes: [
    {
      title: 'Integrations',
      route: '',
      index: true,
      component: IntegrationsListPage,
    },
    {
      title: '',
      route: ':integrationSlug',
      component: IntegrationDetailPage,
    },
  ],
};
