import {
  CancelDrop,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  KeyboardCoordinateGetter,
  Modifiers,
  UniqueIdentifier,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  SortingStrategy,
  defaultAnimateLayoutChanges,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ObGridHeader } from '@outbound/design-system';
import { LeadQualificationStatus } from '@outbound/types/src/lead/api-resource/lead-qualification-status';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import { LeadCardObserver } from 'src/object-card/lead/lead-card-observer';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { useRootStore } from 'src/state/mobx-experiment/use-root-store';
import { DashboardRouteParams } from '../../dashboard-route-param.type';
import { convertQualificationStatusToLabel } from '../utils/lead-qualification-status-to-label';
import t from './../leads.i18n.json';
import {
  Container,
  DraggableItem,
  useIsComponentInitialRenderCompletedCheck,
} from './drag-and-drop';
import { ContainerProps } from './drag-and-drop/Container';
import {
  GroupedDragItemUniqueIdentifierMap,
  SortDetails,
  useSortableDragAndDropGroups,
} from './drag-and-drop/use-sortable-drag-and-drop-groups';

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export const LeadListPage = observer(() => {
  const { workspaceSlug } = useParams<DashboardRouteParams>();
  const { navigateToLeadDetail } = useAppNavigation();
  const { leads } = useRootStore();

  if (!workspaceSlug) {
    return <></>;
  }

  const allLeads = leads.list();

  return (
    <div className='min-h-[100svh]'>
      <header className='px-8 pt-4 max-w-screen'>
        <ObGridHeader
          title={t.LEAD_MODULE_HEADING}
          count={allLeads.length}
          controls={<></>}
        />
      </header>
      <div className='overflow-x-scroll h-full'>
        <LeadDragAndDropContext onClickItemCallback={navigateToLeadDetail} />
      </div>
    </div>
  );
});

const GroupDroppableContainer = ({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
}) => {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
      items.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      count={items.length}
      scrollable={true}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
      id={id.toString()}
    >
      {children}
    </Container>
  );
};

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  itemCount?: number;
  items?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  scrollable?: boolean;
  vertical?: boolean;
  onClickItemCallback?: (id: string) => any;
}

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

const moveLeadToStatusContainerThatItWasDraggedOverWithoutSavingChangeInCaseUserKeepsDragging =
  (draggedOverContainerForStatus: LeadQualificationStatus, lead: Lead) => {
    switch (draggedOverContainerForStatus as LeadQualificationStatus) {
      case 'NEW':
        lead.updateQualificationStatusToNew();
        break;
      case 'WORKING':
        lead.updateQualificationStatusToWorking();
        break;
      case 'NURTURE':
        lead.updateQualificationStatusToNurture();
        break;
      case 'UNQUALIFIED':
        lead.updateQualificationStatusToNotQualified();
        break;
      case 'QUALIFIED':
        lead.updateQualificationStatusToQualified();
        break;
    }
  };
export const LeadDragAndDropContext = observer(
  ({
    adjustScale = false,
    columns,
    handle = false,
    containerStyle,
    getItemStyles = () => ({}),
    wrapperStyle = () => ({}),
    strategy = verticalListSortingStrategy,
    vertical = false,
    onClickItemCallback,
    scrollable,
  }: Props) => {
    //REFACTOR OUT TO DOMAIN DRAG AND DROP EVENT HANDLER HOOK
    const runDomainEventsInResponseToDragCancel = (event: DragCancelEvent) => {
      const lead = leads.getById(event.active.id as string);
      if (lead) {
        lead.discardUnsavedChanges();
      }
    };

    //REFACTOR OUT TO DOMAIN DRAG AND DROP EVENT HANDLER HOOK
    const runDomainEventsInResponseToSort = (sortDetails: SortDetails) => {
      const { uniqueIdOfSortedItem, positionAfterSort } = sortDetails;

      const directlyUnder =
        sortDetails.positionAfterSort.directlyUnderUniqueIdentifier ===
        'TOP_OF_LIST'
          ? 'TOP_OF_LIST'
          : leads.getById(
              positionAfterSort.directlyUnderUniqueIdentifier as string
            )!;

      const directlyAbove =
        positionAfterSort.directlyAboveUniqueIdentifier === 'BOTTOM_OF_LIST'
          ? 'BOTTOM_OF_LIST'
          : leads.getById(
              positionAfterSort.directlyAboveUniqueIdentifier as string
            )!;

      const droppedLead = leads.getById(uniqueIdOfSortedItem as string);

      droppedLead?.prioritize({ directlyUnder, directlyAbove });
    };

    //REFACTOR OUT TO DOMAIN DRAG AND DROP EVENT HANDLER HOOK
    const runDomainEventsInResponseToDragEnd = (event: DragEndEvent) => {
      const droppedLead = leads.getById(event.active.id as string);
      if (droppedLead) {
        droppedLead.save(['qualificationStatus']);
      }
    };

    //REFACTOR OUT TO DOMAIN DRAG AND DROP EVENT HANDLER HOOK
    const runDomainEventsInResponseToDragToNewGroup = (
      draggedItemId: UniqueIdentifier,
      draggedOverGroupId: UniqueIdentifier
    ) => {
      moveLeadToStatusContainerThatItWasDraggedOverWithoutSavingChangeInCaseUserKeepsDragging(
        draggedOverGroupId as LeadQualificationStatus,
        leads.getById(draggedItemId as string)!
      );
    };

    const getLeadFromDragItemUniqueIdentifier = (
      draggedItemId: UniqueIdentifier
    ) => {
      return leads.getById(draggedItemId as string);
    };

    const getQualificationStatusFromGroupUniqueIdentifier = (
      groupId: UniqueIdentifier
    ): LeadQualificationStatus | undefined => {
      if (groupId == null) {
        return undefined;
      }
      return groupId as LeadQualificationStatus;
    };

    //REFACTOR OUT TO DOMAIN DRAG AND DROP EVENT HANDLER HOOK
    const shouldDraggedItemBeMovedToGroup = (
      draggedItemId: UniqueIdentifier,
      draggedOverGroupId: UniqueIdentifier
    ): boolean => {
      const leadBeingDragged: Lead | null =
        getLeadFromDragItemUniqueIdentifier(draggedItemId);

      const currentlyDraggedOverGroupForQualificationStatus:
        | LeadQualificationStatus
        | undefined =
        getQualificationStatusFromGroupUniqueIdentifier(draggedOverGroupId);

      return hasLeadBenMovedToANewQualificationStatusGroup(
        leadBeingDragged,
        currentlyDraggedOverGroupForQualificationStatus
      );
    };

    //REFACTOR OUT TO DRAG AND DROP BETWEEN GROUPS COMPONENT
    const {
      dndKitContextProps,
      activeDraggingItemId,
      setDragItemIdsGroupedByGroupingId,
      findGroupItemIsBeingDraggedOver,
      getIndexOfItemWithinItsCurrentGroup,
    } = useSortableDragAndDropGroups({
      runDomainEventsInResponseToDragCancel,
      runDomainEventsInResponseToSort,
      runDomainEventsInResponseToDragEnd,
      runDomainEventsInResponseToDragToNewGroup,
      shouldDraggedItemBeMovedToGroup,
    });

    const { leads } = useRootStore();

    const leadsGroupedByStatus: Record<
      'NEW' | 'WORKING' | 'NURTURE' | 'QUALIFIED' | 'UNQUALIFIED',
      Lead[]
    > = leads.listGroupedByStatus();

    useEffect(() => {
      const leadIdsGroupByStatusId: GroupedDragItemUniqueIdentifierMap =
        convertLeadsGroupByStatusToDragAndDropItemsUniqueIdentifierGroupedByStatusUniqueIdentifier(
          leadsGroupedByStatus
        );
      setDragItemIdsGroupedByGroupingId(leadIdsGroupByStatusId);
    }, [leadsGroupedByStatus, setDragItemIdsGroupedByGroupingId]);

    const groupsToShowInView: Array<UniqueIdentifier> = [
      'NEW',
      'WORKING',
      'NURTURE',
      'UNQUALIFIED',
      'QUALIFIED',
    ];

    return (
      <DndContext {...dndKitContextProps}>
        <div
          style={{
            display: 'inline-grid',
            boxSizing: 'border-box',
            padding: '20px',
            overflow: 'hidden',
            height: 'full',

            gridAutoFlow: vertical ? 'row' : 'column',
          }}
        >
          {groupsToShowInView.map((groupId) => (
            <GroupDroppableContainer
              key={groupId}
              id={groupId.toString()}
              label={`${convertQualificationStatusToLabel(
                groupId as LeadQualificationStatus
              )}`}
              columns={columns}
              items={
                leadsGroupedByStatus[groupId as LeadQualificationStatus]?.map(
                  (leads) => leads.id
                ) ?? []
              }
              scrollable={scrollable}
              style={containerStyle}
              unstyled={false}
            >
              <SortableContext
                items={
                  leadsGroupedByStatus[groupId as LeadQualificationStatus]?.map(
                    (lead) => lead.id
                  ) ?? []
                }
                strategy={strategy}
              >
                {leadsGroupedByStatus[groupId as LeadQualificationStatus].map(
                  (value, index) => {
                    return (
                      <SortableItem
                        disabled={false}
                        onClickedCallback={() => {
                          onClickItemCallback?.(value.obrn);
                        }}
                        key={value.id}
                        id={value.id}
                        index={index}
                        handle={handle}
                        style={getItemStyles}
                        wrapperStyle={wrapperStyle}
                        containerId={groupId}
                        getIndex={getIndexOfItemWithinItsCurrentGroup}
                      />
                    );
                  }
                )}
              </SortableContext>
            </GroupDroppableContainer>
          ))}
        </div>
        {createPortal(
          <DragOverlay
            adjustScale={adjustScale}
            dropAnimation={dropAnimation}
          >
            {activeDraggingItemId
              ? renderSortableItemDragOverlay(activeDraggingItemId)
              : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    );

    function renderSortableItemDragOverlay(id: UniqueIdentifier) {
      const leadBeingDragged = leads.getById(id as string);
      return (
        <DraggableItem
          value={
            <LeadCardObserver
              lead={leadBeingDragged!}
              variant='grid'
              localization={t}
            />
          }
          style={getItemStyles({
            containerId: findGroupItemIsBeingDraggedOver(
              id
            ) as UniqueIdentifier,
            overIndex: -1,
            index: getIndexOfItemWithinItsCurrentGroup(id),
            value: id,
            isSorting: true,
            isDragging: true,
            isDragOverlay: true,
          })}
          wrapperStyle={wrapperStyle({ index: 0 })}
          dragOverlay
        />
      );
    }
  }
);

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  disabled?: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
  onClickedCallback: () => any;
}

function SortableItem({
  disabled,
  id,
  index,
  handle,
  style,
  containerId,
  getIndex,
  wrapperStyle,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const isItemDoneWithItsInitialRender =
    useIsComponentInitialRenderCompletedCheck();

  const whenThisComponentRenderedInResponseToAUserDraggingTheItemToANewContainer =
    isDragging && !isItemDoneWithItsInitialRender;

  const lead = useRootStore().leads.getById(id as string)!;

  return (
    <DraggableItem
      ref={disabled ? undefined : setNodeRef}
      value={
        <LeadCardObserver
          lead={lead}
          variant='grid'
          localization={t}
        />
      }
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={
        whenThisComponentRenderedInResponseToAUserDraggingTheItemToANewContainer
      }
      listeners={listeners}
    />
  );
}

const convertLeadsGroupByStatusToDragAndDropItemsUniqueIdentifierGroupedByStatusUniqueIdentifier =
  (
    leadsGroupedByQualificationStatus: Record<
      'NEW' | 'WORKING' | 'NURTURE' | 'QUALIFIED' | 'UNQUALIFIED',
      Lead[]
    >
  ): Record<UniqueIdentifier, UniqueIdentifier[]> => {
    return Object.keys(leadsGroupedByQualificationStatus).reduce(
      (acc, key) => {
        const status = key as LeadQualificationStatus;
        acc[key] = leadsGroupedByQualificationStatus[status].map(
          (lead) => lead.id as UniqueIdentifier
        );
        return acc;
      },
      {} as Record<UniqueIdentifier, UniqueIdentifier[]>
    );
  };

const hasLeadBenMovedToANewQualificationStatusGroup = (
  lead: Lead | null,
  currentlyOverQualificationStatusGroup: LeadQualificationStatus | undefined
) => {
  const isNotDraggedOverAQualificationStatusGroup =
    currentlyOverQualificationStatusGroup == null;
  const isDraggedLeadNotProvided = lead == null;
  if (isNotDraggedOverAQualificationStatusGroup || isDraggedLeadNotProvided) {
    return false;
  }
  const currentQualificationStatusOfLeadBeingDragged = lead.qualificationStatus;

  const isLeadsCurrentQualificationStatusDifferentThanTheGroupItIsBeingDraggedOver =
    currentQualificationStatusOfLeadBeingDragged !==
    currentlyOverQualificationStatusGroup;

  return isLeadsCurrentQualificationStatusDifferentThanTheGroupItIsBeingDraggedOver;
};
