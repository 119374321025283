import { cva } from 'class-variance-authority';
import { IconSize } from '../tokens/icons/ob-icon/ob-icon';
import { ObAvatarSize } from './avatar.types';

/**
 * Shared sizes for avatars implemented using ObAvatarSize
 */
export const avatarSizeStyles = cva('', {
  variants: {
    size: {
      'xx-small': ['h-[20px] min-h-[20px]', 'w-[20px] min-w-[20px]'],
      'x-small': ['h-[24px] min-h-[24px]', 'w-[24px] min-w-[24px]'],
      small: ['h-[32px] min-h-[32px]', 'w-[32px] min-w-[32px]'],
      medium: ['h-[40px] min-h-[40px]', 'w-[40px] min-w-[40px]'],
      large: ['h-[48px] min-h-[48px]', 'w-[48px] min-w-[48px]'],
      larger: ['h-[64px] min-h-[64px]', 'w-[64px] min-w-[64px]'],
      'x-large': ['h-[96px] min-h-[96px]', 'w-[96px] min-w-[96px]'],
      'xx-large': ['h-[128px] min-h-[128px]', 'w-[128px] min-w-[128px]'],
    },
  },
});

/**
 * Shared shapes for avatars implemented using ObAvatarShape
 */
export const avatarShapeStyles = cva('', {
  variants: {
    shape: {
      circle: ['rounded-full'],
      rounded: ['rounded'],
    },
  },
});

/**
 * Get the correct icon size for the avatar size
 * @param size The size of the avatar
 * @returns The size of the icon
 */
export const getIconSizeForAvatarSize = (size: ObAvatarSize): IconSize => {
  switch (size) {
    case 'xx-small':
      return 'xx-small';
    case 'x-small':
      return 'x-small';
    case 'small':
      return 'small';
    case 'medium':
      return 'small';
    case 'large':
      return 'medium';
    case 'larger':
      return 'large';
    case 'x-large':
      return 'x-large';
    case 'xx-large':
      return 'xx-large';
  }
};
