import { cx } from 'class-variance-authority';
import { ObButton } from '../../elements/ob-button/ob-button';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObDrawerHeaderProps {
  /**
   * Primary Heading for the Drawer
   */
  title: string;
  /**
   * Optional Secondary Heading for the Drawer
   */
  description?: string;

  onBackCallback?: () => any;
}

export const ObDrawerHeader: React.FC<ObDrawerHeaderProps> = ({
  title,
  description,
  onBackCallback,
}: ObDrawerHeaderProps) => {
  return (
    <header className='flex dark:bg-bgSurfaceDark items-center border-b border-b-borderDefaultNormalDark flex-row  justify-center'>
      {onBackCallback && (
        <ObButton
          variant='ghost'
          iconLeft='chevronLeft'
          buttonType='icon'
          size='xlarge'
          onClick={onBackCallback}
        />
      )}
      <div className={cx('py-2 flex-grow', !onBackCallback && 'pl-6')}>
        <ObTypography variant='h4'>{title}</ObTypography>
        <ObTypography
          variant='body2'
          color='secondary'
        >
          {description}
        </ObTypography>
      </div>
    </header>
  );
};
