/**
 * This component is not intended to provide checkbox functionality, only the UI of a checkbox.
 * The implementation of a checkbox input can use this component as a base for the styles. This component is used in
 * various places where we may want to show a checkbox but defer the implementation of the behavior.
 *
 * An example is in a multiselect dropdown where we show a checkbox next to each item in the dropdown.
 * The checkbox is used to indicate if the item is selected or not. The parent component will handle the state of the checkbox
 * but we do not want the checkbox to be interactive in the dropdown, it is simply a visual indicator and will be updated
 * by the "selected" state of the item in the dropdown.
 */

import {
  DisabledProps,
  ErroredProps,
  StateManagedByParentProps,
} from '../../../base-component-props.type';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';

export type CheckboxStates = 'checked' | 'unchecked' | 'intermediate';

export interface ObCheckboxUiProps
  extends DisabledProps,
    ErroredProps,
    StateManagedByParentProps<CheckboxStates> {
  size?: 'small' | 'medium';
}

/**
 * A component that displays the UI of a checkbox.
 * This component is not intended to provide checkbox functionality, only the UI of a checkbox.
 * Any functionality should be implemented by the parent component including accessibility, keyboard navigation, and state management.
 *
 * DO NOT USE THIS COMPONENT DIRECTLY IN FORMS.
 * See ObCheckbox for a fully functional checkbox component.
 * @returns
 */
export const ObCheckboxUi = ({
  value,
  isErrored,
  isDisabled,
  size = 'small',
}: ObCheckboxUiProps) => {
  return (
    <span
      className={`inline-block box-content border  mr-2 
        ${
          size === 'small'
            ? 'w-5 h-5 min-w-[20px] min-h-[20px] rounded-[8px]'
            : 'w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-[12px]'
        }
        ${
          value === 'checked' || value === 'intermediate'
            ? ' dark:text-actionPrimaryDark text-actionPrimaryLight hover:dark:text-actionPrimaryHoverDark hover:text-actionPrimaryHoverLight dark:border-actionOutlineNormalDark dark:hover:border-actionOutlineHoverDark'
            : 'dark:bg-bgSurfaceDark bg-white dark:border-actionOutlineNormalDark hover:border-gray-500'
        }
        ${isErrored ? 'border-warning hover:border-warning-dark' : ''}
        ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
    >
      {value === 'checked' && (
        <ObIcon
          icon='checkBox'
          color='inherit'
          size={size == 'small' ? 'small' : 'large'}
        />
      )}
      {value === 'intermediate' && (
        <ObIcon
          icon='checkboxIntermediate'
          color='inherit'
          size={size}
        />
      )}
    </span>
  );
};
