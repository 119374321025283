import {
  ObIcon,
  ObInputListbox,
  ObMenuItem,
  ObNavigationSidenav,
  ObTypography,
} from '@outbound/design-system';
import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ListboxButton } from '@headlessui/react';
import { usePlaybookDownloads } from './hooks/use-playbook-downloads';
import { playbookPageSections } from './playbook-page-section.def';

export const PlaybookV2 = () => {
  return (
    <Page>
      <ContentNavigationDesktop />
      <VerticalScrollableContent>
        <div className='w-full min-w-0 xl:min-w-[637px] p-6'>
          <div className='max-w-3xl w-full m-auto h-full'>
            <ContentHeaderMobile />
            <Outlet />
          </div>
        </div>
      </VerticalScrollableContent>
    </Page>
  );
};

const Page = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className=' flex flex-row justify-start w-full h-full'>{children}</div>
  );
};

const VerticalScrollableContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='vh-100 overflow-y-scroll w-full'>{children}</div>;
};

const ContentNavigationDesktop = () => {
  const {
    generateAndDownloadHandout,
    isHandoutGenerating,
    handoutLabel,
    isHandoutReady,
  } = usePlaybookDownloads();

  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams();

  const onNavigationItemClicked = useCallback(
    (nav: any) => {
      // This should be moved to app navigation at some point
      navigate(`/${workspaceSlug}/playbook/${nav.item}`);
    },
    [navigate, workspaceSlug]
  );

  return (
    <div className='hidden lg:block border-r border-borderDefaultNormalDark h-full'>
      <div className='flex flex-col justify-between h-full'>
        <div>
          <div className='pt-6'></div>
          <ObNavigationSidenav
            onClickCallback={onNavigationItemClicked}
            sections={playbookPageSections}
            activeId={location.pathname}
          />
        </div>

        <div className='border-t border-dark/border/default/normal'>
          {/* Random padding here to align the height of the export handout button with the primary nav border */}
          <div className='px-4 py-[8.75px]'>
            <ObMenuItem
              isActive={false}
              leftIcon={isHandoutReady ? 'check' : 'download'}
              isProcessing={isHandoutGenerating}
              size='small'
              kind='tertiary'
              disabled={isHandoutGenerating}
              onClickCallback={() =>
                !isHandoutGenerating && generateAndDownloadHandout()
              }
            >
              {handoutLabel}
            </ObMenuItem>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContentHeaderMobile = () => {
  const {
    generateAndDownloadHandout,
    isHandoutGenerating,
    handoutLabel,
    isHandoutReady,
  } = usePlaybookDownloads();

  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams();

  const onNavigationItemClicked = useCallback(
    (nav: any) => {
      // This should be moved to app navigation at some point
      navigate(`/${workspaceSlug}/playbook/${nav.item}`);
    },
    [navigate, workspaceSlug]
  );
  return (
    <div className='flex lg:hidden flex-row justify-between items-center'>
      <ObInputListbox
        listBoxButton={
          <ListboxButton className={'flex-1 flex'}>
            <div className='flex flex-row justify-start items-center gap-2 hover:bg-dark/action/ghost/hover active:bg-dark/action/ghost/active focus-visible:shadow-interactive flex-1 py-3 rounded-md'>
              <ObTypography
                variant='h3'
                as='h1'
              >
                {playbookPageSections[0].pages.find((page) => {
                  console.log(
                    'Testing ',
                    page,
                    location.pathname.endsWith(page.id)
                  );
                  return location.pathname.endsWith(page.id);
                })?.title ?? ''}
              </ObTypography>
              <div className='pt-1'>
                <ObIcon
                  icon='chevronDown'
                  size={'medium'}
                />
              </div>
            </div>
          </ListboxButton>
        }
        inputId={'playbook-nav'}
        isErrored={false}
        size={'medium'}
        isDisabled={false}
        value={
          playbookPageSections[0].pages.find(
            (page) => page.id === location.pathname
          )?.title ?? ''
        }
        options={
          playbookPageSections[0].pages.map((page) => ({
            key: page.id,
            label: page.title,
            value: page.id,
            description: '',
            data: page,
          })) ?? []
        }
        onValueChangedCallback={(option) => {
          onNavigationItemClicked({ item: option });
        }}
        isLoading={false}
      />

      <ObMenuItem
        iconOnly={true}
        isActive={false}
        leftIcon={isHandoutReady ? 'check' : 'download'}
        isProcessing={isHandoutGenerating}
        size='small'
        kind='tertiary'
        disabled={isHandoutGenerating}
        onClickCallback={() =>
          !isHandoutGenerating && generateAndDownloadHandout()
        }
      >
        {handoutLabel}
      </ObMenuItem>
    </div>
  );
};
