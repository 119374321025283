import {
  FormDefinition,
  ObFormRenderer,
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { ObDrawerFooter } from '@outbound/design-system/src/components/molecules/ob-drawer-footer/ob-drawer-footer';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import t from '../../leads.i18n.json';
import {
  LeadContactEditFormDefinition,
  LeadContactEditFormValue,
} from './lead-contact-edit-form.def';

interface LeadContactEditDrawerProps {
  leadToEdit: Lead;
  onFormSubmitCallback: (lead: LeadContactEditFormValue) => Promise<void>;
}
/**
 * Drawer used to create and edit.
 */
export const LeadContactEditDrawer = ({
  onFormSubmitCallback,
  leadToEdit,
}: LeadContactEditDrawerProps) => {
  const drawerService = useDrawerService();

  const { getRegisteredFormById } = useObFormRendererContext();
  const finalFormDef: FormDefinition = LeadContactEditFormDefinition;

  let defaultValues: undefined | LeadContactEditFormValue = undefined;
  if (leadToEdit) {
    defaultValues = {
      leadContactFirstName: leadToEdit.firstName,
      leadContactLastName: leadToEdit.lastName,
      leadContactEmail: leadToEdit.email,
      leadContactPhone: leadToEdit.phone,
      leadContactZipCode: leadToEdit.zipCode,
    };
  }
  return (
    <div className='overflow-x-auto p-4'>
      <ObFormRenderer
        formDefinition={finalFormDef}
        defaultValues={defaultValues}
        hideSubmitButton={true}
        onSubmitCallback={(data) => {
          return onFormSubmitCallback(data);
        }}
      />
      <div>
        <ObDrawerFooter
          primaryActionLabel={t.LEAD_EDIT_CONTACT_DIALOG.SAVE_CONTACT_CTA}
          primaryActionCallback={() => {
            getRegisteredFormById(
              LeadContactEditFormDefinition.id
            )?.submitFunction();
            return Promise.resolve();
          }}
          secondaryActionLabel={t.LEAD_EDIT_CONTACT_DIALOG.CANCEL_CTA}
          secondaryActionCallback={() => {
            drawerService.popDrawer(false);
            return Promise.resolve();
          }}
        />
      </div>
      <div
        data-spacer
        className='min-h-[80px]'
      ></div>
    </div>
  );
};
