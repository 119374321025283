import { cx } from 'class-variance-authority';

export type Props = React.ComponentPropsWithoutRef<'dl'>;

export const DescriptionList = ({ className, ...props }: Props) => {
  return (
    <div className='@container/dl'>
      <dl
        {...props}
        className={cx(
          className,
          'grid grid-cols-1 text-base/6 @md/dl:grid-cols-2 @md/dl:text-sm/6 '
        )}
      />
    </div>
  );
};
