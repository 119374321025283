import { parseObrn } from '@otbnd/utils';
import {
  BaseLocationResource,
  CreatePhysicalLocationResourceRequest,
  CreateServiceAreaResourceRequest,
} from '@outbound/types';
import { action, computed, runInAction } from 'mobx';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import Location from './location';
import LocationTransformer from './location-transformer';
import LocationTransport from './location-transport';
import PhysicalLocation from './physical-location/physical-location';
import ServiceAreaLocation from './service-area/service-area';

class LocationStore extends BaseStore<Location, BaseLocationResource> {
  constructor(rootStore: RootStore, resourceTransport: LocationTransport) {
    super(
      rootStore,
      'location',
      resourceTransport,
      new LocationTransformer(rootStore)
    );
  }

  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this._baseTransport?.requestResourceById(id);
  }

  @computed
  public getPhysicalLocations(): PhysicalLocation[] {
    return Array.from(this.modelMap.values()).filter(
      (location) => location.locationType === 'PHYSICAL'
    ) as PhysicalLocation[];
  }

  @computed
  public getServiceAreas(): ServiceAreaLocation[] {
    return Array.from(this.modelMap.values()).filter(
      (location) => location.locationType === 'SERVICE_AREA'
    ) as ServiceAreaLocation[];
  }

  /**
   * Optimistically delete the Location from the store and send the request to the server.
   * @param id
   */
  @action
  public async delete(id: string) {
    const model = this.modelMap.get(id);
    if (model) {
      this.optimisticDeleteModel(id);
      try {
        await this.rootStore.transport.locationTransport.deleteLocation(id);
      } catch (error) {
        //TODO THIS IS NOT TRIGGERING A RE-RERENDER IN THE UI. FIX THIS
        console.error('Error Deleting Integration Configuration', error);
        this.undoOptimisticDeleteModel(id);
        //Snackbar or Toast Error
      }
    }
  }

  /**
   * Creates a new Service Area for the workspace.
   * @param userInput
   * @param workspaceId
   */
  @action
  public async createServiceArea(
    userInput: CreateServiceAreaResourceRequest
  ): Promise<void> {
    const id = await this.rootStore.transport.locationTransport.createLocation(
      userInput
    );

    /**
     * TODO: This is a temporary fix to get the scope from the resource.
     * Fetching the resource to get the scope
     * When creating locations during Onboarding we don't have the workspaceId available to pull from
     */
    const resource = await this.rootStore.transport.locationTransport.fetchById(
      id
    );

    const { scope } = parseObrn(resource?.obrn!);
    runInAction(async () => {
      const optimisticLocation = new ServiceAreaLocation(
        this.rootStore,
        id,
        scope,
        userInput.name,
        userInput.description,
        userInput.driveTimeMinutes,
        userInput.driveDistanceMiles,
        userInput.serviceAreaType,
        userInput.address?.street1,
        userInput.address?.street2 ?? '',
        userInput.address?.city,
        userInput.address?.state,
        userInput.address?.country ?? 'US',
        userInput.address?.postalCode,
        'WAITING_FOR_MAP_IMAGE_GENERATION',
        undefined,
        undefined,
        userInput.postalCodes,
        userInput.counties,
        userInput.states,
        userInput.countries
      );

      optimisticLocation.makeObservable();
      this.modelMap.set(id, optimisticLocation);
      this.createOptimisticSyncMetaForNewModel(id, optimisticLocation);
    });
    /**
     * Loading the map image for the location is not working.
     * When the map image generation status is ACTIVE, the map image is not being loaded in the UI.
     */
    this.rootStore.transport.locationTransport.pollForMapImageGeneration(id);
  }

  /**
   * Creates a new Physical Location for the workspace.
   * @param userInput
   * @param workspaceId
   */
  @action
  public async createPhysicalLocation(
    userInput: CreatePhysicalLocationResourceRequest
  ): Promise<void> {
    const id = await this.rootStore.transport.locationTransport.createLocation(
      userInput
    );

    /**
     * TODO: This is a temporary fix to get the scope from the resource.
     * Fetching the resource to get the scope
     * When creating locations during Onboarding we don't have the workspaceId available to pull from
     */
    const resource = await this.rootStore.transport.locationTransport.fetchById(
      id
    );

    const { scope } = parseObrn(resource?.obrn!);

    // Wrap only synchronous state mutations in runInAction
    runInAction(() => {
      const optimisticLocation = new PhysicalLocation(
        this.rootStore,
        id,
        scope,
        userInput.name,
        userInput.description,
        userInput.address.street1,
        userInput.address.street2 ?? '',
        userInput.address.city,
        userInput.address.state,
        userInput.address.country ?? 'US',
        userInput.address.postalCode,
        'WAITING_FOR_MAP_IMAGE_GENERATION'
      );

      optimisticLocation.makeObservable();
      this.modelMap.set(id, optimisticLocation);
      this.createOptimisticSyncMetaForNewModel(id, optimisticLocation);
    });
    /**
     * Loading the map image for the location is not working.
     * When the map image generation status is ACTIVE, the map image is not being loaded in the UI.
     */
    this.rootStore.transport.locationTransport.pollForMapImageGeneration(id);
  }
}

export default LocationStore;
