import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { NavItem } from '../../../components/navigation/dashboard-route.type';

import { ErrorBoundary } from 'react-error-boundary';
import { FullPageErrorMessage } from '../campaigns/components/full-page-error-message';
import { LandingPageDetailMobxWrapper } from './landing-page-detail-mobx-wrapper';
import { LandingPageDetailPage } from './landing-page-detail-page';
import { LandingPageListPage } from './landing-page-list-page';

/**
 * Routes for the Campaigns Module of the Outbound Platform
 */
export const landingPageRoutes: NavItem = {
  title: 'Landing Pages',
  route: 'landing-pages',
  icon: 'LandingPageIcon',
  component: () => (
    <ErrorBoundary
      fallback={
        <FullPageErrorMessage
          heading={'Unable to Load Landing Pages'}
          message={
            'We apologize for the inconvenience. Our team has been notified of the error. If the issue persists, please contact support.'
          }
          actionLabel={'Reload Page'}
          actionOnClick={() => window.location.reload()}
        />
      }
    >
      <Suspense>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  ), //Handle the List or Detail View
  showInNavMenu: ['workspace-side-nav'],
  childRoutes: [
    {
      title: 'Landing Pages',
      route: '',
      index: true,

      component: () => <LandingPageListPage />,
    },
    {
      title: '',
      route: ':landingPageId',
      component: () => <Outlet />,
      childRoutes: [
        {
          index: true,
          title: 'Landing Page Detail',
          route: 'details',
          component: LandingPageDetailPage,
          childRoutes: [
            {
              title: 'Landing Page Detail',
              route: '',
              index: true,
              component: LandingPageDetailMobxWrapper,
            },
          ],
        },
      ],
    },
  ],
};
