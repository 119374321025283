import { ObTypography } from '@outbound/design-system';
import { useEffect } from 'react';
import { BasePageLayout } from '../../../components/layouts/base-page';
import {
  useCompleteWorkspaceTaskMutation,
  useFetchWorkspaceTasks,
} from '../../../query/workspace/use-workspace-tasks-endpoints';
import { CreateCampaignDrawerWrapper } from '../../dashboard/campaigns/hooks/use-create-campaign-drawer';

export const FirstCampaignPage = () => {
  const { data: tasks } = useFetchWorkspaceTasks();
  const { mutateAsync: completeTask } = useCompleteWorkspaceTaskMutation();

  /**
   * Check if we have an onboarding task and complete it if it's assigned
   * If we are hitting this screen we assume the user has confirmed their
   * playbook and is ready to move on.
   */
  useEffect(() => {
    if (tasks && tasks.items.length > 0) {
      const onboardingTaskToComplete = tasks.items.find(
        (task) =>
          task.type === 'COMPLETE_ONBOARDING' && task.status === 'ASSIGNED'
      );
      if (onboardingTaskToComplete) {
        completeTask(onboardingTaskToComplete.id);
      }
    }
  }, [tasks, completeTask]);

  return (
    <BasePageLayout contentPosition='center-top'>
      <div className='h-screen max-w-3xl'>
        <div className='p-5'>
          <ObTypography variant='h2'>Craft Your Marketing Plan</ObTypography>
          <ObTypography
            variant='body1'
            color='secondary'
          >
            Build your perfect Marketing Plan and get ready to launch your first
            advertising campaign with Outbound.
          </ObTypography>
        </div>
        <div className='w-full h-screen'>
          <CreateCampaignDrawerWrapper variant='full-screen' />
        </div>
      </div>
    </BasePageLayout>
  );
};
