import { ClientDelta, LeadQualificationStatus } from '@outbound/types';
import { AxiosInstance } from 'axios';
import ServerMutationHandler from '../../sync-framework/muation-handler/abstract-server-mutation-handler';
import { ObjectDeltaMap } from '../../sync-framework/muation-handler/object-delta-map';
import { OptionalClientDelta } from '../../sync-framework/muation-handler/optional-client-delta';
import Lead from '../domain/lead';

class QualificationStatusAndPriorityMutationHandler extends ServerMutationHandler {
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  async handle(objectDeltaMap: ObjectDeltaMap): Promise<void> {
    objectDeltaMap.forEach((deltasForLead, leadId) => {
      const qualificationStatus =
        this.findQualificationStatusChange(deltasForLead);
      const priority = this.findPriorityChange(deltasForLead);
      if (
        this.attributeHasBeenUpdated(priority) &&
        this.attributeHasBeenUpdated(qualificationStatus)
      ) {
        this.notifyServerOfQualificationStatusChange({
          leadId,
          qualificationStatus: qualificationStatus.value,
          prioritize: {
            directlyUnder: priority.value.directlyUnder,
            directlyAbove: priority.value.directlyAbove,
          },
        });
      } else if (this.attributeHasBeenUpdated(priority)) {
        this.notifyServerOfPriorityChangeWithoutQualificationStatusHasNotChanged(
          {
            leadId,
            directlyUnder: priority.value.directlyUnder,
            directlyAbove: priority.value.directlyAbove,
          }
        );
      } else if (this.attributeHasBeenUpdated(qualificationStatus)) {
        this.notifyServerOfQualificationChangeWithoutPriorityDetails({
          leadId,
          qualificationStatus: qualificationStatus.value,
        });
      }
    });
  }

  private findPriorityChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.priorityFractionalIndex, deltas);
  }

  private findQualificationStatusChange(
    deltas: ClientDelta[]
  ): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.qualificationStatus, deltas);
  }

  private async notifyServerOfPriorityChangeWithoutQualificationStatusHasNotChanged({
    leadId,
    directlyUnder,
    directlyAbove,
  }: {
    leadId: string;
    directlyUnder?: string | 'TOP_OF_LIST';
    directlyAbove?: string | 'BOTTOM_OF_LIST';
  }): Promise<void> {
    const updatePriorityOnlyRequestBody = {
      directlyUnder: directlyUnder,
      directlyAbove: directlyAbove,
    };

    try {
      await this.axiosInstance.post(
        `/leads/${leadId}/update-priority`,
        updatePriorityOnlyRequestBody
      );
    } catch (error) {
      throw error;
    }
  }

  private async notifyServerOfQualificationChangeWithoutPriorityDetails({
    leadId,
    qualificationStatus,
  }: {
    leadId: string;
    qualificationStatus: LeadQualificationStatus;
  }): Promise<void> {
    return this.notifyServerOfQualificationStatusChange({
      leadId,
      qualificationStatus,
    });
  }

  private async notifyServerOfQualificationStatusChange({
    leadId,
    qualificationStatus,
    prioritize,
  }: NotifyServerOfQualificationStatusChangeInput): Promise<void> {
    const updateQualificationStatusRequestBody = {
      qualificationStatus,
      prioritize: prioritize,
    };
    try {
      await this.axiosInstance.post(
        `/leads/${leadId}/update-qualification-status`,
        updateQualificationStatusRequestBody
      );
    } catch (error) {
      throw error;
    }
  }
}

export default QualificationStatusAndPriorityMutationHandler;

interface NotifyServerOfQualificationStatusChangeInput {
  leadId: string;
  qualificationStatus: LeadQualificationStatus;
  prioritize?: {
    directlyUnder?: string | 'TOP_OF_LIST';
    directlyAbove?: string | 'BOTTOM_OF_LIST';
  };
}
