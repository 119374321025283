import { RootStore } from '../../../root-store';
import { AbstractLeadEvent, LeadEventDto } from './abstract-lead-event';

export interface CreatedFromFormSubmissionLeadEventContext {
  formSubmissionObrn: string;
  formObrn: string;
  formName: string;
  formSubmissionTimestamp: string;
  values: {
    fullName: string;
    email: string;
    phone: string;
    zipCode: string;
    message: string;
  };
}

const DEFAULT_FORM_FIELD_KEYS = {
  fullName: 'ob-default:fullName',
  phone: 'ob-default:phoneNumber',
  zipCode: 'ob-default:zipCode',
  email: 'ob-default:email',
  message: 'ob-default:message',
};

export class LeadEventCreatedFromForm extends AbstractLeadEvent<CreatedFromFormSubmissionLeadEventContext> {
  protected constructor(
    rootStore: RootStore,
    leadEventData: LeadEventDto,
    context: CreatedFromFormSubmissionLeadEventContext
  ) {
    super(rootStore, leadEventData, context);
  }

  static fromDto(
    rootStore: RootStore,
    data: LeadEventDto
  ): LeadEventCreatedFromForm {
    this.assertContextIsValidForEvent(data.context);
    return new LeadEventCreatedFromForm(rootStore, data, {
      formSubmissionObrn: data.context.formSubmissionObrn,
      formObrn: data.context.formObrn,
      formName: data.context.formName,
      formSubmissionTimestamp: data.context.formSubmissionTimestamp,
      values: {
        fullName: data.context.values[DEFAULT_FORM_FIELD_KEYS.fullName],
        email: data.context.values[DEFAULT_FORM_FIELD_KEYS.email],
        phone: data.context.values[DEFAULT_FORM_FIELD_KEYS.phone],
        zipCode: data.context.values[DEFAULT_FORM_FIELD_KEYS.zipCode],
        message: data.context.values[DEFAULT_FORM_FIELD_KEYS.message],
      },
    });
  }

  static assertContextIsValidForEvent(context: Record<string, any>): void {
    if (context == null) {
      throw new Error('Context is required for CREATED_FROM_SOURCE_FORM event');
    }

    if (context.formSubmissionObrn == null) {
      throw new Error(
        'formSubmissionObrn is required for CREATED_FROM_SOURCE_FORM event'
      );
    }
    if (context.values == null) {
      throw new Error('values is required for CREATED_FROM_SOURCE_FORM event');
    }
    if (context.values[DEFAULT_FORM_FIELD_KEYS.fullName] == null) {
      throw new Error(
        'values.fullName is required for CREATED_FROM_SOURCE_FORM event'
      );
    }
    if (context.values[DEFAULT_FORM_FIELD_KEYS.email] == null) {
      throw new Error(
        'values.email is required for CREATED_FROM_SOURCE_FORM event'
      );
    }
    if (context.values[DEFAULT_FORM_FIELD_KEYS.phone] == null) {
      throw new Error(
        'values.phone is required for CREATED_FROM_SOURCE_FORM event'
      );
    }
    if (context.values[DEFAULT_FORM_FIELD_KEYS.zipCode] == null) {
      throw new Error(
        'values.zipCode is required for CREATED_FROM_SOURCE_FORM event'
      );
    }
    if (context.values[DEFAULT_FORM_FIELD_KEYS.message] == null) {
      throw new Error(
        'values.message is required for CREATED_FROM_SOURCE_FORM event'
      );
    }
  }
}
