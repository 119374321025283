import { InitializingGoogleSearch } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { ReactNode, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { FullPageLoadingSpinner } from '../../../components/layouts/full-page-loading-spinner';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import Campaign from '../../../state/mobx-experiment/campaign/campaign';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';
import Workspace from '../../../state/mobx-experiment/workspace/workspace';
import { ErrorFallback } from './components/error-fallback';
import { FullPageErrorMessage } from './components/full-page-error-message';
import { FullPageSecondaryText } from './components/full-page-secondary-text-message';

type RouteParams = {
  campaignId: string;
  workspaceSlug: string;
};

export const CampaignDetailPage = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
        fallback={
          <FullPageLoadingSpinner reason='campaign-detail-suspense-boundary' />
        }
      >
        <CampaignDetailState />
      </Suspense>
    </ErrorBoundary>
  );
};

export const CampaignDetailState = () => {
  const { workspaceSlug, campaignId } = useParams<RouteParams>();

  const { campaignStore, workspaceStore } = useRootStore();
  const { navigateToWorkspacePicker } = useAppNavigation();

  const workspace = workspaceStore.getById(workspaceSlug!);
  const campaign = campaignStore.getById(campaignId!);

  if (workspace == null) {
    return (
      <FullPageErrorMessage
        message={`We were unable to locate the workspace with the ID ${workspaceSlug}. Please check your URL`}
        heading={'Workspace Not Found'}
        actionLabel={'Select Workspace'}
        actionOnClick={() => {
          navigateToWorkspacePicker();
        }}
      />
    );
  }

  return (
    <CampaignLoader
      campaign={campaign}
      workspace={workspace}
    />
  );
};

const messages: Array<string> = [
  'Researching Optimum Keywords',
  'Generating Ad Variations',
  'Just a few more seconds...',
];

/**
 * Forces the campaign to load regardless of the route and regardless if the page uses the campaign data.
 * Also deals with unhappy paths where we wouldn't want to show the page.
 * It is important to realize that this component will not show if the campaign is being lazy loaded.
 * This will default back to the Suspense Boundary above.
 * @returns
 */
export const CampaignLoader = observer(
  ({ campaign }: { campaign: Campaign | null; workspace: Workspace }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [setupMessage, setSetupMessage] = useState<string>(
      'Setting Up Campaign'
    );

    useEffect(() => {
      if (campaign?.lifecycleStatus === 'INITIALIZING') {
        let index = 0;

        const intervalId = setInterval(() => {
          setSetupMessage(messages[index]);
          index += 1;

          // Clear interval when all messages are shown
          if (index >= messages.length) {
            clearInterval(intervalId);
          }
        }, 4000); // 5 seconds delay between each message

        // Cleanup function to clear interval if the component unmounts
        return () => clearInterval(intervalId);
      }
    }, [campaign?.lifecycleStatus]);

    if (campaign == null) {
      return (
        <FullPageErrorMessage
          heading='Campaign does not exist'
          actionLabel='Back'
          actionOnClick={() => window.history.back()}
          message='The campaign you are trying to access does not exist. Please check your link and ensure you are logged in with the correct account.'
        />
      );
    }

    if (campaign.lifecycleStatus === 'INITIALIZING') {
      /**
       * Opertunity to show a progress bar with callouts of everything we are doing for the user
       * Researching Optimum Keywords
       * Generating 240 Ad Variations For Your Campaign
       * Designing Landing Pages
       *
       */
      return <InitializingGoogleSearch message={setupMessage} />;
    }

    if (campaign.lifecycleStatus === 'INITIALIZATION_FAILED') {
      return (
        <FullPageErrorMessage
          heading='Failed to Create Campaign'
          actionLabel='Remove Campaign'
          actionOnClick={() => {
            campaign.delete();
            navigate('../..');
          }}
          message='Something went wrong when we were trying to setup this campaign. Our team has been notified and we apologize for the inconvenience. If the issue persists please contact us.'
        />
      );
    }

    if (campaign.lifecycleStatus === 'ARCHIVED') {
      return <FullPageSecondaryText message='Campaign has been Archived' />;
    }

    setTimeout(() => {
      // Check if the query parameter exists
      if (searchParams.has('hide-side-nav')) {
        // Remove the query parameter

        setSearchParams(
          (current) => {
            current.delete('hide-side-nav');
            return new URLSearchParams(current);
          },
          { replace: true }
        );
      }
    }, 3000);

    return (
      <VerticalScrollableContent>
        <div className='max-w-screen-xl w-full m-auto'>
          <Outlet />
        </div>
      </VerticalScrollableContent>
    );
  }
);

CampaignLoader.displayName = 'CampaignLoader';

const VerticalScrollableContent = ({ children }: { children: ReactNode }) => {
  return <div className='h-screen overflow-y-scroll'>{children}</div>;
};
