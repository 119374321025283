import { RootStore } from '../../../root-store';
import { AbstractLeadEvent, LeadEventDto } from './abstract-lead-event';

const FROM_CONTACT_DETAILS_KEY = 'fromContactDetails';
const TO_CONTACT_DETAILS_KEY = 'toContactDetails';

export interface ContactDetailsUpdatedLeadEventContext {
  fromContactDetails: {
    email?: string;
    phone?: string;
    zipCode?: string;
    firstName?: string;
    lastName?: string;
  };
  toContactDetails: {
    email?: string;
    phone?: string;
    zipCode?: string;
    firstName?: string;
    lastName?: string;
  };
}

export class LeadEventContactDetailsUpdated extends AbstractLeadEvent<ContactDetailsUpdatedLeadEventContext> {
  protected constructor(
    rootStore: RootStore,
    leadEventData: LeadEventDto,
    context: ContactDetailsUpdatedLeadEventContext
  ) {
    super(rootStore, leadEventData, context);
  }

  static fromDto(
    rootStore: RootStore,
    data: LeadEventDto
  ): LeadEventContactDetailsUpdated {
    const rawContext = data.context;
    this.assertContextIsValidForEvent(rawContext);

    return new LeadEventContactDetailsUpdated(rootStore, data, {
      fromContactDetails: rawContext[FROM_CONTACT_DETAILS_KEY],
      toContactDetails: rawContext[TO_CONTACT_DETAILS_KEY],
    });
  }

  static create(
    rootStore: RootStore,
    fromContactDetails: Record<string, any>,
    toContactDetails: Record<string, any>,
    timestamp: string
  ) {
    const user = rootStore.authenticatedUser;
    if (user == null) {
      throw new Error('Only authenticated users can create events');
    }

    return new LeadEventContactDetailsUpdated(
      rootStore,
      {
        timestamp,
        eventType: 'CONTACT_DETAILS_MODIFIED',
        actorObrn: user.obrn,
        context: {
          fromContactDetails,
          toContactDetails,
        },
      },
      {
        fromContactDetails: fromContactDetails,
        toContactDetails: toContactDetails,
      }
    );
  }

  static assertContextIsValidForEvent(context: Record<string, any>): void {
    if (context == null) {
      throw new Error('Context is required for CONTACT_DETAILS_MODIFIED event');
    }

    if (context[FROM_CONTACT_DETAILS_KEY] == null) {
      throw new Error('from is required for CONTACT_DETAILS_MODIFIED event');
    }
    if (context[TO_CONTACT_DETAILS_KEY] == null) {
      throw new Error('to is required for CONTACT_DETAILS_MODIFIED event');
    }
  }
}
