import { useDrawerService } from '@outbound/design-system';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { LeadContactEditDrawer } from './lead-contact-edit-drawer';
import { LEAD_CONTACT_EDIT_DRAWER_TEST_ID } from './lead-contact-edit-drawer-test-id';
import { LeadContactEditFormValue } from './lead-contact-edit-form.def';

export const useEditLeadContactDrawer = () => {
  const drawerService = useDrawerService();
  const openDrawerInEditMode = (lead: Lead) => {
    drawerService
      .pushDrawer({
        testid: LEAD_CONTACT_EDIT_DRAWER_TEST_ID,
        title: 'Edit Contact',
        description: 'Update Contact Details of the Lead',
        drawerContent: (
          <LeadContactEditDrawer
            onFormSubmitCallback={(data) => {
              updateLeadModelWithFormValuesIfChanged(lead, data);
              lead.save();
              drawerService.popDrawer();
              return Promise.resolve();
            }}
            leadToEdit={lead}
          />
        ),
      })
      .catch((_error) => {
        //Action Cancelled, No action needed
      });
  };
  return {
    openDrawerInEditMode,
  };
};
const updateLeadModelWithFormValuesIfChanged = (
  lead: Lead,
  formData: LeadContactEditFormValue
) => {
  console.log('Edit Data', formData);
  if (formData.leadContactFirstName !== lead.firstName) {
    lead.firstName = formData.leadContactFirstName;
  }
  if (formData.leadContactLastName !== lead.lastName) {
    lead.lastName = formData.leadContactLastName;
  }
  if (formData.leadContactEmail !== lead.email) {
    lead.email = formData.leadContactEmail;
  }
  if (formData.leadContactPhone !== lead.phone) {
    lead.phone = formData.leadContactPhone;
  }
  if (formData.leadContactZipCode !== lead.zipCode) {
    lead.zipCode = formData.leadContactZipCode;
  }
};
