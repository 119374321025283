import { parseObrn } from '@otbnd/utils';
import { LeadEventType } from '@outbound/types/src/lead/api-resource/lead-event-type';
import { catchSuspense } from 'src/state/mobx-experiment/framework/suspense-utils';
import { RootStore } from '../../../root-store';
import EventActor from './event-actor';

export interface LeadEventDto {
  timestamp: string;
  eventType: LeadEventType;
  actorObrn: string;
  context: Record<string, any>;
}

export abstract class AbstractLeadEvent<T> {
  private _rootStore: RootStore;
  private _eventType: LeadEventType;
  private _timestamp: string;
  private _actorObrn: string;
  private _context: T;

  protected constructor(
    rootStore: RootStore,
    leadEventData: LeadEventDto,
    context: T
  ) {
    this._rootStore = rootStore;
    this._timestamp = leadEventData.timestamp;
    this._eventType = leadEventData.eventType;
    this._actorObrn = leadEventData.actorObrn;
    this._context = context;
  }

  get timestamp(): string {
    return this._timestamp;
  }

  get eventType(): LeadEventType {
    return this._eventType;
  }

  get actorObrn(): string {
    return this._actorObrn;
  }

  getActor(): EventActor {
    return catchSuspense(() => {
      try {
        const { objectType, localPathId } = parseObrn(this._actorObrn);
        if (objectType === 'user') {
          const user = this._rootStore.userStore.getById(localPathId);
          if (user == null) {
            return EventActor.fromNotFoundUser({
              obrn: this._actorObrn,
            });
          } else {
            return EventActor.fromUser({
              firstName: user.givenName,
              lastName: user.familyName,
              email: user.email,
              avatarImageUrl: user.profileImageUrl,
              obrn: this._actorObrn,
              id: user.id,
            });
          }
        } else {
          return EventActor.fromSystem({
            id: this._actorObrn,
            name: this._actorObrn,
            avatarImageUrl: null,
          });
        }
      } catch (e) {
        console.error('Failed to parse obrn', this._actorObrn, e);
        return EventActor.fromNotFoundUser({
          obrn: this._actorObrn,
        });
      }
    });
  }

  get context(): T {
    return this._context;
  }

  static compareByTimestamp(
    a: AbstractLeadEvent<any>,
    b: AbstractLeadEvent<any>
  ): number {
    return a.timestamp.localeCompare(b.timestamp);
  }
}
