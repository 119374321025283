import { LocationMapGenerationStatus, LocationType } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ObLoadingSpinner } from '../../components/elements/ob-loading-spinner/ob-loading-spinner';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObBadge } from '../../indicators';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';
import { CardVariantDeprecatedProps } from '../card.type';
import mapPlaceholder from './storybook/service-area-map-placeholder.png';

interface LocationCtaProps {
  onNavigateCallback: (locationObrn: string) => void;
}

interface LocationAttributeProps {
  /**
   * The Outbound Resource Name of the location
   */
  obrn: string;

  /*
   * The ID of the location. Unique within the context of the location domain
   */
  id: string;

  /**
   * The name of the location
   */
  name: string;

  /**
   * The description of the location
   */
  description: string;

  /**
   * The Location Type
   */
  type: LocationType;

  /**
   * Indicates the status of the location map generation.
   * (When a location is first created this map will be generating and won't be immediately available).
   * This can also need to be updated if location data that would affect the map is updated.
   */
  locationImageStatus: LocationMapGenerationStatus;

  locationImageForDarkBackgroundPublicUrl?: string;

  locationImageForLightBackgroundPublicUrl?: string;
}

/**
 * The public interface for the Location Card
 */
export interface LocationCardProps
  extends CardVariantDeprecatedProps,
    LocationCtaProps,
    LocationAttributeProps {}

/**
 * Internal interface for the Location Card used to implement the different variants
 */
export type LocationImplProps = Omit<LocationCardProps, 'variant'>;

export const LocationCard = ({ variant, ...props }: LocationCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'micro':
    case 'mini':
    case 'list':
    case 'detail':
      return (
        <ObTypography color='negative'>
          Location <span>{variant}</span> Card Not Implemented
        </ObTypography>
      );
  }
};

const GridCard = ({
  obrn,
  name,
  description,
  type,
  locationImageStatus,
  locationImageForDarkBackgroundPublicUrl,
  onNavigateCallback,
}: LocationImplProps) => {
  return (
    <div
      tabIndex={0}
      role='button'
      onClick={() => onNavigateCallback?.(obrn)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback?.(obrn);
        }
      }}
      className='flex flex-col rounded-lg border border-dark/border/default/normal hover:border-dark/action/primary-v2/normal transition-all overflow-hidden bg-dark/background/surface focus-visible:shadow-interactive focus:outline-none'
    >
      <div className='relative overflow-hidden'>
        <div
          className={cx(
            'w-full bg-no-repeat bg-center bg-cover h-[188px] ',
            locationImageStatus != 'ACTIVE' && 'blur-md opacity-60'
          )}
          style={{
            backgroundImage: `url(${
              locationImageStatus == 'ACTIVE'
                ? locationImageForDarkBackgroundPublicUrl
                : mapPlaceholder
            })`,
          }}
        />
        {locationImageStatus === 'WAITING_FOR_MAP_IMAGE_GENERATION' && (
          <div className='absolute top-0 right-0'>
            <div className='flex flex-row gap-2 justify-center p-2'>
              <ObLoadingSpinner
                size={16}
                includeBrand={false}
              />
              <ObTypography
                variant='subtitle2'
                color='secondary'
              >
                Generating Map
              </ObTypography>
            </div>
          </div>
        )}
        <div className='absolute top-0'>
          <div className='flex items-center'>
            <div className='px-2 py-1'>
              <ObBadge
                level='NEUTRAL'
                variant='rounded'
                content={type === 'PHYSICAL' ? 'Address' : 'Service Area'}
              />
            </div>
          </div>
        </div>
        <div
          className='absolute bottom-0 w-full '
          style={{
            background:
              'linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))',
          }}
        >
          <div className='p-2 flex flex-row justify-between'>
            <div className='flex flex-row gap-2 items-start'>
              <div className='pt-[10px]'>
                <ObIcon
                  icon='MarkerPin06'
                  size='medium'
                />
              </div>
              <div className='flex flex-col'>
                <ObTypography
                  variant='h2'
                  className='line-clamp-1'
                >
                  {name}
                </ObTypography>
                <ObTypography
                  variant='body1'
                  color='secondary'
                  className='line-clamp-1'
                >
                  {description}
                </ObTypography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
