'use client';
import {
  createContext,
  FC,
  Fragment,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Deferred } from '@otbnd/utils';

import { v4 as uuidv4 } from 'uuid';

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { cva, cx } from 'class-variance-authority';
import PropTypes from 'prop-types';
import { ObDrawer } from '../../organisms/ob-drawer/ob-drawer';

export interface DrawerServiceContextValue {
  pushDrawer: (drawer: PushDrawer) => Promise<void>;
  /**
   * Closes the top drawer in the stack.
   * @param resolve - Indicates if the drawer should resolve or reject the promise that was returned when the drawer was opened.
   *                  If true the promise will resolve, if false the promise will reject.
   *                  A small way for the drawer opener to receive feedback on the drawer close. For example if the user clicked cancel
   *                  the drawer could reject the promise, if the user clicked save the promise could resolve.
   *                  The click outside to close or esc to close will always reject the promise.
   * @param data - Optional Data to provide that will be returned to the drawer opener in the resolved or rejected promise.
   * @returns
   */
  popDrawer: (resolve?: boolean, data?: any) => Promise<void>;
  closeAllDrawers: () => Promise<void>;
}

const throwError = () => {
  throw new Error('Drawer Service Context not provided');
};

const DrawerServiceContext = createContext<DrawerServiceContextValue>({
  pushDrawer: throwError,
  popDrawer: throwError,
  closeAllDrawers: throwError,
});

interface DrawerServiceProviderProps {
  children?: ReactNode;
}

export interface PushDrawer {
  backdrop?: boolean;
  drawerContent: ReactNode;
  title: string;
  description: string;
  /**
   * Optional Test ID for the drawer, useful to assert that the drawer has been opened in tests without relying on the drawer content.
   */
  testid?: string;
  position?: 'full-height' | 'under-header';
  size?: 'small' | 'medium';
  /**
   * Callback that is called when the drawer is about to close.
   * If the callback returns a promise, the drawer will wait for the promise to resolve before closing.
   * If the promise rejects, the drawer will not close.
   */
  beforeCloseCallback?: () => Promise<void>;
}

interface PushDrawerInternal extends PushDrawer {
  show: boolean;
  id: string;
  /**
   * Resolved or Rejected when the drawer is closed.
   */
  deferred: Deferred<any>;
}

interface DrawerWrapperProps {
  stackIndex: number;
  show: boolean;
  drawerContent: ReactNode;
  title: string;
  description: string;
  backdrop: boolean;
  position: 'full-height' | 'under-header';
  date: Date;
  testId?: string;
  size?: 'small' | 'medium';
  afterLeaveCallback: () => void;
  /**
   * Indicates if the drawer is visible.
   * The drawer is in front if it is the last drawer in the stack.
   */
  isInFront?: boolean;

  drawerStackIndex?: number;
}

const DrawerWrapper: FC<DrawerWrapperProps> = ({
  show = false,
  title,
  description,
  drawerContent,
  backdrop = false,
  position = 'full-height',
  size,
  testId,
  afterLeaveCallback,
  isInFront,
  drawerStackIndex,
}: DrawerWrapperProps) => {
  const drawerService = useDrawerService();

  const drawerSizeClasses = cva('', {
    variants: {
      size: {
        small: [' md:max-w-[420px] md:w-[420px] md:min-w-[420px]'],
        medium: [' w-full md:max-w-[720px] md:min-w-[720px] '],
      },
    },
  });

  return (
    <>
      <Transition
        appear={true}
        as={Fragment}
        show={show}
      >
        <Dialog
          as='div'
          data-testid={testId}
          autoFocus={true}
          data-drawer-dialog-index={drawerStackIndex}
          onClose={(data: any) => {
            if (isInFront) {
              drawerService.popDrawer(false, data);
            }
          }}
          className={`relative z-50`}
        >
          {backdrop && (
            <TransitionChild
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div
                data-ob-drawer__backdrop
                className='fixed inset-0 bg-[#1B1D2C] backdrop-blur-sm bg-opacity-50 transition-opacity'
                aria-hidden='true'
              />
            </TransitionChild>
          )}
          <div className='fixed inset-0 overflow-hidden z-50'>
            <div className='absolute inset-0 overflow-hidden'>
              <TransitionChild
                afterLeave={afterLeaveCallback}
                as={Fragment}
                enter='transform transition ease-in-out duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-100'
                leave='transform transition ease-in-out duration-300'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <DialogPanel
                  data-drawer-panel
                  className={cx(
                    drawerSizeClasses({ size }),
                    ' fixed inset-y-0 right-0 flex max-w-full md:pl-10'
                  )}
                >
                  <ObDrawer
                    position={position}
                    title={title}
                    description={description}
                    onBackCallback={drawerService.popDrawer}
                  >
                    {drawerContent}
                  </ObDrawer>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const DrawerServiceProvider: FC<DrawerServiceProviderProps> = ({
  children,
}) => {
  const [drawerStack] = useState<Array<PushDrawerInternal>>([]);
  const [hack, setHack] = useState(new Date()); //Misuse of state to force a re-render (Should cleanup later)

  /**
   * Pushes a drawer onto the stack and displays it to the user.
   * Handles pushing on a root drawer and subsequent drawers.
   * @param drawer
   * @returns
   */
  const pushDrawer = (drawer: PushDrawer) => {
    const deferred = new Deferred<any>();
    drawerStack.push({ ...drawer, show: true, id: uuidv4(), deferred });
    setHack(new Date()); //Misuse of state to force a re-render (Should cleanup later)
    return deferred.promise;
  };

  /**
   * Hides the drawer from the user and than removes it from the drawer stack.
   * @returns
   */
  const popDrawer = (resolve?: boolean, data?: any) => {
    if (drawerStack.length === 0) {
      /**
       * We should never be in a state where there are no drawers to close unless a consumer is calling popDrawer when it shouldn't.
       */
      throw new Error('No drawers to close');
    }
    /**
     * The intent of the "safeToClose" logic below is to provide the service consumer the ability to reject the drawer close.
     * This is helpful when the drawer has unsaved changes or some other state that should be confirmed before closing.
     * An example usage is to open a modal to confirm the drawer close letting the user know they have unsaved changes and
     * will loose their changes if they close the drawer.
     */
    let safeToClose = drawerStack[drawerStack.length - 1]?.beforeCloseCallback;
    if (safeToClose == undefined) {
      safeToClose = () => Promise.resolve();
    }
    return safeToClose().then(
      () => {
        const drawerToClose = drawerStack[drawerStack.length - 1];
        drawerToClose.show = false;
        setHack(new Date()); //Misuse of state to force a re-render (Should cleanup later)
        setTimeout(() => {
          drawerStack.pop();
          setHack(new Date()); //Misuse of state to force a re-render (Should cleanup later)
        }, 300);
        if (resolve) {
          drawerToClose.deferred.resolve(data);
        } else {
          drawerToClose.deferred.reject(data);
        }
        return Promise.resolve();
      },
      () => {
        return Promise.reject();
      }
    );
  };

  /**
   * Shortcut to close all drawers in the drawer stack.
   * @returns
   */
  const closeAllDrawers = () => {
    console.log('Not Implemented');
    return Promise.resolve();
  };

  const service = useMemo(
    () => ({
      pushDrawer: pushDrawer,
      popDrawer: popDrawer,
      closeAllDrawers: closeAllDrawers,
    }),
    //Service is a singleton and implementations will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * A recursive render function that renders the drawer stack.
   * This is the second generation of the render function.
   * Our first implementation was a simple map over the drawer stack.
   * This worked for a single drawer and kind of worked for multiple drawers however there were
   * issues with click outside to close and any Portal based components on drawer 2 and above.
   * This is due to the way Headless UI deals with what is outside the focus trap.
   *
   * In order to play nice with headless UI we moved to this recursive render function
   * so that all content that is on the top of the stack is always a child of the content below it
   * verse a sibling.
   *
   * This discussion thread helped to identify the issue and informed the solution
   * https://github.com/tailwindlabs/headlessui/pull/1268#discussion_r834775321
   * @param drawerStack
   * @param depth
   * @returns
   */
  const renderStack = (
    drawerStack: Array<PushDrawerInternal>,
    depth: number = 0
  ) => {
    if (drawerStack.length === 0) {
      return null;
    }
    const [drawer, ...rest] = drawerStack;

    return (
      <DrawerWrapper
        key={drawer.id}
        date={hack}
        testId={drawer.testid}
        show={drawer.show}
        size={drawer.size ?? 'medium'}
        position={drawer.position ?? 'full-height'}
        stackIndex={depth}
        backdrop={depth === 0} //Root Drawer will always have backdrop (May change in the future)
        drawerContent={
          (
            <>
              {drawer?.drawerContent} {renderStack(rest, depth + 1)}
            </>
          ) ?? <></>
        }
        title={drawer?.title}
        description={drawer?.description}
        isInFront={rest.length === 0}
        drawerStackIndex={depth}
        afterLeaveCallback={() => {
          //Called after transition is complete
        }}
      />
    );
  };

  return (
    <DrawerServiceContext.Provider value={service}>
      {children}
      {renderStack(drawerStack)}
    </DrawerServiceContext.Provider>
  );
};

DrawerServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const DrawerServiceConsumer = DrawerServiceContext.Consumer;

export const useDrawerService = () => useContext(DrawerServiceContext);
