import { LeadSourceType } from '@outbound/types';
import { LeadSourceLocalizationProps } from '../lead-card/lead-card.type';

export interface FormattedLeadSource {
  formattedLeadSource: string;
}

const leadSourceToLocalizationKeyMap: Record<
  LeadSourceType,
  keyof LeadSourceLocalizationProps
> = {
  FORM_SUBMISSION: 'sourceFormSubmissionLabel',
  CALL_TRACKING: 'sourcePhoneTrackingLabel',
  MANUAL_ENTRY: 'sourceManualEntryLabel',
};

export const formatLeadSourceForDisplay = (
  leadSource: LeadSourceType,
  localization: LeadSourceLocalizationProps
): FormattedLeadSource => {
  let label;
  if (leadSource in leadSourceToLocalizationKeyMap) {
    label = localization[leadSourceToLocalizationKeyMap[leadSource]];
  } else {
    label = 'Unknown Source';
  }
  return {
    formattedLeadSource: label,
  };
};
