import { ObAvatar } from '../../avatars';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantProps } from '../card.type';
import { ReviewAttributeProps } from './review.types';

interface ReviewLocalizationProps {
  localization__NoReviewTextProvided: string;
}

interface ReviewCardCtaProps {
  onNavigateAriaLabel?: string;
  onNavigateCallback: (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>
  ) => void;
}

export interface ReviewCardProps
  extends CardVariantProps,
    ReviewCardCtaProps,
    ReviewLocalizationProps,
    ReviewAttributeProps {}

type ReviewCardImplementationProps = Omit<ReviewCardProps, 'variant'>;

export const ReviewCard = ({ variant, ...props }: ReviewCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'mini':
      return <MiniCard {...props} />;
    case 'micro':
      return <MicroCard {...props} />;
    case 'table':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

/**
 * Grid Variant of the Review Card
 * @param param0
 * @returns
 */
const GridCard = ({
  authorFullName,
  rating,
  reviewText,
  obrn,
  localization__NoReviewTextProvided,
  onNavigateAriaLabel,
  onNavigateCallback,
}: ReviewCardImplementationProps) => {
  return (
    <div
      tabIndex={0}
      data-testid={`card__grid__${obrn}`}
      aria-label={
        onNavigateAriaLabel ||
        `View ${rating} Star Review from ${authorFullName}`
      }
      role='button'
      onClick={(e) => onNavigateCallback?.(e)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback?.(event);
        }
      }}
      className=' focus-visible:shadow-interactive focus:outline-none overflow-hidden'
    >
      <ObCardSurface
        isClickable={true}
        showHoverOverlay={false}
        showBorder={true}
      >
        <div
          className='flex flex-col gap-2 px-4 py-4 justify-between items-start self-stretch flex-1 min-h-[180px] 
         rounded-lg'
        >
          <div className='flex flex-row  justify-between self-stretch'>
            <div className='flex flex-row gap-2 justify-between items-center max-w-[100%]'>
              <div className='flex flex-row gap-3 justify-start items-center max-w-[100%]'>
                <ObAvatar
                  shape='circle'
                  size={'large'}
                  fallbackText={authorFullName}
                />
                <div className='flex flex-1 flex-col max-w-[100%] overflow-auto'>
                  <ObTypography
                    variant='body3'
                    className='line-clamp-1'
                  >
                    {'⭐️'.repeat(rating)}
                  </ObTypography>
                  <ObTypography
                    variant='h3'
                    className='line-clamp-1 pb-1 block min-w-0 truncate '
                  >
                    {authorFullName}
                  </ObTypography>
                </div>
              </div>
            </div>
          </div>
          <div className='overflow-hidden max-w-[100%]'>
            {!reviewText && (
              <ObTypography
                variant='subtitle2'
                color='tertiary'
                className='italic'
              >
                {localization__NoReviewTextProvided}
              </ObTypography>
            )}
            {reviewText && (
              <div
                className='flex-shrink overflow-hidden fade-to-transparent-mask h-full max-w-[100%] '
                role='presentation'
              >
                <ObTypography
                  variant='body2'
                  className='line-clamp-3 max-w-[100%] text-ellipsis min-w-0'
                >
                  {reviewText}
                </ObTypography>
              </div>
            )}
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};

/**
 * Mini Variant of the Review Card
 * @param param0
 * @returns
 */
const MiniCard = ({
  authorFullName,
  rating,
  obrn,
  onNavigateCallback,
}: ReviewCardImplementationProps) => {
  return (
    <div
      tabIndex={0}
      data-testid={`card__mini__${obrn}`}
      role='button'
      onClick={(e) => onNavigateCallback?.(e)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback?.(event);
        }
      }}
      className=' focus-visible:shadow-interactive focus:outline-none'
    >
      <ObCardSurface
        isClickable={true}
        showBorder={true}
      >
        <div
          className='flex flex-col gap-2 px-4 py-2 justify-start items-start self-stretch flex-1 
        hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg'
        >
          <div className='flex flex-row  justify-between self-stretch'>
            <div className='flex flex-row gap-2 justify-between items-center self-stretch'>
              <div className='flex flex-row gap-2 justify-start items-center self-stretch'>
                <ObAvatar
                  shape='circle'
                  size={'large'}
                  fallbackText={authorFullName}
                />
                <div>
                  <ObTypography
                    variant='body2'
                    className='line-clamp-1'
                  >
                    {'⭐️'.repeat(rating)}
                  </ObTypography>
                  <ObTypography
                    variant='h4'
                    className='line-clamp-1'
                  >
                    {authorFullName}
                  </ObTypography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};

/**
 * Micro Variant of the Review Card
 * @param param0
 * @returns
 */
const MicroCard = ({
  authorFullName,
  rating,
  obrn,
  onNavigateCallback,
}: ReviewCardImplementationProps) => {
  return (
    <div
      tabIndex={0}
      data-testid={`card__micro__${obrn}`}
      role='button'
      onClick={(e) => onNavigateCallback?.(e)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onNavigateCallback?.(event);
        }
      }}
      className=' focus-visible:shadow-interactive focus:outline-none'
    >
      <ObCardSurface
        isClickable={true}
        showBorder={false}
      >
        <div
          className='flex flex-col gap-2 p-1 justify-start items-start self-stretch flex-1 
        hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active rounded-lg'
        >
          <div className='flex flex-row gap-2 justify-between items-center self-stretch'>
            <div className='flex flex-row gap-2 justify-start items-center self-stretch'>
              <ObAvatar
                shape='circle'
                size={'xx-small'}
                fallbackText={authorFullName}
              />

              <ObTypography
                variant='body2'
                className='line-clamp-1'
              >
                {'⭐️'.repeat(rating)}
              </ObTypography>
            </div>
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};
