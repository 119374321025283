import {
  ObButton,
  ObGridHeader,
  ObNoContentCta,
  ObTypography,
} from '@outbound/design-system';

import { ReactNode } from 'react';
import { useAppNavigation } from '../../../hooks/use-app-navigation';
import { CampaignCardObserver } from '../../../object-card/campaign/campaign-card-observer';
import { useRootStore } from '../../../state/mobx-experiment/use-root-store';
import { FullPageErrorMessage } from './components/full-page-error-message';
import { useCreateCampaignDrawer } from './hooks/use-create-campaign-drawer';

export const CampaignsPage = () => {
  const { navigateToCampaignById } = useAppNavigation();

  const { campaignStore } = useRootStore();

  const campaigns = campaignStore.list();

  const { openCreateCampaignDrawer } = useCreateCampaignDrawer();

  const createAndNavigateToCampaign = () => {
    openCreateCampaignDrawer().then((campaign: any) => {
      console.log('Created campaign', campaign);
      navigateToCampaignById(campaign.campaignId);
    });
  };

  if (campaigns.length === 0) {
    return (
      <FullPageErrorMessage
        heading={'No Campaigns'}
        message={'Begin advertising your business by creating a campaign'}
        actionLabel={'Create Campaign'}
        actionOnClick={() => openCreateCampaignDrawer()}
      />
    );
  }

  return (
    <VerticalScrollableContent>
      <div className='max-w-screen-lg w-full m-auto min-h-screen flex'>
        <div className='flex flex-col p-6 h-full flex-1'>
          <header>
            <ObGridHeader
              title={'Campaigns'}
              count={campaigns.length}
              controls={
                <ObButton
                  label={'New Campaign'}
                  size='large'
                  variant='outline'
                  onClick={() => createAndNavigateToCampaign()}
                />
              }
            />
          </header>
          <div className='flex sm:hidden py-3'>
            <ObButton
              label={'New Campaign'}
              size='medium'
              variant='outline'
              onClick={() => createAndNavigateToCampaign()}
            />
          </div>
          {campaigns.length === 0 && (
            <section className='h-full flex justify-center items-center pt-4'>
              <ObNoContentCta
                title={'Launch Your Marketing in Minutes!'}
                description={`Once you've created a campaign you will be able to access it from here`}
                /* Standard icon for no data so we have consistency across data-grids*/
                iconName={'fileSearch'}
                buttonText={'Create Campaign'}
                onButtonClick={() => createAndNavigateToCampaign()}
              />
            </section>
          )}
          {campaigns.length > 0 && (
            <section className='h-full flex justify-center items-start pt-4'>
              <div className=' lg:hidden grid grid-cols-1 gap-8 w-full'>
                {campaigns.map((campaign) => (
                  <CampaignCardObserver
                    key={campaign.obrn}
                    campaign={campaign}
                    variant='grid'
                  />
                ))}
              </div>
              <table className='hidden lg:block border-separate border-spacing-y-4 w-full'>
                <thead className='w-full'>
                  <tr className='w-full'>
                    <th className='text-left px-4 py-2 w-1/3 '>
                      <ObTypography
                        variant='subtitle3'
                        color='secondary'
                        className='uppercase text-left'
                        as='span'
                      >
                        Campaign
                      </ObTypography>
                    </th>
                    <th className='text-left px-4 py-2 '>
                      <ObTypography
                        variant='subtitle3'
                        color='secondary'
                        className='uppercase text-left'
                        as='span'
                      >
                        Status
                      </ObTypography>
                    </th>
                    <th className='text-left px-4 py-2 '>
                      <ObTypography
                        variant='subtitle3'
                        color='secondary'
                        className='uppercase text-left'
                        as='span'
                      >
                        Integration
                      </ObTypography>
                    </th>
                    {/* <th className='text-left px-4 py-2 '>
                    <ObTypography
                      variant='subtitle3'
                      color='secondary'
                      className='uppercase text-left'
                      as='span'
                    >
                      Health
                    </ObTypography>
                  </th> */}
                  </tr>
                </thead>
                <tbody className='w-full'>
                  {campaigns.map((campaign) => (
                    <CampaignCardObserver
                      key={campaign.obrn}
                      campaign={campaign}
                      variant='table'
                    />
                  ))}
                </tbody>
              </table>
            </section>
          )}
        </div>
      </div>
    </VerticalScrollableContent>
  );
};

const VerticalScrollableContent = ({ children }: { children: ReactNode }) => {
  return <div className='h-screen overflow-y-scroll'>{children}</div>;
};
