import { UserResource } from '@outbound/types';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import User from './user';
import UserTransformer from './user-transformer';
import UserTransport from './user-transport';

class UserStore extends BaseStore<User, UserResource> {
  constructor(rootStore: RootStore, resourceTransport: UserTransport) {
    super(rootStore, 'user', resourceTransport, new UserTransformer(rootStore));
  }

  protected async requestLoadModelFromServer(_id: string): Promise<void> {
    await this.rootStore.transport.userTransport.requestResourceById(_id);
  }
}

export default UserStore;
