import { cx } from 'class-variance-authority';

type DescriptionDetailsProps = React.ComponentPropsWithoutRef<'dd'> & {
  testId: string;
};

export const DescriptionDetails = ({
  className,
  testId,
  ...props
}: DescriptionDetailsProps) => {
  return (
    <dd
      {...props}
      data-testid={`${testId}__detail`}
      className={cx(
        className,
        //Pulled from Catalyst. Will come back and update to OB Styles when I have time
        'pt-1 pb-3 text-light/content/primary  sm:border-zinc-950/5 sm:pb-3 sm:nth-2:border-none dark:text-dark/content/primary dark:sm:border-white/5'
      )}
    />
  );
};
