import { LeadQualificationStatus } from '@outbound/types';
import { LeadQualificationStatusLocalizationProps } from '../lead-card/lead-card.type';

export interface FormattedLeadQualificationStatus {
  formattedLeadQualificationStatus: string;
}

const leadQualificationStatusToLocalizationKeyMap: Record<
  LeadQualificationStatus,
  keyof LeadQualificationStatusLocalizationProps
> = {
  QUALIFIED: 'qualifiedLabel',
  UNQUALIFIED: 'unqualifiedLabel',
  WORKING: 'workingLabel',
  NURTURE: 'nurturingLabel',
  NEW: 'newLabel',
};

export const formatLeadQualificationStatusForDisplay = (
  leadQualificationStatus: LeadQualificationStatus,
  localization: LeadQualificationStatusLocalizationProps
): FormattedLeadQualificationStatus => {
  let label;
  if (leadQualificationStatus in leadQualificationStatusToLocalizationKeyMap) {
    label =
      localization[
        leadQualificationStatusToLocalizationKeyMap[leadQualificationStatus]
      ];
  } else {
    label = 'Unknown Status';
  }
  return {
    formattedLeadQualificationStatus: label,
  };
};
