import { cx } from 'class-variance-authority';
import { motion } from 'motion/react';
import { ReactNode } from 'react';
import { ObDrawerFooter } from '../../../components/molecules/ob-drawer-footer/ob-drawer-footer';

export interface ObPeekabooFooterTemplateProps {
  /**
   * The content of the drawer body
   */
  children?: ReactNode;
  /**
   * Whether the footer should be shown
   */
  showFooter?: boolean;
  /**
   * The label for the affirmative action
   */
  affirmativeActionButtonLabel: string;
  /**
   * The callback for the affirmative action
   */
  affirmativeActionButtonCallback: () => Promise<void>;
  /**
   * The label for the dismissive action
   */
  dismissiveActionButtonLabel: string;
  /**
   * The callback for the dismissive action
   */
  dismissiveActionButtonCallback: () => Promise<void>;
}
export const ObPeekabooFooterTemplate = ({
  children,
  showFooter = true,
  affirmativeActionButtonCallback,
  affirmativeActionButtonLabel,
  dismissiveActionButtonCallback,
  dismissiveActionButtonLabel,
}: ObPeekabooFooterTemplateProps) => {
  return (
    <main
      className={cx(
        'flex flex-grow flex-col h-full box-border scroll-overlay overflow-y-auto overflow-x-clip px-4'
      )}
    >
      <div data-footer-visible={showFooter}>
        {children}
        <motion.div
          variants={
            showFooter
              ? { hidden: { opacity: 0, y: 0 }, visible: { opacity: 1, y: 0 } }
              : { hidden: { opacity: 1, y: 0 }, visible: { opacity: 0, y: 0 } }
          }
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ duration: 0.3 }}
          className={cx(
            'sticky bottom-0 pt-4',
            showFooter ? 'pointer-events-auto' : 'pointer-events-none'
          )}
        >
          <ObDrawerFooter
            primaryActionLabel={affirmativeActionButtonLabel}
            primaryActionCallback={affirmativeActionButtonCallback}
            secondaryActionLabel={dismissiveActionButtonLabel}
            secondaryActionCallback={dismissiveActionButtonCallback}
          />
        </motion.div>
      </div>
    </main>
  );
};
