import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import { useAuth0Extended } from '../../auth/use-auth0-extended';
import { FullPageLoadingSpinner } from '../../components/layouts/full-page-loading-spinner';
import { usePollForTargetWorkspace } from '../../query/my-workspaces/use-my-workspaces-endpoints';
import { getInviteSearchParams } from '../../services/Auth0Service';
import {
  userIsAMemberOfExactlyOneWorkspace,
  userIsNotAMemberOfAnyWorkspace,
} from '../../services/TenantService';

const AppRedirect = () => {
  const {
    isAuthenticated,
    isAuthenticatedWithWorkspace,
    authenticatedWorkspaceId,
    isLoading,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    switchOrganization,
  } = useAuth0Extended();

  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();

  const loginToWorkspaceIdRaw = searchParams.get('login-to-workspace');
  const inviteAuth0OrganizationId = searchParams.get('organization');
  const inviteToken = searchParams.get('invitation');
  const inviteFlow = inviteToken != null && inviteAuth0OrganizationId != null;

  console.log('loginToWorkspaceIdRaw', loginToWorkspaceIdRaw);

  const { navigateToWorkspacePicker, navigateToWorkspaceHome } =
    useAppNavigation();

  const {
    useQueryOutput: myWorkspacesQuery,
    pollsRemaining,
    isPollingForWorkspaceInProgress,
  } = usePollForTargetWorkspace({
    workspaceId: loginToWorkspaceIdRaw,
    maxPollCount: inviteFlow ? -1 : 10,
    refreshInterval: 2000,
  });

  const { data: myWorkspaces, isLoading: myWorkspacesIsLoading } =
    myWorkspacesQuery;

  useEffect(() => {
    if (inviteFlow) {
      console.log('Invite Present in URL Search Params');
      const { organization, invitation } = getInviteSearchParams(searchParams);
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}${pathname}`,
          organization: organization,
          invitation: invitation,
        },
      });
      //No Further Action is Required
      return;
    }

    if (
      isLoading || //Waiting on Auth0 to Initialize
      (isAuthenticated && myWorkspacesIsLoading) //Authenticated as User, Waiting on Tenants Query
    ) {
      if (isAuthenticatedWithWorkspace) {
        console.log(
          'Authenticated with Workspace, Redirecting to Workspace Dashboard'
        );
        navigateToWorkspaceHome(authenticatedWorkspaceId!);
      }
      /*
      When the application is initialized to the root we examine
      the current status of the users authentication and make a decision
      on where to route them. While we are waiting for the information we
      need to make a decision we will show them a full page loading indicator
    */
      console.log('Required Redirect Data Loading...', {
        isLoading,
        myWorkspacesIsLoading,
        isAuthenticated,
        user,
        loginToWorkspaceIdRaw,
        pollsRemaining,
        isPollingForWorkspaceInProgress,
      });
    } else if (
      loginToWorkspaceIdRaw != null &&
      isAuthenticated &&
      isPollingForWorkspaceInProgress
    ) {
      console.log(
        'Waiting for Target Workspace to Load. With Onboarding this may take a few seconds for the eventual consistency to catch up.'
      );
    } else if (
      isAuthenticated &&
      userIsNotAMemberOfAnyWorkspace(myWorkspaces)
    ) {
      console.log(
        'User is Authenticated as a user but is not member on any workspace. Redirecting to My-Workspaces.'
      );
      navigateToWorkspacePicker();
    } else if (isAuthenticated) {
      if (loginToWorkspaceIdRaw != null) {
        const workspaceToLoginTo = myWorkspaces?.items.find(
          (workspace) => workspace.id === loginToWorkspaceIdRaw
        );
        if (workspaceToLoginTo != null) {
          switchOrganization(
            workspaceToLoginTo.auth0OrganizationId,
            loginToWorkspaceIdRaw
          );
        } else {
          console.error(
            `Workspace with ID ${loginToWorkspaceIdRaw} not found in the users workspaces.`
          );
          navigateToWorkspacePicker();
        }
      } else if (userIsAMemberOfExactlyOneWorkspace(myWorkspaces)) {
        /*
        In the case that a user has an active Auth0 session (Authenticated) and that session includes
        a tenantId and organization claim we make the determination that the user
        is authenticated to a particular tenant (workspace) the assumption that they want
        to continue on with that tenant and workspace, so we redirect them to the dashboard
      */
        console.log(
          'User Only Has One Workspace; Logging In to that Workspace and Navigating ',
          user,
          myWorkspaces?.items[0]
        );

        const workspace = myWorkspaces!.items[0];

        switchOrganization(workspace.auth0OrganizationId, workspace.id);
      } else {
        console.log(
          'User is Authenticated as a user and has many workspaces. Redirecting to Dashboard.'
        );
        /*
          ProtectedWorkspaceRoute will evaluate if needs to go to -> My-Workspaces.
        */
        navigateToWorkspacePicker();
      }
    } else {
      console.log({ origin: window.location.origin, pathname: pathname });
      const redirectUri = `${window.location.origin}${pathname}`;
      console.log(
        `User is not Authenticated. Redirect to Login Page with Redirect ${redirectUri}`
      );

      loginWithRedirect({
        authorizationParams: {
          redirect_uri: redirectUri,
          organization: undefined, //Auth0 will remember the last organization the user was authenticated to unless we explicitly clear it
        },
      });
    }
  }, [
    getAccessTokenSilently,
    navigateToWorkspacePicker,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    myWorkspaces,
    myWorkspacesIsLoading,
    pathname,
    switchOrganization,
    user,
    searchParams,
    loginToWorkspaceIdRaw,
    pollsRemaining,
    isPollingForWorkspaceInProgress,
    inviteFlow,
    isAuthenticatedWithWorkspace,
    navigateToWorkspaceHome,
    authenticatedWorkspaceId,
  ]);

  return (
    <FullPageLoadingSpinner
      reason={
        'app-redirect-logic-processing' +
        ' ' +
        JSON.stringify(
          {
            isLoading,
            myWorkspacesIsLoading,
            isAuthenticated,
            user,
            loginToWorkspaceIdRaw,
            pollsRemaining,
            isPollingForWorkspaceInProgress,
          },
          null,
          2
        )
      }
    />
  );
};

export default AppRedirect;
