import { ObIconAvatar } from '../../avatars';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { IconSystemKeys } from '../../tokens/icons/icons';

export interface NoContentMessageProps {
  icon?: IconSystemKeys;
  heading: string;
  message: string;
  actionLabel: string;
  actionLevel: 'primary' | 'outline';
  actionOnClick?: () => any;
}
export const NoContentMessage = ({
  icon,
  heading,
  message,
  actionLevel,
  actionLabel,
  actionOnClick,
}: NoContentMessageProps) => {
  return (
    <div className='flex flex-col gap-3 max-w-xl'>
      {icon && (
        <div className='pt-1'>
          <ObIconAvatar
            shape='rounded'
            icon={icon}
            size='large'
          />
        </div>
      )}
      <div>
        <ObTypography variant='h4'>{heading}</ObTypography>
        <ObTypography
          variant='body3'
          color='secondary'
        >
          {message}
        </ObTypography>
      </div>

      <div>
        {actionOnClick && (
          <ObButton
            size='medium'
            fullWidth='mobile'
            onClick={actionOnClick}
            variant={actionLevel}
            label={actionLabel}
          />
        )}
      </div>
    </div>
  );
};
