import React, { forwardRef } from 'react';

import { cx } from 'class-variance-authority';
import styles from './Container.module.scss';

//A lot of these options were pulled in from the example code. Many of them can be pruned once we have time to verify we do not need them
export interface Props {
  id: string;
  children: React.ReactNode;
  count?: number;
  columns?: number;
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  onClick?(): void;
  onRemove?(): void;
}

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      columns = 1,
      horizontal,
      hover,
      onClick,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      count,
      id,
      ...props
    }: Props,
    ref
  ) => {
    const Component = 'div';

    const groupLabelId = `group-header__${id}`;

    return (
      <Component
        {...props}
        ref={ref}
        style={
          {
            ...style,
            '--columns': columns,
          } as React.CSSProperties
        }
        className={cx(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <div className={styles.Header}>
            <div id={groupLabelId}>{label}</div>
            <div>{count}</div>
          </div>
        ) : null}
        {placeholder ? (
          children
        ) : (
          <ul
            className='overflow-y-auto'
            {...(label ? { 'aria-labelledby': groupLabelId } : {})}
          >
            {children}
          </ul>
        )}
      </Component>
    );
  }
);
