import {
  FormDefinition,
  FormFieldDefinition,
  FormFieldType,
} from '@outbound/design-system';

import t from '../../leads.i18n.json';

const leadContactFirstName: FormFieldDefinition = {
  id: 'leadContactFirstName',
  autofocus: true,
  label: t.LEAD_EDIT_CONTACT_DIALOG.FIRST_NAME_INPUT_LABEL,
  helperText: t.LEAD_EDIT_CONTACT_DIALOG.FIRST_NAME_HELPER_TEXT,
  type: FormFieldType.TEXT,
  validationSchema: {
    isRequired: false,
    minLength: 0,
    maxLength: 100,
  },
  fieldTypeSettings: {},
};
const leadContactLastName: FormFieldDefinition = {
  id: 'leadContactLastName',
  autofocus: true,
  label: t.LEAD_EDIT_CONTACT_DIALOG.LAST_NAME_INPUT_LABEL,
  helperText: t.LEAD_EDIT_CONTACT_DIALOG.LAST_NAME_HELPER_TEXT,
  type: FormFieldType.TEXT,
  validationSchema: {
    isRequired: false,
    minLength: 0,
    maxLength: 100,
  },
  fieldTypeSettings: {},
};

const leadContactEmail: FormFieldDefinition = {
  id: 'leadContactEmail',
  autofocus: true,
  label: t.LEAD_EDIT_CONTACT_DIALOG.EMAIL_INPUT_LABEL,
  helperText: t.LEAD_EDIT_CONTACT_DIALOG.EMAIL_HELPER_TEXT,
  type: FormFieldType.TEXT,
  validationSchema: {
    isRequired: false,
    minLength: 0,
    maxLength: 300,
  },
  fieldTypeSettings: {},
};

const leadContactPhone: FormFieldDefinition = {
  id: 'leadContactPhone',
  autofocus: true,
  label: t.LEAD_EDIT_CONTACT_DIALOG.PHONE_INPUT_LABEL,
  helperText: t.LEAD_EDIT_CONTACT_DIALOG.PHONE_HELPER_TEXT,
  type: FormFieldType.TEXT,
  validationSchema: {
    isRequired: false,
    minLength: 0,
    maxLength: 300,
  },
  fieldTypeSettings: {},
};

const leadContactZipCode: FormFieldDefinition = {
  id: 'leadContactZipCode',
  autofocus: true,
  label: t.LEAD_EDIT_CONTACT_DIALOG.ZIP_CODE_INPUT_LABEL,
  helperText: t.LEAD_EDIT_CONTACT_DIALOG.ZIP_CODE_HELPER_TEXT,
  type: FormFieldType.TEXT,
  validationSchema: {
    isRequired: false,
    minLength: 0,
    maxLength: 12,
  },
  fieldTypeSettings: {},
};

export const LeadContactEditFormDefinition: FormDefinition = {
  id: 'lead-contact-edit-form',
  heading: '',
  subHeading: '',
  sections: [
    {
      id: 'main',
      heading: '',
      subHeading: '',
      fields: [
        leadContactFirstName,
        leadContactLastName,
        leadContactEmail,
        leadContactPhone,
        leadContactZipCode,
      ],
    },
  ],
};

export interface LeadContactEditFormValue {
  leadContactFirstName: string;
  leadContactLastName: string;
  leadContactEmail: string;
  leadContactPhone: string;
  leadContactZipCode: string;
}
