'use client';
import React, { useEffect, useState } from 'react';
import { ObCheckbox } from '../../elements/ob-checkbox/ob-checkbox';

export interface ObImageCheckboxContainerProps {
  id: string;
  label: string;
  imageUrl: string;
  initialChecked?: boolean;
  disabled?: boolean;
  classification?: 'CONTENT' | 'LOGO';
  onCheckboxChange?: (checked: boolean, category: 'CONTENT' | 'LOGO') => void;
}

export const ObImageCheckboxContainer: React.FC<
  ObImageCheckboxContainerProps
> = ({
  id,
  label,
  imageUrl,
  initialChecked = false,
  onCheckboxChange,
  disabled = false,
  classification = 'LOGO',
}) => {
  /**
   * Tracks if the checkbox is checked or not
   */
  const [isChecked, setChecked] = useState(initialChecked);
  /**
   * Tracks if the category of the checkbox
   */
  const [category, setCategory] = useState<'CONTENT' | 'LOGO'>(classification);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !disabled) {
      toggleCheckbox();
      event.preventDefault(); // Prevent any default behavior associated with pressing Enter.
    }
  };

  const isSvg = (url: string) => {
    return url.toLowerCase().endsWith('.svg');
  };

  const toggleCheckbox = () => {
    setChecked(!isChecked);
    if (onCheckboxChange) {
      onCheckboxChange(!isChecked, category);
    }
  };

  /**
   * To simplify dealing with state management, we will set the category to CONTENT if the checkbox is not checked
   */
  useEffect(() => {
    if (!isChecked) {
      setCategory('CONTENT');
    }
  }, [isChecked, setCategory]);

  return (
    <>
      <label
        id={id}
        className='sr-only'
      >
        {label}
      </label>
      <div
        className={`${
          isChecked
            ? ''
            : ' opacity-70 hover:opacity-100 transition-opacity duration-200'
        } sm:max-w-[300px]`}
      >
        <div
          aria-labelledby={id}
          tabIndex={0}
          className={`relative border-2 rounded-md overflow-hidden cursor-pointer transition-all duration-300  sm:max-h-[300px] aspect-square
        ${
          isChecked
            ? 'dark:border-dark/action/primary/normal hover:dark:border-dark/action/primary-v2/hover active:dark:border-dark/action/primary-v2/active border-actionPrimaryLight'
            : 'dark:border-actionOutlineNormalDark hover:dark:border-dark/action/primary-v2/hover active:dark:border-dark/action/primary-v2/active border-actionNeutralNormalLight'
        }`}
          onClick={toggleCheckbox}
          onKeyDown={handleKeyDown}
          role='checkbox'
          aria-checked={isChecked}
        >
          <div
            className='absolute inset-0 dark:bg-actionPrimaryDark dark:hover:bg-dark/action/primary/hover dark:hover:bg-opacity-[0.20] dark:active:bg-opacity-[0.30] bg-actionPrimaryLight dark:bg-opacity-[0.15] bg-opacity-[0.15]'
            style={{
              display: isChecked ? 'block' : 'none',
              opacity: isChecked ? '1' : '0.7',
            }}
          ></div>
          {isSvg(imageUrl) ? (
            <div className='flex items-center justify-center p-10 h-full select-none'>
              <object
                type='image/svg+xml'
                data={imageUrl}
                style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
                aria-label='SVG Image'
              >
                SVG not supported by your browser
              </object>
            </div>
          ) : (
            <div className='flex justify-center items-center h-full select-none'>
              <img
                src={imageUrl}
                alt='Contained'
                className='object-contain max-w-full max-h-auto'
              />
            </div>
          )}

          <div className='absolute top-2 right-0.5'>
            <ObCheckbox
              label={'Select Image'}
              size='medium'
              hideLabel={true}
              checked={isChecked ? 'checked' : 'unchecked'}
              removeFromTabOrder={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
