import {
  IntegrationConfigurationLifecycleStatus,
  IntegrationConfigurationOperationalStatus,
  IntegrationConfigurationType,
  ServerDelta,
} from '@outbound/types';

import { parseObrn } from '@otbnd/utils';
import { SetupIntegrationStage } from '@outbound/design-system';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../base-model';
import { RootStore } from '../../root-store';
import User from '../../user/user';
import Integration from '../integration/integration';
import HealthCheckItemEvaluation from './health-check-item-execution/health-check-item-execution';
import IntegrationConfigurationValue from './integration-configuration-value.type';

class IntegrationConfiguration extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    type: '/type',
    lifecycleStatus: '/lifeCycleStatus',
    operationalStatus: '/operationalStatus',
    configuredByUserId: '/configuredByUserId',
    configuredAtTimestamp: '/configuredAtTimestamp',
    latestHealthCheckItemEvaluations: '/latestHealthCheckItemEvaluations',
    healthCheckLastCompletedAtTimestamp: '/healthCheckLastCompletedAtTimestamp',
  };
  /**
   * The Foreign Key to the integration this configuration is for.
   */
  private readonly _integrationId: string;
  /**
   * The type of configuration this is.
   * This field cab be used to cast the configuration to the correct type for the integration.
   */
  private readonly _type: IntegrationConfigurationType;

  private _lifecycleStatus: IntegrationConfigurationLifecycleStatus;

  private _operationalStatus: IntegrationConfigurationOperationalStatus;

  private _configuredByUserId: string;

  private _configuredAtTimestamp: string;

  private _healthCheckLastCompletedAtTimestamp?: string;

  private _isHealthCheckInProgress: boolean;

  private _latestHealthCheckItemEvaluationMap: Map<
    string,
    HealthCheckItemEvaluation
  > = new Map();

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    value: IntegrationConfigurationValue
  ) {
    super(
      rootStore,
      'integration-configuration',
      '1',
      id,
      parseObrn(obrn).scope!,
      obrn
    );
    this._integrationId = value.integrationId;
    this._type = value.type;
    this._lifecycleStatus = value.lifeCycleStatus;
    this._operationalStatus = value.operationalStatus;
    this._configuredByUserId = value.configuredByUserId;
    this._configuredAtTimestamp = value.configuredAtTimestamp;
    this._healthCheckLastCompletedAtTimestamp =
      value.healthCheckLastCompletedAtTimestamp;
    this._latestHealthCheckItemEvaluationMap =
      value.healthCheckItemLatestExecutionsMap;

    /**
     * This is a frontend only field at the moment. We don't communicate this from the server.
     * We will set this to true when this client requests a health check for this configuration
     * and will set it back to false when we get a patch that has a lastHealthCheckTimestamp
     * greater than the last time we requested a health check.
     *
     * This could be upgraded in the future to be communicated from the server so we can see
     * if a health check is in progress from another client. Out of scope for the time being.
     */
    this._isHealthCheckInProgress = false;
  }

  /**
   * Allow the model to update itself based on a patch from the server.
   */
  applyPatch(_patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of _patch) {
        switch (delta.path) {
          case IntegrationConfiguration.paths.lifecycleStatus: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._lifecycleStatus =
                  delta.value as IntegrationConfigurationLifecycleStatus;
                break;
              case 'remove':
                console.error('Cannot remove lifecycle status');
            }
            break;
          }
          case IntegrationConfiguration.paths.operationalStatus: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._operationalStatus =
                  delta.value as IntegrationConfigurationOperationalStatus;
                break;
              case 'remove':
                console.error('Cannot remove operational status');
            }
            break;
          }
          /**
           * We allow updates to this to correct for optimistic updates providing a client time that is different from the server time.
           */
          case IntegrationConfiguration.paths.configuredAtTimestamp: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                this._configuredAtTimestamp = delta.value as string;
                break;
              case 'remove':
                console.error('Cannot remove configuredAtTimestamp');
            }
            break;
          }

          case IntegrationConfiguration.paths
            .healthCheckLastCompletedAtTimestamp: {
            switch (delta.op) {
              case 'add':
              case 'replace':
                if (
                  this._healthCheckLastCompletedAtTimestamp &&
                  this._healthCheckLastCompletedAtTimestamp < delta.value
                ) {
                  console.log('SETTING HEALTH CHECK PROGRESS TO FALSE');

                  this._isHealthCheckInProgress = false;
                }

                this._healthCheckLastCompletedAtTimestamp = delta.value;

                break;
              case 'remove':
                console.error(
                  'Cannot remove healthCheckLastCompletedAtTimestamp'
                );
            }
            break;
          }
          default: {
            //NoOp We assume the extending class will handle the rest
          }
        }
        if (
          delta.path.startsWith(
            IntegrationConfiguration.paths.latestHealthCheckItemEvaluations
          )
        ) {
          switch (delta.op) {
            case 'replace': {
              /**
               * Since this is a nested object we will delegate the updates to it's attributes
               * down to the nested object itself.
               */

              /**
               * First thing we will do is split the path by the '/' character to get the segments of the path.
               * (OBRN is URL encoded which is why this works)
               */
              const [
                ,
                ,
                healthCheckEvaluationObrnEncoded,
                ...healthCheckLocalSegments
              ] = delta.path.split('/');

              //Get the unencoded OBRN
              const healthCheckItemObrn = decodeURIComponent(
                healthCheckEvaluationObrnEncoded
              );

              /**
               * Create a new delta that can be applied to the nested object
               * In order to do this we need to remove the path segments that are relevant to the parent.
               * Since we selective only decoded the OBRN on the parent we can assume all keys of nested objects on the child are
               * property encoded.
               */
              const delegatedDelta = {
                ...delta,
                path: `/${healthCheckLocalSegments.join('/')}`,
              };

              //Apply the patch to the nested object.
              //Do we need any handling if the value doesn't exist?
              this._latestHealthCheckItemEvaluationMap
                .get(healthCheckItemObrn)
                ?.applyPatch([delegatedDelta]);
              break;
            }

            case 'add': {
              const healthCheckItemEvaluation = new HealthCheckItemEvaluation(
                this._rootStore,
                delta.value[HealthCheckItemEvaluation.paths.id],
                delta.value[HealthCheckItemEvaluation.paths.obrn],
                {
                  healthCheckItemId:
                    delta.value[
                      HealthCheckItemEvaluation.paths.healthCheckItemId
                    ],
                  name: delta.value[HealthCheckItemEvaluation.paths.name],
                  outcomeCode:
                    delta.value[HealthCheckItemEvaluation.paths.outcomeCode],
                  message: delta.value[HealthCheckItemEvaluation.paths.message],
                  status: delta.value[HealthCheckItemEvaluation.paths.status],
                  skipped: delta.value[HealthCheckItemEvaluation.paths.skipped],
                  lastRanAtTimestamp:
                    delta.value[HealthCheckItemEvaluation.paths.ranAtTimestamp],
                }
              );
              healthCheckItemEvaluation.makeObservable();
              this._latestHealthCheckItemEvaluationMap.set(
                delta.value[HealthCheckItemEvaluation.paths.obrn],
                healthCheckItemEvaluation
              );

              break;
            }
            case 'remove': {
              this._latestHealthCheckItemEvaluationMap.delete(delta.value.obrn);
              break;
            }
          }
        }
      }
    });
  }

  toJson(): Record<string, any> {
    const baseJson = super.toJson();
    const healthCheckEvaluations = Array.from(
      this._latestHealthCheckItemEvaluationMap.values()
    ).reduce<Record<string, any>>((acc, healthCheckItem) => {
      acc[healthCheckItem.obrn] = healthCheckItem.toJson();
      return acc;
    }, {});

    return {
      ...baseJson,
      [IntegrationConfiguration.paths.type]: this._type,
      [IntegrationConfiguration.paths.lifecycleStatus]: this._lifecycleStatus,
      [IntegrationConfiguration.paths.operationalStatus]:
        this._operationalStatus,
      [IntegrationConfiguration.paths.configuredByUserId]:
        this._configuredByUserId,
      [IntegrationConfiguration.paths.configuredAtTimestamp]:
        this._configuredAtTimestamp,
      [IntegrationConfiguration.paths.healthCheckLastCompletedAtTimestamp]:
        this._healthCheckLastCompletedAtTimestamp,
      [IntegrationConfiguration.paths.latestHealthCheckItemEvaluations]: {
        ...healthCheckEvaluations,
      },
    };
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _lifecycleStatus: observable,
      _operationalStatus: observable,
      _isHealthCheckInProgress: observable,
      _healthCheckLastCompletedAtTimestamp: observable,
      _configuredAtTimestamp: observable,
      _latestHealthCheckItemEvaluationMap: observable,
    } as any);
  }

  get calculateSetupFlowState(): SetupIntegrationStage {
    return 'success';
  }

  get integration(): Integration {
    return this._rootStore.integrationStore.getById(this._integrationId)!;
  }

  get type(): IntegrationConfigurationType {
    return this._type;
  }

  get lifecycleStatus(): IntegrationConfigurationLifecycleStatus {
    return this._lifecycleStatus;
  }

  get operationalStatus(): IntegrationConfigurationOperationalStatus {
    return this._operationalStatus;
  }

  get configuredAtTimestamp(): string {
    return this._configuredAtTimestamp;
  }

  get configuredByUser(): User {
    return this._rootStore.userStore.getById(this._configuredByUserId)!;
  }

  public save(): void {
    // Save the configuration to the server
  }

  public delete(): void {
    runInAction(() => {
      this._rootStore.integrationConfigurationStore.delete(this._id);
    });
  }

  public runHealthChecks(): void {
    if (this.isHealthCheckInProgress) {
      return;
    }
    runInAction(() => {
      this._isHealthCheckInProgress = true;
    });
    this._rootStore.integrationConfigurationStore.runHealthChecks(this._id);
  }

  get latestHealthCheckItemEvaluations(): Array<HealthCheckItemEvaluation> {
    const array = Array.from(this._latestHealthCheckItemEvaluationMap.values());
    console.log('VALUES', array);
    return array;
  }

  get isHealthCheckInProgress(): boolean {
    return this._isHealthCheckInProgress;
  }

  get healthCheckLastCompletedAtTimestamp(): string | undefined {
    return this._healthCheckLastCompletedAtTimestamp;
  }
}

export default IntegrationConfiguration;
