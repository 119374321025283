import {
  CampaignDeploymentStatusType,
  CampaignLifecycleStatus,
  CampaignPausedByUserState,
  CampaignServingState,
  CampaignServingStateReason,
} from '@outbound/types';

export interface ServingStateReasonCalculationArgs {
  /**
   * The current state of the campaign being paused by the user.
   * We assume this state will override any behavior of the Ad Channel and is a high priority reason
   * for why something is not running.
   */
  campaignPausedByUserState: CampaignPausedByUserState;

  /**
   * Indicates that a representation of the Outbound Campaign was created on the Ad Channel.
   * This triggers a switch in how we calculate the serving state reason by now including the
   * Ad Channels last known serving state reason into the calculation.
   */
  isCreatedOnAdChannel: boolean;
  /**
   * The last serving state reason reported by the Ad Channel.
   * This value is assumed by this logic to be the latest information we know about the campaign
   * on the Ad Channel. This value is an internal Outbound Serving State Reason and not the Ad Channel's
   * Internal statues.
   * (Logic for converting between the Ad Channel's Internal implementation and Outbound's is not a concern of this function)
   */
  lastKnownAdChannelServingStateReason: CampaignServingStateReason | null;
  /**
   * The lifecycle status of the campaign will impact the serving state reason.
   */
  campaignLifecycleStatus: CampaignLifecycleStatus;
  /**
   * The status of the latest deployment of the Campaign to an Ad Channel.
   * This is primarily used before the campaign is created on the Ad Channel
   * to calculate the serving state reason.
   * null means that this campaign has no deployments.
   */
  latestDeploymentStatus: CampaignDeploymentStatusType | null;
}

/**
 * Calculate what serving state should be shown to a user based on the internal state of the campaign.
 * @returns CampaignServingStateReason that should be displayed to the user.
 */
export const calculateUserFacingServingStateReason = ({
  campaignPausedByUserState,
  isCreatedOnAdChannel,
  campaignLifecycleStatus,
  latestDeploymentStatus,
  lastKnownAdChannelServingStateReason,
}: ServingStateReasonCalculationArgs): CampaignServingStateReason => {
  if (!isCreatedOnAdChannel) {
    if (campaignLifecycleStatus === 'ARCHIVED') {
      return 'CAMPAIGN_ARCHIVED';
    }
    if (campaignLifecycleStatus === 'INITIALIZATION_FAILED') {
      return 'CAMPAIGN_INITIALIZATION_FAILED';
    }
    if (campaignLifecycleStatus === 'INITIALIZING') {
      //We don't expect that Ads are Serving for a Campaign that is still initializing
      return 'CAMPAIGN_INITIALIZING';
    } else if (latestDeploymentStatus == null) {
      //When no deployments exist for a Campaign it can't be provisioned and thus can't be serving.
      return 'CAMPAIGN_NEVER_DEPLOYED';
    } else {
      //Since we have a deployment we should check the status of it
      switch (latestDeploymentStatus) {
        case 'INITIALIZING':
        case 'IN_PROGRESS':
          return 'FIRST_DEPLOYMENT_IN_PROGRESS';
        case 'SUCCEEDED':
          /**
           * This Behavior is undefined. We should not have a case where the campaign has not been created
           * but we do not have a campaign instance identifier. The instance identifier is what tells us the Ad
           * Channel's unique id for the campaign. If we have a deployment that has succeeded we should have a
           * id from the ad channel.
           */
          return 'UNDEFINED';
        case 'FAILED':
          /**
           * Since the isCreatedOnAdChannel === false we assume this is the first deployment
           * and it would have created the campaign if it had been successful.
           *
           * To clarify this logic assumes that the isCreatedOnAdChannel === false means that the campaign
           * was not created "AT ALL" on the Ad Channel. If the deployment failed due to partial failure but
           * the campaign object itself exists we would expect isCreatedOnAdChannel === true and this logic would
           * never be reached.
           */
          return 'FIRST_DEPLOYMENT_FAILED';
      }
    }
  } else {
    /**
     * No matter what the state of the campaign, if it exists on the Ad Channel, and the user has paused it
     * it is paused.
     */
    if (campaignPausedByUserState === 'CAMPAIGN_PAUSED_BY_USER') {
      return 'CAMPAIGN_PAUSED_BY_USER';
    }

    /**
     * We expect that at this point in the logic there should be a SERVING STATE REASON from the Ad Channel.
     * If this is not true it is an Undefined Case we do not have requirements for.
     */
    if (lastKnownAdChannelServingStateReason == null) {
      return 'UNDEFINED';
    }
    return lastKnownAdChannelServingStateReason;
  }
};

export const calculateServingStateFromServingStateReason = (
  servingStateReason: CampaignServingStateReason
): CampaignServingState => {
  switch (servingStateReason) {
    case 'CAMPAIGN_NEVER_DEPLOYED':
    case 'CAMPAIGN_PAUSED_BY_USER':
    case 'CAMPAIGN_DELETED_ON_AD_CHANNEL':
    case 'FIRST_DEPLOYMENT_IN_PROGRESS':
    case 'FIRST_DEPLOYMENT_FAILED':
    case 'AD_CHANNEL_APPROVAL_PENDING':
    case 'AD_CHANNEL_APPROVAL_REJECTED':
    case 'CAMPAIGN_SCHEDULED_TO_START_IN_FUTURE':
    case 'CAMPAIGN_SCHEDULED_TO_END_IN_PAST':
    case 'CAMPAIGN_INITIALIZING':
    case 'CAMPAIGN_INITIALIZATION_FAILED':
    case 'ALL_HIGHLIGHTS_PAUSED':
    case 'TOTAL_CAMPAIGN_DRIFT_DETECTED':
      return 'NOT_SERVING';
    case 'SERVING':
      return 'SERVING';
    case 'SOME_ADS_UNDER_REVIEW':
    case 'SOME_ADS_WERE_REJECTED':
    case 'SOME_HIGHLIGHTS_PAUSED':
    case 'PARTIAL_CAMPAIGN_DRIFT_DETECTED':
      return 'PARTIALLY_SERVING';
    case 'UNKNOWN':
    case 'CAMPAIGN_ARCHIVED':
    case 'UNDEFINED':
    case 'SYNCING_WITH_AD_CHANNEL':
      return 'UNKNOWN';
  }
};
