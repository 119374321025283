'use client';
import { cva, cx } from 'class-variance-authority';
import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { IconSystemKeys } from '../../../tokens/icons/icons';
import { ObIcon } from '../../../tokens/icons/ob-icon/ob-icon';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  key?: string | number;
  label?: string;
  size?: ObButtonSize;
  fullWidth?: 'mobile' | 'desktop' | 'always' | 'never';
  disabled?: boolean;
  buttonType?: 'icon' | 'text';
  iconLeft?: IconSystemKeys;
  iconLeftClassName?: string;
  iconRight?: IconSystemKeys;
  onClick?: (e: any) => any;
  variant?: ObButtonStyle;
  className?: string;
  children?: ReactNode;
  textTruncate?: boolean;
  textClassname?: string;
}

export type ObButtonSize = 'small' | 'medium' | 'large' | 'xlarge';
export type ObButtonStyle = 'primary' | 'secondary' | 'ghost' | 'outline';

/**
 *
 * @param heading
 * @constructor
 */
export const ObButton = forwardRef<HTMLButtonElement, ObButtonProps>(
  (
    {
      label,
      size = 'small',
      disabled = false,
      onClick: handleClick,
      variant: style = 'primary',
      buttonType = 'text',
      className,
      textTruncate,
      textClassname,
      iconLeft,
      iconLeftClassName,
      iconRight,
      children,
      fullWidth = 'never',
      ...props
    },
    ref
  ) => {
    const button = cva(
      'button focus:outline-none focus-visible:shadow-interactive ',
      {
        variants: {
          disabled: {
            true: ['pointer-events-none', 'opacity-[0.7]'],
            false: [],
          },
          fullWidth: {
            always: ['w-full'],
            mobile: ['w-full', 'sm:w-auto'], //Anything above mobile will be width auto
            desktop: ['sm:w-full'], //Anything larger than mobile will be w-auto
            never: [],
          },
          intent: {
            primary: [
              'text-contentPrimaryDark',
              /* General Ghost Variant Styling  */
              'rounded',

              /* Light Mode Default State  */
              'bg-primary-9 border-transparent',
              /* Light Mode Hover State  */
              'hover:bg-primary-10',
              /* Light Mode Active State  */
              /* Light Mode Disabled State  */
              'disabled:bg-primary-5',

              'dark:active:bg-dark/action/primary-v2/active',

              /* Dark Mode Default State  */
              'dark:bg-primary-8',
              /* Dark Mode Hover State  */
              'dark:hover:bg-primary-9',
              /* Dark Mode Active State  */
              /* Dark Mode Disabled State  */
            ],
            secondary: [
              /* General Ghost Variant Styling  */
              'rounded',

              /* Light Mode Default State  */
              /* Light Mode Hover State  */
              /* Light Mode Active State  */
              /* Light Mode Disabled State  */

              /* Dark Mode Default State  */
              'dark:bg-dark/action/ghost/hover',
              'dark:text-dark/content/secondary',
              /* Dark Mode Hover State  */

              'dark:hover:bg-dark/action/ghost/active',
              /* Dark Mode Active State  */

              /* Dark Mode Disabled State  */

              'hover:bg-blackAlpha-2',
            ],
            outline: [
              'rounded',
              'border',

              /* General Ghost Variant Styling  */

              /* Light Mode Default State  */
              /* Light Mode Hover State  */
              /* Light Mode Active State  */
              /* Light Mode Disabled State  */

              /* Dark Mode Default State  */
              'dark:bg-dark/action/ghost/hover',
              'dark:border-whiteAlpha-5',

              'dark:text-contentPrimaryDark',
              'dark:border-actionOutlineNormalDark',
              /* Dark Mode Hover State  */
              'dark:hover:border-actionOutlineHoverDark',
              'dark:hover:bg-dark/action/ghost/hover',
              /* Dark Mode Active State  */
              'dark:active:bg-dark/border/default/active',
              'dark:hover:bg-dark/action/ghost/active',
              /* Dark Mode Disabled State  */
            ],
            ghost: [
              /* General Ghost Variant Styling  */
              'rounded',

              /* Text Styles */
              'text-light/content/secondary',
              'dark:text-dark/content/secondary',

              /* Text Hover Styles */
              'hover:text-light/content/secondary',
              'hover:dark:text-dark/content/secondary',

              /* Background Styles */
              /* bg styles for default state intentionally not set; The ghost variant is intended to blend in with its bg */

              /* Background Hover State */
              'hover:bg-light/action/ghost/hover',
              'dark:hover:bg-dark/action/ghost/hover',

              /* Background Active State */
              'active:bg-light/action/ghost/active',
              'dark:active:bg-dark/action/ghost/active',
            ],
          },
          buttonType: {
            text: ['min-w-[48px]'],
            icon: [],
          },
          size: {
            small: [
              'ob-button-small',
              'h-[24px]',
              'px-2',
              'py-1.5',
              'border-box',
            ],
            medium: [
              'ob-button-medium',
              'h-[32px]',
              'px-4',
              'py-2.5',
              'border-box',
            ],
            large: [
              'ob-button-large',
              'h-[40px]',
              'px-4',
              'py-2.5',
              'border-box',
            ],
            xlarge: [
              'ob-button-xlarge',
              'h-[50px]',
              'px-4',
              'py-2.5',
              'border-box',
            ], //Used to keep the button size consistent with an input field on the login / signup pages
          },
        },
        compoundVariants: [
          {
            disabled: true,
            intent: ['secondary'],
            className:
              'dark:bg-bgSurfaceDark/[.84] dark:border-actionSecondaryHoverBorderDark/[.16] ',
          },
          {
            buttonType: 'icon',
            size: 'small',
            className: 'h-[24px] w-[24px] max-h-[24px] max-w-[24px]',
          },
          {
            buttonType: 'icon',
            size: 'medium',
            className: 'h-[32px] w-[32px] max-h-[32px] max-w-[32px] p-1.5',
          },
          {
            buttonType: 'icon',
            size: 'large',
            className: 'h-[40px] w-[40px] max-h-[40px] max-w-[40px] p-1.5',
          },
          {
            buttonType: 'icon',
            size: 'xlarge',
            className: 'h-[50px] w-[50px] max-h-[50px] max-w-[50px] p-1.5',
          },
        ],
        defaultVariants: {
          intent: 'primary',
          size: 'medium',
        },
      }
    );

    return (
      <button
        {...props}
        ref={ref}
        className={cx(
          button({
            intent: style,
            buttonType,
            size,
            fullWidth,
            disabled,
          }),
          'flex justify-center items-center gap-2',
          'transition',
          className
        )}
        type={props.type || 'button'}
        disabled={disabled}
        onClick={handleClick}
      >
        {iconLeft && (
          <ObIcon
            aria-hidden={true}
            color='inherit'
            classNames={cx(iconLeftClassName)}
            icon={iconLeft}
            size={size === 'small' ? 'x-small' : 'small'}
          />
        )}
        {label && (
          <ObTypography
            className={cx(
              textClassname,
              buttonType === 'text' ? '' : 'sr-only',
              'line-clamp-1 text-ellipsis'
            )}
            truncate={textTruncate}
            variant={size === 'small' ? 'buttonSmall' : 'buttonLarge'}
            color='inherit'
          >
            {label}
          </ObTypography>
        )}
        {children}
        {iconRight && (
          <ObIcon
            aria-hidden={true}
            color='inherit'
            icon={iconRight}
            size={size === 'small' ? 'x-small' : 'small'}
          />
        )}
      </button>
    );
  }
);
