import { ObCardSurface } from '../../../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { LeadQualificationStatusInput } from '../../lead-qualification-stauts-input';
import { LeadRatingInput } from '../../lead-rating-input';
import { formatContactNameForDisplay } from '../../utils/format-lead-contact-name-for-display';
import { formatLeadSourceForDisplay } from '../../utils/format-lead-source-for-display';

import { LeadCardImplementationProps } from '../lead-card.type';

export const LeadGridCard = ({
  qualificationStatus,
  firstName,
  lastName,
  sourceType,
  rating,
  onNavigateCallback,
  onNavigateAriaLabel,
  onUpdateRatingCallback,
  onUpdateQualificationStatusCallback,
  nestedServiceMicroCard,
  localization,
}: LeadCardImplementationProps) => {
  const { formattedName, nameProvided } = formatContactNameForDisplay(
    firstName,
    lastName,
    localization.noFirstNameOrLastNameProvidedMessage
  );
  const { formattedLeadSource } = formatLeadSourceForDisplay(
    sourceType,
    localization
  );

  return (
    <div className='flex flex-1'>
      <ObCardSurface
        ariaLabel={onNavigateAriaLabel}
        onClickedCallback={() => {
          onNavigateCallback?.();
        }}
      >
        <div className='min-h-[80px] px-3 py-2'>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-col'>
              <ObTypography
                variant='body2'
                color='tertiary'
              >
                {formattedLeadSource}
              </ObTypography>
              <div className='flex gap-2 items-center'>
                <div className='mt-[4px]'>
                  <LeadQualificationStatusInput
                    onQualificationStatusSelected={
                      onUpdateQualificationStatusCallback
                    }
                    selectedQualificationStatus={qualificationStatus}
                    buttonStyle={'icon'}
                    buttonIconSize='small'
                    localization={localization}
                  />
                </div>
                <ObTypography
                  variant='h4'
                  color={nameProvided ? 'primary' : 'secondary'}
                >
                  {formattedName}
                </ObTypography>
              </div>
            </div>
            <div className='flex max-w-full overflow-hidden'>
              {nestedServiceMicroCard ?? <>{nestedServiceMicroCard}</>}
            </div>
            <div>
              <LeadRatingInput
                selectedRating={rating}
                localization={localization}
                onRatingSelected={onUpdateRatingCallback}
              />
            </div>
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};
