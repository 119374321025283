import { parseObrn } from '@otbnd/utils';
import {
  ClientDelta,
  CreativeResource,
  CreativeTemplateValueResource,
} from '@outbound/types';
import { AxiosInstance } from 'axios';
import { BaseTransport } from '../../base-transport';
import { Transport } from '../../transport';

class CreativeTransport extends BaseTransport<CreativeResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  protected async fetchById(id: string): Promise<CreativeResource> {
    const resource = await this._axiosInstance.get(
      `/creative-manager/creatives/${id}`
    );
    return resource.data;
  }

  /**
   * Overriding the BaseTransport method to handle the CreativeResource
   * @param id
   */
  protected onResourceFetchedFromServer(
    id: string,
    resource: CreativeResource
  ): void {
    /**
     * When a creative is in the PENDING_INITIALIZATION state we want to poll the server until it is no longer in that state.
     */
    if (resource.status === 'PENDING_INITIALIZATION') {
      this.pollForResourceInTargetState(
        id,
        //Init Complete Check
        (resource) => {
          console.log('Current Status', id, resource.status);
          return resource.status !== 'PENDING_INITIALIZATION';
        },
        //We don't have an Error State on Creative but we should.
        (_resource) => {
          return false;
        },
        'CREATIVE-INIT'
      );
    }
  }

  /**
   * Calls the Creative Endpoint that accepts a list of setting values to update.
   * Only the settings that are being updated need to be included in the list.
   * The server will merge the new values with the existing values using a last-write-wins strategy.
   * @param id
   * @param settingValuesToUpdate
   */
  public async sendSettingUpdatesToServer(
    id: string,
    settingValuesToUpdate: Array<CreativeTemplateValueResource>
  ) {
    return this._axiosInstance.patch(
      `/creative-manager/creatives/${id}/template-setting-values`,
      {
        templateSettingValues: settingValuesToUpdate,
      }
    );
  }

  public acceptEmbeddedResource(resource: CreativeResource): void {
    this.onResourceFetchedFromServer(resource.id, resource);
    this.updateSubjectWithResourceUpdate(resource.id, resource);
    console.log('Creative Resource Accepted via Embedded Channel', resource);
  }

  protected async processDeltaQueueFlush(
    dedupeDeltaQueue: Array<ClientDelta>
  ): Promise<void> {
    const settingValuesForCampaignId: Map<
      string,
      Array<CreativeTemplateValueResource>
    > = new Map();

    console.log('Processing Creative Transport Queue Flush', dedupeDeltaQueue);
    Object.entries(dedupeDeltaQueue).forEach(([, delta]) => {
      if (delta.object === 'creative/setting') {
        const { localPathId } = parseObrn(delta.obrn);
        const [campaignId] = localPathId.split('/');

        let settingsToUpdate: Array<CreativeTemplateValueResource> | undefined =
          settingValuesForCampaignId.get(campaignId);
        const settingValues = settingValuesForCampaignId.get(campaignId);

        if (settingValues == null) {
          settingsToUpdate = [];
          settingValuesForCampaignId.set(campaignId, settingsToUpdate);
        }

        settingsToUpdate!.push({
          id: delta.id,
          type: 'text', //How can we determine the type of the setting value here?
          value: delta.value,
          obrn: delta.obrn,
          updatedAtTimestamp: delta.clientTimestamp,
          remixLocked: false, //Future Use
        });
      }
    });

    //Send Batched Settings
    await Promise.all(
      Array.from(settingValuesForCampaignId.entries()).map(
        ([creativeId, settings]) => {
          console.log('Sending Settings for creativeId', creativeId, settings);
          return this.sendSettingUpdatesToServer(creativeId, settings);
        }
      )
    );

    /**
     * After we have sent the updates to the server we want to poll any creatives that we have touched to get the latest updates.
     * This is a temporary solution to make sure we get others edits back unit we have a more realtime solution in place.
     */
    setTimeout(() => {
      console.log('Requesting Updates to all Creatives we Touched');
      Array.from(settingValuesForCampaignId.entries()).map(([creativeId]) => {
        console.log('Fetch Creative: ', creativeId);
      });
    }, 1000); //Wait a sec to give the server time to process the update
  }
}

export default CreativeTransport;
