import { ReactNode } from 'react';
import { ObAvatar, ObIconAvatar } from '../../../../../avatars';
import { ObTypography } from '../../../../../components/elements/ob-typography/ob-typography';
import { IconSystemKeys } from '../../../../../tokens/icons/icons';
import { ObIcon } from '../../../../../tokens/icons/ob-icon/ob-icon';
import { timeAgoFromIsoString } from '../../../../../utilities/datetime-utilities';
import { BaseEvent, BaseEventProps } from '../base-event';

export interface Props extends Pick<BaseEventProps, 'isLastFetchedEvent'> {
  title: string;
  actorName: string;
  actorImageUrl: string;
  actorType?: 'USER' | 'SYSTEM';
  eventIcon: IconSystemKeys;
  timestamp: string;
  children: ReactNode;
}
export const BaseEventMajor = ({
  title,
  actorName,
  actorImageUrl,
  actorType = 'USER',
  timestamp,
  eventIcon,
  children,
  isLastFetchedEvent,
}: Props) => {
  return (
    <BaseEvent isLastFetchedEvent={isLastFetchedEvent}>
      {actorType === 'USER' && (
        <UserActorAvatar
          actorName={actorName}
          actorImageUrl={actorImageUrl}
          eventIcon={eventIcon}
        />
      )}
      {actorType === 'SYSTEM' && <SystemActorAvatar eventIcon={eventIcon} />}
      <div className='min-w-0 flex-1'>
        <div>
          <ObTypography
            variant='subtitle1'
            as='span'
            color='primary'
          >
            {title}
          </ObTypography>
          <ObTypography
            variant='subtitle3'
            color='secondary'
            className='capitalize mt-0.5'
          >
            {timeAgoFromIsoString(timestamp)}
          </ObTypography>
        </div>
        <div className='mt-2'>{children}</div>
      </div>
    </BaseEvent>
  );
};

const UserActorAvatar = ({
  actorName,
  actorImageUrl,
  eventIcon,
}: Pick<Props, 'actorImageUrl' | 'actorName' | 'eventIcon'>) => {
  return (
    <div className='relative'>
      <div className='ring-8 ring-black rounded-full'>
        <ObAvatar
          fallbackText={actorName}
          profileImageUrl={actorImageUrl}
          shape='circle'
          size='medium'
        />
      </div>
      <span className='absolute -right-1 -bottom-1 rounded bg-gray-700 ring-1 ring-black px-0.5 py-px'>
        <ObIcon
          icon={eventIcon}
          size='x-small'
        />
      </span>
    </div>
  );
};

const SystemActorAvatar = ({ eventIcon }: Pick<Props, 'eventIcon'>) => {
  return (
    <div className='relative'>
      <div className='ring-8 ring-black rounded-md'>
        <ObIconAvatar
          icon={eventIcon}
          color='blue'
          shape='rounded'
          size='medium'
        />
      </div>
    </div>
  );
};
