import { useEffect, useState } from 'react';

export const useIsComponentInitialRenderCompletedCheck = () => {
  const [
    isComponentInitialRenderComplete,
    setIsComponentInitialRenderComplete,
  ] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(
      () => setIsComponentInitialRenderComplete(true),
      500
    );
    return () => clearTimeout(timeout);
  }, []);
  return isComponentInitialRenderComplete;
};
