import { Outlet } from 'react-router-dom';

export const SettingsPageOutlet = () => {
  return (
    <VerticalScrollableContent>
      <div
        data-testid='workspace-setting-page-component'
        className='max-w-screen-md p-6 w-full mx-auto'
      >
        <div>
          <Outlet />
        </div>
      </div>
    </VerticalScrollableContent>
  );
};

const VerticalScrollableContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='vh-100 overflow-y-scroll w-full'>{children}</div>;
};
