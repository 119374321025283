import { LeadCard } from '@outbound/design-system';
import { CardVariant } from '@outbound/design-system/src/business-objects/card.type';
import { LeadRatingStatus } from '@outbound/types';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';

export const LeadCardObserver = observer(
  ({
    lead,
    variant,
    localization,
  }: {
    lead: Lead;
    variant: CardVariant;
    localization: Record<string, any>;
  }) => {
    const { navigateToLeadDetail } = useAppNavigation();
    return (
      <LeadCard
        variant={variant}
        firstName={lead.firstName}
        lastName={lead.lastName}
        rating={lead.rating}
        value={0}
        sourceType={lead.sourceType}
        qualificationStatus={lead.qualificationStatus}
        nestedServiceMicroCard={undefined}
        onNavigateAriaLabel={`${localization.KANBAN_BOARD.VIEW_LEAD_DETAIL_LABEL} ${lead.firstName} ${lead.lastName}`}
        onNavigateCallback={() => {
          navigateToLeadDetail(lead.obrn);
        }}
        onUpdateQualificationStatusCallback={(qualificationStatus) => {
          switch (qualificationStatus) {
            case 'QUALIFIED':
              lead.updateQualificationStatusToQualified();
              break;
            case 'UNQUALIFIED':
              lead.updateQualificationStatusToNotQualified();
              break;
            case 'WORKING':
              lead.updateQualificationStatusToWorking();
              break;
            case 'NURTURE':
              lead.updateQualificationStatusToNurture();
              break;
            case 'NEW':
              lead.updateQualificationStatusToNew();
              break;
          }
          lead.save(['qualificationStatus']);
        }}
        onUpdateRatingCallback={(rating: LeadRatingStatus) => {
          lead.rating = rating;
          lead.save(['rating']);
        }}
        localization={{
          sourceFormSubmissionLabel: 'Form Submission',
          sourcePhoneTrackingLabel: 'Call Tracking',
          sourceManualEntryLabel: 'Manual Entry',
          hotLabel: 'Hot',
          warmLabel: 'Warm',
          coldLabel: 'Cold',
          noFirstNameOrLastNameProvidedMessage: 'Name Not Provided',
          qualifiedLabel: t.KANBAN_BOARD.QUALIFIED_COLUMN_NAME,
          unqualifiedLabel: t.KANBAN_BOARD.NOT_QUALIFIED_COLUMN_NAME,
          workingLabel: t.KANBAN_BOARD.WORKING_COLUMN_NAME,
          nurturingLabel: t.KANBAN_BOARD.NURTURE_COLUMN_NAME,
          newLabel: t.KANBAN_BOARD.NEW_COLUMN_NAME,
          updateQualificationStatusCtaLabel:
            t.CTA.UPDATE_QUALIFICATION_STATUS_ARIA_LABEL,
        }}
      />
    );
  }
);
