import { cx } from 'class-variance-authority';
import { ObBadge, ObTypography } from '../..';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { CardVariant } from '../card.type';

export interface ServiceCardProps {
  variant: CardVariant;
  obrn: string;
  name: string;
  description: string;
  onNavigateCallback: (serviceObrn: string) => void;
}

type ServiceCardImplProps = Omit<ServiceCardProps, 'variant'>;

export const ServiceCard = ({ variant, ...props }: ServiceCardProps) => {
  switch (variant) {
    case 'mini':
      return <MiniCard {...props} />;
    case 'grid':
      return <GridCard {...props} />;
    case 'micro':
    default:
      return (
        <ObTypography
          className='capitalize'
          color='negative'
        >
          {variant} Not Implemented
        </ObTypography>
      );
  }
};

const MiniCard = ({ name, onNavigateCallback, obrn }: ServiceCardImplProps) => {
  return (
    <ObCardSurface isClickable={true}>
      <div
        tabIndex={0}
        onClick={() => onNavigateCallback(obrn)}
        role={'button'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onNavigateCallback(obrn);
          }
        }}
        className={cx(
          'p-2 h-[96px] flex flex-row gap-2 items-center transition-colors duration-200  hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active hover:cursor-pointer w-full rounded-lg',
          'hover:cursor-pointer focus-visible:shadow-interactive focus:outline-none'
        )}
      >
        <div className='flex flex-col justify-start h-full'>
          <ObTypography
            variant='h4'
            className='line-clamp-2'
          >
            {name}
          </ObTypography>

          <div>
            <ObBadge
              level='NEUTRAL'
              className=''
              variant='rounded'
              content='Service'
            />
          </div>
        </div>
      </div>
    </ObCardSurface>
  );
};

const GridCard = ({
  name,
  description,
  onNavigateCallback,
  obrn,
}: ServiceCardImplProps) => {
  return (
    <ObCardSurface isClickable={true}>
      <div
        tabIndex={0}
        onClick={() => onNavigateCallback(obrn)}
        role={'button'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onNavigateCallback(obrn);
          }
        }}
        className={cx(
          'p-2 h-[180px] flex flex-row gap-2 items-center transition-colors duration-200  hover:bg-dark/action/neutral/hover active:bg-dark/action/neutral/active hover:cursor-pointer w-full rounded-lg',
          'hover:cursor-pointer focus-visible:shadow-interactive focus:outline-none'
        )}
      >
        <div className='flex flex-col justify-start h-full'>
          <ObTypography
            variant='h4'
            className='line-clamp-2'
          >
            {name}
          </ObTypography>
          <ObTypography
            variant='body3'
            className='line-clamp-2'
            color='secondary'
          >
            {description}
          </ObTypography>
          <div>
            <ObBadge
              level='NEUTRAL'
              className=''
              variant='rounded'
              content='Service'
            />
          </div>
        </div>
      </div>
    </ObCardSurface>
  );
};
