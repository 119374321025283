import { PersonaResource, ServiceResourceIncludeAssets } from '@outbound/types';
import { AxiosInstance } from 'axios';
import AssetTransport from './assets/asset-transport';
import CustomerProfileTransport from './business-context/customer-profile/customer-profile-transport';
import LocationTransport from './business-context/location/location-transport';
import ReviewTransport from './business-context/review/review-transport';
import ServiceTransport from './business-context/service/service-transport';
import CampaignTransport from './campaign/transport/campaign-transport';
import CreativeTransport from './creative/transport/creative-transport';
import LandingPageTransport from './landing-page/landing-page-transport';
import LeadTransport from './leads/lead-transport';
import UserTransport from './user/user-transport';
import IntegrationConfigurationTransport from './workspace/integration-configuration/integration-configuration-transport';
import { IntegrationTransport } from './workspace/integration/integration-transport';
import MembershipTransport from './workspace/membership/membership-transport';
import WorkspaceTransporter from './workspace/workspace-transporter';

/**
 * This class acts as a base transport.
 * The only thing it seems to be doing at the moment tough is giving us a way to initialize
 * all of the other transports and pass them around together as a single unit.
 *
 * From what I can tell we never really need to access them in a way that requires us to
 * pass them around as a single unit and it creates a layer of indirection.
 *
 * I am exploring eliminating this class and relying instead on some kind of factory to
 * initialize the transports.
 */
export class Transport {
  public readonly campaignTransport: CampaignTransport;
  public readonly creativeTransport: CreativeTransport;
  public readonly serviceTransport: ServiceTransport;
  public readonly locationTransport: LocationTransport;
  public readonly customerProfileTransport: CustomerProfileTransport;
  public readonly integrationTransport: IntegrationTransport;
  public readonly integrationConfigurationTransport: IntegrationConfigurationTransport;
  public readonly workspaceTransport: WorkspaceTransporter;
  public readonly assetTransport: AssetTransport;
  public readonly membershipTransport: MembershipTransport;
  public readonly userTransport: UserTransport;
  public readonly landingPageTransport: LandingPageTransport;
  public readonly reviewTransport: ReviewTransport;
  public readonly leadTransport: LeadTransport;

  private _axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this._axiosInstance = axiosInstance;
    this.campaignTransport = new CampaignTransport(this, axiosInstance);
    this.creativeTransport = new CreativeTransport(this, axiosInstance);
    this.serviceTransport = new ServiceTransport(this, axiosInstance);
    this.assetTransport = new AssetTransport(this, axiosInstance);
    this.locationTransport = new LocationTransport(this, axiosInstance);
    this.integrationTransport = new IntegrationTransport(this, axiosInstance);
    this.integrationConfigurationTransport =
      new IntegrationConfigurationTransport(this, axiosInstance);
    this.membershipTransport = new MembershipTransport(this, axiosInstance);
    this.userTransport = new UserTransport(this, axiosInstance);
    this.customerProfileTransport = new CustomerProfileTransport(
      this,
      axiosInstance
    );
    this.workspaceTransport = new WorkspaceTransporter(this, axiosInstance);
    this.landingPageTransport = new LandingPageTransport(this, axiosInstance);
    this.reviewTransport = new ReviewTransport(this, axiosInstance);
    this.leadTransport = new LeadTransport(this, axiosInstance);
  }

  /**
   * This should be moved out of here and into individual resource transports.
   */
  async initializeBusinessContext() {
    try {
      const response = await this._axiosInstance.get(`/playbook/settings`, {
        params: {
          // include: 'assets',
        },
      });
      if (response.data && response.status === 200) {
        if (response.data.personas) {
          response.data.personas.forEach((_persona: PersonaResource) => {
            this.customerProfileTransport.acceptEmbeddedResource(_persona);
          });
        }
        if (response.data.services) {
          response.data.services.forEach(
            (service: ServiceResourceIncludeAssets) => {
              this.serviceTransport.acceptEmbeddedResource(service);
            }
          );
        }
      }
    } catch (error) {
      console.error('Error fetching business context settings', error);
    }
  }

  public destroy(): void {
    console.log('Destroying Transport');
  }
}
