import { UserResource } from '@outbound/types';
import { BaseTransport } from '../base-transport';

class UserTransport extends BaseTransport<UserResource> {
  internalBootstrap(): Promise<void> {
    return Promise.resolve();
  }
  protected fetchById(_id: string): Promise<UserResource | null> {
    throw new Error('Method not implemented.');
  }
  public acceptEmbeddedResource(resource: UserResource): void {
    this.updateSubjectWithResourceUpdate(resource.id, resource);
  }
}

export default UserTransport;
