/* eslint-disable unused-imports/no-unused-vars */
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { LeadQualificationStatus } from '@outbound/types';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import {
  LeadQualificationCtaProps,
  LeadQualificationStatusLocalizationProps,
} from '../lead-card/lead-card.type';
import {
  LeadQualificationStatusIcon,
  LeadQualificationStatusIconProps,
} from '../lead-qualification-status-icon';

export interface Props {
  buttonStyle: 'icon' | 'text';
  buttonIconSize?: LeadQualificationStatusIconProps['size'];
  selectedQualificationStatus: LeadQualificationStatus;
  onQualificationStatusSelected: (
    qualificationStatus: LeadQualificationStatus
  ) => void;
  localization: LeadQualificationStatusLocalizationProps &
    LeadQualificationCtaProps;
}
const localizedQualificationStatusOptions = (
  localization: LeadQualificationStatusLocalizationProps
): Array<{ value: LeadQualificationStatus; label: string }> => {
  return [
    { value: 'NEW', label: localization.newLabel },
    { value: 'WORKING', label: localization.workingLabel },
    { value: 'NURTURE', label: localization.nurturingLabel },
    { value: 'UNQUALIFIED', label: localization.unqualifiedLabel },
    { value: 'QUALIFIED', label: localization.qualifiedLabel },
  ];
};

export const LeadQualificationStatusInput = ({
  buttonStyle,
  buttonIconSize = 'small',
  selectedQualificationStatus,
  onQualificationStatusSelected,
  localization,
}: Props) => {
  const options = localizedQualificationStatusOptions(localization);
  const selectedOption = options.find(
    (option) => option.value === selectedQualificationStatus
  );
  return (
    <Listbox
      value={selectedQualificationStatus}
      onChange={(value) => {
        onQualificationStatusSelected(value as LeadQualificationStatus);
      }}
    >
      {buttonStyle === 'text' && (
        <ListboxButton
          className='border rounded-md pl-2 pr-3 py-1 border-dark/border/default/normal hover:border-dark/border/default/hover active:border-dark/border/default/active'
          onClick={(event) => event.stopPropagation()}
          aria-label={localization.updateQualificationStatusCtaLabel}
        >
          {selectedOption == null ? (
            <ObTypography>Rate Lead</ObTypography>
          ) : (
            <OptionLabel
              label={selectedOption.label}
              qualificationStatus={selectedOption.value}
            />
          )}
        </ListboxButton>
      )}
      {buttonStyle === 'icon' && (
        <ListboxButton onClick={(event) => event.stopPropagation()}>
          <div className='p-1 rounded-full'>
            <LeadQualificationStatusIcon
              qualificationStatus={selectedQualificationStatus}
              size={buttonIconSize}
            />
          </div>
        </ListboxButton>
      )}
      <ListboxOptions
        anchor='bottom'
        className='rounded-md'
      >
        {options.map((option) => (
          <ListboxOption
            key={option.value}
            value={option.value}
            onClick={(event) => {
              event.stopPropagation();
            }}
            className='cursor-pointer hover:bg-dark/action/neutral/hover bg-dark/action/neutral/normal px-3 py-2'
          >
            <OptionLabel
              label={option.label}
              qualificationStatus={option.value}
            />
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

const OptionLabel = ({
  label,
  qualificationStatus,
}: {
  label: string;
  qualificationStatus: LeadQualificationStatus;
}) => {
  return (
    <div className='flex items-center gap-2'>
      <LeadQualificationStatusIcon
        qualificationStatus={qualificationStatus}
        size={'xSmall'}
      />
      <ObTypography
        as='span'
        variant='h6'
      >
        {label}
      </ObTypography>
    </div>
  );
};
