import { ObDrawerFooter } from '@outbound/design-system/src/components/molecules/ob-drawer-footer/ob-drawer-footer';
import { cx } from 'class-variance-authority';
import { motion } from 'motion/react';

export interface PlaybookDrawerContentProps {
  children?: React.ReactNode;
  showFooter?: boolean;
  primaryActionLabel: string;
  primaryActionCallback: () => Promise<void>;
  secondaryActionLabel: string;
  secondaryActionCallback: () => Promise<void>;
}

/**
 * @deprecated Use ObPeekabooFooterTemplate instead
 * @returns
 */
export const PlaybookDrawerContent = ({
  children,
  showFooter = true,
  primaryActionCallback,
  primaryActionLabel,
  secondaryActionCallback,
  secondaryActionLabel,
}: PlaybookDrawerContentProps) => {
  return (
    <main
      className={cx(
        'flex flex-grow flex-col h-full box-border scroll-overlay overflow-y-auto overflow-x-clip px-4'
      )}
    >
      <div data-footer-visible={showFooter}>
        {children}
        <motion.div
          variants={
            showFooter
              ? { hidden: { opacity: 0, y: 0 }, visible: { opacity: 1, y: 0 } }
              : { hidden: { opacity: 1, y: 0 }, visible: { opacity: 0, y: 0 } }
          }
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ duration: 0.3 }}
          className={cx(
            'sticky bottom-0 pt-4',
            showFooter ? 'pointer-events-auto' : 'pointer-events-none'
          )}
        >
          <ObDrawerFooter
            primaryActionLabel={primaryActionLabel}
            primaryActionCallback={primaryActionCallback}
            secondaryActionLabel={secondaryActionLabel}
            secondaryActionCallback={secondaryActionCallback}
          />
        </motion.div>
      </div>
    </main>
  );
};
