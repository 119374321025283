import { action } from 'mobx';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Campaign from './campaign';
import { TransportCampaignResource } from './campaign-resource.type';
import CampaignTransformer from './transformer/campaign-transformer';
import CampaignTransport from './transport/campaign-transport';

class CampaignStore extends BaseStore<Campaign, TransportCampaignResource> {
  constructor(rootStore: RootStore, resourceTransport: CampaignTransport) {
    super(
      rootStore,
      'campaign',
      resourceTransport,
      new CampaignTransformer(rootStore)
    );
  }

  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this._baseTransport?.requestResourceById(id);
  }

  /**
   * Optimistically delete the Campaign from the store and send the request to the server.
   * @param id
   */
  @action
  public async delete(id: string) {
    const model = this.modelMap.get(id);
    if (model) {
      this.optimisticDeleteModel(id);
      try {
        await this.rootStore.transport.campaignTransport.delete(id);
      } catch (error) {
        //TODO THIS IS NOT TRIGGERING A RE-RERENDER IN THE UI. FIX THIS
        console.error('Error Deleting Campaign', error);
        this.undoOptimisticDeleteModel(id);
        //Snackbar or Toast Error
      }
    }
  }
}

export default CampaignStore;
