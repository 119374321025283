import { parseObrn, toObrn } from '@otbnd/utils';
import { ReviewResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import Review from './review';
import ReviewTransformer from './review-transformer';
import ReviewTransport from './review-transport';

class ReviewStore extends BaseStore<Review, ReviewResource> {
  constructor(rootStore: RootStore, resourceTransport: ReviewTransport) {
    super(
      rootStore,
      'review',
      resourceTransport,
      new ReviewTransformer(rootStore)
    );
  }
  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this._baseTransport?.requestResourceById(id);
  }
  public create({
    workspaceId,
    authorFullName,
    rating,
    reviewTextPlain,
    relatedProductsAndServices,
  }: {
    workspaceId: string;
    authorFullName: string;
    rating: number;
    reviewTextPlain?: string;
    relatedProductsAndServices?: string[];
  }): Review {
    const optimisticId = crypto.randomUUID();
    const optimisticObrn = toObrn({
      localPathId: optimisticId,
      scope: workspaceId,
      objectType: 'review',
    });
    const review = new Review(this.rootStore, optimisticId, optimisticObrn, {
      authorFullName,
      source: 'OUTBOUND', //Only Supported Type at the moment.
      rating,
      textPlain: reviewTextPlain ?? '',
      relatedProductsAndServices: relatedProductsAndServices ?? [],
    });

    this.optimisticCreateModel(review);
    /**
     * Speculative Contract. To be Confirmed with Transport Layer Implementation.
     */
    this._baseTransport?.requestCreateResource({
      id: optimisticId,
      authorFullName,
      source: 'OUTBOUND',
      rating,
      textPlain: reviewTextPlain ?? '',
      relatedProductsAndServices: relatedProductsAndServices ?? [],
    });

    return review;
  }
  public deleteByObrn(obrn: string): void {
    const { localPathId } = parseObrn(obrn);
    this.optimisticDeleteModel(localPathId);
    try {
      this._baseTransport?.requestDeleteResourceByObrn(obrn);
    } catch (error) {
      this.undoOptimisticDeleteModel(localPathId);
      throw error;
    }
  }
}

export default ReviewStore;
