'use client';
import React, { useEffect, useRef } from 'react';
import { ObColorType } from '../../../tokens/colors/colors';
import { ObCheckboxUi } from '../ob-checkbox-ui/ob-checkbox-ui';
import { ObTypography } from '../ob-typography/ob-typography';

export type CheckBoxState = 'checked' | 'unchecked' | 'intermediate';

export interface ObCheckboxProps {
  checked?: CheckBoxState;
  onChange?: (state: CheckBoxState) => void;
  label: string;
  description?: string;
  labelColor?: ObColorType;
  isWarning?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  removeFromTabOrder?: boolean;
  /**
   * Optional React node to Render in place of the label. Label will still be used for accessibility purposes.
   **/
  labelNode?: React.ReactNode;
  tabindex?: number;
  size?: 'small' | 'medium';
}

/**
 * Controlled checkbox component for Outbound Design System
 * Parent is responsible for managing the state of the checkbox
 * @param param0
 * @returns
 */
export const ObCheckbox: React.FC<ObCheckboxProps> = ({
  checked = 'unchecked',
  onChange,
  label,
  description,
  labelNode,
  labelColor = 'primary',
  isWarning = false,
  disabled = false,
  hideLabel = false,
  removeFromTabOrder = false,
  tabindex = 0,
  size = 'small',
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = checked === 'intermediate';
    }
  }, [checked]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === 'Enter' && !disabled) {
      toggleCheckbox();
      event.preventDefault(); // Prevent any default behavior associated with pressing Enter.
    }
  };

  const toggleCheckbox = () => {
    if (disabled) return;

    let newState: CheckBoxState;
    if (checked === 'checked' || checked === 'intermediate') {
      newState = 'unchecked';
    } else {
      newState = 'checked';
    }
    if (onChange) {
      onChange(newState); // Notify parent about the change
    }
  };

  return (
    <label
      className={`flex items-start cursor-pointer select-none ${
        disabled ? 'opacity-50' : ''
      }`}
      tabIndex={removeFromTabOrder ? undefined : tabindex}
      onKeyDown={handleKeyDown}
    >
      <div>
        {/* 
      ------------- ACCESSIBILITY NOTE ------------- 
      We assume that if the checkbox is not in the tab-order then the parent will provide an accessible way to toggle the checkbox.
      For instance we remove the checkbox from the tab order when it is used in the ObImageCheckboxContainer component
      and that component has aria-role checkbox with an aria-checked attribute. Since the checkbox is decorative, we don't need to
      provide a way to toggle it and we don't want it to be in the tab order and we don't want it to be announced by screen readers.
       */}
        {!removeFromTabOrder && (
          <input
            type='checkbox'
            className='hidden'
            checked={checked === 'checked'}
            ref={checkboxRef}
            disabled={disabled}
            aria-label={label}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={toggleCheckbox}
          />
        )}
        <div className={description ? 'pt-1' : 'pt-[2px]'}>
          <ObCheckboxUi
            value={checked}
            isDisabled={disabled}
            isErrored={isWarning}
            size={size}
          />
        </div>
      </div>
      {!hideLabel && labelNode == null && (
        <div className='flex flex-col'>
          <ObTypography
            variant='body1'
            as='span'
            color={labelColor}
          >
            {label}
          </ObTypography>
          <ObTypography
            color='secondary'
            variant='body3'
            as='span'
          >
            {description}
          </ObTypography>
        </div>
      )}
      {!hideLabel && labelNode && labelNode}
    </label>
  );
};
