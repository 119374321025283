import { CreativeResource } from '@outbound/types';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Creative from './creative';
import CreativeTransformer from './creative-transformer';
import CreativeTransport from './transport/creative-transport';

class CreativeStore extends BaseStore<Creative, CreativeResource> {
  readonly creativeTransformer: CreativeTransformer;

  constructor(rootStore: RootStore, resourceTransport: CreativeTransport) {
    let creativeTransformer = new CreativeTransformer(rootStore);
    super(rootStore, 'creative', resourceTransport, creativeTransformer);
    this.creativeTransformer = creativeTransformer;
  }

  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this._baseTransport!.requestResourceById(id);
  }
}

export default CreativeStore;
