import { LeadEventCreatedFromForm } from 'src/state/mobx-experiment/leads/domain/lead-events/lead-event-created-from-form';
import { LeadEventQualificationStatusUpdated } from 'src/state/mobx-experiment/leads/domain/lead-events/lead-event-qualification-status-updated';
import { LeadEvent } from 'src/state/mobx-experiment/leads/domain/lead-events/lead-event-type';
import { EventCreatedFromForm } from './events/lead-created-from-submission-event';
import { EventQualificationStatusUpdated } from './events/lead-qualification-status-updated-event';

export const LeadEventFactory = ({ event }: { event: LeadEvent }) => {
  switch (event.eventType) {
    case 'CREATED_FROM_SOURCE_FORM':
      return <EventCreatedFromForm event={event as LeadEventCreatedFromForm} />;
    case 'QUALIFICATION_STATUS_MODIFIED':
      return (
        <EventQualificationStatusUpdated
          event={event as LeadEventQualificationStatusUpdated}
        />
      );
    default:
      return RENDER_NOTHING;
  }
};

const RENDER_NOTHING = null;
