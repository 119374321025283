import { ServiceCard } from '@outbound/design-system';
import { CardVariant } from '@outbound/design-system/src/business-objects/card.type';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import { Service } from 'src/state/mobx-experiment/business-context/service/service';

export const ServiceCardObserver = observer(
  ({ service, variant }: { service: Service; variant: CardVariant }) => {
    const { navigateToService } = useAppNavigation();
    return (
      <ServiceCard
        variant={variant}
        obrn={service.obrn}
        name={service.name}
        description={service.description}
        onNavigateCallback={() => navigateToService(service.id)}
      />
    );
  }
);
