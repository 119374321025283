import { LeadQualificationStatus } from '@outbound/types/src/lead/api-resource/lead-qualification-status';
import { RootStore } from '../../../root-store';
import { AbstractLeadEvent, LeadEventDto } from './abstract-lead-event';

const FROM_QUALIFICATION_STATUS_KEY = 'fromQualificationStatus';
const TO_QUALIFICATION_STATUS_KEY = 'toQualificationStatus';

interface QualificationStatusUpdatedLeadEventContext {
  fromQualificationStatus: LeadQualificationStatus;
  toQualificationStatus: LeadQualificationStatus;
}

export class LeadEventQualificationStatusUpdated extends AbstractLeadEvent<QualificationStatusUpdatedLeadEventContext> {
  protected constructor(
    rootStore: RootStore,
    leadEventData: LeadEventDto,
    context: QualificationStatusUpdatedLeadEventContext
  ) {
    super(rootStore, leadEventData, context);
  }

  static fromDto(
    rootStore: RootStore,
    data: LeadEventDto
  ): LeadEventQualificationStatusUpdated {
    const rawContext = data.context;
    this.assertContextIsValidForEvent(rawContext);

    return new LeadEventQualificationStatusUpdated(rootStore, data, {
      fromQualificationStatus: rawContext[FROM_QUALIFICATION_STATUS_KEY],
      toQualificationStatus: rawContext[TO_QUALIFICATION_STATUS_KEY],
    });
  }

  static create(
    rootStore: RootStore,
    fromQualificationStatus: LeadQualificationStatus,
    toQualificationStatus: LeadQualificationStatus,
    timestamp: string
  ) {
    const user = rootStore.authenticatedUser;
    if (user == null) {
      throw new Error('Only authenticated users can create events');
    }

    return new LeadEventQualificationStatusUpdated(
      rootStore,
      {
        timestamp,
        eventType: 'QUALIFICATION_STATUS_MODIFIED',
        actorObrn: user.obrn,
        context: {
          fromQualificationStatus,
          toQualificationStatus,
        },
      },
      {
        fromQualificationStatus,
        toQualificationStatus,
      }
    );
  }

  static assertContextIsValidForEvent(context: Record<string, any>): void {
    if (context == null) {
      throw new Error(
        'Context is required for QUALIFICATION_STATUS_MODIFIED event'
      );
    }

    if (context[FROM_QUALIFICATION_STATUS_KEY] == null) {
      throw new Error(
        'fromQualificationStatus is required for QUALIFICATION_STATUS_MODIFIED event'
      );
    }
    if (context[TO_QUALIFICATION_STATUS_KEY] == null) {
      throw new Error(
        'toQualificationStatus is required for QUALIFICATION_STATUS_MODIFIED event'
      );
    }
  }
}
