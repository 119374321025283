import { ReviewCard } from '@outbound/design-system';
import { CardVariant } from '@outbound/design-system/src/business-objects/card.type';
import { observer } from 'mobx-react-lite';
import { useAppNavigation } from 'src/hooks/use-app-navigation';
import Review from 'src/state/mobx-experiment/business-context/review/review';

export const ReviewCardObserver = observer(
  ({ review, variant }: { review: Review; variant: CardVariant }) => {
    const { navigateToReviewDetail } = useAppNavigation();
    return (
      <ReviewCard
        variant={variant}
        reviewText={review.textPlain}
        authorFullName={review.authorFullName}
        rating={review.rating}
        localization__NoReviewTextProvided='Rating Only, No Review Text Provided.'
        onNavigateAriaLabel={`View ${review.rating} Star Review from ${review.authorFullName}`}
        onNavigateCallback={() => {
          navigateToReviewDetail(review.obrn);
        }}
        source={'OUTBOUND'}
        obrn={review.obrn}
      />
    );
  }
);
