export const ObrnObjectValues = [
  'workspace',
  'workspace/membership',
  'integration-configuration',
  //An evaluation of a Health Check Item for an Integration Configuration
  'integration-configuration/health-check-item-execution',
  //Defines the Health Check and Outcomes for the Integration. These are design time or CMS objects.
  'integration/health-check-item',
  'integration/health-check-item/outcome',

  'integration',
  'asset',
  'location',
  'landing-page',
  'service',
  'service/service-offering',
  'customer-profile',
  'creative',
  'creative/setting',
  'creative-template/setting-definition',
  'creative-template/setting-section',
  'creative-template/setting-section/sub-section',
  'creative-template',
  'campaign',
  'campaign/highlight',
  'campaign/location',
  'campaign/deployment',
  'campaign/campaign-customer-profile',
  'user',
  'review',
  'lead',
  'lead/event',
] as const;

export type ObrnObject = (typeof ObrnObjectValues)[number];

export const ObrnDomainValues = [
  'campaign',
  'creative',
  'playbook',
  'workspace',
  'review',
  'lead',
] as const;

export type ObrnDomain = (typeof ObrnDomainValues)[number];
