import { ReactNode } from 'react';
import { ObAvatar } from '../../../../../avatars';
import { ObTypography } from '../../../../../components/elements/ob-typography/ob-typography';
import { IconSystemKeys } from '../../../../../tokens/icons/icons';
import { timeAgoFromIsoStringWithAbbreviatedLabel } from '../../../../../utilities/datetime-utilities';
import { BaseEvent, BaseEventProps } from '../base-event';

export interface Props extends Pick<BaseEventProps, 'isLastFetchedEvent'> {
  title: string;
  actorName: string;
  actorImageUrl: string;
  eventIcon: IconSystemKeys;
  timestamp: string;
  children: ReactNode;
}
export const BaseEventMinor = ({
  actorName,
  actorImageUrl,
  timestamp,
  children,
  isLastFetchedEvent,
}: Props) => {
  return (
    <BaseEvent isLastFetchedEvent={isLastFetchedEvent}>
      <>
        <div>
          <div className='relative px-1'>
            <div className='flex  items-center justify-center rounded-full bg-gray-100 ring-8 ring-black'>
              <ObAvatar
                fallbackText={actorName}
                profileImageUrl={actorImageUrl}
                shape='circle'
                size='small'
              />
            </div>
          </div>
        </div>
        <div className='min-w-0 flex-1 py-1 '>
          <div className='text-sm text-gray-500 flex gap-1'>
            <div>
              <ObTypography
                as='span'
                className=''
              >
                {children}{' '}
                <ObTypography
                  className='whitespace-nowrap'
                  color='secondary'
                  as='span'
                >
                  {timeAgoFromIsoStringWithAbbreviatedLabel(timestamp)}
                </ObTypography>
              </ObTypography>
            </div>
          </div>
        </div>
      </>
    </BaseEvent>
  );
};
