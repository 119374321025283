import { ObActionMenu } from '../../components/elements/ob-action-menu/ob-action-menu';
import { ObActionMenuItem } from '../../components/elements/ob-action-menu/ob-action-menu-item';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ReviewCtaProps } from './review.types';

/**
 * Call to Action Menu for Review, provides quick access to all actions that can be performed on a Review.
 * Considered a secondary access point for actions that can be performed on a Review. Ideally actions in this
 * menu should have an additional access point on the Review Object itself.
 * @returns
 */
export const ReviewCallToActionMenu = ({
  onEditCallback,
  loc__editReviewCtaLabel,
  loc__editReviewCtaAriaLabel,
  onDeleteCallback,
  loc__deleteReviewCtaLabel,
  loc__deleteReviewCtaAriaLabel,
}: ReviewCtaProps) => {
  return (
    <ObActionMenu
      menuOpenButton={
        <ObButton
          label='View Review Actions'
          className='rounded-full'
          variant='outline'
          buttonType='icon'
          iconLeft='moreVert'
          size='medium'
        />
      }
    >
      <ObActionMenuItem
        title={loc__editReviewCtaLabel}
        icon={'pencil02'}
        closeOnAction={true}
        onClickCallback={onEditCallback}
        ariaLabel={loc__editReviewCtaAriaLabel ?? loc__editReviewCtaLabel}
      />
      <ObActionMenuItem
        title={loc__deleteReviewCtaLabel}
        icon={'delete'}
        closeOnAction={true}
        onClickCallback={onDeleteCallback}
        ariaLabel={loc__deleteReviewCtaAriaLabel ?? loc__deleteReviewCtaLabel}
      />
    </ObActionMenu>
  );
};
