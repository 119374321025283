import { useCallback, useEffect, useRef, useState } from 'react';
import { ObChannelAvatar } from '../../avatars';
import { ObActionMenu } from '../../components/elements/ob-action-menu/ob-action-menu';
import { ObActionMenuItem } from '../../components/elements/ob-action-menu/ob-action-menu-item';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ObInputControlled } from '../../components/elements/ob-input-controlled/ob-input-controlled';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { ObIcon } from '../../tokens/icons/ob-icon/ob-icon';
import { CampaignCardProps } from './campaign-card';
import { ObGoalAvatar } from './components/campaign-goal/campaign-goal';
import { CampaignLatestDeploymentBadge } from './components/campaign-latest-deployment-status-badge';
import { ServingStateBadge } from './components/campaign-status-badge';
import { CampaignIntegrationBadge } from './components/channel-integration-status-badge';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

/**
 * Callback functions for Calls to Action that will appear on the Campaign Detail
 *
 */
interface CampaignDetailCallToActionProps {
  /**
   * Callback function called when the user want to set the
   * user Paused State to Paused
   * @returns
   */
  onUserPausedStateSetToPaused: () => void;

  /**
   * Callback function called when the user want to set the
   * user Paused State to Not Paused
   * @returns
   */
  onUserPausedStateSetToNotPaused: () => void;

  onDeployCampaignCallback: () => void;

  onDeleteCampaignCallback: () => void;
}

export interface CampaignDetailProps
  extends Omit<CampaignCardProps, 'variant'>,
    CampaignDetailCallToActionProps {
  /**
   * Pass Through for the Latest Campaign Deployment Slot
   * If not provided, a Not Published Badge will be displayed in its place
   */
  latestCampaignDeploymentSlot?: React.ReactNode;
}

const CampaignDetailActionMenu = ({
  pausedByUserState,
  onUserPausedStateSetToNotPaused,
  onUserPausedStateSetToPaused,
  setIsEditNameModeEnabled,
  onDeployCampaignCallback,
  onDeleteCampaignCallback,
}: Pick<
  CampaignDetailProps,
  | 'onUserPausedStateSetToNotPaused'
  | 'onUserPausedStateSetToPaused'
  | 'pausedByUserState'
  | 'onDeployCampaignCallback'
  | 'onDeleteCampaignCallback'
> & { setIsEditNameModeEnabled: () => void }) => {
  return (
    <>
      <ObActionMenu
        menuOpenButton={
          <ObButton
            className='focus-visible:shadow-interactive focus:outline-none'
            buttonType='icon'
            variant='ghost'
            iconLeft='moreVert'
            label='Open Campaign Actions Menu'
          />
        }
      >
        {/* This logic should be moved into the Campaign Model campaign.isPauseActionAvailable() */}
        {pausedByUserState === 'CAMPAIGN_NOT_PAUSED_BY_USER' && (
          <ObActionMenuItem
            title={'Pause Campaign'}
            icon={'pause'}
            closeOnAction={true}
            onClickCallback={onUserPausedStateSetToPaused}
            ariaLabel={'Pause Campaign'}
          />
        )}
        {/* This logic should be moved into the Campaign Model campaign.isEnableActionAvailable() */}
        {pausedByUserState === 'CAMPAIGN_PAUSED_BY_USER' && (
          <ObActionMenuItem
            title={'Enable Campaign'}
            icon={'play'}
            closeOnAction={true}
            onClickCallback={onUserPausedStateSetToNotPaused}
            ariaLabel={'Pause Campaign'}
          />
        )}
        <ObActionMenuItem
          title={'Edit Campaign Name'}
          icon={'pencil02'}
          closeOnAction={true}
          onClickCallback={setIsEditNameModeEnabled}
          ariaLabel={'Edit Campaign Name'}
        />
        <ObActionMenuItem
          title={'Publish Campaign'}
          icon={'dataFlow'}
          closeOnAction={true}
          onClickCallback={onDeployCampaignCallback}
          ariaLabel={'Publish Campaign'}
        />
        <ObActionMenuItem
          title={'Delete Campaign'}
          icon={'delete'}
          closeOnAction={true}
          onClickCallback={onDeleteCampaignCallback}
          ariaLabel={'Delete Campaign'}
        />
      </ObActionMenu>
    </>
  );
};

export const CampaignDetail = ({
  name,
  channel,
  servingState,
  servingStateReason,
  servingStateLastCalculatedAtTimestamp,
  goal,
  integrationXConfigurationSlot,
  latestCampaignDeploymentSlot,
  onSaveNameClickedCallback,
  onUserPausedStateSetToNotPaused,
  onUserPausedStateSetToPaused,
  onDeployCampaignCallback,
  onDeleteCampaignCallback,
  pausedByUserState,
}: CampaignDetailProps) => {
  const [isEditNameModeEnabled, setIsEditNameModeEnabled] = useState(false);
  const [newName, setNewName] = useState(name);
  const formRef = useRef<HTMLFormElement>(null); // Ref for the form

  const saveName = useCallback(() => {
    if (newName !== name) {
      onSaveNameClickedCallback?.(newName);
    }
    setIsEditNameModeEnabled(false);
  }, [newName, name, onSaveNameClickedCallback]);

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      console.log('Form submitted');
      saveName();
    },
    [saveName]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        setIsEditNameModeEnabled(false);
      }
    },
    []
  );

  useEffect(() => {
    // Close edit mode if clicking outside the form
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setIsEditNameModeEnabled(false); // Close edit mode
      }
    };

    if (isEditNameModeEnabled) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditNameModeEnabled]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row gap-3 flex-grow items-start '>
          <div className='hidden md:block'>
            <ObChannelAvatar
              channel={channel}
              size={'xx-large'}
            />
          </div>
          <div className='block md:hidden pt-2'>
            <ObChannelAvatar
              channel={channel}
              size={'large'}
            />
          </div>
          <div className='flex flex-col flex-1 gap-3 items-start justify-between self-stretch'>
            <div className='flex flex-col self-stretch justify-start'>
              {isEditNameModeEnabled ? (
                <form
                  ref={formRef}
                  onSubmit={handleFormSubmit}
                  className='flex flex-1 self-stretch items-center w-full max-h-[40px] gap-2 pr-4'
                >
                  <ObInputControlled
                    value={newName}
                    size='medium'
                    onKeyDown={handleKeyDown}
                    autoFocus={true}
                    onValueChangedCallback={(value) => setNewName(value)}
                    isLoading={false}
                    inputId={''}
                  />
                  <div className='flex-shrink-0'>
                    <ObButton
                      variant='primary'
                      size='large'
                      label='Save'
                      type='submit'
                      onSubmit={handleFormSubmit}
                    />
                  </div>
                </form>
              ) : (
                <button
                  onClick={() => setIsEditNameModeEnabled(true)}
                  className='flex flex-row gap-2 justify-start items-center group '
                >
                  <ObTypography
                    className='dark:text-dark/content/primary hover:dark:text-dark/action/primary-v2/hover text-left'
                    color='inherit'
                    variant='h2'
                  >
                    {name}
                  </ObTypography>
                  <div className='opacity-0 group-hover:opacity-100 transition-opacity duration-200 pt-2'>
                    <ObIcon
                      icon='pencil02'
                      size={'small'}
                    />
                  </div>
                </button>
              )}
              <ObGoalAvatar
                goal={goal}
                size='medium'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-3 flex-wrap'>
        <ServingStateBadge
          servingState={servingState}
          servingStateReason={servingStateReason}
          servingStateLastCalculatedAtTimestamp={
            servingStateLastCalculatedAtTimestamp
          }
        />
        <CampaignIntegrationBadge
          integrationXConfigurationSlot={integrationXConfigurationSlot}
        />
        <CampaignLatestDeploymentBadge
          latestCampaignDeploymentSlot={latestCampaignDeploymentSlot}
        />
        <div className='flex flex-1 items-end pb-1'>
          <CampaignDetailActionMenu
            pausedByUserState={pausedByUserState}
            onDeployCampaignCallback={onDeployCampaignCallback}
            onDeleteCampaignCallback={onDeleteCampaignCallback}
            onUserPausedStateSetToNotPaused={onUserPausedStateSetToNotPaused}
            onUserPausedStateSetToPaused={onUserPausedStateSetToPaused}
            setIsEditNameModeEnabled={() => setIsEditNameModeEnabled(true)}
          />
        </div>
      </div>
    </div>
  );
};
