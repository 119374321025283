import { AnimatePresence, TargetAndTransition, motion } from 'motion/react';
import { useEffect, useState } from 'react';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import {
  CONTENT_HEADER_HEIGHT_PX,
  LEAD_DETAILS_ASIDE_WIDTH_PX,
} from '../../dimensions';
import { LeadDetailAsideContentWithoutContainerStyles } from './content-without-container-styles';

export interface LeadDetailsAsideProps {
  lead: Lead;
  isOpen: boolean;
  onCloseCallback: () => void;
}

export const LeadDetailsAside = ({ isOpen, lead }: LeadDetailsAsideProps) => {
  const [isInitialRenderComplete, setIsInitialRenderComplete] =
    useState<boolean>(false);

  useEffect(() => {
    setIsInitialRenderComplete(true);
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.aside
          initial={
            isInitialRenderComplete
              ? configureInitialPositionOfAnimationToBeOffTheRightOfTheScreen()
              : DO_NOT_ANIMATE
          }
          animate={configureCssPropertiesToAnimate()}
          exit={configureAsideToExitToTheRightWhilePullingContentToTheLeftWithIt()}
          transition={configureAnimationTransitionSpeedAndPhysics()}
          className='hidden lg:flex flex-col gap-8 border-l border-dark/border/default/normal p-6 bg-dark/background/surface justify-between'
          style={{
            height: `calc(100vh - ${CONTENT_HEADER_HEIGHT_PX}px)`,
            width: `${LEAD_DETAILS_ASIDE_WIDTH_PX}px`,
          }}
        >
          <LeadDetailAsideContentWithoutContainerStyles lead={lead} />
        </motion.aside>
      )}
    </AnimatePresence>
  );
};

const DO_NOT_ANIMATE = false;

const configureCssPropertiesToAnimate = () => ({
  x: 0,
  marginLeft: 0,
});

const configureAnimationTransitionSpeedAndPhysics = () => ({
  type: 'spring',
  stiffness: 300,
  damping: 28,
});

const configureAsideToExitToTheRightWhilePullingContentToTheLeftWithIt =
  (): TargetAndTransition => ({
    x: LEAD_DETAILS_ASIDE_WIDTH_PX,
    marginLeft: LEAD_DETAILS_ASIDE_WIDTH_PX * -1,
  });

const configureInitialPositionOfAnimationToBeOffTheRightOfTheScreen = () => ({
  x: LEAD_DETAILS_ASIDE_WIDTH_PX,
  marginLeft: LEAD_DETAILS_ASIDE_WIDTH_PX * -1,
});
