import {
  FormDefinition,
  FormFieldDefinition,
  FormFieldType,
} from '@outbound/design-system';

import t from '../reviews.i18n.json';

const authorFullname: FormFieldDefinition = {
  id: 'authorFullName',
  autofocus: true,
  label: t.CREATE_REVIEW_DIALOG.AUTHOR_FULL_NAME_INPUT_LABEL,
  helperText: t.CREATE_REVIEW_DIALOG.AUTHOR_FULL_NAME_INPUT_HELPER_TEXT,
  type: FormFieldType.TEXT,
  validationSchema: {
    isRequired: true,
    minLength: 3,
    maxLength: 100,
  },
  fieldTypeSettings: {},
};
const rating: FormFieldDefinition = {
  id: 'rating',
  label: t.CREATE_REVIEW_DIALOG.RATING_INPUT_LABEL,
  type: FormFieldType.SELECT,
  helperText: t.CREATE_REVIEW_DIALOG.RATING_INPUT_HELPER_TEXT,
  validationSchema: {
    isRequired: true,
  },
  fieldTypeSettings: {
    options: [
      {
        value: '5',
        label: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_5,
        displayValue: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_5,
      },
      {
        value: '4',
        label: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_4,
        displayValue: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_4,
      },
      {
        value: '3',
        label: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_3,
        displayValue: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_3,
      },
      {
        value: '2',
        label: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_2,
        displayValue: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_2,
      },
      {
        value: '1',
        label: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_1,
        displayValue: t.CREATE_REVIEW_DIALOG.RATING_INPUT_OPTION_1,
      },
    ],
  },
};
const reviewTextPlain: FormFieldDefinition = {
  id: 'reviewText',
  label: t.CREATE_REVIEW_DIALOG.REVIEW_TEXT_INPUT_LABEL,
  helperText: t.CREATE_REVIEW_DIALOG.REVIEW_TEXT_INPUT_HELPER_TEXT,
  type: FormFieldType.TEXT_AREA,
  validationSchema: {
    isRequired: false,
    maxLength: 5000,
  },
  fieldTypeSettings: {},
};
const relatedTo: FormFieldDefinition = {
  id: 'relatedProductsAndServices',
  label: t.CREATE_REVIEW_DIALOG.RELATED_PRODUCTS_AND_SERVICES_INPUT_LABEL,
  helperText:
    t.CREATE_REVIEW_DIALOG.RELATED_PRODUCTS_AND_SERVICES_INPUT_HELPER_TEXT,
  type: FormFieldType.COMBO_BOX,
  validationSchema: {
    isRequired: false,
  },
  fieldTypeSettings: {
    options: [],
  },
};

/*
 * Form Definition for Review Create / Edit Form
 */
export const CreateEditReviewForm: FormDefinition = {
  id: 'review-create-edit-form',
  heading: '',
  subHeading: '',
  sections: [
    {
      id: 'main',
      heading: '',
      subHeading: '',
      fields: [authorFullname, rating, reviewTextPlain, relatedTo],
    },
  ],
};
export interface ReviewCreateEditFormValue {
  authorFullName: string;
  rating: string;
  reviewText: string;
  relatedProductsAndServices: string[];
}
