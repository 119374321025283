import { ObLoadingSpinner } from '../../components/elements/ob-loading-spinner/ob-loading-spinner';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { useBrowserSupport } from '../../hooks/use-browser-support';

import aiOrbSafari from './outbound-2-hevc-safari.mp4';
import aiOrbChromium from './outbound-2.webm';

import aiBoltSafari from './bolt.mov';
import aiBoltChromium from './bolt.webm';

import aiIcon from './ai-icon.svg';
import {
  HEVC_AI_ORB_VIDEO_METADATA,
  WEBM_AI_ORB_VIDEO_METADATA,
} from './video-codecs';
export interface ObAiGeneratingLoadingIndicatorProps {
  variant: 'spinner' | 'bar';
  progress?: number | 'indeterminate';
  size?: number;
}

/**
 * Loading Indicator specifically to be used to show that AI is Generating
 * This is a first pass implementation from a UX perspective and may need to be updated
 * by a visual designer to be more polished.
 *
 * The goal here is to have a dedicated loading indicator for AI generating that can be used
 * to communicate to the user that some generative AI process is happening vs a generic loading spinner
 * that is used for general loading of data or network requests.
 *
 * For a generic loading spinner use the ObLoadingSpinner component
 * @returns
 */
export const ObAiGeneratingLoadingIndicator = ({
  variant,
  progress,
  size,
}: ObAiGeneratingLoadingIndicatorProps) => {
  if (variant === 'spinner') {
    return (
      <ObAiGeneratingSpinner
        progress={progress}
        size={size}
      />
    );
  } else {
    return (
      <ObAiGeneratingBar
        progress={progress}
        size={size}
      />
    );
  }
};

const ObAiGeneratingBar = ({
  progress = 'indeterminate',
}: Omit<ObAiGeneratingLoadingIndicatorProps, 'variant'>) => {
  if (progress === 'indeterminate') {
    return (
      <ObTypography color='negative'>Progress Not Implemented</ObTypography>
    );
  } else {
    return (
      <ObTypography color='negative'>Progress Not Implemented</ObTypography>
    );
  }
};

const AiOrbVideo = ({ size }: { size: number }) => {
  const { isVideoPlayableWithAlpha } = useBrowserSupport();

  const isHevcWithAlphaSupported: boolean = isVideoPlayableWithAlpha(
    HEVC_AI_ORB_VIDEO_METADATA.videoCodec,
    HEVC_AI_ORB_VIDEO_METADATA.mimeType
  );

  const isWebMWithAlphaSupported: boolean = isVideoPlayableWithAlpha(
    WEBM_AI_ORB_VIDEO_METADATA.videoCodec,
    WEBM_AI_ORB_VIDEO_METADATA.mimeType
  );

  let sizeStyleHeight;
  let sizeStyleWidth;

  if (size > 100) {
    sizeStyleHeight = `${size}px`;
    sizeStyleWidth = `${size}px`;
  } else {
    sizeStyleHeight = `${size}px`;
    sizeStyleWidth = `${size / 2}px`;
  }

  /**
   * At the time of writing this code,
   * HEVC Test needs to be first because safari
   * claims to support the webm code (It doesn't support transparency)
   * but Chrome does not support the hevc codec so this order works
   * while flipping the order does not.
   * */
  if (isHevcWithAlphaSupported) {
    return (
      <div
        className='flex justify-center items-center overflow-visible'
        style={{
          width: sizeStyleWidth,
          height: sizeStyleHeight,
        }}
      >
        <video
          loop={true}
          autoPlay={true}
          playsInline={true}
          muted={true}
        >
          <source
            src={size > 100 ? aiOrbSafari : aiBoltSafari}
            type='video/mp4'
          ></source>
        </video>
      </div>
    );
  } else if (isWebMWithAlphaSupported) {
    return (
      <div
        className='flex justify-center items-center overflow-visible'
        style={{
          width: sizeStyleWidth,
          height: sizeStyleHeight,
        }}
      >
        <video
          loop={true}
          autoPlay={true}
          playsInline={true}
          muted={true}
        >
          <source
            src={size > 100 ? aiOrbChromium : aiBoltChromium}
            type='video/webm'
          ></source>
        </video>
      </div>
    );
  } else {
    // Fallback if neither video is supported
    return (
      <ObLoadingSpinner
        includeBrand={false}
        logoPath={aiIcon}
        size={size}
      />
    );
  }
};

const ObAiGeneratingSpinner = ({
  progress = 'indeterminate',
  size,
}: Omit<ObAiGeneratingLoadingIndicatorProps, 'variant'>) => {
  if (progress === 'indeterminate') {
    return <AiOrbVideo size={size ?? 80} />;
  } else {
    return (
      <ObTypography color='negative'>Progress Not Implemented</ObTypography>
    );
  }
};
