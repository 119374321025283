import { useDrawerService } from '@outbound/design-system';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { LeadDetailAsideContentWithoutContainerStyles } from '../content-without-container-styles';

export const useLeadDetailAsideDrawer = () => {
  const drawerService = useDrawerService();

  const openLeadDetailAsideInDrawer = (lead: Lead) =>
    drawerService
      .pushDrawer({
        testid: '',
        size: 'medium',
        title: 'Lead Details',
        description: 'Information about this lead',
        drawerContent: <LeadDetailAsideDrawerLayout lead={lead} />,
      })
      .catch((_error) => {
        //Action Cancelled, No action needed
      });
  return {
    openLeadDetailAsideInDrawer,
  };
};

const LeadDetailAsideDrawerLayout = ({ lead }: { lead: Lead }) => {
  return (
    <div className='p-4 flex gap-8 flex-col'>
      <LeadDetailAsideContentWithoutContainerStyles lead={lead} />
    </div>
  );
};
