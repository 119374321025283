import { useCallback, useEffect, useState } from 'react';

export const SMALL_RESOLUTION_BREAKPOINT_PX = 640;
export const LARGE_RESOLUTION_BREAKPOINT_PX = 1024;

export const useWindowDimensions = (
  widthOffset: number = 0,
  heightOffset: number = 0
) => {
  const formatDimensions = useCallback(() => {
    return {
      width:
        window.innerWidth -
        (widthOffset <= window.innerWidth ? widthOffset : 0),
      height:
        window.innerHeight -
        (heightOffset <= window.innerHeight ? heightOffset : 0),
      isSmallOrLargerViewport:
        window.innerWidth >= SMALL_RESOLUTION_BREAKPOINT_PX,
      isLargerViewport: window.innerWidth >= LARGE_RESOLUTION_BREAKPOINT_PX,
    };
  }, [heightOffset, widthOffset]);

  const [dimensions, setDimensions] = useState(formatDimensions());

  const updateDimensions = useCallback(() => {
    setDimensions(formatDimensions());
  }, [formatDimensions]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [dimensions, updateDimensions]);

  return dimensions;
};
