import {
  BaseTemplateValueResource,
  SettingDefinitionResource,
  TextSettingDefinitionResource,
} from '@outbound/types';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

export interface FieldValidationResult {
  //The value that was validated
  value: any;

  //Whether the value is invalid
  isValid: boolean;

  //The errors that occurred during validation
  errors: string[];
  //The id of the field
  id: string;
}

export interface ValidationResult {
  results: { [key: string]: any };
  isValid: boolean;
}

const buildYupValidationForTextFieldResource = (
  field: TextSettingDefinitionResource
) => {
  return buildYupValidationForTextField(
    field.label,
    field.publishingValidation.isRequired,
    field.publishingValidation.minLength,
    field.publishingValidation.maxLength
  );
};

export const buildYupValidationForTextField = (
  label: string,
  isRequired: boolean,
  minLength?: number,
  maxLength?: number
): Yup.StringSchema<string | undefined, AnyObject, string | undefined> => {
  let yupField = Yup.string();
  //Add Validation For Required Fields
  if (isRequired) {
    yupField = yupField.required(`${fieldLabel(label)} is required.`);
  }
  //Add Validation For Fields with a Max Length
  if (maxLength) {
    yupField = yupField.max(
      maxLength,
      `${fieldLabel(label)} can not be more than ${maxLength} characters.`
    );
  }
  //Add Validation For Fields with a Min Length
  if (minLength) {
    yupField = yupField.min(
      minLength,
      `${fieldLabel(label)} can not be less than ${minLength} characters.`
    );
  }

  /**
   * Custom validation to ensure that commas are followed by a space
   */
  yupField = yupField.test(
    'comma-spacing', // Test name
    `${fieldLabel(
      label
    )} cannot have a comma followed by a non-space character.`,
    (value) => {
      // Allow undefined/null unless required
      if (value === undefined || value === null) {
        return true;
      }
      return !/,([^\s])/g.test(value); // Fail if a comma is not followed by a space
    }
  );

  /**
   * Custom validation to ensure the value does not have excessive capitalization
   */
  yupField = yupField.test(
    'excessive-capitalization', // Test name
    `${fieldLabel(label)} cannot have excessive capitalization.`,
    (value) => {
      if (value === undefined || value === null) {
        return false;
      }
      return !hasExcessiveCapitalization(value);
    }
  );

  /**
   * Custom validation to ensure the value does not have gimmicky capitalization
   */
  yupField = yupField.test(
    'gimmicky-capitalization', // Test name
    `${fieldLabel(label)} cannot have gimmicky capitalization.`,
    (value) => {
      if (value === undefined || value === null) {
        return false;
      }
      return !hasGimmickyCapitalization(value);
    }
  );

  return yupField;
};
/**
 * NOTE: This function is temporary, as we expand the global template validation checks this function will
 * probably need to move into a class with a base implementation to accommodate the different Ad Channel types
 * that will have different validation requirements.
 */
export function calculateGlobalTemplateValidationErrors(
  settings: Map<string, any>
): Array<{
  settingId: string;
  settingName: string;
  errors: string[];
}> {
  const errors: Array<{
    settingId: string;
    settingName: string;
    errors: string[];
  }> = [];

  const valueMap = new Map<string, string[]>(); // Map to track duplicate values with their setting IDs.

  // Step 1: Identify duplicate setting values
  for (const [key, setting] of Array.from(settings.entries())) {
    const value = setting.value.text; // Assuming each setting has a 'value' property
    if (!value) continue;

    if (!valueMap.has(value)) {
      valueMap.set(value, []);
    }
    valueMap.get(value)!.push(key);
  }

  // Step 2: Add duplicate errors
  for (const [value, settingIds] of Array.from(valueMap.entries())) {
    if (settingIds.length > 1) {
      settingIds.forEach((id) => {
        const setting = settings.get(id);
        if (setting) {
          errors.push({
            settingId: setting.id,
            settingName: setting.name,
            errors: [`Duplicate value found: "${value}"`],
          });
        }
      });
    }
  }

  return errors;
}

export const validateTextSetting = (
  id: string,
  value: string,
  validationRules: Yup.StringSchema
): FieldValidationResult => {
  const validationResult: FieldValidationResult = {
    id: id,
    value: value,
    isValid: true,
    errors: [],
  };
  //Build the validation schema

  try {
    validationRules.validateSync(value, { abortEarly: false });
  } catch (err) {
    //If Error is a Yup error
    if (err instanceof Yup.ValidationError) {
      //Populate the errors in the validation results
      err.inner.forEach((error: any) => {
        validationResult.errors.push(error.message);
        validationResult.isValid = false;
      });
    } else {
      throw err;
    }
  }

  return validationResult;
};

export const buildValidationSchemaForTemplateSettings = (
  fields: Array<SettingDefinitionResource>
) => {
  const schema: { [key: string]: any } = {};

  fields.forEach((field) => {
    switch (field.type) {
      case 'text': {
        let yupField = Yup.string();
        if (isTextSettingFieldResource(field)) {
          yupField = buildYupValidationForTextFieldResource(field);
        }
        schema[field.id] = yupField;
        break;
      }
      case 'color': {
        //Future implementation
        break;
      }
      case 'image': {
        //Future implementation
        break;
      }
      default: {
        throw new Error('Unsupported field type');
      }
    }
  });

  return Yup.object(schema);
};

/**
 * Helper function to validate the given template settings.
 * @param template
 * @param templateSettingValues
 */
export const validateTemplateSettings = async (
  templateSettingFields: Array<SettingDefinitionResource>,
  templateSettingValues: Array<BaseTemplateValueResource>
): Promise<ValidationResult> => {
  //Build map from values array of id to value
  const valuesMap = templateSettingValues.reduce(
    (acc, value) => {
      //Initial handling for this can be made more robust for other types and cases as the need arises
      if (value.type === 'text' && value.value) {
        acc[value.id] = value.value.text;
      } else {
        acc[value.id] = value.value;
      }

      return acc;
    },
    {} as { [key: string]: any }
  );

  //Build the validation schema
  const schema = buildValidationSchemaForTemplateSettings(
    templateSettingFields ?? []
  );

  //Prepare the return map of id -> ValidationResult
  const validationResults: { [key: string]: FieldValidationResult } =
    templateSettingFields.reduce(
      (acc, field) => {
        acc[field.id] = {
          value: valuesMap[field.id],
          errors: [],
          isValid: true,
          id: field.id,
        };
        return acc;
      },
      {} as { [key: string]: FieldValidationResult }
    );

  try {
    await schema.validate(valuesMap, { abortEarly: false });
  } catch (err) {
    //If Error is a Yup error
    if (err instanceof Yup.ValidationError) {
      //Populate the errors in the validation results
      err.inner.forEach((error: any) => {
        validationResults[error.path].errors.push(error.message);
        validationResults[error.path].isValid = false;
      });
    } else {
      throw err;
    }
  }

  //Check if all fields are valid
  const isValid = Object.values(validationResults).every(
    (result) => result.isValid
  );

  return { results: validationResults, isValid };
};

const fieldLabel = (label: string) => {
  return label ?? 'Field';
};

/**
 * Type guard for checking if a field is a text field
 * @param field
 * @returns
 */
const isTextSettingFieldResource = (
  field: SettingDefinitionResource
): field is TextSettingDefinitionResource => {
  return field.type === 'text';
};

const hasExcessiveCapitalization = (input: string): boolean => {
  const words = splitInput(input);
  let capitalizedCount = 0;

  for (const word of words) {
    const letters = word.split(''); // Convert word to array of characters
    const uppercaseCount = letters.filter(
      (char) => char >= 'A' && char <= 'Z'
    ).length;

    /**
     * Detects fully capitalized words while allowing:
     * - Single-letter words (ignored)
     * - Words with up to 4 characters to be fully capitalized (e.g., "FREE")
     */
    if (word.length > 4 && uppercaseCount === letters.length) {
      capitalizedCount++;
    }
  }

  return capitalizedCount > 0;
};

const hasGimmickyCapitalization = (input: string): boolean => {
  const words = splitInput(input);

  // Directly flag input if all words are fully capitalized
  if (areAllWordsAllCaps(words)) return true;

  // Directly flag input if multiple mixed capitalization words exist
  if (hasMultipleMixedCapsWords(words)) return true;

  let gimmickyCount = 0;

  for (const word of words) {
    const letters = word.split(''); // Convert word to character array
    const uppercaseCount = letters.filter(
      (char) => char >= 'A' && char <= 'Z'
    ).length;

    // Skip fully capitalized words (already checked in hasExcessiveCapitalization)
    if (uppercaseCount === letters.length) continue;

    const dotSeparated = word.includes('.');
    const parts = word.split('.').filter((part) => part !== '');

    /**
     * Checks if word is a valid set of initials (e.g., "A.B.C.")
     * - Allows up to 3 parts, each 1 letter long
     */
    const isValidInitials =
      dotSeparated &&
      parts.length > 1 &&
      parts.length <= 3 &&
      parts.every((part) => part.length === 1);

    /**
     * Flags fully capitalized words with dot separation (e.g., "F.L.O.W.E.R.S.")
     */
    const isFullyCapitalizedWithDots =
      dotSeparated &&
      parts.every((part) => part.length === 1 && part.toUpperCase() === part);

    /**
     * Flags words with excessive uppercase letters (3+ uppercase letters unless it's a valid initial)
     */
    const hasExcessiveUppercase = uppercaseCount >= 3 && !isValidInitials;

    /**
     * Identifies mixed capitalization (e.g., "F.L.O.W.E.R.S", "hElLo")
     */
    const hasMixedCapitalization =
      uppercaseCount > 1 &&
      !isValidInitials &&
      (hasExcessiveUppercase || isFullyCapitalizedWithDots);

    if (hasMixedCapitalization) {
      gimmickyCount++;
    }
  }

  return gimmickyCount > 0;
};

/**
 * Checks if all words in the input are fully capitalized
 */
const areAllWordsAllCaps = (words: string[]): boolean => {
  return words.length > 1 && words.every((word) => isFullyUppercase(word));
};

/**
 * Checks if there are at least two words with mixed capitalization
 */
const hasMultipleMixedCapsWords = (words: string[]): boolean => {
  let mixedCapsCount = 0;

  for (const word of words) {
    if (isMixedCapitalization(word)) {
      mixedCapsCount++;
      if (mixedCapsCount >= 2) return true; // Stop early if at least 2 mixed caps words exist
    }
  }

  return false;
};

/**
 * Determines if a word contains mixed capitalization (e.g., "bUy", "GeT")
 */
const isMixedCapitalization = (word: string): boolean => {
  let hasUppercase = false;
  let hasLowercase = false;

  for (const char of word) {
    if (char >= 'A' && char <= 'Z') {
      hasUppercase = true;
    } else if (char >= 'a' && char <= 'z') {
      hasLowercase = true;
    }
    // If both uppercase and lowercase are found, break early
    if (hasUppercase && hasLowercase) break;
  }

  if (isTitleCase(word)) return false; // Exclude proper names (Title Case)

  return (
    hasUppercase &&
    hasLowercase &&
    !isFullyUppercase(word) &&
    !isValidInitials([word])
  );
};

/**
 * Checks if a word follows Title Case format (e.g., "Amazon", "Google")
 */
const isTitleCase = (word: string): boolean => {
  return (
    word.length > 1 &&
    word[0] === word[0].toUpperCase() &&
    word.slice(1) === word.slice(1).toLowerCase()
  );
};

/**
 * Determines if a word follows valid initials format (e.g., "A.B.C.")
 */
const isValidInitials = (words: string[]): boolean => {
  return words.every((word) => {
    const parts = word.split('.').filter((part) => part !== '');
    return (
      word.includes('.') &&
      parts.length > 1 &&
      parts.length <= 3 &&
      parts.every((part) => part.length === 1)
    );
  });
};

/**
 * Determines if a word is fully uppercase (e.g., "FREE")
 */
const isFullyUppercase = (word: string): boolean => {
  return word === word.toUpperCase();
};

/**
 * Splits input into words, treating spaces and hyphens as delimiters
 */
const splitInput = (input: string): string[] => {
  const words: string[] = [];
  let word = '';

  for (const char of input) {
    if (char === ' ' || char === '-' || char === '–' || char === '—') {
      if (word.length > 0) {
        words.push(removeTrailingPunctuation(word));
        word = '';
      }
    } else {
      word += char;
    }
  }

  if (word.length > 0) words.push(removeTrailingPunctuation(word));
  return words;
};

/**
 * Removes punctuation from the end of a word (.,!?;:)
 */
const removeTrailingPunctuation = (word: string): string => {
  const endPunctuation = ['.', ',', '!', '?', ';', ':'];
  while (word.length > 0 && endPunctuation.includes(word[word.length - 1])) {
    word = word.slice(0, -1);
  }
  return word;
};
