import {
  CampaignDeploymentStatusType,
  CampaignLifecycleStatus,
  CampaignPausedByUserState,
  CampaignServingState,
  CampaignServingStateReason,
  IntegrationConfigurationOperationalStatus,
  ObCustomerStatus,
} from '@outbound/types';
import { CampaignHealthTodo } from './campaign';
import {
  ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_DESCRIPTION,
  ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_DOCS_HREF,
  ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_TITLE,
  ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_DESCRIPTION,
  ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_DOCS_HREF,
  ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_TITLE,
  ACTION_REQUIRED_ADS_HAVE_ERRORS_DESCRIPTION,
  ACTION_REQUIRED_ADS_HAVE_ERRORS_TITLE,
  ACTION_REQUIRED_CONTACT_SUPPORT_FOR_FIRST_DEPLOYMENT_FAILURE_DESCRIPTION,
  ACTION_REQUIRED_CONTACT_SUPPORT_FOR_FIRST_DEPLOYMENT_FAILURE_TITLE,
  ACTION_REQUIRED_ENABLE_CAMPAIGN_DESCRIPTION,
  ACTION_REQUIRED_ENABLE_CAMPAIGN_DOCS_HREF,
  ACTION_REQUIRED_ENABLE_CAMPAIGN_TITLE,
  ACTION_REQUIRED_PUBLISH_CAMPAIGN_DESCRIPTION,
  ACTION_REQUIRED_PUBLISH_CAMPAIGN_DOCS_HREF,
  ACTION_REQUIRED_PUBLISH_CAMPAIGN_TITLE,
  ACTION_REQUIRED_REPUBLISH_CAMPAIGN,
  ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_DESCRIPTION,
  ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_DOCS_HREF,
  ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_TITLE,
  ADS_NOT_PUBLISHED_SUGGESTION_DESCRIPTION,
  ADS_NOT_PUBLISHED_SUGGESTION_INSTRUCTION,
  ADS_NOT_PUBLISHED_SUGGESTION_TITLE,
  ADS_NOT_RUNNING_TITLE,
  AD_CHANNEL_REJECTED_ADS_DESCRIPTION,
  AD_CHANNEL_REJECTED_ADS_TITLE,
  CAMPAIGN_IS_BEING_DEPLOYED_DESCRIPTION,
  CAMPAIGN_IS_BEING_DEPLOYED_TITLE,
  CAMPAIGN_IS_BEING_REVIEWED_BY_AD_CHANNEL_BEFORE_RUNNING_DESCRIPTION,
  CAMPAIGN_IS_BEING_REVIEWED_BY_AD_CHANNEL_BEFORE_RUNNING_TITLE,
  FIRST_DEPLOYMENT_FAILED_DESCRIPTION,
  INFORMATIVE_ADS_BEING_GENERATED_DESCRIPTION,
  INFORMATIVE_ADS_BEING_GENERATED_INSTRUCTIONS,
  INFORMATIVE_ADS_BEING_GENERATED_PUBLISH_AFTER_CREATIVE_GEN_DESCRIPTION,
  INFORMATIVE_ADS_BEING_GENERATED_PUBLISH_AFTER_CREATIVE_GEN_TITLE,
  INFORMATIVE_ADS_BEING_GENERATED_TITLE,
  INFORMATIVE_ADS_BEING_GENERATED_VIEW_CREATIVE_DESCRIPTION,
  INFORMATIVE_ADS_BEING_GENERATED_VIEW_CREATIVE_TITLE,
  NOMINAL_ADS_RUNNING_DESCRIPTION,
  NOMINAL_ADS_RUNNING_TITLE,
  NOT_APPLICABLE_DESCRIPTION,
  NOT_APPLICABLE_TITLE,
  ONLY_INTEGRATION_ISSUES_ADS_NOT_RUNNING_DESC,
  ONLY_INTEGRATION_ISSUES_ADS_NOT_RUNNING_TITLE,
  ONLY_INTEGRATION_ISSUES_ADS_RUNNING_DESC,
  ONLY_INTEGRATION_ISSUES_ADS_RUNNING_TITLE,
  ONLY_ISSUE_IS_AD_CREATIVE_DESCRIPTION,
  ONLY_ISSUE_IS_AD_CREATIVE_TITLE,
  PUBLISHED_BUT_NOT_RUNNING_DESCRIPTION,
} from './campaign-content';
import { GOOGLE_ADS_INTEGRATION_HEALTH_CHECK_ID } from './integration-health-check';

export interface HealthEvaluationResult {
  title: string;
  description: string;
  instructions?: string;
  status: ObCustomerStatus;
  todos: Array<CampaignHealthTodo>;
}

export interface PartialIntegrationConfigHealthCheckItemEvalResult {
  healthCheckItemId: string;
  outcome: {
    status: string | null;
  } | null;
}

export const createTodoForIntegrationNotConfigured = (
  integrationSlug: string
): CampaignHealthTodo => {
  switch (integrationSlug) {
    case 'google-ads-manager':
      return {
        title: ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_TITLE,
        description: ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_DESCRIPTION,
        completionStatus: 'TODO',
        documentationHref:
          ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_DOCS_HREF,
        todoType: 'integration-not-configured',
      };
    default:
      return {
        title: 'Integration Not Configured',
        description: 'The Integration is not configured',
        completionStatus: 'TODO',
        documentationHref: '',
        todoType: 'integration-not-configured',
      };
  }
};

export const evaluateGoogleAdsIntegrationHealth = (
  integrationConfiguredByEmail: string | null,
  integrationConfigHealthCheckItemEvaluations: Array<PartialIntegrationConfigHealthCheckItemEvalResult>
): Array<CampaignHealthTodo> => {
  const integrationTodos: Array<CampaignHealthTodo> = [];
  const googleAdsAdminInviteHealthCheckNotPassed =
    integrationConfigHealthCheckItemEvaluations.find(
      (item) =>
        item.healthCheckItemId ===
          GOOGLE_ADS_INTEGRATION_HEALTH_CHECK_ID.ADMIN_INVITE_ACCEPTED &&
        item.outcome?.status !== 'PASS' &&
        item.outcome?.status !== 'ABSTAIN'
    );

  const googleAdsPaymentMethodHealthCheckNotPassed =
    integrationConfigHealthCheckItemEvaluations.find(
      (item) =>
        item.healthCheckItemId ===
          GOOGLE_ADS_INTEGRATION_HEALTH_CHECK_ID.PAYMENT_METHOD_PRESENT &&
        item.outcome?.status !== 'PASS'
    );

  if (googleAdsAdminInviteHealthCheckNotPassed) {
    integrationTodos.push({
      title: ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_TITLE(
        integrationConfiguredByEmail
      ),
      description: ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_DESCRIPTION,
      completionStatus: 'TODO',
      documentationHref: ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_DOCS_HREF,
      todoType: 'integration-health-check-item',
    });
  }

  if (googleAdsPaymentMethodHealthCheckNotPassed) {
    integrationTodos.push({
      title: ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_TITLE,
      description:
        ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_DESCRIPTION,
      completionStatus: 'TODO',
      documentationHref:
        ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_DOCS_HREF,
      todoType: 'integration-health-check-item',
    });
  }
  return integrationTodos;
};

export interface EvaluateCampaignHealthParams {
  campaignLifecycleStatus: CampaignLifecycleStatus;
  campaignServingState: CampaignServingState;
  campaignServingStateReason: CampaignServingStateReason;
  campaignPausedByUserState: CampaignPausedByUserState;
  isAnyCreativeStillGenerating: boolean;
  isAnyCreativeErrored: boolean;
  latestDeploymentStage: CampaignDeploymentStatusType | null;
  integrationConfigurationOperationStatus: IntegrationConfigurationOperationalStatus | null;
  integrationConfiguredByEmail: string | null;
  integrationConfigHealthCheckItemEvaluations: Array<PartialIntegrationConfigHealthCheckItemEvalResult>;
  integrationId: string;
}

export const evaluateCampaignHealth = ({
  campaignLifecycleStatus,
  campaignServingState,
  campaignServingStateReason,
  campaignPausedByUserState,
  isAnyCreativeStillGenerating,
  isAnyCreativeErrored,
  latestDeploymentStage,
  integrationConfigurationOperationStatus,
  integrationConfiguredByEmail,
  integrationConfigHealthCheckItemEvaluations,
  integrationId,
}: EvaluateCampaignHealthParams): HealthEvaluationResult => {
  if (campaignLifecycleStatus !== 'ACTIVE') {
    return {
      status: 'not-applicable',
      title: NOT_APPLICABLE_TITLE,
      description: NOT_APPLICABLE_DESCRIPTION,
      todos: [],
    };
  }
  /**
   * When evaluating health we will look at the Campaigns Attributes as well as the Integration Configuration that the Campaign uses
   * to assess the overall "Health" of the Campaign.
   */
  let integrationTodos: Array<CampaignHealthTodo> = [];

  if (integrationConfigurationOperationStatus == null) {
    integrationTodos.push(createTodoForIntegrationNotConfigured(integrationId));
  } else if (integrationId === 'google-ads-manager') {
    integrationTodos = evaluateGoogleAdsIntegrationHealth(
      integrationConfiguredByEmail,
      integrationConfigHealthCheckItemEvaluations
    );
  }

  if (latestDeploymentStage == null) {
    if (isAnyCreativeStillGenerating) {
      let todosForCampaignGenerating: Array<CampaignHealthTodo> = [
        {
          title: INFORMATIVE_ADS_BEING_GENERATED_VIEW_CREATIVE_TITLE,
          description:
            INFORMATIVE_ADS_BEING_GENERATED_VIEW_CREATIVE_DESCRIPTION,
          completionStatus: 'TODO',
          documentationHref: '',
          todoType: 'view-creative',
        },
        {
          title:
            INFORMATIVE_ADS_BEING_GENERATED_PUBLISH_AFTER_CREATIVE_GEN_TITLE,
          description:
            INFORMATIVE_ADS_BEING_GENERATED_PUBLISH_AFTER_CREATIVE_GEN_DESCRIPTION,
          completionStatus: 'TODO',
          documentationHref: '',
          todoType: 'no-action',
        },
      ];
      return {
        status: 'internal-action-required',
        title: INFORMATIVE_ADS_BEING_GENERATED_TITLE,
        description: INFORMATIVE_ADS_BEING_GENERATED_DESCRIPTION,
        instructions: INFORMATIVE_ADS_BEING_GENERATED_INSTRUCTIONS,
        todos: todosForCampaignGenerating,
      };
    }
    if (isAnyCreativeErrored) {
      return {
        status: 'customer-action-required',
        title: ONLY_ISSUE_IS_AD_CREATIVE_TITLE,
        description: ONLY_ISSUE_IS_AD_CREATIVE_DESCRIPTION,
        todos: [
          ...integrationTodos,
          {
            title: ACTION_REQUIRED_ADS_HAVE_ERRORS_TITLE,
            description: ACTION_REQUIRED_ADS_HAVE_ERRORS_DESCRIPTION,
            completionStatus: 'TODO',
            documentationHref: '',
            todoType: 'review-creative-errors',
          },
        ],
      };
    }

    let todosForCampaignNotPublished: Array<CampaignHealthTodo> = [
      /**
       * Specifically not including integrationTodos here because the campaign is not published yet.
       * and we do not want to overwhelm the user with errors
       */

      {
        title: ACTION_REQUIRED_PUBLISH_CAMPAIGN_TITLE,
        description: ACTION_REQUIRED_PUBLISH_CAMPAIGN_DESCRIPTION,
        completionStatus: 'TODO',
        documentationHref: ACTION_REQUIRED_PUBLISH_CAMPAIGN_DOCS_HREF,
        todoType: 'publish-campaign',
      },
    ];

    if (campaignPausedByUserState === 'CAMPAIGN_PAUSED_BY_USER') {
      todosForCampaignNotPublished.push({
        title: ACTION_REQUIRED_ENABLE_CAMPAIGN_TITLE,
        description: ACTION_REQUIRED_ENABLE_CAMPAIGN_DESCRIPTION,
        completionStatus: 'TODO',
        documentationHref: ACTION_REQUIRED_ENABLE_CAMPAIGN_DOCS_HREF,
        todoType: 'activate-campaign',
      });
    }

    return {
      status: 'suggestion',
      title: ADS_NOT_PUBLISHED_SUGGESTION_TITLE,
      description: ADS_NOT_PUBLISHED_SUGGESTION_DESCRIPTION,
      instructions: ADS_NOT_PUBLISHED_SUGGESTION_INSTRUCTION,
      todos: todosForCampaignNotPublished,
    };
  }
  if (
    campaignServingState === 'NOT_SERVING' &&
    latestDeploymentStage != null &&
    (latestDeploymentStage === 'SUCCEEDED' ||
      latestDeploymentStage === 'FAILED')
  ) {
    if (campaignPausedByUserState === 'CAMPAIGN_PAUSED_BY_USER') {
      return {
        status: 'customer-action-required',
        title: ADS_NOT_RUNNING_TITLE,
        description: PUBLISHED_BUT_NOT_RUNNING_DESCRIPTION,
        todos: [
          ...integrationTodos,
          {
            title: ACTION_REQUIRED_ENABLE_CAMPAIGN_TITLE,
            description: ACTION_REQUIRED_ENABLE_CAMPAIGN_DESCRIPTION,
            completionStatus: 'TODO',
            documentationHref: ACTION_REQUIRED_ENABLE_CAMPAIGN_DOCS_HREF,
            todoType: 'activate-campaign',
          },
        ],
      };
    } else {
      switch (campaignServingStateReason) {
        case 'CAMPAIGN_DELETED_ON_AD_CHANNEL':
          return {
            status: 'customer-action-required',
            title: ADS_NOT_RUNNING_TITLE,
            description: ACTION_REQUIRED_REPUBLISH_CAMPAIGN,
            todos: [
              {
                title: ACTION_REQUIRED_PUBLISH_CAMPAIGN_TITLE,
                description: ACTION_REQUIRED_PUBLISH_CAMPAIGN_DESCRIPTION,
                documentationHref: ACTION_REQUIRED_PUBLISH_CAMPAIGN_DOCS_HREF,
                todoType: 'publish-campaign',
                completionStatus: 'TODO',
              },
            ],
          };
        case 'FIRST_DEPLOYMENT_IN_PROGRESS':
          return {
            status: 'internal-action-required',
            title: CAMPAIGN_IS_BEING_DEPLOYED_TITLE,
            description: CAMPAIGN_IS_BEING_DEPLOYED_DESCRIPTION,
            todos: [],
          };
        case 'FIRST_DEPLOYMENT_FAILED':
          return {
            status: 'customer-action-required',
            title: ADS_NOT_RUNNING_TITLE,
            description: FIRST_DEPLOYMENT_FAILED_DESCRIPTION,
            todos: [
              {
                title:
                  ACTION_REQUIRED_CONTACT_SUPPORT_FOR_FIRST_DEPLOYMENT_FAILURE_TITLE,
                description:
                  ACTION_REQUIRED_CONTACT_SUPPORT_FOR_FIRST_DEPLOYMENT_FAILURE_DESCRIPTION,
                documentationHref: '',
                todoType: 'contact-support',
                completionStatus: 'TODO',
              },
              ...integrationTodos,
            ],
          };
        case 'AD_CHANNEL_APPROVAL_PENDING':
          if (integrationTodos.length > 0) {
            break;
          } else {
            return {
              status: 'internal-action-required',
              title:
                CAMPAIGN_IS_BEING_REVIEWED_BY_AD_CHANNEL_BEFORE_RUNNING_TITLE,
              description:
                CAMPAIGN_IS_BEING_REVIEWED_BY_AD_CHANNEL_BEFORE_RUNNING_DESCRIPTION,
              todos: [],
            };
          }

        case 'AD_CHANNEL_APPROVAL_REJECTED':
          return {
            status: 'customer-action-required',
            title: AD_CHANNEL_REJECTED_ADS_TITLE,
            description: AD_CHANNEL_REJECTED_ADS_DESCRIPTION,
            todos: [],
          };
        case 'CAMPAIGN_SCHEDULED_TO_START_IN_FUTURE':
        case 'CAMPAIGN_SCHEDULED_TO_END_IN_PAST':
          return {
            status: 'internal-action-required',
            title: ADS_NOT_RUNNING_TITLE,
            description: '',
            todos: [],
          };
        case 'CAMPAIGN_NEVER_DEPLOYED':
        case 'CAMPAIGN_PAUSED_BY_USER':
        case 'SERVING':
        case 'CAMPAIGN_INITIALIZING':
        case 'UNKNOWN':
        case 'SOME_ADS_UNDER_REVIEW':
        case 'SOME_ADS_WERE_REJECTED':
        case 'TOTAL_CAMPAIGN_DRIFT_DETECTED':
        case 'PARTIAL_CAMPAIGN_DRIFT_DETECTED':
        case 'ALL_HIGHLIGHTS_PAUSED':
        case 'SOME_HIGHLIGHTS_PAUSED':
        case 'CAMPAIGN_INITIALIZATION_FAILED':
        case 'CAMPAIGN_ARCHIVED':
        case 'UNDEFINED':
        default:
          console.error(
            'Unhandled campaign health scenario. It will default to healthy but may not be.'
          );
      }
    }
  }

  /**
   * This case is here for when there are not problems with the campaign itself but there are issues with the integration
   * that the campaign uses.
   */
  if (integrationTodos.length > 0) {
    /**
     * Since the campaign is not running our messaging will reflect that.
     */
    if (campaignServingState === 'NOT_SERVING') {
      return {
        status: 'customer-action-required',
        title: ONLY_INTEGRATION_ISSUES_ADS_NOT_RUNNING_TITLE,
        description: ONLY_INTEGRATION_ISSUES_ADS_NOT_RUNNING_DESC,
        todos: integrationTodos,
      };
    }

    /**
     * Serving State is 'SERVING' but there are still some issues with the integration that appear that the user needs to resolve.
     * Use messaging that reflects that the campaign is running but the integration has issues.
     */
    return {
      status: 'customer-action-required',
      title: ONLY_INTEGRATION_ISSUES_ADS_RUNNING_TITLE,
      description: ONLY_INTEGRATION_ISSUES_ADS_RUNNING_DESC,
      todos: integrationTodos,
    };
  }
  /**
   * Default case for Active Ads.
   * We assume if the campaign is in a non-nominal state such logic above would of caught it.
   */
  return {
    status: 'nominal',
    title: NOMINAL_ADS_RUNNING_TITLE,
    description: NOMINAL_ADS_RUNNING_DESCRIPTION,
    todos: [],
  };
};
