import { ObButton } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useEditLeadContactDrawer } from 'src/pages/dashboard/leads/dialog/edit-contact/use-lead-edit-contact-drawer';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { DescriptionItem } from '../../description-item';
import { DescriptionList } from '../../description-list';
import { SideBarSectionHeader } from '../components/side-bar-section-header';

interface ContactSectionProps {
  lead: Lead;
}

export const ContactSection = observer(({ lead }: ContactSectionProps) => {
  const { openDrawerInEditMode } = useEditLeadContactDrawer();
  return (
    <section aria-labelledby='contact-heading'>
      <SideBarSectionHeader
        heading='Contact Details'
        id='contact-heading'
        editButton={
          <ObButton
            variant='ghost'
            label={t.CTA.EDIT_CONTACT_LABEL}
            aria-label={t.CTA.EDIT_CONTACT_ARIA_LABEL}
            onClick={() => {
              openDrawerInEditMode(lead);
            }}
          />
        }
      />
      <DescriptionList>
        <DescriptionItem
          testId='contact-name'
          term='Name'
          detail={lead.firstName + ' ' + lead.lastName}
        />
        <DescriptionItem
          testId='contact-email'
          term='Email'
          detail={lead.email}
        />
        <DescriptionItem
          testId='contact-phone'
          term='Phone'
          detail={lead.phone}
        />
        <DescriptionItem
          testId='zip-code'
          term='Zip Code'
          detail={lead.zipCode}
        />
      </DescriptionList>
    </section>
  );
});
