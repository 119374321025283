import { cx } from 'class-variance-authority';

type Props = React.ComponentPropsWithoutRef<'dt'> & {
  testId: string;
};

export const DescriptionTerm = ({ className, testId, ...props }: Props) => {
  return (
    <dt
      {...props}
      data-testid={`${testId}__term`}
      className={cx(
        className,
        //Pulled from Catalyst. Will come back and update to OB Styles when I have time
        'col-start-1 border-t border-zinc-950/5 pt-3 text-zinc-500 first:border-none sm:border-t text-sm sm:border-zinc-950/5 dark:border-white/5 dark:text-dark/content/tertiary sm:dark:border-white/5'
      )}
    />
  );
};
