import { ClientDelta, PostLeadContactDetailsResource } from '@outbound/types';
import { AxiosInstance } from 'axios';
import ServerMutationHandler from '../../sync-framework/muation-handler/abstract-server-mutation-handler';
import { ObjectDeltaMap } from '../../sync-framework/muation-handler/object-delta-map';
import { OptionalClientDelta } from '../../sync-framework/muation-handler/optional-client-delta';
import Lead from '../domain/lead';

class ContactMutationHandler extends ServerMutationHandler {
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  async handle(objectDeltaMap: ObjectDeltaMap): Promise<void> {
    objectDeltaMap.forEach((deltasForLead, leadId) => {
      const leadUpdate: PostLeadContactDetailsResource = {};

      const firstNameChange = this.findFirstNameChange(deltasForLead);
      const lastNameChange = this.findLastNameChange(deltasForLead);
      const emailChange = this.findEmailChange(deltasForLead);
      const phoneChange = this.findPhoneChange(deltasForLead);
      const zipCodeChange = this.findZipCodeChange(deltasForLead);

      if (this.attributeHasBeenUpdated(firstNameChange)) {
        leadUpdate.firstName = firstNameChange.value;
      }

      if (this.attributeHasBeenUpdated(lastNameChange)) {
        leadUpdate.lastName = lastNameChange.value;
      }

      if (this.attributeHasBeenUpdated(emailChange)) {
        leadUpdate.email = emailChange.value;
      }

      if (this.attributeHasBeenUpdated(phoneChange)) {
        leadUpdate.phone = phoneChange.value;
      }

      if (this.attributeHasBeenUpdated(zipCodeChange)) {
        leadUpdate.zipCode = zipCodeChange.value;
      }

      if (this.theLeadHasBeenUpdated(leadUpdate)) {
        this.notifyServerOfContactDetailsChange({
          leadId: leadId,
          contactDetails: leadUpdate,
        });
      }
    });
  }

  private theLeadHasBeenUpdated(leadUpdate: PostLeadContactDetailsResource) {
    return Object.keys(leadUpdate).length > 0;
  }

  private findFirstNameChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.contactFirstName, deltas);
  }

  private findLastNameChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.contactLastName, deltas);
  }
  private findEmailChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.contactEmail, deltas);
  }

  private findPhoneChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.contactPhone, deltas);
  }
  private findZipCodeChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.contactZipCode, deltas);
  }

  private async notifyServerOfContactDetailsChange({
    leadId,
    contactDetails,
  }: {
    leadId: string;
    contactDetails: PostLeadContactDetailsResource;
  }): Promise<void> {
    const updateContactDetailsRequestBody = {
      firstName: contactDetails.firstName,
      lastName: contactDetails.lastName,
      email: contactDetails.email,
      phone: contactDetails.phone,
      zipCode: contactDetails.zipCode,
    };
    try {
      await this.axiosInstance.post(
        `/leads/${leadId}/update-contact-details`,
        updateContactDetailsRequestBody
      );
    } catch (error) {
      throw error;
    }
  }
}

export default ContactMutationHandler;
