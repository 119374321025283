import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { LeadCardImplementationProps } from '../lead-card/lead-card.type';
import { LeadQualificationStatusInput } from '../lead-qualification-stauts-input';
import { LeadRatingInput } from '../lead-rating-input';
import { formatContactNameForDisplay } from '../utils/format-lead-contact-name-for-display';
import { formatLeadSourceForDisplay } from '../utils/format-lead-source-for-display';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Props
  extends Omit<LeadCardImplementationProps, 'onNavigateCallback'> {
  //Future Use
}

export const LeadDetail = ({
  firstName,
  lastName,
  qualificationStatus,
  sourceType,
  nestedServiceMicroCard,
  rating,
  onUpdateRatingCallback,
  onUpdateQualificationStatusCallback,
  localization,
}: Props) => {
  const { formattedLeadSource } = formatLeadSourceForDisplay(
    sourceType,
    localization
  );

  const { formattedName, nameProvided } = formatContactNameForDisplay(
    firstName,
    lastName,
    localization.noFirstNameOrLastNameProvidedMessage
  );
  return (
    <div className='flex flex-col gap-2 self-stretch w-full'>
      <div className='flex flex-col '>
        <ObTypography
          variant='body2'
          color='tertiary'
        >
          {formattedLeadSource}
        </ObTypography>
        <div className='flex gap-2 items-center'>
          <div className='mt-[4px]'>
            <LeadQualificationStatusInput
              onQualificationStatusSelected={
                onUpdateQualificationStatusCallback
              }
              selectedQualificationStatus={qualificationStatus}
              buttonStyle={'icon'}
              buttonIconSize='medium'
              localization={localization}
            />
          </div>
          <ObTypography
            variant='h1'
            color={nameProvided ? 'primary' : 'secondary'}
          >
            {formattedName}
          </ObTypography>
        </div>
      </div>
      <div className='flex max-w-full overflow-hidden'>
        {nestedServiceMicroCard ?? <>{nestedServiceMicroCard}</>}
      </div>
      <div className='flex flex-row flex-wrap gap-2'>
        <LeadQualificationStatusInput
          onQualificationStatusSelected={onUpdateQualificationStatusCallback}
          selectedQualificationStatus={qualificationStatus}
          buttonStyle={'text'}
          localization={localization}
        />
        <LeadRatingInput
          selectedRating={rating}
          localization={localization}
          onRatingSelected={onUpdateRatingCallback}
        />
      </div>
    </div>
  );
};
