import { toObrn } from '@otbnd/utils';
import { LandingPageResource } from '@outbound/types';
import { action } from 'mobx';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import LandingPage, { LandingPageConstructorData } from './landing-page';
import LandingPageTransformer from './landing-page-transformer';
import LandingPageTransport from './landing-page-transport';
import LandingPageSelfHosted from './self-hosted/landing-page-self-hosted';

class LandingPageStore extends BaseStore<LandingPage, LandingPageResource> {
  constructor(rootStore: RootStore, resourceTransport: LandingPageTransport) {
    super(
      rootStore,
      'landing-page',
      resourceTransport,
      new LandingPageTransformer(rootStore)
    );
  }
  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this._baseTransport!.requestResourceById(id);
  }

  /**
   * Optimistically delete the Landing Page from the store and send the request to the server.
   * @param id
   */
  @action
  public async delete(id: string) {
    const model = this.modelMap.get(id);
    if (model) {
      this.optimisticDeleteModel(id);
      try {
        await this.rootStore.transport.landingPageTransport.delete(id);
      } catch (error) {
        console.error('Error Deleting Landing Page', error);
        this.undoOptimisticDeleteModel(id);
        //Snackbar or Toast Error
      }
    }
  }

  @action
  public createSelfHosted(
    url: string,
    workspaceId: string
  ): LandingPageSelfHosted {
    const baseModelValue: LandingPageConstructorData = {
      type: 'SELF_HOSTED',
      lifecycleStatus: 'INITIALIZING',
      url,
      pageTitle: url,
      thumbnailGenerationStatus: 'WAITING_FOR_THUMBNAIL_IMAGE_GENERATION',
      thumbnailUrlPublicUrl: null,
      httpsEnabled: false,
    };

    const optimisticId = crypto.randomUUID();
    const optimisticModel: LandingPage = new LandingPageSelfHosted(
      this.rootStore,
      optimisticId,
      toObrn({
        workspaceId,
        objectType: 'landing-page',
        localPathId: optimisticId,
      }),
      baseModelValue
    );

    optimisticModel.makeObservable();
    this.modelMap.set(optimisticId, optimisticModel);
    this.createOptimisticSyncMetaForNewModel(optimisticId, optimisticModel);
    this.rootStore.transport.landingPageTransport.createSelfHosted({
      id: optimisticId,
      type: 'SELF_HOSTED',
      url,
    });

    return optimisticModel;
  }
}
export default LandingPageStore;
