import { WorkspaceMemberResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import Membership from './membership';

class MembershipStore extends BaseStore<Membership, WorkspaceMemberResource> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'workspace/membership');
  }
  protected async requestLoadModelFromServer(_id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}

export default MembershipStore;
