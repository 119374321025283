interface FormatContactNameResult {
  formattedName: string;
  nameProvided: boolean;
}

export const formatContactNameForDisplay = (
  firstName: string,
  lastName: string,
  noNameProvidedPlaceholderText: string
): FormatContactNameResult => {
  const trimmedFirstName = firstName?.trim() ?? '';
  const trimmedLastName = lastName?.trim() ?? '';
  if (firstAndLastNameAreBothMissing(trimmedFirstName, trimmedLastName)) {
    return {
      formattedName: noNameProvidedPlaceholderText,
      nameProvided: false,
    };
  }
  return {
    formattedName: formatNameForDisplay(trimmedFirstName, trimmedLastName),
    nameProvided: true,
  };
};

const concatenateFirstAndLastNames = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};

const cleanupStringIfFirstNameOrLastNameIsMissing = (fullName: string) => {
  return fullName.trim();
};

const firstAndLastNameAreBothMissing = (
  firstName: string,
  lastName: string
) => {
  return !firstName && !lastName;
};

const formatNameForDisplay = (
  trimmedFirstName: string,
  trimmedLastName: string
) => {
  const fullName = concatenateFirstAndLastNames(
    trimmedFirstName,
    trimmedLastName
  );
  return cleanupStringIfFirstNameOrLastNameIsMissing(fullName);
};
