import { parseObrn } from '@otbnd/utils';
import {
  IntegrationConfigHealthCheckStatus,
  ServerDelta,
} from '@outbound/types';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../../base-model';
import { RootStore } from '../../../root-store';
import { HealthCheckItemExecutionValue } from './health-check-item-execution-value.type';

class HealthCheckItemEvaluation extends BaseModel {
  static readonly paths = {
    ...BaseModel.paths,
    name: '/name',
    outcomeCode: '/outcomeCode',
    message: '/message',
    status: '/status',
    skipped: '/skipped',
    ranAtTimestamp: '/ranAtTimestamp',
    healthCheckItemId: '/healthCheckItemId',
  };

  private _name: string;
  private _healthCheckItemId: string;
  /**
   * Foreign key to the outcome code.
   * (Outcomes are pre-defined by the health check item)
   */
  private _outcomeCode?: number;
  private _message?: string;
  private _status?: IntegrationConfigHealthCheckStatus;
  private _isSkipped: boolean;
  private _ranAtTimestamp?: string;

  constructor(
    rootStore: RootStore,
    id: string,
    obrn: string,
    data: HealthCheckItemExecutionValue
  ) {
    super(
      rootStore,
      'integration-configuration/health-check-item-execution',
      '1',
      id,
      parseObrn(obrn).scope, //Depreciated to be removed in future in favor of parsing from obrn internal to the base model
      obrn
    );

    this._healthCheckItemId = data.healthCheckItemId;
    this._name = data.name;
    this._outcomeCode = data.outcomeCode;
    this._message = data.message;
    this._status = data.status;
    this._isSkipped = data.skipped;
    this._ranAtTimestamp = data.lastRanAtTimestamp;
  }

  toJson(): Record<string, any> {
    return {
      ...super.toJson(),
      [HealthCheckItemEvaluation.paths.name]: this._name,
      [HealthCheckItemEvaluation.paths.outcomeCode]: this._outcomeCode,
      [HealthCheckItemEvaluation.paths.message]: this._message,
      [HealthCheckItemEvaluation.paths.status]: this._status,
      [HealthCheckItemEvaluation.paths.skipped]: this._isSkipped,
      [HealthCheckItemEvaluation.paths.ranAtTimestamp]: this._ranAtTimestamp,
      [HealthCheckItemEvaluation.paths.healthCheckItemId]:
        this._healthCheckItemId,
    };
  }

  applyPatch(patch: ServerDelta[]): void {
    runInAction(() => {
      for (const delta of patch) {
        switch (delta.path) {
          case HealthCheckItemEvaluation.paths.name:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._name = delta.value;
              case 'remove':
                console.warn('Cannot remove health check item name');
                break;
            }
            break;
          case HealthCheckItemEvaluation.paths.outcomeCode:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._outcomeCode = delta.value;
              case 'remove':
                console.warn('Cannot remove health check item outcome code');
                break;
            }
            break;
          case HealthCheckItemEvaluation.paths.message:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._message = delta.value;
              case 'remove':
                console.warn('Cannot remove health check item message');
                break;
            }
            break;
          case HealthCheckItemEvaluation.paths.status:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._status = delta.value;
              case 'remove':
                console.warn('Cannot remove health check item status');
                break;
            }
            break;
          case HealthCheckItemEvaluation.paths.skipped:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._isSkipped = delta.value;
              case 'remove':
                console.warn('Cannot remove health check item skipped');
                break;
            }
            break;
          case HealthCheckItemEvaluation.paths.ranAtTimestamp:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._ranAtTimestamp = delta.value;
              case 'remove':
                console.warn(
                  'Cannot remove health check item ran at timestamp'
                );
                break;
            }
            break;
          case HealthCheckItemEvaluation.paths.healthCheckItemId:
            switch (delta.op) {
              case 'replace':
              case 'add':
                this._healthCheckItemId = delta.value;
              case 'remove':
                console.warn('Cannot remove health check item ran id');
                break;
            }
            break;
        }
      }
    });
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _name: observable,
      _outcomeCode: observable,
      _message: observable,
      _status: observable,
      _isSkipped: observable,
      _healthCheckItemId: observable,
    } as any);
  }

  get name(): string {
    return this._name;
  }

  get message(): string | undefined {
    return this._message;
  }

  /**
   * Returns the health check that was executed.
   */
  get healthCheck(): { id: string; name: string } {
    return {
      id: this.id,
      name: this.name,
    };
  }

  /**
   * Returns the outcome of the health check item execution.
   * null means that the health check was skipped.
   */
  get outcome(): {
    code: number;
    message: string | null;
    status: IntegrationConfigHealthCheckStatus | null;
  } | null {
    if (this._outcomeCode == null) {
      return null;
    } else {
      return {
        code: this._outcomeCode,
        message: this._message ?? null,
        status: this._status ?? null,
      };
    }
  }

  get status(): IntegrationConfigHealthCheckStatus | null {
    return this._status ?? null;
  }

  get isSkipped(): boolean {
    return this._isSkipped;
  }

  get ranAtTimestamp(): string | null {
    return this._ranAtTimestamp ?? null;
  }

  get healthCheckItemId(): string {
    return this._healthCheckItemId;
  }
}

export default HealthCheckItemEvaluation;
