import {
  ObAiGeneratingLoadingIndicator,
  ObTypography,
} from '@outbound/design-system';
import { AnimatePresence, motion } from 'motion/react';
import { useEffect, useState } from 'react';

export interface GoalSelectorProps {
  domain: string;
}

const messages: Array<string> = [
  'Reading Website',
  'Identifying Brand',
  'Analyzing Media',
];

export const GoalSelector = ({ domain }: GoalSelectorProps) => {
  const [setupMessage, setSetupMessage] = useState<string>(
    `Navigating to ${domain}`
  );

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      setSetupMessage(messages[index]);
      index += 1;

      // Clear interval when all messages are shown
      if (index >= messages.length) {
        clearInterval(intervalId);
      }
    }, 4000); // 5 seconds delay between each message

    // Cleanup function to clear interval if the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <main
      data-testid='goal-selector'
      className='flex flex-col justify-center items-center flex-grow w-full gap-8 px-8'
    >
      <motion.div
        className='flex flex-col gap-1 justify-center items-center'
        initial='hidden'
        animate='visible'
        variants={{
          visible: {
            opacity: 1,
            transition: {
              delay: 0.5,
              staggerChildren: 0.4, // Restores staggered effect
              ease: 'easeIn',
            },
          },
          hidden: { opacity: 0 },
        }}
      >
        <motion.div
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 10 },
          }}
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ duration: 0.3, delay: 0.5 }}
        >
          <ObTypography
            variant='h3'
            color='primary'
          >
            Analyzing Your Business
          </ObTypography>
        </motion.div>
        {/* Spinner with Fade and Scale */}
        <motion.div
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0.8 },
          }}
          transition={{
            delay: 0.5,
            duration: 0.5, // Animation duration
            ease: 'easeOut',
          }}
        >
          <ObAiGeneratingLoadingIndicator
            variant='spinner'
            size={240}
          />
        </motion.div>

        {/* Text Animation with AnimatePresence */}
        <AnimatePresence mode='wait'>
          <motion.div
            key={setupMessage} // Ensures re-render for new message
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 10 },
            }}
            initial='hidden'
            animate='visible'
            exit='hidden'
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            <ObTypography
              variant='body1'
              color='secondary'
            >
              {setupMessage}
            </ObTypography>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </main>
  );
};
