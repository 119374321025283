import { cx } from 'class-variance-authority';
import { ObDrawerHeader } from '../../molecules/ob-drawer-header/ob-drawer-header';

export interface ObDrawerProps {
  title: string;
  position?: 'full-height' | 'under-header';
  description: string;
  children?: React.ReactNode;
  onCloseCallback?: () => Promise<void>;
  onBackCallback?: () => Promise<void>;
}

export const ObDrawer: React.FC<ObDrawerProps> = ({
  children,
  title,
  description,
  position = 'full-height',
  onBackCallback,
}: ObDrawerProps) => {
  return (
    <div
      className={cx(
        'flex flex-col flex-grow dark:bg-bgSurfaceDark border border-borderDefaultNormalDark box-border ',
        position === 'full-height'
          ? 'min-h-screen h-[100dvh]'
          : 'min-h-[calc(100vhd-63px)] h-[calc(100vhd-63px)] mt-[63px]'
      )}
    >
      <ObDrawerHeader
        title={title}
        description={description}
        onBackCallback={onBackCallback}
      />
      {children}
    </div>
  );
};
