import { isoStringToDateAndTime } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { DescriptionItem } from '../../description-item';
import { DescriptionList } from '../../description-list';
import { SideBarSectionHeader } from '../components/side-bar-section-header';

interface MetadataSectionProps {
  lead: Lead;
}

export const MetadataSection = observer(({ lead }: MetadataSectionProps) => {
  return (
    <section aria-labelledby='metadata'>
      <SideBarSectionHeader
        heading='Metadata'
        id='metadata'
      />
      <DescriptionList>
        <DescriptionItem
          testId='created-at'
          term='Created At'
          detail={isoStringToDateAndTime(lead.createdAtTimestamp)}
        />
        <DescriptionItem
          testId='last-updated-at'
          term='Last Updated At'
          detail={isoStringToDateAndTime(lead.lastModifiedAtTimestamp)}
        />
      </DescriptionList>
    </section>
  );
});
