import { WorkspaceResource } from '@outbound/types';
import { BaseStore } from '../base-store';
import { RootStore } from '../root-store';
import Workspace from './workspace';
import WorkspaceTransformer from './workspace-transformer';
import WorkspaceTransporter from './workspace-transporter';

class WorkspaceStore extends BaseStore<Workspace, WorkspaceResource> {
  constructor(rootStore: RootStore, resourceTransport: WorkspaceTransporter) {
    super(
      rootStore,
      'workspace',
      resourceTransport,
      new WorkspaceTransformer(rootStore)
    );
  }

  protected async requestLoadModelFromServer(id: string): Promise<void> {
    this.rootStore.transport.workspaceTransport.requestResourceById(id);
  }
}

export default WorkspaceStore;
