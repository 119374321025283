import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';

import { LeadCardProps } from './lead-card.type';
import { LeadGridCard } from './variants/lead-grid-card';
import { LeadTableCard } from './variants/lead-table-card';

export const LeadCard = ({ variant, ...props }: LeadCardProps) => {
  switch (variant) {
    case 'grid':
      return <LeadGridCard {...props} />;
    case 'table':
      return <LeadTableCard {...props} />;
    case 'mini':
    case 'micro':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};
