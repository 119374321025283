import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';

export interface ObCardSurfaceProps {
  isDisabled?: boolean;
  isClickable?: boolean;
  onClickedCallback?: () => any;
  ariaLabel?: string;
  showBorder?: boolean;
  showHoverOverlay?: boolean;
  children?: ReactNode;
}

/**
 * Basic styles for a card surface
 */
export const ObCardSurface = ({
  children,
  isDisabled = false,
  isClickable = false,
  onClickedCallback,
  ariaLabel,
  showHoverOverlay = true,
  showBorder = true,
}: ObCardSurfaceProps) => {
  return (
    <div
      //Conditionally add properties based on isButtonProps
      {...(onClickedCallback && {
        tabIndex: 0,
        role: 'button',
        'aria-label': ariaLabel,
        onClick: (e) => {
          e.stopPropagation();
          onClickedCallback();
        },
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            onClickedCallback();
          }
        },
      })}
      className={cx(
        'rounded-lg group flex-1',
        showBorder ? ' border' : 'border border-hidden',
        isClickable &&
          showHoverOverlay &&
          'dark:hover:bg-dark/background/surface+1',
        isClickable &&
          'hover:border dark:hover:border-dark/action/primary-v2/normal  transition-all focus-visible:shadow-interactive focus:outline-none',
        !isDisabled &&
          'dark:border-dark/border/default/normal bg-dark/background/surface',
        isDisabled && 'dark:border-dark/border/default/normal ',
        onClickedCallback &&
          'hover:cursor-pointer focus-visible:shadow-interactive focus:outline-none'
      )}
    >
      {children}
    </div>
  );
};
