import { ObCardSurface } from '../../../../cards/components/card-surface/ob-card-surface';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { LeadQualificationStatusIcon } from '../../lead-qualification-status-icon';
import { formatContactNameForDisplay } from '../../utils/format-lead-contact-name-for-display';
import { formatLeadSourceForDisplay } from '../../utils/format-lead-source-for-display';
import { LeadCardImplementationProps } from '../lead-card.type';

export const LeadTableCard = ({
  qualificationStatus,
  firstName,
  lastName,
  sourceType,
  localization,
}: LeadCardImplementationProps) => {
  const { formattedName } = formatContactNameForDisplay(
    firstName,
    lastName,
    localization.noFirstNameOrLastNameProvidedMessage
  );
  const { formattedLeadSource } = formatLeadSourceForDisplay(
    sourceType,
    localization
  );
  return (
    <ObCardSurface>
      <div className='grid grid-cols-2 gap-3 p-3'>
        <div className='flex items-center gap-3'>
          <div className='mt-[3px]'>
            <LeadQualificationStatusIcon
              qualificationStatus={qualificationStatus}
              size='small'
            />
          </div>
          <ObTypography variant='h5'>{formattedName}</ObTypography>
        </div>

        <ObTypography variant='body1'>{formattedLeadSource}</ObTypography>
      </div>
    </ObCardSurface>
  );
};
