import {
  FormDefinition,
  FormFieldType,
  FullPageActionHeader,
  ObBrand,
  ObFormRenderer,
  ObThinkingButton,
  ObTypography,
  useObFormRendererContext,
} from '@outbound/design-system';
import { PatchCompleteProfileOnLoginRequestResource } from '@outbound/types';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OUTBOUND_API_BASE_URL } from 'src/environment';
import { LinkTermsOfService } from '../../components/elements/link-customer-terms-of-service';
import { LinkPrivacyPolicy } from '../../components/elements/link-privacy-policy';
import LegalAgreementCheckbox from '../../components/organisms/legal-agreement-checkbox/legal-agreement-checkbox';
import { getJwtTokenPayload } from '../../utilties/jwt-utilities';
import { redirectToExternalUrl } from './browser';

/**
 * Interface of the token generated by auth0 during the post-signin action.
 * To update what is included in this token you must do so via Auth0's dashboard.
 */
interface CompleteProfileSessionToken {
  iss: string;
  sub: string;
  exp: number;
  ip: string;
  email: string;
  externalUserId: string; //Outbound's internal user id
  continueUri: string; //The URL we will redirect back to at the end of the process
  givenName?: string;
  familyName?: string;
  continueToOrg?: string;
  continueToWorkspace?: string;
}

/**
 * Utility function to construct the redirect URL for Auth0; If we end up doing this multiple times it would make sense to move
 * this into a utility class
 * @param baseUri
 * @param state
 * @returns
 */
export const buildRedirectUrl = (baseUri: string, state: string) => {
  const url = new URL(baseUri);
  url.searchParams.append('state', state);
  return url.toString();
};

export const CompleteProfilePage = () => {
  const [searchParams] = useSearchParams();
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false);
  const [sessionToken, setSessionToken] =
    useState<CompleteProfileSessionToken | null>(null);

  const { getRegisteredFormById } = useObFormRendererContext();
  /**
   * Generated by Auth0 Redirect Action.
   * This token is expected to contain information about the user
   * including their auth0 user id and their email address.
   */
  const rawSessionToken = searchParams.get('session_token');

  /**
   * Generated by Auth0 in order to resume an authentication flow
   * after we redirect back to the continueUri provided in the session token
   * If this is not present we should not continue as the user will not be
   * able to resume their authentication and it means someone tampered with
   * the URL.
   */
  const opaqueStateToken = searchParams.get('state');

  /**
   * Attempt to parse the session token and use it to configure the page
   * If for some reason the token is invalid on not present we cannot continue
   * with the profile completion process.
   */
  useEffect(() => {
    //We only want to run this logic once and never again
    if (sessionToken != null) {
      return;
    }
    if (rawSessionToken == null || opaqueStateToken == null) {
      setIsTokenInvalid(true);
    } else {
      try {
        const token =
          getJwtTokenPayload<CompleteProfileSessionToken>(rawSessionToken);
        setSessionToken(token);
        console.log('Session Token', token);
      } catch (error) {
        console.log('Raw Session Token', error);
        setIsTokenInvalid(true);
      }
    }
  }, [opaqueStateToken, rawSessionToken, sessionToken]);

  const submitUpdateProfileRequest = async (
    values: PatchCompleteProfileOnLoginRequestResource
  ) => {
    setSubmitting(true);
    if (!isChecked) {
      setErrorMessage(
        `You must agree to the Terms Of Service and Privacy Policy in order to use Outbound.com's services.`
      );
      setSubmitting(false);
      throw new Error(
        `You must agree to the Terms Of Service and Privacy Policy in order to use Outbound.com's services.`
      );
    }
    const response = await axios.patch(
      `${OUTBOUND_API_BASE_URL}/auth-0-actions/complete-profile-on-login`,
      {
        ...values,
        agreedToPrivacyPolicy: isChecked,
        agreedToTermsOfService: isChecked,
      },
      {
        headers: {
          Authorization: `Bearer ${rawSessionToken}`,
        },
      }
    );
    /** allow time for the success animation to complete */
    setTimeout(() => {
      redirectToContinueAuthenticationFlow();
    }, 500);
    return response.data;
  };

  const redirectToContinueAuthenticationFlow = () => {
    let url = buildRedirectUrl(sessionToken!.continueUri, opaqueStateToken!);
    if (sessionToken?.continueToWorkspace) {
      url += `&login-to-workspace=${sessionToken.continueToWorkspace}`;
    }
    redirectToExternalUrl(url);
  };

  const heading = 'Tell us about you';
  const subHeading = 'We will use this information to set up your user profile';

  const completeProfileForm: FormDefinition = {
    id: '1',
    heading: '',
    subHeading: '',
    sections: [
      {
        id: '1',
        heading: '',
        subHeading: '',
        fields: [
          {
            id: 'givenName',
            label: 'First Name',
            type: FormFieldType.TEXT,
            helperText: '',
            autofocus: true,
            disablePasswordManagers: true,
            validationSchema: {
              isRequired: true,
            },
            fieldTypeSettings: {},
          },
          {
            id: 'familyName',
            label: 'Last Name',
            type: FormFieldType.TEXT,
            helperText: '',
            disablePasswordManagers: true,
            validationSchema: {
              isRequired: true,
            },
            fieldTypeSettings: {},
          },
        ],
      },
    ],
  };

  /**
   * Tracks if the checkbox is checked or not
   */
  const [isChecked, setIsChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    setErrorMessage('');
  };

  if (sessionToken == null) {
    //TODO Complete this
    return <></>;
  }
  if (isTokenInvalid) {
    //TODO Complete this
    return <></>;
  } else {
    return (
      <div className='flex flex-col h-screen justify-between dark:bg-black'>
        <header className='flex justify-center items-center pt-8'>
          <ObBrand
            variant='icon'
            size='medium'
          />
        </header>

        <main className='flex flex-col justify-center items-center flex-grow w-full'>
          <FullPageActionHeader
            heading={heading}
            subHeading={subHeading}
          />
          <div className='min-w-[400px]'>
            <ObFormRenderer
              formDefinition={completeProfileForm}
              defaultValues={{
                givenName: sessionToken?.givenName ?? '',
                familyName: sessionToken?.familyName ?? '',
              }}
              onSubmitCallback={submitUpdateProfileRequest}
              hideSubmitButton={true}
            />
            <LegalAgreementCheckbox
              checked={isChecked}
              onChange={toggleCheckbox}
              label={'I agree'}
              disabled={submitting}
              errorMessage={''}
              labelNode={
                <ObTypography
                  variant='body2'
                  color='secondary'
                >
                  By checking this box, I agree to abide by the{' '}
                  <LinkTermsOfService /> and consent to the{' '}
                  <LinkPrivacyPolicy />.
                </ObTypography>
              }
            />
            <div className='flex gap-4 items-center justify-end pt-4'>
              <ObThinkingButton
                variant='primary'
                size='large'
                label='Continue'
                onClickCallback={async () => {
                  return getRegisteredFormById(
                    completeProfileForm.id
                  )?.submitFunction();
                }}
                type='submit'
              />
            </div>
            <div className='min-h-[36px]'>
              {errorMessage && (
                <ObTypography
                  color='negative'
                  variant='body2'
                >
                  {errorMessage}
                </ObTypography>
              )}
            </div>
          </div>
        </main>
      </div>
    );
  }
};
