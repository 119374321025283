import {
  CollisionDetection,
  DndContextProps,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  MeasuringStrategy,
  UniqueIdentifier,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
} from '@dnd-kit/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDragInteractionSensors } from './drag-interaction-sensors';
import {
  determinePlacementOfDroppedDraggableItemAfterSorting,
  itemWasSorted,
} from './on-drop-utils/determine-sort-order';

export type GroupedDragItemUniqueIdentifierMap = Record<
  UniqueIdentifier,
  UniqueIdentifier[]
>;

export interface PositionAfterSort {
  directlyUnderUniqueIdentifier: 'TOP_OF_LIST' | UniqueIdentifier;
  directlyAboveUniqueIdentifier: 'BOTTOM_OF_LIST' | UniqueIdentifier;
}

export interface SortDetails {
  uniqueIdOfSortedItem: UniqueIdentifier;
  positionAfterSort: PositionAfterSort;
}

interface UseSortableDragAndDropGroupsProps {
  runDomainEventsInResponseToDragCancel?: (event: DragCancelEvent) => void;
  runDomainEventsInResponseToSort?: (sortDetails: SortDetails) => void;
  runDomainEventsInResponseToDragEnd?: (event: DragEndEvent) => void;
  shouldDraggedItemBeMovedToGroup: (
    draggedItemId: UniqueIdentifier,
    draggedOverGroupId: UniqueIdentifier
  ) => boolean;
  runDomainEventsInResponseToDragToNewGroup: (
    draggedItemId: UniqueIdentifier,
    draggedOverGroupId: UniqueIdentifier
  ) => void;
}

export const useSortableDragAndDropGroups = ({
  runDomainEventsInResponseToDragCancel: onDragCancelCallback,
  runDomainEventsInResponseToSort,
  runDomainEventsInResponseToDragEnd,
  shouldDraggedItemBeMovedToGroup,
  runDomainEventsInResponseToDragToNewGroup,
}: UseSortableDragAndDropGroupsProps) => {
  const dragDetectionSensors = useDragInteractionSensors();

  const [activeDraggingItemId, setActiveDraggingItemId] =
    useState<UniqueIdentifier | null>(null);

  const [dragItemIdsGroupedByGroupingId, setDragItemIdsGroupedByGroupingId] =
    useState<GroupedDragItemUniqueIdentifierMap>(
      {} as GroupedDragItemUniqueIdentifierMap
    );

  // Wrap the setter in useCallback to prevent unnecessary re-renders
  const updateDragItemIdsGroupedByGroupingId = useCallback(
    (newValue: GroupedDragItemUniqueIdentifierMap) => {
      setDragItemIdsGroupedByGroupingId((prev) =>
        JSON.stringify(prev) === JSON.stringify(newValue) ? prev : newValue
      );
    },
    []
  );

  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [dragItemIdsGroupedByGroupingId]);

  const onDragStart = (event: DragStartEvent): void => {
    setActiveDraggingItemId(event.active.id);
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const containerOrLeadCurrentlyDraggedOver = over?.id;
    const idOfLeadCurrentlyBeingDragged = active.id;

    if (containerOrLeadCurrentlyDraggedOver == null) {
      return;
    }

    const draggedOverContainerForStatus = findGroupItemIsBeingDraggedOver(
      containerOrLeadCurrentlyDraggedOver
    );

    if (draggedOverContainerForStatus != null) {
      if (
        shouldDraggedItemBeMovedToGroup(
          idOfLeadCurrentlyBeingDragged,
          draggedOverContainerForStatus
        )
      ) {
        runDomainEventsInResponseToDragToNewGroup(
          idOfLeadCurrentlyBeingDragged,
          draggedOverContainerForStatus
        );
        recentlyMovedToNewContainer.current = true;
      }
    } else {
      return;
    }
  };

  const onDragCancel = (event: DragCancelEvent) => {
    onDragCancelCallback?.(event);
    setActiveDraggingItemId(null);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const itemDroppedWhenDragEnded = active;
    const dropContainerItemWasDroppedOn = over;

    if (
      itemWasSorted(dropContainerItemWasDroppedOn, itemDroppedWhenDragEnded)
    ) {
      const itemPositionAfterSort =
        determinePlacementOfDroppedDraggableItemAfterSorting(
          itemDroppedWhenDragEnded,
          dropContainerItemWasDroppedOn!
        );
      runDomainEventsInResponseToSort?.({
        uniqueIdOfSortedItem: itemDroppedWhenDragEnded.id,
        positionAfterSort: itemPositionAfterSort,
      });
    }

    runDomainEventsInResponseToDragEnd?.(event);
    setActiveDraggingItemId(null);
  };

  const configureMeasurementStrategyToConstantMeasurement = () => {
    return {
      droppable: {
        strategy: MeasuringStrategy.Always,
      },
    };
  };

  /**
   * REFACTOR INTO CUSTOM DRAG AND DROP GROUPS COLLISION DETECTION STRATEGY HOOK
   *
   *
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (
        activeDraggingItemId &&
        activeDraggingItemId in dragItemIdsGroupedByGroupingId
      ) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in dragItemIdsGroupedByGroupingId
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in dragItemIdsGroupedByGroupingId) {
          const itemsInGroup = dragItemIdsGroupedByGroupingId[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (itemsInGroup.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId && itemsInGroup.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeDraggingItemId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [
      activeDraggingItemId,
      lastOverId,
      dragItemIdsGroupedByGroupingId,
      recentlyMovedToNewContainer,
    ]
  );

  const idBelongsToAGroup = useCallback(
    (id: UniqueIdentifier) => {
      return id in dragItemIdsGroupedByGroupingId;
    },
    [dragItemIdsGroupedByGroupingId]
  );

  const getGroupIdOfTheGroupThatContainsTheItemWithTheGivenId = useCallback(
    (id: UniqueIdentifier) => {
      return Object.keys(dragItemIdsGroupedByGroupingId).find((key) =>
        dragItemIdsGroupedByGroupingId[key].includes(id)
      );
    },
    [dragItemIdsGroupedByGroupingId]
  );

  const findGroupItemIsBeingDraggedOver = useCallback(
    (
      idOfContainerOrIdOfItemInsideOfContainerThatDraggedItemIsCurrentlyOver: UniqueIdentifier
    ) => {
      if (
        idBelongsToAGroup(
          idOfContainerOrIdOfItemInsideOfContainerThatDraggedItemIsCurrentlyOver
        )
      ) {
        const groupIdOfContainer =
          idOfContainerOrIdOfItemInsideOfContainerThatDraggedItemIsCurrentlyOver;
        return groupIdOfContainer;
      } else {
        const itemIdOfItemInsideOfGroup =
          idOfContainerOrIdOfItemInsideOfContainerThatDraggedItemIsCurrentlyOver;
        return getGroupIdOfTheGroupThatContainsTheItemWithTheGivenId(
          itemIdOfItemInsideOfGroup
        );
      }
    },
    [getGroupIdOfTheGroupThatContainsTheItemWithTheGivenId, idBelongsToAGroup]
  );

  const getIndexOfItemWithinItsCurrentGroup = useCallback(
    (idOfItemInsideSomeGroup: UniqueIdentifier) => {
      const groupIdOfGroupItemIsBeingDraggedOver =
        findGroupItemIsBeingDraggedOver(idOfItemInsideSomeGroup);

      if (!groupIdOfGroupItemIsBeingDraggedOver) {
        return -1;
      }

      const index = dragItemIdsGroupedByGroupingId[
        groupIdOfGroupItemIsBeingDraggedOver
      ].indexOf(idOfItemInsideSomeGroup);

      return index;
    },
    [dragItemIdsGroupedByGroupingId, findGroupItemIsBeingDraggedOver]
  );

  const dndKitContextProps: Pick<
    DndContextProps,
    | 'onDragStart'
    | 'onDragOver'
    | 'onDragCancel'
    | 'onDragEnd'
    | 'sensors'
    | 'measuring'
    | 'collisionDetection'
  > = {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDragCancel,
    measuring: configureMeasurementStrategyToConstantMeasurement(),
    sensors: dragDetectionSensors,
    collisionDetection: collisionDetectionStrategy,
  };

  return {
    dndKitContextProps,
    activeDraggingItemId,
    lastOverId,
    recentlyMovedToNewContainer,
    dragItemIdsGroupedByGroupingId,
    getIndexOfItemWithinItsCurrentGroup,
    findGroupItemIsBeingDraggedOver,
    setDragItemIdsGroupedByGroupingId: updateDragItemIdsGroupedByGroupingId,
  };
};
