import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { ObTypography } from '../ob-typography/ob-typography';

export interface ObInputLabelProps {
  /**
   * Decorates the label with an * to indicate that the field is required
   */
  isRequired: boolean;
  label: string;
  instructionText?: string;
  id: string;
  size?: 'small' | 'medium' | 'large';
  /**
   * Hides the label from view but keeps it in the dom for accessibility
   */
  visuallyHidden?: boolean;
  className?: string;
  setIdOnLabel?: boolean;
}

export const ObInputLabel: FC<ObInputLabelProps> = ({
  isRequired,
  label,
  id,
  visuallyHidden = false,
  size = 'small',
  instructionText,
  className = '',
  setIdOnLabel = false,
}) => {
  const getTypographyProps = (
    size: 'small' | 'medium' | 'large'
  ): {
    color: 'secondary' | 'primary';
    variant: 'body1' | 'subtitle3' | 'h2';
  } => {
    switch (size) {
      case 'small':
        return {
          variant: 'subtitle3',
          color: 'secondary',
        };
      case 'medium':
        return {
          variant: 'body1',
          color: 'primary',
        };
      case 'large':
        return {
          variant: 'h2',
          color: 'primary',
        };
    }
  };

  const typographyProps = getTypographyProps(size);

  return (
    <div className='flex flex-col'>
      <label
        /**
         * In rare cases, the id should be set on the label element instead of the input element
         * such as when we are labeling a button and using aria-labelledby to associate the label with the button.
         */
        {...(setIdOnLabel ? { id } : { htmlFor: id })}
        className={cx({ 'sr-only': visuallyHidden }, className)}
      >
        <ObTypography
          as={'span'}
          {...typographyProps}
        >
          {label}
        </ObTypography>
        {isRequired && (
          <ObTypography
            as={'span'}
            color='negative'
            className=''
          >
            {' '}
            *
          </ObTypography>
        )}
      </label>
      {size != 'small' && instructionText && (
        <ObTypography
          as='span'
          id={`${id}__instruction-text`}
        >
          {instructionText}
        </ObTypography>
      )}
    </div>
  );
};
