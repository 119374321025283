import { FC } from 'react';
import {
  ObAiGeneratingLoadingIndicator,
  ObProgressBar,
  ObTypography,
} from '../../..';

export interface CreativeInitializingLoaderProps {
  estimatedPercentComplete: number;
}
export const CreativeInitializingLoader: FC<
  CreativeInitializingLoaderProps
> = ({ estimatedPercentComplete }: CreativeInitializingLoaderProps) => {
  return (
    <div className='flex flex-col gap-4 items-center justify-center'>
      <ObAiGeneratingLoadingIndicator
        variant='spinner'
        size={240}
      />
      <ObTypography
        className='text-center'
        variant='h4'
      >
        Outbound AI is Generating Your Content
      </ObTypography>
      <div className='w-[80%]'>
        <ObProgressBar percentage={estimatedPercentComplete} />
      </div>
    </div>
  );
};
