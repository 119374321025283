import { googleSearchAdTemplate } from '@outbound/design-system/src/creative/template-library/google-search/google-search/google-search-ad.schema';
import { TemplateSchemaResource } from '@outbound/types';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import Template from './template';
import TemplateTransformer from './template-transformer';

export class TemplateStore extends BaseStore<Template, TemplateSchemaResource> {
  private _templateTransformer: TemplateTransformer;

  constructor(rootStore: RootStore) {
    super(rootStore, 'creative-template');
    this._templateTransformer = new TemplateTransformer(rootStore);

    /**
     * TEMP IMPLEMENTATION
     * We will replace this with a proper implementation from a server call
     * once we are creating a lot of templates
     */
    const googleSearchTemplate = this._templateTransformer.fromApiResource(
      googleSearchAdTemplate
    );

    googleSearchTemplate.makeObservable();
    this.modelMap.set(googleSearchTemplate.id, googleSearchTemplate);
    this.updateSyncMetaForLoadedModel(
      googleSearchTemplate.id,
      googleSearchTemplate
    );
  }

  protected async requestLoadModelFromServer(_id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
