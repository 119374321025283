import { ServerDelta } from '@outbound/types';
import { LeadRatingStatus } from '@outbound/types/src/lead/api-resource/lead-rating-status';
import { LeadSourceType } from '@outbound/types/src/lead/api-resource/lead-source-type';
import { generateKeyBetween } from 'fractional-indexing';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from '../../base-model';
import { RootStore } from '../../root-store';
import PriorityReindexRequired from './exceptions/priority-reindex-required-exception';
import UnableToPrioritizeDueToDifferentStatusException from './exceptions/unable-to-prioritize-due-to-different-status-exception';
import UnableToPrioritizeDueToInvalidAfterAndBeforeOrderException from './exceptions/unable-to-prioritize-due-to-invlalid-after-and-before-order-exception';
import {
  AbstractLeadEvent,
  LeadEventDto,
} from './lead-events/abstract-lead-event';
import LeadEventFactory from './lead-events/lead-event-factory';
import { LeadEventQualificationStatusUpdated } from './lead-events/lead-event-qualification-status-updated';
import { LeadEvent } from './lead-events/lead-event-type';

const ABSOLUTE_MAX_BASE_62_VALUE = 'zzzzzzzzzzzzzzzzzzzzzzzzzzz';
const ABSOLUTE_MIN_BASE_62_VALUE = 'A00000000000000000000000001'; //This is technically not the lowest value but the lib we are using seems to have a bug.

const qualificationStatusValues = [
  'NEW',
  'WORKING',
  'NURTURE',
  'QUALIFIED',
  'UNQUALIFIED',
] as const;
type QualificationStatus = (typeof qualificationStatusValues)[number];

export interface ReconstituteLeadDto {
  rootStore: RootStore;
  obrn: string;
  workspaceId: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  priorityFractionalIndex: string;
  qualificationStatus: QualificationStatus;
  lastModifiedAtTimestamp: string;
  createdAtTimestamp: string;
  inStatusSinceTimestamp: string;
  sourceType: LeadSourceType;
  rating: LeadRatingStatus;
  events: Array<LeadEventDto>;
  originallyAssociatedWithServiceObrn?: string;
  originallyAssociatedWithCampaignObrn?: string;
  originallyAssociatedWithCampaignHighlightObrn?: string;
}

type LeadPath = keyof typeof Lead.paths;

class Lead extends BaseModel {
  private _firstName: string = '';
  private _lastName: string = '';
  private _email: string = '';
  private _phone: string = '';
  private _zipCode: string = '';
  private _priorityFractionalIndex: string = '';
  private _qualificationStatus: QualificationStatus;
  private _qualificationStatusUpdatedEventToSetOnNextSave?: LeadEventQualificationStatusUpdated;
  private _rating: LeadRatingStatus;
  private _sourceType: LeadSourceType;
  private _lastModifiedAtTimestamp: string;
  private _createdAtTimestamp: string;
  private _inStatusSinceTimestamp: string;
  private _timeline: Array<LeadEvent> = [];
  private readonly _originallyAssociatedWithServiceObrn?: string;
  private readonly _originallyAssociatedWithCampaignObrn?: string;
  private readonly _originallyAssociatedWithCampaignHighlightObrn?: string;
  private constructor({
    rootStore,
    id,
    workspaceId,
    obrn,
    firstName,
    lastName,
    phoneNumber,
    zipCode,
    qualificationStatus,
    lastModifiedAtTimestamp,
    createdAtTimestamp,
    inStatusSinceTimestamp,
    events,
    rating,
    sourceType,
    email,
    originallyAssociatedWithCampaignHighlightObrn,
    originallyAssociatedWithCampaignObrn,
    originallyAssociatedWithServiceObrn,
    priorityFractionalIndex,
  }: ReconstituteLeadDto) {
    super(rootStore, 'lead', '1', id, workspaceId, obrn);
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._phone = phoneNumber;
    this._zipCode = zipCode;
    this._qualificationStatus = qualificationStatus;
    this._lastModifiedAtTimestamp = lastModifiedAtTimestamp;
    this._createdAtTimestamp = createdAtTimestamp;
    this._inStatusSinceTimestamp = inStatusSinceTimestamp;
    this._rating = rating;
    this._sourceType = sourceType;
    this._priorityFractionalIndex = priorityFractionalIndex;
    this._originallyAssociatedWithCampaignHighlightObrn =
      originallyAssociatedWithCampaignHighlightObrn;
    this._originallyAssociatedWithCampaignObrn =
      originallyAssociatedWithCampaignObrn;
    this._originallyAssociatedWithServiceObrn =
      originallyAssociatedWithServiceObrn;
    const unsortedTimeline: Array<LeadEvent> = [];
    events?.forEach((event) => {
      unsortedTimeline.push(LeadEventFactory.fromDto(rootStore, event));
    });
    this._timeline = unsortedTimeline.toSorted(
      AbstractLeadEvent.compareByTimestamp
    );
  }

  static readonly paths = {
    ...BaseModel.paths,
    contactFirstName: '/contactFirstName',
    contactLastName: '/contactLastName',
    contactEmail: '/contactEmail',
    contactPhone: '/contactPhone',
    contactZipCode: '/contactZipCode',
    qualificationStatus: '/qualificationStatus',
    rating: '/rating',
    sourceType: '/sourceType',
    priorityFractionalIndex: '/priority',
    lastModifiedAtTimestamp: '/lastModifiedAtTimestamp',
    inStatusSinceTimestamp: '/inStatusSinceTimestamp',
  };

  public static reconstituteFromPersistence(
    reconstituteLeadDto: ReconstituteLeadDto
  ) {
    return new Lead(reconstituteLeadDto);
  }

  applyPatch(_patch: ServerDelta[]): void {
    //Implement when needed
  }

  protected makeObservableInternal(): void {
    makeObservable(this, {
      _email: observable,
      _firstName: observable,
      _lastName: observable,
      _phone: observable,
      _zipCode: observable,
      _priorityFractionalIndex: observable,
      _qualificationStatus: observable,
      _lastModifiedAtTimestamp: observable,
      _inStatusSinceTimestamp: observable,
      _timeline: observable.shallow,
      _rating: observable,
      updateQualificationStatusToWorking: action,
      updateQualificationStatusToQualified: action,
    } as any);
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this.addUnsyncedLocalChange(
      value,
      this._firstName,
      Lead.paths.contactFirstName
    );
    runInAction(() => {
      this._firstName = value;
    });
  }

  private updateLastModifiedAtTimestamp() {
    this._lastModifiedAtTimestamp = new Date().toISOString();
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this.addUnsyncedLocalChange(
      value,
      this._lastName,
      Lead.paths.contactLastName
    );
    runInAction(() => {
      this._lastName = value;
    });
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this.addUnsyncedLocalChange(value, this._email, Lead.paths.contactEmail);
    runInAction(() => {
      this._email = value;
    });
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this.addUnsyncedLocalChange(value, this._phone, Lead.paths.contactPhone);
    runInAction(() => {
      this._phone = value;
    });
  }

  get zipCode(): string {
    return this._zipCode;
  }

  set zipCode(value: string) {
    this.addUnsyncedLocalChange(
      value,
      this._zipCode,
      Lead.paths.contactZipCode
    );
    runInAction(() => {
      this._zipCode = value;
    });
  }

  set rating(value: LeadRatingStatus) {
    this.addUnsyncedLocalChange(value, this._rating, Lead.paths.rating);
    runInAction(() => {
      this._rating = value;
    });
  }

  get priorityFractionalIndex(): string {
    return this._priorityFractionalIndex;
  }

  get qualificationStatus(): QualificationStatus {
    return this._qualificationStatus;
  }

  get rating(): LeadRatingStatus {
    return this._rating;
  }

  get sourceType(): LeadSourceType {
    return this._sourceType;
  }

  updateQualificationStatusToWorking() {
    this.updateQualificationStatusTo('WORKING');
  }

  updateQualificationStatusToQualified() {
    this.updateQualificationStatusTo('QUALIFIED');
  }

  updateQualificationStatusToNotQualified() {
    this.updateQualificationStatusTo('UNQUALIFIED');
  }

  updateQualificationStatusToNew() {
    this.updateQualificationStatusTo('NEW');
  }

  updateQualificationStatusToNurture() {
    this.updateQualificationStatusTo('NURTURE');
  }
  private updateQualificationStatusTo(
    qualificationStatus: QualificationStatus
  ) {
    if (this.qualificationStatus === qualificationStatus) {
      return;
    }
    const previousQualificationStatus = this._qualificationStatus;
    const updatedAtTimestamp = new Date().toISOString();
    this.addUnsyncedLocalChange(
      qualificationStatus,
      this._qualificationStatus,
      Lead.paths.qualificationStatus
    );
    this._qualificationStatusUpdatedEventToSetOnNextSave =
      LeadEventQualificationStatusUpdated.create(
        this._rootStore,
        previousQualificationStatus,
        qualificationStatus,
        updatedAtTimestamp
      );

    runInAction(() => {
      this._qualificationStatus = qualificationStatus;
    });
  }

  get lastModifiedAtTimestamp(): string {
    return this._lastModifiedAtTimestamp;
  }

  get createdAtTimestamp(): string {
    return this._createdAtTimestamp;
  }

  get inStatusSinceTimestamp(): string {
    return this._inStatusSinceTimestamp;
  }

  get timeline(): ReadonlyArray<LeadEvent> {
    return this._timeline;
  }

  get originallyAssociatedWithServiceObrn():
    | string
    | 'NOT_ASSOCIATED_WITH_SERVICE_ON_CREATION' {
    return this._originallyAssociatedWithServiceObrn
      ? this._originallyAssociatedWithServiceObrn
      : 'NOT_ASSOCIATED_WITH_SERVICE_ON_CREATION';
  }

  get originallyAssociatedWithCampaignObrn():
    | string
    | 'NOT_ASSOCIATED_WITH_CAMPAIGN_ON_CREATION' {
    return this._originallyAssociatedWithCampaignObrn
      ? this._originallyAssociatedWithCampaignObrn
      : 'NOT_ASSOCIATED_WITH_CAMPAIGN_ON_CREATION';
  }
  get originallyAssociatedWithCampaignHighlightObrn():
    | string
    | 'NOT_ASSOCIATED_WITH_CAMPAIGN_HIGHLIGHT_ON_CREATION' {
    return this._originallyAssociatedWithCampaignHighlightObrn
      ? this._originallyAssociatedWithCampaignHighlightObrn
      : 'NOT_ASSOCIATED_WITH_CAMPAIGN_HIGHLIGHT_ON_CREATION';
  }

  private addQualificationStatusEventToTimeline(
    statusChangedEvent: LeadEventQualificationStatusUpdated
  ) {
    this._timeline = [...this._timeline, statusChangedEvent];
  }

  private updateTimestampsForQualificationStatusChange(
    updatedAtTimestamp: string
  ) {
    this._lastModifiedAtTimestamp = updatedAtTimestamp;
    this._inStatusSinceTimestamp = updatedAtTimestamp;
  }

  static PRIORITY_COMPARATOR(
    leadA: Lead | undefined,
    leadB: Lead | undefined
  ): number {
    if (!leadA && !leadB) return 0;
    if (!leadA) return 1;
    if (!leadB) return -1;

    if (leadA.priorityFractionalIndex === leadB.priorityFractionalIndex)
      return 0;

    return leadA.priorityFractionalIndex < leadB.priorityFractionalIndex
      ? -1
      : 1;
  }

  public prioritize(place: {
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }) {
    this.assertLeadsBeingPrioritizedAreAllInSameStatus({
      directlyUnder: place.directlyUnder,
      directlyAbove: place.directlyAbove,
    });
    this.assertThatThePriorityOfTheDirectlyUnderAndDirectlyAboveArePrioritizedHighestToLowest(
      place
    );
    this.assertThereIsRoomAtTheTopOfTheListForTheNewLead(place);
    this.assertThereIsRoomAtTheBottomOfTheListForTheNewLead(place);

    const nextPriority =
      this.calculateNextPriorityForThisLeadBasedOnWhereItShouldBePlaced(place);

    this.applyPriorityChangeToModel(nextPriority);
    this.dispatchPriorityChangeToServer({
      nextPriority,
      directlyAbove: place.directlyAbove,
      directlyUnder: place.directlyUnder,
    });
  }

  private calculateNextPriorityForThisLeadBasedOnWhereItShouldBePlaced(place: {
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }): string {
    return generateKeyBetween(
      place.directlyUnder === 'TOP_OF_LIST'
        ? undefined
        : place.directlyUnder.priorityFractionalIndex,
      place.directlyAbove === 'BOTTOM_OF_LIST'
        ? undefined
        : place.directlyAbove.priorityFractionalIndex
    );
  }

  private dispatchPriorityChangeToServer({
    nextPriority,
    directlyUnder,
    directlyAbove,
  }: {
    nextPriority: string;
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }) {
    let directUnderIdentity =
      this.determineIdentityOfLeadToPrioritizeUnder(directlyUnder);
    let directAboveIdentity =
      this.determineIdentityOfLeadToPrioritizeAbove(directlyAbove);
    const deltaValue = {
      directlyUnder: directUnderIdentity,
      directlyAbove: directAboveIdentity,
      priority: nextPriority,
    };
    this._rootStore.transport.leadTransport.enqueue(
      this.createDelta(
        deltaValue,
        Lead.paths.priorityFractionalIndex,
        'replace'
      )
    );
  }

  private determineIdentityOfLeadToPrioritizeAbove(
    directlyAbove: Lead | 'BOTTOM_OF_LIST'
  ): string | 'BOTTOM_OF_LIST' {
    if (directlyAbove === 'BOTTOM_OF_LIST') {
      return directlyAbove;
    }
    return directlyAbove.id;
  }

  private determineIdentityOfLeadToPrioritizeUnder(
    directlyUnder: Lead | 'TOP_OF_LIST'
  ): string | 'TOP_OF_LIST' {
    if (directlyUnder === 'TOP_OF_LIST') {
      return directlyUnder;
    }
    return directlyUnder.id;
  }

  private applyPriorityChangeToModel(newPriority: string) {
    runInAction(() => {
      this._priorityFractionalIndex = newPriority;
    });
  }
  private assertThereIsRoomAtTheTopOfTheListForTheNewLead(place: {
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }) {
    if (
      place.directlyUnder === 'TOP_OF_LIST' &&
      place.directlyAbove !== 'BOTTOM_OF_LIST'
    ) {
      if (place.directlyAbove.isPriorityAtMinimumValue()) {
        throw new PriorityReindexRequired(place.directlyAbove);
      }
    }
  }

  private assertThereIsRoomAtTheBottomOfTheListForTheNewLead(place: {
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }) {
    if (
      place.directlyAbove === 'BOTTOM_OF_LIST' &&
      place.directlyUnder !== 'TOP_OF_LIST'
    ) {
      if (place.directlyUnder.isPriorityAtMaximumValue()) {
        throw new PriorityReindexRequired(place.directlyUnder);
      }
    }
  }

  public isPriorityAtMinimumValue(): boolean {
    return this.priorityFractionalIndex <= ABSOLUTE_MIN_BASE_62_VALUE;
  }

  public isPriorityAtMaximumValue(): boolean {
    return this.priorityFractionalIndex >= ABSOLUTE_MAX_BASE_62_VALUE;
  }

  private assertThatThePriorityOfTheDirectlyUnderAndDirectlyAboveArePrioritizedHighestToLowest(place: {
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }) {
    if (
      place.directlyAbove !== 'BOTTOM_OF_LIST' &&
      place.directlyUnder !== 'TOP_OF_LIST'
    ) {
      let leadsToPlaceBetween: Lead[] = [
        place.directlyUnder,
        place.directlyAbove,
      ];
      leadsToPlaceBetween.sort(Lead.PRIORITY_COMPARATOR);
      if (leadsToPlaceBetween[0] !== place.directlyUnder) {
        throw new UnableToPrioritizeDueToInvalidAfterAndBeforeOrderException(
          place.directlyUnder,
          place.directlyAbove
        );
      }
    }
  }

  private assertLeadsBeingPrioritizedAreAllInSameStatus(place: {
    directlyUnder: Lead | 'TOP_OF_LIST';
    directlyAbove: Lead | 'BOTTOM_OF_LIST';
  }) {
    let leadToPrioritizeUnder: Lead | undefined;
    let leadToPrioritizeAbove: Lead | undefined;

    if (place.directlyUnder === 'TOP_OF_LIST') {
      leadToPrioritizeUnder = undefined;
    } else {
      leadToPrioritizeUnder = place.directlyUnder;
    }

    if (place.directlyAbove === 'BOTTOM_OF_LIST') {
      leadToPrioritizeAbove = undefined;
    } else {
      leadToPrioritizeAbove = place.directlyAbove;
    }
    if (
      (leadToPrioritizeUnder != null &&
        leadToPrioritizeUnder.qualificationStatus !==
          this.qualificationStatus) ||
      (leadToPrioritizeAbove != null &&
        leadToPrioritizeAbove.qualificationStatus !== this.qualificationStatus)
    ) {
      throw new UnableToPrioritizeDueToDifferentStatusException(
        this,
        leadToPrioritizeUnder,
        leadToPrioritizeAbove
      );
    }
  }

  public discardUnsavedChanges(): void {
    runInAction(() => {
      this._unsyncedLocalChanges.forEach((value, key) => {
        this._unsyncedLocalChanges.delete(key);
        switch (key) {
          case Lead.paths.contactFirstName:
            this._firstName = value?.lastKnownServerValue;
            break;
          case Lead.paths.contactLastName:
            this._lastName = value?.lastKnownServerValue;
            break;
          case Lead.paths.contactEmail:
            this._email = value?.lastKnownServerValue;
            break;
          case Lead.paths.contactPhone:
            this._phone = value?.lastKnownServerValue;
            break;
          case Lead.paths.contactZipCode:
            this._zipCode = value?.lastKnownServerValue;
            break;
          case Lead.paths.qualificationStatus:
            this._qualificationStatus = value?.lastKnownServerValue;
            break;
          case Lead.paths.rating:
            this._rating = value?.lastKnownServerValue;
            break;
          case Lead.paths.priorityFractionalIndex:
            this._priorityFractionalIndex = value?.lastKnownServerValue;
            break;

          default:
            break;
        }
      });
    });
  }

  private runOnCommitCallbacksForQualificationStatusChange() {
    this.addQualificationStatusEventToTimelineForCommittedQualificationStatusChange();
  }

  private addQualificationStatusEventToTimelineForCommittedQualificationStatusChange() {
    if (this._qualificationStatusUpdatedEventToSetOnNextSave) {
      this.updateTimestampsForQualificationStatusChange(
        this._qualificationStatusUpdatedEventToSetOnNextSave?.timestamp
      );
      this.addQualificationStatusEventToTimeline(
        this._qualificationStatusUpdatedEventToSetOnNextSave
      );
      this._qualificationStatusUpdatedEventToSetOnNextSave = undefined;
    }
  }

  private commitUnsyncedChangesForGivenAttributes(
    attributesToSave: Array<LeadPath>
  ) {
    attributesToSave.forEach((attribute) => {
      const value = this._unsyncedLocalChanges.get(Lead.paths[attribute]);
      if (value) {
        this._unsyncedLocalChanges.delete(Lead.paths[attribute]);
        if (Lead.paths[attribute] === Lead.paths.qualificationStatus) {
          this.runOnCommitCallbacksForQualificationStatusChange();
        }
        if (Lead.paths[attribute] === Lead.paths.rating) {
          this.updateLastModifiedAtTimestamp();
        }
        this._rootStore.transport.leadTransport.enqueue(value?.clientDelta);
      }
    });
  }

  private commitAllUnsyncedChanges() {
    this._unsyncedLocalChanges.forEach((value, key) => {
      this._unsyncedLocalChanges.delete(key);
      this._rootStore.transport.leadTransport.enqueue(value?.clientDelta);
    });
  }

  private attributesToSaveWereProvided(attributesToSave?: Array<LeadPath>) {
    return attributesToSave && attributesToSave.length > 0;
  }

  public save(attributesToSave?: Array<LeadPath>): void {
    const unsavedActionCount = this._unsyncedLocalChanges.size;
    runInAction(() => {
      if (this.attributesToSaveWereProvided(attributesToSave)) {
        this.commitUnsyncedChangesForGivenAttributes(attributesToSave!);
      } else {
        this.commitAllUnsyncedChanges();
      }
    });
    if (unsavedActionCount > 0) {
      this.updateLastModifiedAtTimestamp();
    }
  }
}

export default Lead;
