import {
  FormDefinition,
  FormFieldType,
  FormFieldTypeSettingsSchema,
  ObFormRenderer,
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { ObDrawerFooter } from '@outbound/design-system/src/components/molecules/ob-drawer-footer/ob-drawer-footer';
import Review from 'src/state/mobx-experiment/business-context/review/review';
import { useRootStore } from 'src/state/mobx-experiment/use-root-store';
import t from '../reviews.i18n.json';
import {
  CreateEditReviewForm,
  ReviewCreateEditFormValue,
} from './review-create-edit-form.def';
interface ReviewCreateEditDrawerProps {
  reviewToEdit?: Review;
  onFormSubmitCallback: (review: any) => Promise<void>;
}
/**
 * Drawer used to create and edit.
 */
export const ReviewCreateEditDrawer = ({
  onFormSubmitCallback,
  reviewToEdit,
}: ReviewCreateEditDrawerProps) => {
  const drawerService = useDrawerService();
  const { serviceStore } = useRootStore();
  const services = serviceStore.list();
  const { getRegisteredFormById } = useObFormRendererContext();
  const finalFormDef: FormDefinition = CreateEditReviewForm;
  const relatedItemsFieldSettings: FormFieldTypeSettingsSchema<FormFieldType.COMBO_BOX> =
    finalFormDef.sections[0].fields.find(
      (f) => f.id === 'relatedProductsAndServices'
    )!
      .fieldTypeSettings as FormFieldTypeSettingsSchema<FormFieldType.COMBO_BOX>;
  relatedItemsFieldSettings.options =
    services?.map((s) => {
      return {
        key: s.obrn,
        value: s.obrn,
        label: s.name,
        displayValue: s.name,
      };
    }) ?? [];

  let defaultValues: undefined | ReviewCreateEditFormValue = undefined;
  if (reviewToEdit) {
    defaultValues = {
      authorFullName: reviewToEdit.authorFullName,
      relatedProductsAndServices:
        reviewToEdit.relatedProductsAndServices.filter(
          (r) => services?.find((s) => s.obrn === r)
        ),
      reviewText: reviewToEdit.textPlain,
      rating: reviewToEdit.rating.toString(),
    };
  }
  return (
    <div className='overflow-x-auto p-4'>
      <ObFormRenderer
        formDefinition={finalFormDef}
        defaultValues={defaultValues}
        hideSubmitButton={true}
        onSubmitCallback={(data) => {
          return onFormSubmitCallback(data);
        }}
      />
      <div>
        <ObDrawerFooter
          primaryActionLabel={
            !reviewToEdit
              ? t.CREATE_REVIEW_DIALOG.CREATE_BUTTON_LABEL
              : t.CREATE_REVIEW_DIALOG.SAVE_EDIT_BUTTON_LABEL
          }
          primaryActionCallback={() => {
            getRegisteredFormById(CreateEditReviewForm.id)?.submitFunction();
            return Promise.resolve();
          }}
          secondaryActionLabel={t.CREATE_REVIEW_DIALOG.CANCEL_BUTTON_LABEL}
          secondaryActionCallback={() => {
            drawerService.popDrawer(false);
            return Promise.resolve();
          }}
        />
      </div>
      <div
        data-spacer
        className='min-h-[80px]'
      ></div>
    </div>
  );
};
