/* eslint-disable unused-imports/no-unused-vars */
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { LeadRatingStatus } from '@outbound/types';
import { ObTypography } from '../../../components/elements/ob-typography/ob-typography';
import { LeadRatingLocalization } from './localization.type';

export interface Props {
  selectedRating: LeadRatingStatus;
  onRatingSelected: (rating: LeadRatingStatus) => void;
  localization: LeadRatingLocalization;
}

const localizedRatingOptions = (
  localization: LeadRatingLocalization
): Array<{ value: LeadRatingStatus; label: string; emoji: string }> => {
  return [
    { value: 'HOT', label: localization.hotLabel, emoji: '🔥' },
    { value: 'WARM', label: localization.warmLabel, emoji: '☀️' },
    { value: 'COLD', label: localization.coldLabel, emoji: '🧊' },
  ];
};

export const LeadRatingInput = ({
  selectedRating,
  onRatingSelected,
  localization,
}: Props) => {
  const options = localizedRatingOptions(localization);
  const selectedOption = options.find(
    (option) => option.value === selectedRating
  );
  return (
    <Listbox
      value={selectedRating}
      onChange={(value) => {
        onRatingSelected(value as LeadRatingStatus);
      }}
    >
      <ListboxButton
        className='border rounded-md pl-2 pr-3 py-1 border-dark/border/default/normal hover:border-dark/border/default/hover active:border-dark/border/default/active'
        onClick={(event) => event.stopPropagation()}
      >
        {selectedOption == null ? (
          <ObTypography>Rate Lead</ObTypography>
        ) : (
          <RatingOptionLabel
            emoji={selectedOption.emoji}
            label={selectedOption.label}
          />
        )}
      </ListboxButton>
      <ListboxOptions
        anchor='bottom'
        className='rounded-md'
      >
        {options.map((option) => (
          <ListboxOption
            key={option.value}
            value={option.value}
            onClick={(event) => {
              event.stopPropagation();
            }}
            className='cursor-pointer hover:bg-dark/action/neutral/hover bg-dark/action/neutral/normal px-3 py-2'
          >
            <RatingOptionLabel
              emoji={option.emoji}
              label={option.label}
            />
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

const RatingOptionLabel = ({
  emoji,
  label,
}: {
  emoji: string;
  label: string;
}) => {
  return (
    <div className='flex items-center gap-2'>
      <ObTypography
        variant='h6'
        as='span'
        className='aria-hidden'
      >
        {emoji}
      </ObTypography>
      <ObTypography
        as='span'
        variant='h6'
      >
        {label}
      </ObTypography>
    </div>
  );
};
