import {
  ObFormRenderer,
  ObPeekabooFooterTemplate,
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { useCallback } from 'react';
import { useCreateSubServiceForService } from '../../../../../../../query/playbook/use-playbook-settings-service-endpoints';
import { mapFormValuesToApiResource } from './forms/sub-service-form-utils';
import {
  SUB_SERVICE_FORM_DEF,
  useFormWithAssetManagerFileUploader,
} from './forms/sub-service-form.def';

export interface SubServiceCreateDrawerProps {
  serviceId: string;
}

export const SubServiceCreateDrawer: React.FC<SubServiceCreateDrawerProps> = ({
  serviceId,
}: SubServiceCreateDrawerProps) => {
  const { getRegisteredFormById } = useObFormRendererContext();
  const drawerService = useDrawerService();

  const { formDefinitionWithAssetManager } =
    useFormWithAssetManagerFileUploader(SUB_SERVICE_FORM_DEF);

  const { mutateAsync: createSubService } =
    useCreateSubServiceForService(serviceId);

  const handleFormSubmit = useCallback(
    async (values: any) => {
      const result = createSubService(mapFormValuesToApiResource(values));
      result.then((data) => {
        drawerService.popDrawer(true, data);
      });
      return await result;
    },
    [createSubService, drawerService]
  );

  return (
    <ObPeekabooFooterTemplate
      affirmativeActionButtonLabel={'Create'}
      affirmativeActionButtonCallback={async () => {
        return getRegisteredFormById(SUB_SERVICE_FORM_DEF.id)?.submitFunction();
      }}
      dismissiveActionButtonLabel={'Cancel'}
      dismissiveActionButtonCallback={async () => {
        getRegisteredFormById(
          SUB_SERVICE_FORM_DEF.id
        )?.discardChangesFunction();
        drawerService.popDrawer();
      }}
    >
      <div className='flex flex-col flex-1 gap-4'>
        <ObFormRenderer
          formDefinition={formDefinitionWithAssetManager}
          hideSubmitButton={true}
          onSubmitCallback={handleFormSubmit}
        />
      </div>
    </ObPeekabooFooterTemplate>
  );
};
