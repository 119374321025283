'use client';
import {
  ReactNode,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { cx } from 'class-variance-authority';
import { ObButton } from '../../elements/ob-button/ob-button';

export type ObReadMoreLessProps = {
  children: string | ReactNode;
  className?: HTMLDivElement['className'];
  readMoreShadow?: boolean;
  lineHeight?: number;
  linesToShowWhenCollapsed?: number;
  buttonSize?: 'small' | 'medium' | 'large';
};

const DEFAULT_TEXT_LINE_HEIGHT = 24;
const DEFAULT_TRUNCATED_LINES = 3;

export const ObReadMoreLess = ({
  children,
  className,
  readMoreShadow = true,
  lineHeight = DEFAULT_TEXT_LINE_HEIGHT,
  linesToShowWhenCollapsed = DEFAULT_TRUNCATED_LINES,
  buttonSize = 'large',
}: ObReadMoreLessProps) => {
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showExpandAction, setShowExpandAction] = useState<boolean>(true);

  const collapsedHeight = useMemo(() => {
    return lineHeight * linesToShowWhenCollapsed;
  }, [lineHeight, linesToShowWhenCollapsed]);

  const updateExpandState = useCallback(() => {
    const elementHeight: number = textContainerRef?.current
      ?.offsetHeight as unknown as number;

    if (collapsedHeight >= elementHeight) {
      setIsExpanded(true);
      setShowExpandAction(false);
    } else {
      setShowExpandAction(true);
    }
  }, [collapsedHeight]);

  useEffect(() => {
    updateExpandState();

    window.addEventListener('resize', updateExpandState);
    return () => {
      window.removeEventListener('resize', updateExpandState);
    };
  }, [updateExpandState]);

  return (
    <div className={cx('flex flex-col', className)}>
      <div
        data-testid='ob-read-more-less'
        style={{ maxHeight: isExpanded ? 'initial' : collapsedHeight + 'px' }}
        className={cx(
          'overflow-hidden',
          readMoreShadow && !isExpanded && 'gradient-mask-to-bottom'
        )}
      >
        {/* This way, a screen reader gets the correct content only */}
        {!isExpanded ? (
          <div
            style={{
              lineHeight: lineHeight + 'px',
              WebkitLineClamp: linesToShowWhenCollapsed,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              overflow: 'hidden',
            }}
          >
            {children}
          </div>
        ) : null}

        <div
          ref={textContainerRef}
          style={{ lineHeight: lineHeight + 'px' }}
          aria-hidden={!isExpanded}
          data-testid='text-content'
        >
          {children}
        </div>
      </div>
      <div className='mt-4'>
        {showExpandAction ? (
          <ObButton
            variant='outline'
            fullWidth='mobile'
            size={buttonSize}
            label={`Read ${isExpanded ? 'less' : 'more'}`}
            onClick={() => setIsExpanded((prev) => !prev)}
          />
        ) : null}
      </div>
    </div>
  );
};
