import {
  ObFormRenderer,
  ObPeekabooFooterTemplate,
  useDrawerService,
  useObFormRendererContext,
} from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useRootStore } from '../../../../../../state/mobx-experiment/use-root-store';
import { mapFormValuesToApiResource } from '../forms/brick-and-mortar-form-utils';
import { BRICK_AND_MORTAR_FORM_DEF } from '../forms/brick-and-mortar-form.def';

export const BrickAndMortarCreateDrawer = observer(() => {
  const { getRegisteredFormById } = useObFormRendererContext();

  const drawerService = useDrawerService();

  const { locationStore } = useRootStore();

  /**
   * Maps the Form Values to the API Resource and calls the createPhysicalLocation mutation
   */
  const handleCreatePhysicalLocation = useCallback(
    async (values: any) => {
      try {
        await locationStore.createPhysicalLocation({
          ...mapFormValuesToApiResource(values),
        });

        await drawerService.popDrawer();
      } catch (error) {
        console.error('Error creating physical location', error);
      }
    },
    [drawerService, locationStore]
  );

  return (
    <ObPeekabooFooterTemplate
      affirmativeActionButtonLabel={'Create'}
      affirmativeActionButtonCallback={async () => {
        return getRegisteredFormById(
          BRICK_AND_MORTAR_FORM_DEF.id
        )?.submitFunction();
      }}
      dismissiveActionButtonLabel={'Cancel'}
      dismissiveActionButtonCallback={async () => {
        getRegisteredFormById(
          BRICK_AND_MORTAR_FORM_DEF.id
        )?.discardChangesFunction();
        drawerService.popDrawer();
      }}
    >
      <ObFormRenderer
        formDefinition={BRICK_AND_MORTAR_FORM_DEF}
        hideSubmitButton={true}
        onSubmitCallback={handleCreatePhysicalLocation}
      />
    </ObPeekabooFooterTemplate>
  );
});

export const useBrickAndMortarCreateDrawer = () => {
  const drawerService = useDrawerService();

  const openNewBrickAndMortarLocationDrawer = () => {
    drawerService.pushDrawer({
      title: 'Add New Address',
      description:
        'Add and complete the information below to create a new address.',
      drawerContent: <BrickAndMortarCreateDrawer />,
      size: 'medium',
    });
  };

  return { openNewBrickAndMortarLocationDrawer };
};
