import { MouseSensor, useSensor } from '@dnd-kit/core';

export const useMouseSensor = () => {
  const mouseSensor = useSensor(
    MouseSensor,
    configureMouseSensorToRequireMinimumMovementBeforeActivating()
  );
  return mouseSensor;
};

const configureMouseSensorToRequireMinimumMovementBeforeActivating = (
  numberOfPixelsToMoveBeforeActivating: number = 10
) => {
  return {
    activationConstraint: {
      distance: numberOfPixelsToMoveBeforeActivating,
    },
  };
};
