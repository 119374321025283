import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { ContactSection } from './sections/contact';
import { MetadataSection } from './sections/metadata';

/**
 * Content intended to be Shared between the desktop aside and mobile drawer
 * Parent component is responsible for rendering the container styles.
 */
export const LeadDetailAsideContentWithoutContainerStyles = ({
  lead,
}: {
  lead: Lead;
}) => {
  return (
    <>
      <ContactSection lead={lead} />
      <MetadataSection lead={lead} />
    </>
  );
};
