import {
  ClientDelta,
  LeadResource,
  PostLeadContactDetailsResource,
} from '@outbound/types';
import { AxiosInstance, isAxiosError } from 'axios';
import { BaseTransport } from '../base-transport';
import ServerMutationHandler from '../sync-framework/muation-handler/abstract-server-mutation-handler';
import { ObjectDeltaMap } from '../sync-framework/muation-handler/object-delta-map';
import { Transport } from '../transport';
import ContactMutationHandler from './transport-mutation-handlers/contact-mutation-handler';
import QualificationStatusAndPriorityMutationHandler from './transport-mutation-handlers/qualification-status-and-priority-mutation-handler';
import RatingMutationHandler from './transport-mutation-handlers/rating-mutation-handler';

class LeadTransport extends BaseTransport<LeadResource> {
  private readonly mutationHandlers: ServerMutationHandler[];
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
    //Possible candidate to move to base class and make a default version of the processDeltaQueueFlush
    this.mutationHandlers = [
      new QualificationStatusAndPriorityMutationHandler(axiosInstance),
      new RatingMutationHandler(axiosInstance),
      new ContactMutationHandler(axiosInstance),
    ];
  }

  private groupDeltasByLead(deltas: ClientDelta[]): ObjectDeltaMap {
    const deltasGroupedByLead: ObjectDeltaMap = new Map();
    deltas.forEach((delta) => {
      const leadId = delta.id;
      if (!deltasGroupedByLead.has(leadId)) {
        deltasGroupedByLead.set(leadId, []);
      }
      const deltasForLead = deltasGroupedByLead.get(leadId);
      if (deltasForLead) {
        deltasForLead.push(delta);
      }
    });
    return deltasGroupedByLead;
  }

  protected async processDeltaQueueFlush(
    dedupeDeltaQueue: Array<ClientDelta>
  ): Promise<void> {
    /*
     Thinking the process delta queue flush should be updated to pass in 
     a map of deltas grouped by object id. It is implicit that the deltas can
     be for any specific lead entity but this should be made explicit to prevent
     bugs from being introduced by developers who are not familiar with the code.

     I first implemented the priority and status change logic in a way that assumed
     that deltas are all for the same lead because I assumed thats what was passed
     to this function. What I realized after the fact is that all deltas are passed
     in a single array regardless of the Lead entity they belong to. Think of super
     fast dragging and dropping on the board to see how this could be an issue.

     The flushQueue logic in the base class already does the group by for de-dupe purposes
     and then turns it back into an array.

     This comment is for myself or anyone who is doing the refactor in the future.
     */
    const deltasGroupedByLead = this.groupDeltasByLead(dedupeDeltaQueue);

    this.mutationHandlers.forEach((mutationHandler) => {
      mutationHandler.handle(deltasGroupedByLead);
    });
  }

  protected async internalBootstrap(): Promise<void> {
    const response = await this._axiosInstance.get('/leads');

    if (response.data?.items) {
      response.data.items.forEach((result: LeadResource) => {
        console.log('Lead Transport Bootstrapped', result);
        this.updateSubjectWithResourceUpdate(result.id, result);
      });
    }
  }

  protected async fetchById(id: string): Promise<LeadResource | null> {
    try {
      const response = await this._axiosInstance.get<LeadResource>(
        `/leads/${id}`
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 404) {
          return null;
        }
      }
      throw error;
    }
  }
  public acceptEmbeddedResource(resource: LeadResource): void {
    this.updateSubjectWithResourceUpdate(resource.id, resource);
  }

  private async notifyServerOfContactDetailsChange({
    leadId,
    contactDetails,
  }: {
    leadId: string;
    contactDetails: PostLeadContactDetailsResource;
  }): Promise<void> {
    const updateContactDetailsRequestBody = {
      firstName: contactDetails.firstName,
      lastName: contactDetails.lastName,
      email: contactDetails.email,
      phone: contactDetails.phone,
      zipCode: contactDetails.zipCode,
    };
    try {
      await this._axiosInstance.post(
        `/leads/${leadId}/update-contact-details`,
        updateContactDetailsRequestBody
      );
    } catch (error) {
      throw error;
    }
  }
}

export default LeadTransport;
