import { LeadTimeline } from '@outbound/design-system';
import { SectionHeader } from '@outbound/design-system/src/detail-page-sections/section-header';
import { observer } from 'mobx-react-lite';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';
import { LeadEventFactory } from '../timeline-events/event-factory';

export const TimelineSection = observer(({ lead }: { lead: Lead }) => {
  return (
    <section className='w-full self-stretch flex-1'>
      <SectionHeader
        id={'timeline-heading'}
        heading={t.EVENTS.TIMELINE_HEADING}
        subheading='See the History of this Lead'
      />
      <LeadTimeline ariaLabeledBy='timeline-heading'>
        {lead.timeline.toReversed().map((event) => (
          <LeadEventFactory
            key={event.timestamp}
            event={event}
          />
        ))}
      </LeadTimeline>
    </section>
  );
});
