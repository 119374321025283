import { LeadDetail } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import Lead from 'src/state/mobx-experiment/leads/domain/lead';

interface LeadDetailProps {
  lead: Lead;
}

export const LeadDetailHeader = observer(({ lead }: LeadDetailProps) => {
  return (
    <LeadDetail
      onUpdateQualificationStatusCallback={(qualificationStatus) => {
        switch (qualificationStatus) {
          case 'QUALIFIED':
            lead.updateQualificationStatusToQualified();
            break;
          case 'UNQUALIFIED':
            lead.updateQualificationStatusToNotQualified();
            break;
          case 'WORKING':
            lead.updateQualificationStatusToWorking();
            break;
          case 'NURTURE':
            lead.updateQualificationStatusToNurture();
            break;
          case 'NEW':
            lead.updateQualificationStatusToNew();
            break;
        }
        lead.save(['qualificationStatus']);
      }}
      localization={{
        sourceFormSubmissionLabel: 'Form Submission',
        sourcePhoneTrackingLabel: 'Call Tracking',
        sourceManualEntryLabel: 'Manual Entry',
        hotLabel: 'Hot',
        warmLabel: 'Warm',
        coldLabel: 'Cold',
        noFirstNameOrLastNameProvidedMessage: 'Name Not Provided',
        qualifiedLabel: t.KANBAN_BOARD.QUALIFIED_COLUMN_NAME,
        unqualifiedLabel: t.KANBAN_BOARD.NOT_QUALIFIED_COLUMN_NAME,
        workingLabel: t.KANBAN_BOARD.WORKING_COLUMN_NAME,
        nurturingLabel: t.KANBAN_BOARD.NURTURE_COLUMN_NAME,
        newLabel: t.KANBAN_BOARD.NEW_COLUMN_NAME,
        updateQualificationStatusCtaLabel:
          t.CTA.UPDATE_QUALIFICATION_STATUS_ARIA_LABEL,
      }}
      value={0}
      qualificationStatus={lead.qualificationStatus}
      rating={lead.rating}
      sourceType={lead.sourceType}
      firstName={lead.firstName}
      onUpdateRatingCallback={(rating) => {
        lead.rating = rating;
        lead.save(['rating']);
      }}
      lastName={lead.lastName}
    />
  );
});
