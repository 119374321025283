import { runInAction } from 'mobx';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import Integration from './integration';
import { IntegrationTransformer } from './integration-transformer';
import { IntegrationResource } from './integration-transport';

class IntegrationStore extends BaseStore<Integration, IntegrationResource> {
  private transformer: IntegrationTransformer;
  constructor(rootStore: RootStore) {
    super(rootStore, 'integration', rootStore.transport.integrationTransport);
    this.transformer = new IntegrationTransformer(this.rootStore);
    this.rootStore.transport.integrationTransport.registerServerUpdateCallbackHandler(
      this.handleServiceUpdate.bind(this)
    );
  }

  //I feel like this should be a base implementation...
  protected handleServiceUpdate(
    id: string,
    update: IntegrationResource | null
  ) {
    if (update == undefined) {
      this.updateSyncMetaForNotFoundModel(id);
    } else {
      const existingModel = this.modelMap.get(id);
      if (existingModel) {
        //NO OP
      } else {
        runInAction(() => {
          const model = this.transformer.fromApiResource(update);
          this.modelMap.set(id, model);
          this.updateSyncMetaForLoadedModel(id, model);
        });
      }
    }
  }
  protected async requestLoadModelFromServer(_id: string): Promise<void> {
    //NO OP
  }
}

export default IntegrationStore;
