import Lead from '../lead';

class PriorityReindexRequired extends Error {
  constructor(leadAtLimit: Lead) {
    let limitHit: 'Minimum' | 'Maximum';
    if (leadAtLimit.isPriorityAtMinimumValue()) {
      limitHit = 'Minimum';
    } else {
      limitHit = 'Maximum';
    }
    let message = `Priority Reindex Required for Leads in ${leadAtLimit.qualificationStatus} status. Lead  ${leadAtLimit.obrn} is at ${limitHit} value for priority fractional index.`;

    super(message);
  }
}
export default PriorityReindexRequired;
