export const WIZARD_SUBMIT_AND_GO_TO_NEXT_STEP_BUTTON_LABEL = 'Continue';

export const WEBSITE_SCANNER_HEADER = 'Identifying Your Brand';
export const WEBSITE_SCANNER_SUBHEADER =
  'You are moments away from launching your marketing';

/**
 * Color Selection Page
 */
export const COLOR_SELECTION_HEADER_COLORS_FOUND =
  'Here are the colors we found on your site!';
export const COLOR_SELECTION_SUBHEADER_COLORS_FOUND =
  'Select your brand colors!';
export const COLOR_SELECTION_HEADER_COLORS_NOT_FOUND =
  "We didn't find any colors on your site!";

export const COLOR_SELECTION_PRIMARY_COLOR_INSTRUCTION_COLORS_FOUND =
  'Select your primary color';
export const COLOR_SELECTION_SECONDARY_COLOR_INSTRUCTION_COLORS_FOUND =
  'If you have a secondary color, add it now, otherwise, click continue.';

export const COLOR_SELECTION_PRIMARY_COLOR_INSTRUCTION_COLORS_NOT_FOUND_LINE_1 =
  'Don’t worry – you can manually pick them here.';
export const COLOR_SELECTION_PRIMARY_COLOR_INSTRUCTION_COLORS_NOT_FOUND_LINE_2 =
  "Let's start with a primary color.";

export const COLOR_SELECTION_SECONDARY_COLOR_INSTRUCTION_COLORS_NOT_FOUND_LINE_1 =
  'Don’t worry – you can manually pick them here.';
export const COLOR_SELECTION_SECONDARY_COLOR_INSTRUCTION_COLORS_NOT_FOUND_LINE_2 =
  'Now, let’s pick a secondary color (optional).';

export const COLOR_SELECTION_COMPLETE_SUBHEADER = 'These colors look great!';

export const COLOR_SELECTION_NO_PRIMARY_COLOR_SELECTED_ERROR =
  'Please select a primary color before continuing.';

export const COLOR_SELECTION_SUBMIT_BUTTON_LABEL =
  WIZARD_SUBMIT_AND_GO_TO_NEXT_STEP_BUTTON_LABEL;

/**
 * Asset Selection Page
 */
export const ASSET_RESULTS_HEADER = 'Select your Logos';
export const ASSET_RESULTS_SUBHEADER =
  'Select the logos that represent your brand.';
export const ASSET_AVAILABLE_GRID_HEADER = 'Selected for Import';

/**
 * No Logo Dialog
 */
export const ASSET_NO_LOGO_DIALOG_TITLE = 'No Logo? No Problem!';
export const ASSET_NO_LOGO_DIALOG_BODY =
  'You will have the opertunity to upload one later. Lets keep going!';
export const ASSET_NO_LOGO_DIALOG_CONTINUE_BUTTON_LABEL = 'Continue';
export const ASSET_NO_LOGO_DIALOG_CANCEL_BUTTON_LABEL = 'Cancel';
