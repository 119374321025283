import { LeadQualificationStatus } from '@outbound/types';
import { ObTypography } from '../../../../components/elements/ob-typography/ob-typography';
import { LeadQualificationStatusLocalizationProps } from '../../lead-card/lead-card.type';
import { LeadQualificationStatusIcon } from '../../lead-qualification-status-icon';
import { formatLeadQualificationStatusForDisplay } from '../../utils/format-lead-qualification-status-for-display';
import {
  BaseEventMinor,
  BaseEventMinorProps,
} from '../common/base-event-minor';

export interface Props extends Omit<BaseEventMinorProps, 'children'> {
  fromQualificationStatus: LeadQualificationStatus;
  toQualificationStatus: LeadQualificationStatus;
  localization: LeadQualificationStatusLocalizationProps;
}

export const UpdateQualificationStatusEvent = ({
  fromQualificationStatus,
  toQualificationStatus,
  localization,
  ...baseEventMinorProps
}: Props) => {
  return (
    <BaseEventMinor {...baseEventMinorProps}>
      {baseEventMinorProps.actorName}{' '}
      <ObTypography
        as='span'
        color='secondary'
      >
        updated
      </ObTypography>{' '}
      Qualification Status{' '}
      <ObTypography
        as='span'
        color='secondary'
      >
        from
      </ObTypography>{' '}
      <QualificationStatusLabel
        qualificationStatus={fromQualificationStatus}
        localization={localization}
      />{' '}
      <ObTypography
        as='span'
        color='secondary'
      >
        to
      </ObTypography>{' '}
      <QualificationStatusLabel
        qualificationStatus={toQualificationStatus}
        localization={localization}
      />{' '}
    </BaseEventMinor>
  );
};

const QualificationStatusLabel = ({
  qualificationStatus,
  localization,
}: {
  qualificationStatus: LeadQualificationStatus;
  localization: LeadQualificationStatusLocalizationProps;
}) => {
  const { formattedLeadQualificationStatus } =
    formatLeadQualificationStatusForDisplay(qualificationStatus, localization);
  return (
    <div className='inline-flex items-center justify-start relative'>
      <div className='absolute'>
        <LeadQualificationStatusIcon
          size={'xSmall'}
          qualificationStatus={qualificationStatus}
        />
      </div>
      <span className='pl-[18px]'>{formattedLeadQualificationStatus}</span>{' '}
    </div>
  );
};
