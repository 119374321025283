/**
 * Health Header for Never Been Published
 */
export const ADS_NOT_PUBLISHED_SUGGESTION_TITLE =
  'Publish Your Ads to Google Ad Manager';

export const ADS_NOT_PUBLISHED_SUGGESTION_DESCRIPTION =
  "Don't worry! After you publish you will still have a chance to review Your Campaign before it starts running.";

export const ADS_NOT_PUBLISHED_SUGGESTION_INSTRUCTION =
  'Click Publish to send your Ads to Google Ad Manager.';
export const ADS_NOT_RUNNING_TITLE = 'Your Ads Are Not Running!';

/**
 * Health Header for Published But not Running
 */
export const PUBLISHED_BUT_NOT_RUNNING_DESCRIPTION =
  'Your campaign has been published to Google Ad Manager but it is not yet running.';

/**
 * Action Required Campaign is Manually Paused
 */
export const ACTION_REQUIRED_ENABLE_CAMPAIGN_TITLE = 'Enable Your Campaign';
export const ACTION_REQUIRED_ENABLE_CAMPAIGN_DESCRIPTION =
  'Your campaign is currently paused. Enable your campaign to start serving ads.';
export const ACTION_REQUIRED_ENABLE_CAMPAIGN_DOCS_HREF =
  'https://docs.outbound.com/Why-and-When-to-Enable-or-Pause-Your-Ads-185040e853e280c698aed115f75cd1b1';

/**
 * Action Required Due to Campaign Not having any deployments (Not being Published)
 */
export const ACTION_REQUIRED_PUBLISH_CAMPAIGN_TITLE = 'Publish Your Campaign';
export const ACTION_REQUIRED_PUBLISH_CAMPAIGN_DESCRIPTION =
  "You are almost ready to go! Publish your campaign to upload your Ads to Google's Ad Manager.";
export const ACTION_REQUIRED_PUBLISH_CAMPAIGN_DOCS_HREF =
  'https://docs.outbound.com/Publishing-Outbound-Campaigns-to-Ad-Channel-Campaigns-185040e853e280d682ebc0b2d9b1cdbb?pvs=4';

export const ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_TITLE =
  'Add Payment Method to Google Ads Account';
export const ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_DESCRIPTION =
  'You have not added a payment method to your Google Ads Account. Please add a payment method in order to run your ads.';
export const ACTION_REQUIRED_ADD_PAYMENT_METHOD_TO_GOOGLE_ADS_INTEGRATION_DOCS_HREF =
  'https://docs.outbound.com/why-do-i-need-to-pay-for-google-search-ads';

export const ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_TITLE = (
  email: string | null
) => `Accept Google Ads Admin invite sent to ${email ?? 'your email'}.`;
export const ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_DESCRIPTION =
  'You Have not accepted the invite to your Google Ads Account. You must accept this invite in order to add a payment method for your ad spend. If you have already done this click "Check Again".';
export const ACTION_REQUIRED_ACCEPT_GOOGLE_ADS_INVITE_DOCS_HREF =
  'https://docs.outbound.com/Why-You-Received-a-Google-Ads-Admin-Invite-and-What-It-Means-185040e853e280b9ad16f71f3cfece73';

export const ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_TITLE =
  'Setup Google Ads Integration';
export const ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_DESCRIPTION =
  'You have not setup your Google Ads Integration. Please setup your Google Ads Integration to publish your campaign.';
export const ACTION_REQUIRED_SETUP_GOOGLE_ADS_INTEGRATION_DOCS_HREF =
  'https://docs.outbound.com/integrations-google-ads-manager';

export const ONLY_ISSUE_IS_AD_CREATIVE_TITLE = 'Your Ad Creative Has Errors';
export const ONLY_ISSUE_IS_AD_CREATIVE_DESCRIPTION =
  'Your ads have errors. Please correct the errors before publishing.';

export const ACTION_REQUIRED_ADS_HAVE_ERRORS_TITLE = 'Fix Creative Errors';
export const ACTION_REQUIRED_ADS_HAVE_ERRORS_DESCRIPTION =
  'One or more of your ads has an error. Please correct the errors in order to publish.';

export const ACTION_REQUIRED_REPUBLISH_CAMPAIGN =
  'It appears that the campaign has been removed from the Ad Channel. This can be fixed by re-publishing your Campaign.';

export const ONLY_INTEGRATION_ISSUES_ADS_RUNNING_TITLE =
  'Your Google Ads Integration Needs Attention';
export const ONLY_INTEGRATION_ISSUES_ADS_RUNNING_DESC =
  'Your Ads appear to be running but your Google Ads Integration has issues that need to be resolved.';

export const AD_CHANNEL_REJECTED_ADS_TITLE =
  'You Ads are not running because the Ad Channel has rejected them.';
export const AD_CHANNEL_REJECTED_ADS_DESCRIPTION =
  'For some reason the Ad Channel has not approved your Ads. This can be a for a number of reasons. Log int the Ad Channel for more information.';

export const FIRST_DEPLOYMENT_FAILED_DESCRIPTION =
  'It appears that the deployment of your campaign has failed. We apologize for the inconvenience and ask that you contact support so we can assist!';

export const ACTION_REQUIRED_CONTACT_SUPPORT_FOR_FIRST_DEPLOYMENT_FAILURE_TITLE =
  'Contact support to troubleshoot your campaign deployment.';
export const ACTION_REQUIRED_CONTACT_SUPPORT_FOR_FIRST_DEPLOYMENT_FAILURE_DESCRIPTION =
  'Your first campaign deployment failed. We are here to help! Please contact support to troubleshoot the issue.';

/**
 * Informative
 */
export const INFORMATIVE_ADS_BEING_GENERATED_TITLE =
  'We are generating Google Search ads that highlight your Service and each Service Offering';
export const INFORMATIVE_ADS_BEING_GENERATED_DESCRIPTION =
  'You can publish to Google once your ads are ready, Ad generation takes about 30 seconds!';

export const INFORMATIVE_ADS_BEING_GENERATED_INSTRUCTIONS =
  'Your Ads are Generating Below. Scroll down to view each one in your Campaign Highlights. Once your Happy with your ads publish them to Google!';

export const INFORMATIVE_ADS_BEING_GENERATED_VIEW_CREATIVE_TITLE =
  'View Your Google Search Ads';
export const INFORMATIVE_ADS_BEING_GENERATED_VIEW_CREATIVE_DESCRIPTION =
  'We are generating 12 Headlines and 4 Description for Each Service and Service Offering.';

export const INFORMATIVE_ADS_BEING_GENERATED_PUBLISH_AFTER_CREATIVE_GEN_TITLE =
  'Publish to Google';
export const INFORMATIVE_ADS_BEING_GENERATED_PUBLISH_AFTER_CREATIVE_GEN_DESCRIPTION =
  'Once Google has Approved Your Ads they should start running';

export const ONLY_INTEGRATION_ISSUES_ADS_NOT_RUNNING_TITLE =
  ADS_NOT_RUNNING_TITLE;
export const ONLY_INTEGRATION_ISSUES_ADS_NOT_RUNNING_DESC =
  'Your Google Ads Integration has issues that need to be resolved before your campaign can run.';

export const CAMPAIGN_IS_BEING_DEPLOYED_TITLE =
  'Your Campaign is being created on the Ad Channel';
export const CAMPAIGN_IS_BEING_DEPLOYED_DESCRIPTION =
  'Your campaign is being sent from Outbound to the Ad Channel. This may take a few moments!';

export const CAMPAIGN_IS_BEING_REVIEWED_BY_AD_CHANNEL_BEFORE_RUNNING_TITLE =
  'Ads Not Running Yet';
export const CAMPAIGN_IS_BEING_REVIEWED_BY_AD_CHANNEL_BEFORE_RUNNING_DESCRIPTION =
  'Your ads are not running yet, they are pending approval by the Ad Channel. Once the Ad Channel approves they will begin to run.';
/**
 * Nominal
 */
export const NOMINAL_ADS_RUNNING_TITLE = 'Your Ads Are Running!';
export const NOMINAL_ADS_RUNNING_DESCRIPTION =
  'Your Campaign is serving ads and no problems have been detected.';

/**
 * Not Applicable
 */
export const NOT_APPLICABLE_TITLE = 'Health Status Not Available';
export const NOT_APPLICABLE_DESCRIPTION =
  'The health status of this campaign is not available.';
