/**
 * Helper function to check if a string has content
 * @param str
 * @returns
 */
export const stringHasContent = (str: string | undefined): boolean => {
  return str !== undefined && str !== null && str.trim() !== '';
};

/**
 * Helper Function to truncate text to a specified length.
 *
 * @param text
 * @param maxLength
 * @returns
 */
export const truncateText = (
  text: string,
  maxLength: number,
  includeEllipsis = true
): { result: string; originalText: string; isTruncated: boolean } => {
  const textGreaterThanMaxLength = text.length > maxLength;
  const truncatedText = textGreaterThanMaxLength
    ? `${text.slice(0, maxLength)}${includeEllipsis ? '...' : ''}`
    : text;

  return {
    originalText: text,
    result: truncatedText,
    isTruncated: textGreaterThanMaxLength,
  };
};

/**
 * Formats a string to ensure proper spacing after commas.
 * @param {string} input - The input string to be formatted.
 * @returns {string} - The formatted string with corrected spacing.
 */
export const formatCommaSpacing = (input: string): string => {
  return input.replace(/,([^\s])/g, ', $1');
};

/**
 * Formats a string to ensure proper capitalization.
 * - Converts fully capitalized words to Title Case (e.g., "HELLO" -> "Hello").
 * - Preserves words already in Title Case.
 * - Handles hyphenated words properly.
 * - Handles initialized words properly.
 * @param {string} input - The input string to be formatted.
 * @returns {string} - The formatted string with corrected capitalization.
 */
export const formatExcessiveCapitalization = (input: string): string => {
  return input
    .split(' ')
    .map((word) => {
      // Preserve words already in Title Case (e.g., "Hello", "World")
      if (
        word.charAt(0) === word.charAt(0).toUpperCase() &&
        word.slice(1) === word.slice(1).toLowerCase()
      ) {
        return word;
      }

      // Handle hyphenated words separately
      if (word.includes('-')) {
        return word
          .split('-')
          .map((part) => formatExcessiveCapitalization(part)) // Recursive call for each part
          .join('-');
      }

      // Handle initialized words separately
      if (word.includes('.')) {
        return word
          .split('.')
          .map((part) => formatExcessiveCapitalization(part)) // Recursive call for each part
          .join('.');
      }

      // Convert fully uppercase words to Title Case (HELLO -> Hello)
      if (word === word.toUpperCase()) {
        return word.charAt(0) + word.slice(1).toLowerCase();
      }

      return word; // If it doesn't match any case, return unchanged
    })
    .join(' ');
};
