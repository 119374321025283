class EventActor {
  public readonly obrn: string;
  public readonly id: string;
  public readonly name: string;
  public avatarImageUrl: string | null;
  public readonly type: EventActorType;

  private constructor({
    obrn,
    id,
    name,
    type,
    avatarImageUrl,
  }: {
    obrn: string;
    id: string;
    name: string;
    type: EventActorType;
    avatarImageUrl: string | null;
  }) {
    this.obrn = obrn;
    this.id = id;
    this.name = name;
    this.type = type;
    this.avatarImageUrl = avatarImageUrl;
  }

  public static fromUser({
    firstName,
    lastName,
    email,
    id,
    obrn,
    avatarImageUrl,
  }: {
    firstName: string | null;
    lastName: string | null;
    email: string;
    id: string;
    obrn: string;
    avatarImageUrl: string | null;
  }): EventActor {
    let name = '';
    if (firstName == null && lastName == null) {
      name = email;
    } else {
      name = `${firstName || ''} ${lastName || ''}`.trim();
    }
    return new EventActor({
      obrn,
      id,
      name: name,
      type: 'USER',
      avatarImageUrl,
    });
  }

  public static fromSystem({
    id,
    name,
    avatarImageUrl,
  }: {
    id: string;
    name: string;
    avatarImageUrl: string | null;
  }): EventActor {
    return new EventActor({
      obrn: 'system',
      id,
      name: name,
      type: 'SYSTEM',
      avatarImageUrl,
    });
  }

  public static fromNotFoundUser({ obrn }: { obrn: string }): EventActor {
    return new EventActor({
      obrn: obrn,
      id: 'not-found',
      name: 'Deleted User',
      type: 'USER',
      avatarImageUrl: null,
    });
  }
}

export default EventActor;
export type EventActorType = 'USER' | 'SYSTEM';
