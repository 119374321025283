import { ReactNode } from 'react';

export interface Props {
  isLastFetchedEvent: boolean;
  children?: ReactNode;
}

export const BaseEvent = ({ isLastFetchedEvent, children }: Props) => {
  return (
    <li className='group relative pb-8 last:pb-0'>
      <div data-last-event={isLastFetchedEvent}>
        <VerticalLineConnector />
        <div className='relative flex items-start space-x-3'>
          <>{children}</>
        </div>
      </div>
    </li>
  );
};

const VerticalLineConnector = () => {
  return (
    <span
      aria-hidden='true'
      className='absolute top-5 left-5 -ml-px h-full w-[1px] bg-dark/border/default/normal group-last:hidden'
    />
  );
};
