import { formatDistance, formatDistanceStrict, parseISO } from 'date-fns';
import { format as formatWithTz, utcToZonedTime } from 'date-fns-tz';

const getUserTimeZone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isoStringToDateAndTime = (
  iso8601String: string,
  timeZone?: string
): string => {
  try {
    if (iso8601String == null || iso8601String.trim().length === 0) {
      return '';
    }
    const tz = timeZone ?? getUserTimeZone();
    const date = parseISO(iso8601String);
    const zonedDate = utcToZonedTime(date, tz);
    return formatWithTz(zonedDate, "MMMM d, yyyy 'at' h:mmaaa zzz", {
      timeZone: tz,
    });
  } catch (error) {
    return iso8601String; // Return the original string if there is an error
  }
};

export const timeAgoFromIsoString = (iso8601String: string): string => {
  try {
    if (iso8601String == null || iso8601String.trim().length === 0) {
      return '';
    }
    return formatDistance(parseISO(iso8601String), new Date(), {
      addSuffix: true,
    });
  } catch (error) {
    return iso8601String; // Return the original string if there is an error
  }
};

export const timeAgoFromIsoStringWithAbbreviatedLabel = (
  iso8601String: string
): string => {
  try {
    if (iso8601String == null || iso8601String.trim().length === 0) {
      return '';
    }
    const result = formatDistanceStrict(parseISO(iso8601String), new Date(), {
      roundingMethod: 'floor',
    });

    const mapping: Record<string, string> = {
      second: 's',
      seconds: 's',
      minute: 'min',
      minutes: 'min',
      hour: 'h',
      hours: 'h',
      day: 'd',
      days: 'd',
      month: 'm',
      months: 'm,',
      year: 'y',
      years: 'y',
    };

    const [value, unit] = result.split(' ');
    const shortUnit = mapping[unit] || unit;

    return `${value}${shortUnit} ago`;
  } catch (error) {
    return iso8601String; // Return the original string if there is an error
  }
};
