import { ReactNode } from 'react';

export interface Props {
  ariaLabeledBy?: string;
  children: ReactNode;
}

export const LeadTimeline = ({ ariaLabeledBy, children }: Props) => {
  return (
    <div className='flow-root'>
      <ul
        aria-labelledby={ariaLabeledBy}
        className='-mb-8'
      >
        {children}
      </ul>
    </div>
  );
};
