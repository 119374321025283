import { ListResponse, WorkspaceResource } from '@outbound/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { BaseTransport } from '../base-transport';
import { Transport } from '../transport';

class WorkspaceTransporter extends BaseTransport<WorkspaceResource> {
  constructor(transport: Transport, axiosInstance: AxiosInstance) {
    super(transport, axiosInstance);
  }

  protected async fetchById(id: string): Promise<WorkspaceResource | null> {
    console.log('fetchById', id);
    /**
     * We don't currently have an endpoint to fetch a workspace by ID.
     * We only allow a user to fetch their own workspace.
     */
    const workspaceListResource = await this._axiosInstance.get<
      void,
      AxiosResponse<ListResponse<WorkspaceResource>>
    >('/my-workspaces');

    console.log('Fetched Workspaces', workspaceListResource.data.items);

    if (workspaceListResource && workspaceListResource.data.items.length > 0) {
      const targetWorkspace =
        workspaceListResource.data.items.find(
          (workspace) => workspace.id === id
        ) ?? null;

      console.log('Target Workspace', targetWorkspace);
      return targetWorkspace;
    } else {
      return null;
    }
  }

  public acceptEmbeddedResource(resource: WorkspaceResource): void {
    this.notifyStoreOfServiceUpdateCallback?.(resource.id, resource);
  }

  public async requestResourceById(id: string): Promise<void> {
    const resource = await this.fetchById(id);
    console.log('requestResourceById', id, resource);
    this.updateSubjectWithResourceUpdate(id, resource);
    // this.notifyStoreOfServiceUpdateCallback?.(id, resource);
  }

  protected async fetchAll(): Promise<void> {
    const workspaceListResource = await this._axiosInstance.get<
      void,
      AxiosResponse<ListResponse<WorkspaceResource>>
    >('/my-workspaces');

    workspaceListResource.data.items.forEach((workspace) => {
      this.updateSubjectWithResourceUpdate(workspace.id, workspace);
    });
  }

  async internalBootstrap(): Promise<void> {
    await this.fetchAll();
  }
}

export default WorkspaceTransporter;
