import { TouchSensor, useSensor } from '@dnd-kit/core';

export const useTouchSensor = () => {
  const touchSensor = useSensor(
    TouchSensor,
    configureTouchSensorToRequireALongPressBeforeActivating()
  );
  return touchSensor;
};

const configureTouchSensorToRequireALongPressBeforeActivating = (
  millisecondsToPressBeforeActivating: number = 100,
  amountOfPxThatATouchCanMovedWhileStillCountingAsAPress: number = 5
) => {
  return {
    activationConstraint: {
      delay: millisecondsToPressBeforeActivating,
      tolerance: amountOfPxThatATouchCanMovedWhileStillCountingAsAPress,
    },
  };
};
