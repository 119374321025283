import { ObButton, ObGridHeader } from '@outbound/design-system';

import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewCardObserver } from 'src/object-card/review/review-card-observer';
import { useCreateEditReviewDrawer } from 'src/pages/dashboard/reviews/create-edit-drawer/use-review-create-edit-drawer';
import { useRootStore } from 'src/state/mobx-experiment/use-root-store';
import { FullPageErrorMessage } from '../../campaigns/components/full-page-error-message';
import { DashboardRouteParams } from '../../dashboard-route-param.type';
import t from '../reviews.i18n.json';

export const ReviewListPage = observer(() => {
  const { workspaceSlug } = useParams<DashboardRouteParams>();
  const { reviews } = useRootStore();

  const { openDrawerInCreateMode } = useCreateEditReviewDrawer(workspaceSlug!);

  const allReviews = reviews.list();

  if (!workspaceSlug) {
    return <></>;
  }

  if (allReviews.length === 0) {
    return (
      <FullPageErrorMessage
        icon={'squaresPlus'}
        heading={t.NO_REVIEWS_IN_WORKSPACE_CTA.HEADING}
        message={t.NO_REVIEWS_IN_WORKSPACE_CTA.DESCRIPTION}
        actionLabel={t.NO_REVIEWS_IN_WORKSPACE_CTA.CTA}
        actionOnClick={openDrawerInCreateMode}
      />
    );
  }

  return (
    <VerticalScrollableContent>
      <div className='max-w-screen-lg w-full mx-auto  flex @container/page'>
        <div className='flex flex-col p-6 h-full flex-1'>
          <header>
            <ObGridHeader
              title={t.REVIEW_MODULE_HEADING}
              count={allReviews.length}
              controls={
                <ObButton
                  label={t.NEW_REVIEW_CTA}
                  size='large'
                  variant='outline'
                  onClick={openDrawerInCreateMode}
                />
              }
            />
          </header>
          <div className='flex sm:hidden py-3'>
            <ObButton
              label={t.NEW_REVIEW_CTA}
              size='medium'
              variant='outline'
              onClick={openDrawerInCreateMode}
            />
          </div>
          <div className='pt-8 grid grid-cols-1 gap-6 @2xl/page:grid-cols-2 @4xl/page:grid-cols-3 '>
            {allReviews.map((review) => (
              <ReviewCardObserver
                key={review.obrn}
                variant='grid'
                review={review}
              />
            ))}
          </div>
        </div>
      </div>
    </VerticalScrollableContent>
  );
});

const VerticalScrollableContent = ({ children }: { children: ReactNode }) => {
  return <div className='h-screen overflow-y-scroll'>{children}</div>;
};
