import { ListResponse, WorkspaceResource } from '@outbound/types';
import { useState } from 'react';
import { UseQueryResult, useQuery } from 'react-query';
import { useAuth0Axios } from '../../services/auth0-axios-provider';

export const useFetchMyWorkspaces = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, ListResponse<WorkspaceResource>>(
    ['my-workspaces:get'],
    async () => {
      const response = await auth0AxiosClient.get('/my-workspaces');
      return response.data;
    },
    {
      onSuccess: () => {
        // Additional actions on query success
      },
    }
  );
};

export const usePollForMyWorkspaces = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  // Track the polling start time
  const startPollingTime = Date.now();
  const pollingDuration = 30000; // 30 seconds in milliseconds

  return useQuery<undefined, undefined, ListResponse<WorkspaceResource>>(
    ['my-workspaces:get'],
    async () => {
      const response = await auth0AxiosClient.get('/my-workspaces');
      return response.data;
    },
    {
      refetchInterval: (data?: ListResponse<WorkspaceResource>) => {
        const elapsedTime = Date.now() - startPollingTime;

        // Stop polling after the specified duration (30 seconds)
        if (elapsedTime > pollingDuration) return false;

        const shouldPoll = data?.items?.length === 0;
        return shouldPoll ? 2000 : false;
      },
      refetchIntervalInBackground: true,
      onSuccess: () => {
        // Additional actions on query success
      },
    }
  );
};

export interface UsePollForTargetWorkspaceQueryResult {
  useQueryOutput: UseQueryResult<ListResponse<WorkspaceResource>, undefined>;
  pollsRemaining: number;
  isPollingForWorkspaceInProgress: boolean;
}

const getPollingStrategy = (workspaceIdToLoginTo: string | null) => {
  let pollTestFunc: (
    response: ListResponse<WorkspaceResource> | undefined
  ) => boolean = (response: ListResponse<WorkspaceResource> | undefined) => {
    return (
      response == null || response.items == null || response.items.length === 0
    );
  };

  if (workspaceIdToLoginTo != null) {
    /**
     * Poll till we find the workspace we want to login to
     */

    pollTestFunc = (response: ListResponse<WorkspaceResource> | undefined) => {
      const workspace = response?.items.find(
        (workspace) => workspace.id === workspaceIdToLoginTo
      );
      return workspace == null;
    };
  }

  return pollTestFunc;
};

export const usePollForTargetWorkspace = ({
  workspaceId,
  maxPollCount,
  refreshInterval,
}: {
  /**
   * The workspaceId to poll for (Optional)
   * If Not provided, the endpoint will poll instead for a non-empty workspace set
   */
  workspaceId: string | null;
  /**
   * The maximum number of times to poll for the target workspace
   * If -1 is provided, the endpoint will not be called at all
   * If 0 is provided, the endpoint will be called once
   * */
  maxPollCount: number;
  /**
   * The interval in milliseconds to poll for the target workspace
   * Default is 2000 milliseconds
   * */
  refreshInterval?: number;
}): UsePollForTargetWorkspaceQueryResult => {
  const finalRefreshInterval = refreshInterval ?? 2000;
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  const [pollsRemaining, setPollsRemaining] = useState<number>(maxPollCount);
  const [shouldPoll, setShouldPoll] = useState<boolean>(maxPollCount !== -1);

  const useQueryOutput: UseQueryResult<
    ListResponse<WorkspaceResource>,
    undefined
  > = useQuery<undefined, undefined, ListResponse<WorkspaceResource>>(
    ['my-workspaces:get'],
    async () => {
      const response = await auth0AxiosClient.get('/my-workspaces');
      return response.data;
    },
    {
      enabled: maxPollCount != -1,
      refetchInterval: () => {
        return shouldPoll ? finalRefreshInterval : false;
      },
      refetchIntervalInBackground: true,
      onSuccess: (data) => {
        const shouldContinuePolling = getPollingStrategy(workspaceId)(data);
        setPollsRemaining((prev) => {
          const newPollsRemaining = prev - 1;
          const newShouldPoll = newPollsRemaining > 0 && shouldContinuePolling;
          setShouldPoll(newShouldPoll);
          return newPollsRemaining;
        });
      },
    }
  );

  return {
    useQueryOutput,
    pollsRemaining,
    isPollingForWorkspaceInProgress: shouldPoll,
  };
};

export const useFetchMyWorkspacesOnDemand = () => {
  const { axiosInstance: auth0AxiosClient } = useAuth0Axios();

  return useQuery<undefined, undefined, ListResponse<WorkspaceResource>>(
    ['my-workspaces:get-on-demand'],
    async () => {
      const response = await auth0AxiosClient.get('/my-workspaces');
      return response.data;
    },
    {
      enabled: false,
      onSuccess: () => {
        // Additional actions on query success
      },
    }
  );
};
