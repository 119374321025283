import { NotificationContent } from '@outbound/design-system';
import { AxiosInstance } from 'axios';
import { Observable, Subscription } from 'rxjs';
import { AssetStore } from './assets/asset-store';
import CustomerProfileStore from './business-context/customer-profile/customer-profile-store';
import LocationStore from './business-context/location/location-store';
import ReviewStore from './business-context/review/review-store';
import ServiceStore from './business-context/service/service-store';
import CampaignStore from './campaign/campaign-store';
import CreativeStore from './creative/creative-store';
import { TemplateStore } from './creative/template/template-store';
import LandingPageStore from './landing-page/landing-page-store';
import LeadStore from './leads/lead-store';
import { RootStoreAuthenticationState } from './root-store-provider';
import { Transport } from './transport';
import User from './user/user';
import UserStore from './user/user-store';
import IntegrationConfigurationStore from './workspace/integration-configuration/integration-configuration-store';
import IntegrationStore from './workspace/integration/integration-store';
import MembershipStore from './workspace/membership/membership-store';
import WorkspaceStore from './workspace/workspace-store';

/**
 * The primary client side state stor for the application
 */
export class RootStore {
  private rootStoreId: string;
  private authenticatedUserId: string | null = null;
  readonly pushNotification: (
    notification: NotificationContent
  ) => Promise<void>;
  private environment: string;
  private readonly INIT_DELAY: number = 1000;
  private initTimeoutId: ReturnType<typeof setTimeout> | null = null;
  private _clientId: string = 'abc-123';
  private _transport: Transport;
  private _campaignStore: CampaignStore;
  private _creativeStore: CreativeStore;
  private _serviceStore: ServiceStore;
  private _locationStore: LocationStore;
  private _customerProfileStore: CustomerProfileStore;
  private _templateStore: TemplateStore;
  private _assetStore: AssetStore;
  private _integrationStore: IntegrationStore;
  private _integrationConfigurationStore: IntegrationConfigurationStore;
  private _workspaceStore: WorkspaceStore;
  private _userStore: UserStore;
  private _membershipStore: MembershipStore;
  private _landingPageStore: LandingPageStore;
  private _authenticationState: Observable<RootStoreAuthenticationState>;
  private _authenticatedStateSubscription: Subscription;
  readonly reviews: ReviewStore;
  readonly leads: LeadStore;

  /**
   *
   * @param transport
   * @param authenticatedUserId Null if there is no authenticated user
   */
  constructor(
    transport: Transport,
    pushNotification: (notification: NotificationContent) => Promise<void>,
    bootstrap: boolean = true,
    authenticationState: Observable<RootStoreAuthenticationState>
  ) {
    this._authenticationState = authenticationState;

    this.pushNotification = pushNotification;

    // Generate a short random ID for the root store (Used for debugging)
    this.rootStoreId = Math.random().toString(36).substring(7);
    console.log('Creating Root Store', this.rootStoreId);

    console.log('Root Store Authenticated User ID', this.authenticatedUserId);

    this._transport = transport;
    this._workspaceStore = new WorkspaceStore(
      this,
      this._transport.workspaceTransport
    );
    this._userStore = new UserStore(this, this._transport.userTransport);
    this._membershipStore = new MembershipStore(this);
    this._campaignStore = new CampaignStore(
      this,
      this._transport.campaignTransport
    );
    this._creativeStore = new CreativeStore(
      this,
      this._transport.creativeTransport
    );
    this._serviceStore = new ServiceStore(
      this,
      this._transport.serviceTransport
    );
    this._locationStore = new LocationStore(
      this,
      this._transport.locationTransport
    );
    this._customerProfileStore = new CustomerProfileStore(this);
    this._templateStore = new TemplateStore(this);
    this._assetStore = new AssetStore(this);
    this._integrationStore = new IntegrationStore(this);
    this._integrationConfigurationStore = new IntegrationConfigurationStore(
      this
    );
    this._landingPageStore = new LandingPageStore(
      this,
      this._transport.landingPageTransport
    );

    this.reviews = new ReviewStore(this, this._transport.reviewTransport);
    this.leads = new LeadStore(this, this._transport.leadTransport);

    this.environment = 'dev';

    this._authenticatedStateSubscription = this._authenticationState.subscribe(
      (state) => {
        if (this.authenticatedUserId !== state.authenticatedUserId) {
          this.authenticatedUserId = state.authenticatedUserId;
          console.log(
            'Root Store Authenticated User ID',
            this.authenticatedUserId
          );
          /**
           * This timeout is a bit of a smell and is only here because sometimes the React app reloads a few times before
           * It stabilizes. This timeout says "I don' trust my caller"
           * Now that we have the Auth state in an observable, we may be able to remove this timeout and rely solely on the observable.
           * If we need to withhold authenticated state from the root store until the app has "settled" that can be done at the react level.
           *
           * This would also allow us to remove the "bootstrap" constructor argument.
           */
          if (bootstrap) {
            this.initTimeoutId = setTimeout(() => {
              this.initialize();
              this.initTimeoutId = null; // Clear the timeout ID after bootstrapping
            }, this.INIT_DELAY);
          }
        } else if (this.authenticatedUserId == null) {
          console.log('Root Store Authenticated User ID is NULL');
        }
      }
    );
  }

  public async initialize() {
    console.log('Initializing Root Store ', this.rootStoreId);

    /**
     * Ideally at some point we will store data locally and not need to do a full bootstrap
     * on each app init. This is something we would like to do as a future optimization.
     *
     * If you neglect to call this method and you call list() on a store, you will get an a promise thrown that will trigger suspense in the UI and never resolve.
     *
     * We may want to consider a more sophisticated way to handle this in the future
     * such as a register method or something instead of needing to create a transport for every model / resource
     */
    this._transport.initializeBusinessContext();
    this._transport.assetTransport.bootstrap(); //Load all assets from server
    this._transport.integrationTransport.bootstrap();
    this._transport.workspaceTransport.bootstrap();
    this._transport.membershipTransport.bootstrap();
    this._transport.userTransport.bootstrap();
    this._transport.integrationConfigurationTransport.bootstrap();
    this._transport.customerProfileTransport.bootstrap();
    this._transport.locationTransport.bootstrap();
    this._transport.serviceTransport.bootstrap();
    this._transport.creativeTransport.bootstrap();
    this._transport.campaignTransport.bootstrap();
    this._transport.landingPageTransport.bootstrap();
    this._transport.reviewTransport.bootstrap();
    this._transport.leadTransport.bootstrap();
  }

  public destroy(): void {
    /**
     * Temporary fix to prevent heavy initialization from being called during the React
     * app initialization. I noticed during development that the store is being created and torn down
     * several times on app bootstrap. This wasn't an issue until I started to make a bunch of API calls
     * to bootstrap all the stores.
     * Ideally we should solve the root cause of the issue, but for now this is a quick fix.
     */
    if (this.initTimeoutId) {
      console.log('Canceling Root Store Initialization', this.rootStoreId);
      clearTimeout(this.initTimeoutId);
      this.initTimeoutId = null; // Clear the timeout ID
    }
    console.log('Destroying Root Store', this.rootStoreId);
    this._transport?.destroy();
    this._campaignStore?.dispose();
    this._landingPageStore?.dispose();
    this._locationStore?.dispose();
    this._creativeStore?.dispose();
    this.reviews.dispose();
    this.leads.dispose();
    this.userStore.dispose();
  }
  get clientId(): string {
    return this._clientId;
  }

  get transport(): Transport {
    return this._transport;
  }

  get creativeStore(): CreativeStore {
    return this._creativeStore;
  }

  get campaignStore(): CampaignStore {
    return this._campaignStore;
  }

  get serviceStore(): ServiceStore {
    return this._serviceStore;
  }

  get locationStore(): LocationStore {
    return this._locationStore;
  }

  get customerProfileStore(): CustomerProfileStore {
    return this._customerProfileStore;
  }

  get templateStore(): TemplateStore {
    return this._templateStore;
  }

  get assetStore(): AssetStore {
    return this._assetStore;
  }

  get integrationStore(): IntegrationStore {
    return this._integrationStore;
  }

  get workspaceStore(): WorkspaceStore {
    return this._workspaceStore;
  }

  get membershipStore(): MembershipStore {
    return this._membershipStore;
  }

  get integrationConfigurationStore(): IntegrationConfigurationStore {
    return this._integrationConfigurationStore;
  }

  get userStore(): UserStore {
    return this._userStore;
  }

  get landingPageStore(): LandingPageStore {
    return this._landingPageStore;
  }

  get env(): string {
    return this.environment;
  }

  get authenticatedUser(): User | null {
    if (this.authenticatedUserId == null) {
      return null;
    }
    return this.userStore.getById(this.authenticatedUserId)!;
  }
}

/**
 * Called by the Root Store React Context to Setup the Root Store for the Application
 *
 * @param axiosInstance
 * @returns
 */
export const createRootStore = (
  axiosInstance: AxiosInstance,
  pushNotification: (notification: NotificationContent) => Promise<void>,
  authenticationState: Observable<RootStoreAuthenticationState>
): RootStore => {
  const transport = new Transport(axiosInstance);
  return new RootStore(transport, pushNotification, true, authenticationState);
};
