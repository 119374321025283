import { LeadQualificationStatus } from '@outbound/types';
import { cva, cx } from 'class-variance-authority';

export interface Props {
  size: 'xSmall' | 'small' | 'medium';
  qualificationStatus: LeadQualificationStatus;
}

const iconStyles = cva('rounded-full', {
  variants: {
    size: {
      xSmall: ['w-[14px] h-[14px] max-h-[14px] max-w-[14px]'],
      small: ['w-[16px] h-[16px] max-h-[16px] max-w-[16px]'],
      medium: ['w-[24px] h-[24px] max-h-[24px] max-w-[24px] mt-[4px] mr-[2px]'],
    },
    qualificationStatus: {
      QUALIFIED: ['bg-dark/contextual/positive'],
      UNQUALIFIED: ['bg-dark/contextual/negative'],
      NURTURE: ['bg-dark/contextual/warning'],
      WORKING: ['bg-dark/non-contextual/yellow'],
      NEW: ['bg-dark/contextual/informative'],
    },
  },
});

export const LeadQualificationStatusIcon = ({
  size,
  qualificationStatus,
}: Props) => {
  return <div className={cx(iconStyles({ size, qualificationStatus }))}></div>;
};
