import { LeadQualificationStatus } from '@outbound/types/src/lead/api-resource/lead-qualification-status';
import t from '../leads.i18n.json';

export const convertQualificationStatusToLabel = (
  status: LeadQualificationStatus
): string => {
  switch (status) {
    case 'NEW':
      return t.KANBAN_BOARD.NEW_COLUMN_NAME;
    case 'WORKING':
      return t.KANBAN_BOARD.WORKING_COLUMN_NAME;
    case 'NURTURE':
      return t.KANBAN_BOARD.NURTURE_COLUMN_NAME;
    case 'UNQUALIFIED':
      return t.KANBAN_BOARD.NOT_QUALIFIED_COLUMN_NAME;
    case 'QUALIFIED':
      return t.KANBAN_BOARD.QUALIFIED_COLUMN_NAME;
    default:
      console.warn('Unknown qualification status', status);
      return status;
  }
};
