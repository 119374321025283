import { toObrn } from '@otbnd/utils';
import { IntegrationConfigurationType } from '@outbound/types';
import { IntegrationConfigurationResource } from '@outbound/types/src/workspace/integration-configuration/integration-configuration-resource.type';
import { action } from 'mobx';
import { BaseStore } from '../../base-store';
import { RootStore } from '../../root-store';
import IntegrationConfigGoogleAds from './extended/integration-configuration-google-ads';
import IntegrationConfiguration from './integration-configuration';
import IntegrationConfigurationTransformer from './integration-configuration-transformer';
import IntegrationConfigurationValue from './integration-configuration-value.type';

class IntegrationConfigurationStore extends BaseStore<
  IntegrationConfiguration,
  IntegrationConfigurationResource
> {
  private _integrationConfigurationTransformer: IntegrationConfigurationTransformer;
  constructor(rootStore: RootStore) {
    super(
      rootStore,
      'integration-configuration',
      rootStore.transport.integrationConfigurationTransport
    );
    this._integrationConfigurationTransformer =
      new IntegrationConfigurationTransformer(rootStore);
    this.rootStore.transport.integrationConfigurationTransport.registerServerUpdateCallbackHandler(
      this.handleServerUpdate.bind(this)
    );
  }

  private handleServerUpdate = (
    id: string,
    resource: IntegrationConfigurationResource | null
  ): void => {
    this.handleServerUpdateGeneric(
      id,
      resource,
      this._integrationConfigurationTransformer
    );
  };

  @action
  public runHealthChecks(id: string): void {
    this.rootStore.transport.integrationConfigurationTransport.runHealthChecks(
      id as IntegrationConfigurationType
    );
  }

  /**
   * Optimistically delete the Integration Configuration from the store and send the request to the server.
   * @param id
   */
  @action
  public async delete(id: string) {
    const model = this.modelMap.get(id);
    if (model) {
      this.optimisticDeleteModel(id);
      try {
        await this.rootStore.transport.integrationConfigurationTransport.deleteIntegrationConfiguration(
          id as IntegrationConfigurationType
        );
      } catch (error) {
        //TODO THIS IS NOT TRIGGERING A RE-RERENDER IN THE UI. FIX THIS
        console.error('Error Deleting Integration Configuration', error);
        this.undoOptimisticDeleteModel(id);
        //Snackbar or Toast Error
      }
    }
  }

  /**
   * Creates a new Integration Configuration for the workspace.
   * @param type
   * @param userInput
   * @param workspaceId
   */
  @action
  public create(
    type: IntegrationConfigurationType,
    userInput: any,
    workspaceId: string
  ): void {
    /**
     * Optimistic Model Creation. Set the statuses to the know values for a new Integration Configuration in
     * the initializing state.
     */
    const baseModelValue: IntegrationConfigurationValue = {
      integrationId: type,
      type: type,
      configuredByUserId: this.rootStore.authenticatedUser!.id,
      configuredAtTimestamp: new Date().toISOString(),
      lifeCycleStatus: 'INITIALIZING',
      operationalStatus: 'UNAVAILABLE',
      healthCheckLastCompletedAtTimestamp: undefined, //Health Checks have not been run yet
      healthCheckItemLatestExecutionsMap: new Map(), //No Health Checks have been run yet
    };
    let optimisticIntegration: IntegrationConfiguration;
    switch (type) {
      case 'GOOGLE_AD_MANAGER': {
        console.log('Creating Google Ads Integration Configuration');
        optimisticIntegration = new IntegrationConfigGoogleAds(
          this.rootStore,
          type,
          toObrn({
            workspaceId: workspaceId,
            localPathId: type,
            objectType: 'integration-configuration',
          }),
          {
            ...baseModelValue,
            ...userInput,
          }
        );
        break;
      }
      case 'GOOGLE_AD_MANAGER_GRANT':
      case 'META_AD_MANAGER':
      default: {
        console.log('Creating Generic Integration Configuration');
        optimisticIntegration = new IntegrationConfiguration(
          this.rootStore,
          type,
          toObrn({
            workspaceId: workspaceId,
            localPathId: type,
            objectType: 'integration-configuration',
          }),
          {
            ...baseModelValue,
            ...userInput,
          }
        );
      }
    }

    optimisticIntegration.makeObservable();
    this.modelMap.set(type, optimisticIntegration);
    this.createOptimisticSyncMetaForNewModel(type, optimisticIntegration);
    this.rootStore.transport.integrationConfigurationTransport.createNewIntegrationConfiguration(
      type,
      userInput
    );
  }

  protected async requestLoadModelFromServer(id: string): Promise<void> {
    return this.rootStore.transport.integrationConfigurationTransport.requestResourceById(
      id
    );
  }
}

export default IntegrationConfigurationStore;
