import { ObrnObject } from '@outbound/types';
import { UNABLE_TO_FETCH_DATA_FROM_SERVER_MESSAGE } from './content';

class UnableToFetchDataFromServerError extends Error {
  readonly object: ObrnObject;
  readonly id: string;
  constructor({ object, id }: { object: ObrnObject; id: string }) {
    super(UNABLE_TO_FETCH_DATA_FROM_SERVER_MESSAGE);
    this.name = 'UnableToFetchDataFromServerError';
    this.object = object;
    this.id = id;
    // Maintains proper stack trace (only available on V8 engines like Chrome)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnableToFetchDataFromServerError);
    }
  }
}

export { UnableToFetchDataFromServerError };
