import { LandingPageType } from '@outbound/types';
import { cx } from 'class-variance-authority';
import { ObCardSurface } from '../../cards/components/card-surface/ob-card-surface';
import { ObButton } from '../../components/elements/ob-button/ob-button';
import { ObLoadingSpinner } from '../../components/elements/ob-loading-spinner/ob-loading-spinner';
import { ObTypography } from '../../components/elements/ob-typography/ob-typography';
import { CardVariantProps } from '../card.type';
import placeholder from './components/landing-page-placeholder.png';
import { PageTitle } from './components/page-title';
import { UrlFormatted } from './components/url-formatted';

/**
 * What Calls to Action should Appear on the Cards?
 * (Note that all CTAs may not appear on all cards, the larger the card the more CTAs)
 * A CTA that appears on a card should appear on all cards larger than it
 */
export interface LandingPageCardCtaProps {
  onPreviewCallback: () => void;
  onCardClickedCallback: (obrn: string) => void;
}

/**
 * What Attributes should appear on the Landing Page Cards?
 */
export interface LandingPageCardAttributeProps {
  /**
   * The Outbound Resource Name of the landing page
   */
  obrn: string;
  /**
   * The Title of the Landing Page
   * (Used to uniquely identify the landing page)
   */
  pageTitle: string;
  /**
   * The URL of the Landing Page including domain and path
   */
  url: string;
  /**
   * The type of landing page
   */
  landingPageType: LandingPageType;
  /**
   * A publicly accessible URL to the screenshot of the landing page
   */
  landingPageScreenshotPublicUrl?: string;
}

export interface LandingPageCardProps
  extends CardVariantProps,
    LandingPageCardCtaProps,
    LandingPageCardAttributeProps {}

type LandingPageCardImplementationProps = Omit<LandingPageCardProps, 'variant'>;

export const LandingPageCard = ({
  variant,
  ...props
}: LandingPageCardProps) => {
  switch (variant) {
    case 'grid':
      return <GridCard {...props} />;
    case 'mini':
      return <MiniCard {...props} />;
    case 'micro':
    case 'table':
      return (
        <ObTypography color='negative'>
          Integration {variant.toLocaleUpperCase()} Not Implemented
        </ObTypography>
      );
  }
};

const GridCard = ({
  obrn,
  pageTitle,
  url,
  landingPageType,
  landingPageScreenshotPublicUrl,
  onPreviewCallback,
  onCardClickedCallback,
}: LandingPageCardImplementationProps) => {
  return (
    <div className='relative'>
      <ObCardSurface
        isClickable={true}
        onClickedCallback={() => onCardClickedCallback?.(obrn)}
      >
        <div className='flex flex-col gap-2 p-3 min-h-[36px]'>
          <div className='relative overflow-hidden rounded-sm'>
            <div
              className={cx(
                'w-full bg-no-repeat bg-top bg-cover h-[188px] rounded ',
                landingPageScreenshotPublicUrl == null && ' opacity-60'
              )}
              style={{
                backgroundImage: `url(${
                  landingPageScreenshotPublicUrl != null
                    ? landingPageScreenshotPublicUrl
                    : placeholder
                })`,
              }}
            />
            {landingPageScreenshotPublicUrl == null && (
              <div className='absolute bottom-0 right-0 '>
                <div className='flex flex-row gap-2 justify-center p-2'>
                  <ObLoadingSpinner
                    size={16}
                    includeBrand={false}
                  />
                  <ObTypography
                    variant='subtitle2'
                    color='secondary'
                  >
                    Generating Thumbnail
                  </ObTypography>
                </div>
              </div>
            )}
          </div>
          <div className='ml-[-24px] left-[-8px]'>
            <PageTitle
              title={pageTitle}
              landingPageType={landingPageType}
            />
          </div>
          <UrlFormatted url={url} />
          <div className='flex flex-row justify-end items-center gap-3 flex-1 pointer-events-auto'>
            <ObButton
              variant='ghost'
              iconLeft='externalLink'
              size='medium'
              className=''
              buttonType='icon'
              onClick={(e) => {
                e.stopPropagation();
                onPreviewCallback?.();
              }}
              label='Preview'
            />
          </div>
        </div>
      </ObCardSurface>
    </div>
  );
};

const MiniCard = ({
  pageTitle,
  url,
  landingPageType,
  onPreviewCallback,
}: LandingPageCardImplementationProps) => {
  return (
    <ObCardSurface isClickable={true}>
      <div className='flex flex-col gap-2 p-3 min-h-[36px]'>
        <div className='ml-[-24px] left-[-8px]'>
          <PageTitle
            title={pageTitle}
            landingPageType={landingPageType}
          />
        </div>
        <UrlFormatted url={url} />
        <div className='flex flex-row justify-between items-center gap-3'>
          <ObButton
            variant='secondary'
            size='small'
            fullWidth='always'
            onClick={(e) => {
              e.stopPropagation();
              onPreviewCallback?.();
            }}
            label='Preview'
          />
        </div>
      </div>
    </ObCardSurface>
  );
};
