import {
  ObButton,
  ObImageCheckboxContainer,
  ObLoadingSpinner,
  ObThinkingButton,
  ObTypography,
  useDialogService,
} from '@outbound/design-system';
import { AssetScanResource, SelectedAssetResource } from '@outbound/types';
import { AnimatePresence, motion } from 'motion/react';
import { FC, useEffect, useState } from 'react';
import {
  ASSET_NO_LOGO_DIALOG_BODY,
  ASSET_NO_LOGO_DIALOG_CANCEL_BUTTON_LABEL,
  ASSET_NO_LOGO_DIALOG_CONTINUE_BUTTON_LABEL,
  ASSET_NO_LOGO_DIALOG_TITLE,
  ASSET_RESULTS_HEADER,
  ASSET_RESULTS_SUBHEADER,
} from '../brand-onboarding-wizard.copy';

/**
 * Props for the WebsiteAssetResults component
 */
export type WebsiteAssetResultsProps = {
  availableAssets: Array<AssetScanResource>;
  onSubmitCallback: (selectedAssets: AssetScanResource[]) => Promise<any>;
  onAssetSelected?: (asset: AssetScanResource) => any;
  onAssetDeselected?: (asset: AssetScanResource) => any;
  initialAssets?: SelectedAssetResource[];
  isAssetScrapeComplete: boolean;
  isApiLoading: boolean;
};

export type AssetScanResourceWithCategory = AssetScanResource & {
  classification: 'CONTENT' | 'LOGO';
};

/**
 * Displays and allows the user to select assets from the website scan.
 * @param param0
 * @returns
 */
export const WebsiteAssetResults: FC<WebsiteAssetResultsProps> = ({
  availableAssets,
  onSubmitCallback,
  onAssetSelected,
  onAssetDeselected,
  initialAssets,
  isApiLoading,
  isAssetScrapeComplete,
}: WebsiteAssetResultsProps) => {
  /**
   * Tracks the currently selected assets.
   */
  const [selectedAssets, setSelectedAssets] = useState<
    AssetScanResourceWithCategory[]
  >([]);

  const { openConfirmation } = useDialogService();

  useEffect(() => {
    if (initialAssets?.length && availableAssets?.length) {
      /**
       * Since the user has already selected some assets we will rebuild the page
       * from their previous selections.
       */
      setSelectedAssets(
        initialAssets.flatMap((initialSelectedAsset) => {
          const assetScanResource = availableAssets.find(
            (availableAsset) => availableAsset.id === initialSelectedAsset.id
          );
          /**
           * Combine the selected asset's categorization with the asset scan resource
           * so we can repopulate the page with the user's previous selections.
           *
           * If for some reason there is a selected asset that is not in the available assets (Which should never happen)
           * we will return an empty array which will cause the flatMap function to effectively ignore it.
           */
          return assetScanResource
            ? [
                {
                  ...initialSelectedAsset,
                  ...assetScanResource,
                  classification: 'LOGO',
                },
              ]
            : [];
        })
      );
    }
  }, [initialAssets, availableAssets]);

  /**
   * Called when the user deselects an asset.
   * Provides a way for the parent component to be notified of the deselection in real time
   * @param asset
   */
  const handleAssetDeselected = (asset: AssetScanResource) => {
    if (onAssetDeselected) {
      onAssetDeselected(asset);
    }
  };

  /**
   * Called when the user selects an asset.
   * Provides a way for the parent component to be notified of the selection in real time
   * @param asset
   */
  const handleAssetSelected = (asset: AssetScanResource) => {
    if (onAssetSelected) {
      onAssetSelected(asset);
    }
  };

  /**
   * Called when the user clicks the "Continue" button.
   * Returns the selected assets to the parent component.
   * @param selectedAssets
   */
  const onAssetsImportsFinalized = async () => {
    if (onSubmitCallback) {
      await onSubmitCallback(selectedAssets);
    }
  };

  const onCheckboxChange = (checked: boolean, image: AssetScanResource) => {
    if (checked) {
      handleAssetSelected(image);
      setSelectedAssets([
        ...selectedAssets,
        { ...image, classification: 'LOGO' },
      ]);
    } else {
      handleAssetDeselected(image);
      setSelectedAssets(
        selectedAssets.filter((a: AssetScanResource) => a.id !== image.id)
      );
    }
  };

  if (isApiLoading) {
    return <div data-testid='empty-content-on-load'></div>;
  } else if (!isAssetScrapeComplete) {
    return (
      <div className='flex items-center justify-center'>
        <ObLoadingSpinner includeBrand={true} />
      </div>
    );
  } else if (availableAssets.length === 0) {
    console.log(
      'No assets found! In the future we should skip asset selection step.'
    );
  }

  return (
    <AnimatePresence>
      <div
        data-testid='asset-management-step'
        className='flex flex-col gap-8 items-center justify-center p-8 '
      >
        <div className='flex flex-col justify-center items-center'>
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.65, delay: 0.2 }}
          >
            <ObTypography variant='h1'>{ASSET_RESULTS_HEADER}</ObTypography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.5 }}
          >
            <ObTypography
              variant='h3'
              color='secondary'
              className='text-center'
            >
              {ASSET_RESULTS_SUBHEADER}
            </ObTypography>
          </motion.div>
        </div>
        <div className='gap-4 flex flex-col h-full'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, delay: 0.65 }}
          >
            <section className='gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
              {availableAssets.map((image, index) => {
                const initialAsset = initialAssets?.find(
                  (asset) => asset.id === image.id
                );

                const initialChecked = initialAssets?.length
                  ? initialAssets.some((asset) => asset.id === image.id)
                  : false;

                return (
                  <ObImageCheckboxContainer
                    id={`image-checkbox-${index}`}
                    label={`Image ${index}`}
                    key={image.publicUrl}
                    imageUrl={image.publicUrl}
                    onCheckboxChange={(checked) =>
                      onCheckboxChange(checked, image)
                    }
                    initialChecked={initialChecked}
                    classification={initialAsset?.classification}
                  />
                );
              })}
            </section>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75, delay: 0.9 }}
          className='flex flex-row gap-4 flex-1 w-full md:w-auto justify-center flex-wrap md:flex-nowrap'
        >
          <ObButton
            className=' flex-shrink-0 w-full'
            label='My Logo is not Here'
            size='xlarge'
            variant='outline'
            disabled={selectedAssets.length > 0}
            onClick={() => {
              openConfirmation({
                title: ASSET_NO_LOGO_DIALOG_TITLE,
                body: ASSET_NO_LOGO_DIALOG_BODY,
                affirmativeActionButtonLabel:
                  ASSET_NO_LOGO_DIALOG_CONTINUE_BUTTON_LABEL,

                dismissiveActionButtonLabel:
                  ASSET_NO_LOGO_DIALOG_CANCEL_BUTTON_LABEL,
              }).then(() => {
                onAssetsImportsFinalized();
              });
            }}
          />
          <ObThinkingButton
            className='flex-shrink-0'
            variant='primary'
            size='xlarge'
            label='Continue'
            fullWidth='always'
            onClickCallback={onAssetsImportsFinalized}
            type='submit'
          />
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
