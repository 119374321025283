import { DescriptionDetails } from './components/description-detail';
import { DescriptionTerm } from './components/description-term';

export interface Props {
  term: string;
  detail: string;
  testId: string;
}

export const DescriptionItem = ({ term, detail, testId }: Props) => {
  return (
    <>
      <DescriptionTerm testId={testId}>{term}</DescriptionTerm>
      <DescriptionDetails testId={testId}>{detail}</DescriptionDetails>
    </>
  );
};
