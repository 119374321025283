import { UpdateQualificationStatusEvent } from '@outbound/design-system';
import t from 'src/pages/dashboard/leads/leads.i18n.json';
import EventActor from 'src/state/mobx-experiment/leads/domain/lead-events/event-actor';
import { LeadEventQualificationStatusUpdated } from 'src/state/mobx-experiment/leads/domain/lead-events/lead-event-qualification-status-updated';

interface EventQualificationStatusUpdatedProps {
  event: LeadEventQualificationStatusUpdated;
}

export const EventQualificationStatusUpdated = ({
  event,
}: EventQualificationStatusUpdatedProps) => {
  const context = event.context;
  const actor: EventActor = event.getActor();
  return (
    <UpdateQualificationStatusEvent
      fromQualificationStatus={context.fromQualificationStatus}
      toQualificationStatus={context.toQualificationStatus}
      localization={{
        qualifiedLabel: t.KANBAN_BOARD.QUALIFIED_COLUMN_NAME,
        unqualifiedLabel: t.KANBAN_BOARD.NOT_QUALIFIED_COLUMN_NAME,
        workingLabel: t.KANBAN_BOARD.WORKING_COLUMN_NAME,
        newLabel: t.KANBAN_BOARD.NEW_COLUMN_NAME,
        nurturingLabel: t.KANBAN_BOARD.NURTURE_COLUMN_NAME,
      }}
      title={''}
      actorName={actor.name}
      actorImageUrl={actor.avatarImageUrl ?? ''}
      eventIcon={'search'}
      isLastFetchedEvent={false}
      timestamp={event.timestamp}
    />
  );
};
