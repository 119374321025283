import { Active, Over, UniqueIdentifier } from '@dnd-kit/core';
import { SortableData, hasSortableData } from '@dnd-kit/sortable';

export const itemWasSorted = (
  dropContainerItemWasDroppedOn: Over | null,
  itemDroppedWhenDragEnded: Active
) => {
  return (
    dropContainerItemWasDroppedOn &&
    hasSortableData(itemDroppedWhenDragEnded) &&
    hasSortableData(dropContainerItemWasDroppedOn)
  );
};

export const determinePlacementOfDroppedDraggableItemAfterSorting = (
  itemDroppedWhenDragEnded: Active,
  dropContainerItemWasDroppedOn: Over
): {
  directlyUnderUniqueIdentifier: 'TOP_OF_LIST' | UniqueIdentifier;
  directlyAboveUniqueIdentifier: 'BOTTOM_OF_LIST' | UniqueIdentifier;
} => {
  if (
    dropContainerItemWasDroppedOn &&
    hasSortableData(itemDroppedWhenDragEnded) &&
    hasSortableData(dropContainerItemWasDroppedOn)
  ) {
    const droppableSortableContainer =
      dropContainerItemWasDroppedOn.data.current.sortable;

    const draggableItemIdsBeforeDrag = droppableSortableContainer.items;

    const invisibleItemArrayCloneUsedForSortPositionCalculation = [
      ...draggableItemIdsBeforeDrag,
    ];

    removeDraggedItemIdFromOriginalPositionInInvisibleCalculationArray(
      itemDroppedWhenDragEnded,
      invisibleItemArrayCloneUsedForSortPositionCalculation
    );

    addDraggedItemIdBackToInvisibleCalculationArrayAtNewPosition(
      itemDroppedWhenDragEnded,
      dropContainerItemWasDroppedOn,
      invisibleItemArrayCloneUsedForSortPositionCalculation
    );

    const calculatedNextIndexOfDroppedLead =
      determineNewPositionOfDroppedItemIdInArray(
        itemDroppedWhenDragEnded,
        invisibleItemArrayCloneUsedForSortPositionCalculation
      );

    const directlyUnderUniqueIdentifier: string | 'TOP_OF_LIST' =
      determineTheIdThatTheDroppedItemIdIsDirectlyUnder(
        calculatedNextIndexOfDroppedLead,
        invisibleItemArrayCloneUsedForSortPositionCalculation
      );

    const directlyAboveUniqueIdentifier: string | 'BOTTOM_OF_LIST' =
      determineTheIdThatTheDroppedItemIdIsDirectlyAbove(
        calculatedNextIndexOfDroppedLead,
        invisibleItemArrayCloneUsedForSortPositionCalculation
      );

    return {
      directlyUnderUniqueIdentifier,
      directlyAboveUniqueIdentifier,
    };
  } else {
    throw new Error('Item was not sorted');
  }
};

const getItemSortableContextFromDroppedItem = (
  item: Active
): SortableData['sortable'] => {
  if (hasSortableData(item)) {
    return item.data.current.sortable;
  } else {
    throw new Error('Item does not have sortable data');
  }
};

const getContainerSortableContextFromContainerItemWasDroppedOn = (
  over: Over
): SortableData['sortable'] => {
  if (hasSortableData(over)) {
    return over.data.current.sortable;
  } else {
    throw new Error('Item does not have sortable data');
  }
};

const removeDraggedItemIdFromOriginalPositionInInvisibleCalculationArray = (
  item: Active,
  draggableItemIdArray: Array<UniqueIdentifier>
) => {
  const itemBeingSorted = getItemSortableContextFromDroppedItem(item);
  draggableItemIdArray.splice(itemBeingSorted.index, 1);
};

const addDraggedItemIdBackToInvisibleCalculationArrayAtNewPosition = (
  item: Active,
  over: Over | null,
  draggableItemIdArray: Array<UniqueIdentifier>
) => {
  if (!over) {
    throw new Error('Not Over a Drop Container');
  }
  const overSortable =
    getContainerSortableContextFromContainerItemWasDroppedOn(over);
  draggableItemIdArray.splice(overSortable.index, 0, item.id);
};

const determineNewPositionOfDroppedItemIdInArray = (
  item: Active,
  items: Array<UniqueIdentifier>
): number => {
  return items.indexOf(item.id as string);
};

const determineTheIdThatTheDroppedItemIdIsDirectlyUnder = (
  nextIndexOfDroppedLead: number,
  listOfLeadIdsInNextOrder: Array<UniqueIdentifier>
) => {
  const INDEX_OF_FIRST_ITEM_IN_LIST = 0;
  if (nextIndexOfDroppedLead === INDEX_OF_FIRST_ITEM_IN_LIST) {
    return 'TOP_OF_LIST';
  } else {
    return listOfLeadIdsInNextOrder[nextIndexOfDroppedLead - 1].toString();
  }
};

const determineTheIdThatTheDroppedItemIdIsDirectlyAbove = (
  nextIndexOfDroppedLead: number,
  listOfLeadIdsInNextOrder: Array<UniqueIdentifier>
) => {
  const INDEX_OF_LAST_ITEM_IN_LIST = listOfLeadIdsInNextOrder.length - 1;
  if (nextIndexOfDroppedLead === INDEX_OF_LAST_ITEM_IN_LIST) {
    return 'BOTTOM_OF_LIST';
  } else {
    return listOfLeadIdsInNextOrder[nextIndexOfDroppedLead + 1].toString();
  }
};

export const calculateUniqueIdentifierArrayAfterItemIsDropped = (
  itemsBeforeDrag: UniqueIdentifier[],
  itemDroppedWhenDragEnded: Active,
  dropContainerItemWasDroppedOn: Over | null
): UniqueIdentifier[] => {
  const invisibleItemArrayCloneUsedForSortPositionCalculation = [
    ...itemsBeforeDrag,
  ];

  removeDraggedItemIdFromOriginalPositionInInvisibleCalculationArray(
    itemDroppedWhenDragEnded,
    invisibleItemArrayCloneUsedForSortPositionCalculation
  );

  addDraggedItemIdBackToInvisibleCalculationArrayAtNewPosition(
    itemDroppedWhenDragEnded,
    dropContainerItemWasDroppedOn,
    invisibleItemArrayCloneUsedForSortPositionCalculation
  );

  return invisibleItemArrayCloneUsedForSortPositionCalculation;
};
