import { cva, cx } from 'class-variance-authority';
import { ObInputCaption } from '../../elements/ob-input-caption/ob-input-caption';
import { ObInputLabel } from '../../elements/ob-input-label/ob-input-label';
import { ObTypography } from '../../elements/ob-typography/ob-typography';

export interface ObFieldProps {
  /**
   * Should be unique to the form, used to associate the label with the input field
   * and instruction text
   */
  inputId: string;
  /**
   * The label for the input field
   */
  label: string;
  /**
   * Additional instruction text to help the user understand what to input.
   * Appears below the input as a caption for fields inside of a field group and below the label
   * for fields outside of a field group.
   */
  helperText?: string;
  /**
   * Error text to display when the input is invalid
   */
  errorText?: string;
  /**
   * When required is set to true, the input field will be marked as required with an asterisk
   */
  isRequired?: boolean;
  children?: React.ReactNode;
  /**
   * Describes the context in which the field is being used. This will determine the layout of the field
   * Field In Group
   *  - Used when the field is part of a group of fields such as an address
   * Stand Alone Field
   * - Used when the field is not part of a group of fields but is in a form with other fields
   * Full Page Field
   * - Used when the field is the only field on the page in one question per page forms
   */
  variant: 'field-in-group' | 'stand-alone-field' | 'full-page-field';

  /**
   * When true this will display the input field inline with the label
   * Only used on toggle switches at the moment
   */
  displayChildrenInline?: boolean;

  setIdOnLabel?: boolean;
}
export const ObField = ({
  children,
  inputId,
  label,
  helperText,
  variant,
  errorText,
  isRequired = false,
  displayChildrenInline = false,
  setIdOnLabel = false,
}: ObFieldProps) => {
  const fieldAnnotationStyles = cva('', {
    variants: {
      variant: {
        'field-in-group': 'mb-2',
        'full-page-field': 'mb-3',
        'stand-alone-field': 'mb-2',
      },
    },
  });
  const getInputLabelSize = (variant: ObFieldProps['variant']) => {
    switch (variant) {
      case 'field-in-group':
        return 'small';
      case 'stand-alone-field':
        return 'medium';
      case 'full-page-field':
        return 'large';
    }
  };

  return (
    <div className='flex flex-col flex-1'>
      <div className='flex flex-row justify-between items-center gap-2'>
        <div className={fieldAnnotationStyles({ variant })}>
          <ObInputLabel
            isRequired={isRequired}
            label={label}
            size={getInputLabelSize(variant)}
            id={inputId}
            setIdOnLabel={setIdOnLabel}
          />
          {/* We will never display under-label helper text when the field is in a group */}
          {variant != 'field-in-group' && (
            <ObTypography
              id={`${inputId}__helper-text`}
              variant={'full-page-field' === variant ? 'h4' : 'body2'}
              color='secondary'
              as='span'
            >
              {helperText}
            </ObTypography>
          )}
        </div>
        {displayChildrenInline && <div>{children}</div>}
      </div>
      <div className='flex flex-col gap-2 '>
        {/* INPUT RENDERED HERE */}
        {!displayChildrenInline && children}
        {/* Captions will only be used for errors */}

        <div
          className={cx(
            'opacity-0 transition-opacity ease-in-out duration-200',
            {
              'opacity-100': errorText,
            }
          )}
        >
          <ObInputCaption
            type={'error'}
            message={errorText ?? ''}
          />
        </div>
      </div>
    </div>
  );
};
