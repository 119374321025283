import { createAtom, IAtom } from 'mobx';

const promiseAtomCache = new WeakMap<Promise<unknown>, IAtom>();

/**
 * Experimental Solution to DEV-337
 * Wraps a function that *might* throw a promise (for Suspense).
 * - If a promise is thrown, we ensure a stable MobX atom is used for reactivity.
 * - That atom is re-observed and re-triggered once the promise resolves or rejects,
 *   causing the computed to re-run.
 *
 * See also:
 * https://github.com/mobxjs/mobx/issues/1646
 */
export function catchSuspense<T>(fn: () => T): T {
  try {
    return fn();
  } catch (error) {
    if (error instanceof Promise) {
      console.log(
        'Mobx Suspense: Caught a promise, will re-throw after observing it'
      );
      // Reuse an atom for this specific Promise so we don't create a new one each time.
      let atom = promiseAtomCache.get(error);
      if (!atom) {
        atom = createAtom('suspense-atom');
        promiseAtomCache.set(error, atom);
        // Once the promise is resolved or rejected, we mark the atom changed.
        // That signals MobX to re-run the computed or reaction that observed it.
        error.finally(() => {
          console.log('Mobx Suspense: Promise resolved or rejected');
          atom!.reportChanged();
        });
      }
      // Mark that we're observing this atom, so MobX knows we're dependent on it.
      atom.reportObserved();
    }
    throw error;
  }
}
