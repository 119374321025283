import { ObDivider } from '@outbound/design-system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRootStore } from 'src/state/mobx-experiment/use-root-store';
import { FullPageErrorMessage } from '../../campaigns/components/full-page-error-message';
import t from '../leads.i18n.json';
import { ContentHeader } from './components/content-header/content-header';
import { LeadDetailHeader } from './components/lead-detail-header/lead-detail-header';
import { LeadDetailsAside } from './components/lead-details-aside/lead-details-aside';
import { useLeadDetailAsideDrawer } from './components/lead-details-aside/mobile-lead-detail-aside-drawer/use-mobile-lead-detail-aside-drawer';
import { TimelineSection } from './components/timeline-section/timeline-section';
import { CONTENT_HEADER_HEIGHT_PX } from './dimensions';

export const LeadDetailPage = observer(() => {
  const { leadId, workspaceSlug } = useParams<{
    leadId: string;
    workspaceSlug: string;
  }>();
  const { leads } = useRootStore();
  const { openLeadDetailAsideInDrawer } = useLeadDetailAsideDrawer();

  if (!workspaceSlug || !leadId) {
    return <></>;
  }

  const lead = leads.getById(leadId);

  if (lead == null) {
    return <NotFoundPage />;
  }

  const [
    isDetailPanelOpenForDesktopLayout,
    setIsDetailPanelOpenForDesktopLayout,
  ] = useState<boolean>(true);

  return (
    <PageContent>
      <ContentHeader
        onOpenLeadDetailAsideDrawerOnMobile={() => {
          openLeadDetailAsideInDrawer(lead);
        }}
        onToggleLeadDetailAsideOnDesktop={() => {
          setIsDetailPanelOpenForDesktopLayout((state) => {
            return !state;
          });
        }}
      />
      <ContentBody>
        <ScrollableMainContent>
          <PositionAndLayoutForMainContent>
            <LeadDetailHeader lead={lead} />
            <ObDivider />
            <TimelineSection lead={lead} />
          </PositionAndLayoutForMainContent>
        </ScrollableMainContent>
        <LeadDetailsAside
          lead={lead}
          isOpen={isDetailPanelOpenForDesktopLayout}
          onCloseCallback={() => {
            //Future Use
          }}
        />
      </ContentBody>
    </PageContent>
  );
});

const PageContent = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex flex-col max-h-screen overflow-hidden '>
      {children}
    </div>
  );
};

const ContentBody = ({ children }: { children: ReactNode }) => {
  return <div className='flex flex-row overflow-hidden'>{children}</div>;
};

const ScrollableMainContent = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className='flex-1  flex flex-col overflow-y-scroll pb-20 '
      style={{
        scrollbarGutter: 'auto',
        scrollBehavior: 'smooth',
        minHeight: `calc(vh-100 - ${CONTENT_HEADER_HEIGHT_PX}px)`,
      }}
    >
      {children}
    </div>
  );
};

const PositionAndLayoutForMainContent = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <div className='self-stretch'>
      <div className=' flex flex-col gap-5 p-4 max-w-3xl mx-auto'>
        {children}
      </div>
    </div>
  );
};

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <FullPageErrorMessage
      heading={t.NOT_FOUND.HEADING}
      message={t.NOT_FOUND.DESCRIPTION}
      actionLabel={t.NOT_FOUND.CTA}
      actionOnClick={function () {
        navigate('../..');
      }}
    />
  );
};
