import { ObLink } from '@outbound/design-system/src/components/elements/ob-link/ob-link';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const BackToLink = ({
  defaultNavigateTo,
  defaultNavigateToLabel,
}: {
  defaultNavigateTo: string;
  defaultNavigateToLabel: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnToUriEncoded = searchParams.get('return-to');
  const returnTo = returnToUriEncoded
    ? decodeURIComponent(returnToUriEncoded)
    : null;

  const returnToLabelUriEncoded = searchParams.get('return-to-label');
  const returnToLabel = returnToLabelUriEncoded
    ? decodeURIComponent(returnToLabelUriEncoded)
    : null;

  if (returnTo) {
    return (
      <ObLink
        variant='primary'
        label={returnToLabel ? returnToLabel : 'Back'}
        iconLeft='chevronLeft'
        iconSize='small'
        onClick={() => navigate(returnTo)}
      />
    );
  } else {
    return (
      <ObLink
        variant={'primary'}
        label={defaultNavigateToLabel}
        iconLeft='chevronLeft'
        iconSize='small'
        onClick={() => navigate(defaultNavigateTo)}
      />
    );
  }
};
