import { ClientDelta } from '@outbound/types';
import { AxiosInstance } from 'axios';
import { ObjectDeltaMap } from './object-delta-map';
import { OptionalClientDelta } from './optional-client-delta';

abstract class ServerMutationHandler {
  protected axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  abstract handle(objectDeltaMap: ObjectDeltaMap): Promise<void>;

  protected findDeltaByPath(
    path: string,
    deltas: ClientDelta[]
  ): OptionalClientDelta {
    return (
      deltas.find((delta) => delta.path === path) ?? 'ATTRIBUTE_HAS_NO_CHANGES'
    );
  }

  protected attributeHasBeenUpdated(
    possibleClientDelta: OptionalClientDelta
  ): possibleClientDelta is ClientDelta {
    return possibleClientDelta !== 'ATTRIBUTE_HAS_NO_CHANGES';
  }
}

export default ServerMutationHandler;
