'use client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import {
  ObButton,
  ObDivider,
  ObThinkingButton,
  ObTypography,
  useObFormRendererContext,
} from '../../..';
import { ObButtonSize } from '../../elements/ob-button/ob-button';
import { sanitizeInitialValues } from './helpers/ob-form-initial-values-sanitizer';
import { buildSubmitOutput } from './helpers/ob-form-submit-output-builder';
import FormRendererField from './ob-form-renderer-field';
import { buildYupValidationSchemaForForm } from './ob-form-renderer-validation';
import {
  CustomInputRefsProps,
  FormDefinition,
  FormFieldDefinition,
  FormFieldType,
} from './ob-form-renderer.types';
import {
  ObFormServiceProvider,
  useFormService,
} from './ob-form-service-provider';

export interface ObFormRendererProps {
  className?: string;

  /**
   * The schema definition for the form. This form definition describes the structure of the form
   * including the fields, their types, and any validation rules and conditional logic.
   */
  formDefinition: FormDefinition;
  /**
   * Indicates that the data needed for the form is still loading and
   * the form is not ready for display yet.
   *
   * The intent of this property is show a loading skeleton version of the form
   * while the data is being fetched.
   *
   * Implementation of this is a work in progress. Not all fields support it yet.
   * And we still need need to implement how the form state will be managed once this
   * flips from false to true.
   */
  isInitialDefaultValuesLoading?: boolean;

  /**
   * The initial values of the form
   */
  defaultValues?: any;

  onSubmitCallback: (data: any) => Promise<any>;
  onCancelCallback?: () => Promise<any>;

  submitButtonLabel?: string;
  cancelButtonLabel?: string;

  submitButtonSize?: ObButtonSize;
  submitButtonFullWidth?: 'always' | 'never' | 'mobile' | 'desktop';
  submitButtonTopRenderFn?: any;
  disableAfterSuccessfulSubmit?: boolean;
  /**
   * Optional callback function the parent can pass in order to be notified
   * if the form is dirty or not.
   * @param isDirty
   * @returns
   */
  onFormDirtyChangeCallback?: (isDirty: boolean) => void;

  /**
   * Hides the submit button from the form. Assumes you will handle submission
   * from a different part of the UI using the ob-form-renderer context
   */
  hideSubmitButton?: boolean;
}

/**
 * Utility to build default values for a form from its definition;
 * For forms that are always blank (New Object, Signup Form etc) this saves
 * the developer from having to provide a default values object
 * @param formDefinition
 * @returns
 */
export const buildDefaultValuesForForm = (formDefinition: FormDefinition) => {
  let defaultValues: any = {};
  formDefinition?.sections?.forEach((s) => {
    s.fields.forEach((f) => {
      switch (f.type) {
        case FormFieldType.IMAGES:
          defaultValues[f.id] = [];
          break;
        case FormFieldType.CHIPS:
          defaultValues[f.id] = [];
          break;
        case FormFieldType.COMBO_BOX:
          defaultValues[f.id] = [];
          break;
        case FormFieldType.ADDRESS:
          defaultValues[f.id] = {
            street1: '',
            street2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          };
          break;
        case FormFieldType.SELECT:
          defaultValues[f.id] = '';
          break;
        case FormFieldType.TEXT:
          defaultValues[f.id] = '';
          break;
        case FormFieldType.TEXT_AREA:
          defaultValues[f.id] = '';
          break;
        case FormFieldType.TOGGLE_SWITCH:
          defaultValues[f.id] = false;
          break;
        case FormFieldType.NUMBER:
          defaultValues[f.id] = 0;
          break;
        case FormFieldType.CHECKBOX_GROUP_CARDS:
          defaultValues[f.id] = [];
          break;
        case FormFieldType.COLORS: {
          defaultValues[f.id] = [];
          break;
        }
        case FormFieldType.RULE_BUILDER: {
          defaultValues[f.id] = {
            nodeId: 'root',
            operator: 'AND',
            children: [],
          };
          break;
        }
        case FormFieldType.BUTTON_GROUP_RADIO: {
          const defaultValue = f.defaultValue;
          if (defaultValue) {
            defaultValues[f.id] = defaultValue;
          } else {
            defaultValues[f.id] = undefined;
          }
          break;
        }
        case FormFieldType.CURRENCY: {
          defaultValues[f.id] = 0;
          break;
        }
        default:
          defaultValues[f.id] = '';
          break;
      }
    });
  });
  return defaultValues;
};

const FormRenderer: FC<ObFormRendererProps> = ({
  className,
  submitButtonFullWidth = 'never',
  submitButtonSize = 'large',
  formDefinition,
  defaultValues,
  onSubmitCallback,
  onCancelCallback,
  submitButtonLabel,
  cancelButtonLabel,
  submitButtonTopRenderFn = () => null,
  disableAfterSuccessfulSubmit = false,
  hideSubmitButton = false,
  isInitialDefaultValuesLoading: isInitialDataLoading = false,
  onFormDirtyChangeCallback,
}: ObFormRendererProps) => {
  const formService = useFormService();

  /**
   * The form context allows us to interact with a from outside of the form component.
   * We specifically use this to register the form's submit function with the form context
   * so that we can programmatically submit the form from outside of the form component.
   */
  const { registerForm, unregisterForm } = useObFormRendererContext();

  const [customInputsRefs, setCustomInputsRefs] =
    useState<CustomInputRefsProps>({});

  /**
   * Indicates if the form is currently disabled or not.
   * This property is passed to each field so that each field can disable itself
   */
  const [isFormDisabled, setIsFormDisabled] = useState(false); // Add state variable for submission status

  /**
   * Tracks the state of whether a field should be hidden or not based on the field level
   * conditional logic
   */
  const [conditionallyHideFieldsMap, setConditionallyHideFieldsMap] = useState<{
    [key: string]: any;
  }>();

  const processPropProvidedDefaultValues = useCallback(
    (propProvidedDefaultValues: any) => {
      if (propProvidedDefaultValues != null) {
        /**
         * Since the user provided default values we will sanitize what they gave us to make sure the values work
         * for the form renderer
         */

        return sanitizeInitialValues(
          formDefinition,
          propProvidedDefaultValues,
          customInputsRefs
        );
      } else {
        /**
         * Since the user did not provide default values we will build the default values for them.
         * These will be different based on the type of field, for text fields this will be an empty string whereas
         * an array based field would have an empty array.
         */
        return buildDefaultValuesForForm(formDefinition);
      }
    },
    [customInputsRefs, formDefinition]
  );

  const formDefaultValues = useMemo(() => {
    return processPropProvidedDefaultValues(defaultValues);
  }, [defaultValues, processPropProvidedDefaultValues]);

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { errors, isDirty, defaultValues: formCurrentDefaultValues },
  } = useForm<any>({
    defaultValues: formDefaultValues,
    values: defaultValues,
    shouldUseNativeValidation: false,
    resetOptions: {
      /** When a new value is received from an External source we will not override the user changes  */
      keepDirtyValues: true,
    },
    resolver: async (data, context, options) => {
      // you can debug your validation schema here
      const submittedValues = await yupResolver(
        buildYupValidationSchemaForForm({
          formDefinition,
          // Using data instead of formService.getValues() to get the latest values
          formValues: data,
          customInputsRefs,
        })
      )(data, context, options);
      return submittedValues;
    },
  });

  type FieldHiddenState = { [key: string]: boolean };

  /**
   * Evaluates if the given fields in the given form definition should be hidden or not based on the current values.
   * @param form
   * @param values
   * @returns
   */
  const evaluateIfFieldsShouldBeHidden = useCallback(
    (form: FormDefinition, values: any): FieldHiddenState => {
      const hiddenFieldState: FieldHiddenState = {};
      form.sections?.forEach((s) => {
        s?.fields?.forEach((field: FormFieldDefinition) => {
          hiddenFieldState[field.id] =
            field?.conditionallyHideFieldFunction?.(values) ?? false;
        });
      });
      return hiddenFieldState;
    },
    []
  );

  /**
   * Each time a field value is updated we will re-evaluate if the fields should be hidden or not
   */
  useEffect(() => {
    const subscription = watch((_value, { name: _name, type }) => {
      if (type != null) {
        const values = getValues();
        const hiddenState = evaluateIfFieldsShouldBeHidden(
          formDefinition,
          values
        );
        setConditionallyHideFieldsMap(hiddenState);
      }
    });
    return () => subscription.unsubscribe();
  }, [
    evaluateIfFieldsShouldBeHidden,
    formDefinition,
    getValues,
    watch,
    setConditionallyHideFieldsMap,
  ]);

  /**
   * Triggers the Conditional Hide Logic when the form is first rendered
   * ( When the default values given to the form are updated )
   */
  useEffect(() => {
    const values = getValues();
    const hiddenState = evaluateIfFieldsShouldBeHidden(formDefinition, values);
    setConditionallyHideFieldsMap(hiddenState);
  }, [
    evaluateIfFieldsShouldBeHidden,
    formCurrentDefaultValues,
    formDefinition,
    getValues,
  ]);

  const onValidated = useCallback(
    async (values: any) => {
      setIsFormDisabled(true); // Set submission status to true
      let valuesToSubmit: any = [];
      formDefinition.sections?.forEach((s) => {
        s?.fields?.forEach((field: FormFieldDefinition) => {
          // Skip hidden values from submit results
          if (field?.conditionallyHideFieldFunction?.(values)) {
            return;
          }
          valuesToSubmit = {
            ...valuesToSubmit,
            [field.id]: values[field.id],
          };
        });
      });

      const parsedSubmittedValues = buildSubmitOutput(
        valuesToSubmit,
        customInputsRefs
      );
      let isErrored = false;
      let submissionError: any;
      await onSubmitCallback(parsedSubmittedValues)
        .then(() => {
          if (!disableAfterSuccessfulSubmit) {
            setIsFormDisabled(false); // Set submission status to false
            setTimeout(() => {
              reset(getValues());
            }, 300);
          }
        })
        .catch((error) => {
          submissionError = error;
          isErrored = true;
          setIsFormDisabled(false); // Set submission status to false
        });

      if (isErrored) {
        return Promise.reject(submissionError);
      } else {
        return Promise.resolve();
      }
    },
    [
      customInputsRefs,
      disableAfterSuccessfulSubmit,
      formDefinition.sections,
      getValues,
      onSubmitCallback,
      reset,
    ]
  );

  const onError: SubmitErrorHandler<any> = (errors: any) => {
    setIsFormDisabled(false);

    return Promise.reject(errors);
  };

  useEffect(() => {
    onFormDirtyChangeCallback?.(isDirty);
  }, [isDirty, onFormDirtyChangeCallback]);

  /**
   * Register and deregister the form's submit function with the react form context
   */
  useEffect(() => {
    // Register the form's submit function
    registerForm(
      formDefinition.id,
      async () => {
        return handleSubmit(onValidated, onError)();
      },
      () => {
        reset();
      }
    );

    return () => {
      // Unregister on cleanup
      unregisterForm(formDefinition.id);
    };
  }, [
    formDefinition.id,
    unregisterForm,
    handleSubmit,
    onValidated,
    registerForm,
    reset,
    defaultValues,
    processPropProvidedDefaultValues,
  ]);

  const submitButtonTopComponent = useMemo(() => {
    return submitButtonTopRenderFn({ formService });
  }, [formService, submitButtonTopRenderFn]);

  return (
    <div
      className={className}
      data-testid={`form-render_${formDefinition.id}`}
    >
      <ObTypography variant='h2'>{formDefinition.heading}</ObTypography>
      <ObTypography
        variant='body1'
        color='secondary'
      >
        {formDefinition.subHeading}
      </ObTypography>
      <form
        id={formDefinition.id}
        noValidate
        autoComplete='off'
        onSubmit={(event) => {
          event.preventDefault(); // Prevent form submission or refresh
          console.log('Form submission prevented');
        }}
      >
        {formDefinition.sections.map(({ id, fields, heading }) => {
          return (
            <section
              key={id}
              className=''
            >
              <div className='mb-4'>
                {heading && (
                  <div className='flex flex-col gap-3'>
                    <ObTypography variant='h3'>{heading}</ObTypography>
                    <ObDivider className='my-3' />
                  </div>
                )}
              </div>
              <div className='flex flex-col gap-1'>
                {fields.map((field: FormFieldDefinition) => {
                  const isConditionallyHidden =
                    conditionallyHideFieldsMap?.[field.id] ?? false;
                  return (
                    <FormRendererField
                      key={field.id}
                      isLoading={isInitialDataLoading}
                      id={field.id}
                      isRequired={field.validationSchema?.isRequired}
                      label={field.label}
                      helperText={field.helperText}
                      hideLabel={field.hideLabel}
                      fieldType={field.type}
                      errors={errors}
                      control={control}
                      autofocusInput={field.autofocus}
                      readOnly={field.readOnly}
                      disablePasswordManagers={field.disablePasswordManagers}
                      setCustomInputsRefs={setCustomInputsRefs}
                      fieldTypeSettings={field.fieldTypeSettings}
                      size={field.size}
                      disabled={isFormDisabled} // Disable input fields when submitting
                      conditionallyHide={isConditionallyHidden}
                    />
                  );
                })}
              </div>
            </section>
          );
        })}

        {!hideSubmitButton && (
          <>
            {submitButtonTopComponent}

            <div className='flex gap-4 items-center justify-end pt-4'>
              {/*Optional Cancel Button can reset the form or something else*/}
              {onCancelCallback != null ? (
                <ObButton
                  variant='secondary'
                  type='button'
                  size={submitButtonSize}
                  onClick={onCancelCallback}
                  label={cancelButtonLabel ?? 'Cancel'}
                  disabled={isFormDisabled} // Disable submit button when submitting
                />
              ) : null}

              <ObThinkingButton
                className='submit-button'
                fullWidth={submitButtonFullWidth}
                size={submitButtonSize}
                onClickCallback={handleSubmit(onValidated, onError)}
                label={submitButtonLabel ?? 'Submit'}
                type='submit'
                disabled={isFormDisabled} // Disable submit button when submitting
              />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

const ObFormRenderer: FC<ObFormRendererProps> = ({
  ...props
}: ObFormRendererProps) => {
  return (
    <ObFormServiceProvider>
      <FormRenderer {...props} />
    </ObFormServiceProvider>
  );
};

export { ObFormRenderer };
