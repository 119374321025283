import { ClientDelta, LeadRatingStatus } from '@outbound/types';
import { AxiosInstance } from 'axios';
import ServerMutationHandler from '../../sync-framework/muation-handler/abstract-server-mutation-handler';
import { ObjectDeltaMap } from '../../sync-framework/muation-handler/object-delta-map';
import { OptionalClientDelta } from '../../sync-framework/muation-handler/optional-client-delta';
import Lead from '../domain/lead';

class RatingMutationHandler extends ServerMutationHandler {
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  async handle(objectDeltaMap: ObjectDeltaMap): Promise<void> {
    objectDeltaMap.forEach((deltasForLead, leadId) => {
      const rating = this.findRatingChange(deltasForLead);
      if (this.attributeHasBeenUpdated(rating)) {
        this.notifyServerOfRatingChange({
          leadId,
          rating: rating.value as LeadRatingStatus,
        });
      }
    });
  }

  private findRatingChange(deltas: ClientDelta[]): OptionalClientDelta {
    return this.findDeltaByPath(Lead.paths.rating, deltas);
  }

  private async notifyServerOfRatingChange({
    leadId,
    rating,
  }: {
    leadId: string;
    rating: LeadRatingStatus;
  }): Promise<void> {
    const updateRatingRequestBody = {
      rating,
    };
    try {
      await this.axiosInstance.post(
        `/leads/${leadId}/update-rating`,
        updateRatingRequestBody
      );
    } catch (error) {
      throw error;
    }
  }
}

export default RatingMutationHandler;
